import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody } from "reactstrap";
import ConfirmationDialog from "../../../components/Modals/ConfirmationDialog";

import formatDate from "../../../helpers/formatDate";
import useModal from "../../../hooks/useModal";
import { deleteSale } from "../../../store/dashboard/Sale/actions";

import styles from "./index.module.scss";

const DELETE_MODAL_TITLE = "Delete";
const DELETE_MODAL_DESCRIPTION = "Sale will be deleted";

const SaleDetails = ({ discount, startDate, endDate, couponId, deleteSale }) => {
  const [modalVisible, toggleModal] = useModal();

  const handleConfirm = () => {
    deleteSale();
    toggleModal();
  };
  return (
    <>
      <Card>
        <CardBody>
          <h4 className="font-size-16 mb-4">Sale details</h4>
          <div className="d-flex flex-row justify-content-between align-items-end">
            <div className="d-flex flex-row">
              <div className={styles.saleItem}>
                <div>Discount, %</div>
                <div>{`${discount}%`}</div>
              </div>
              <div className={styles.saleItem}>
                <div>Start date</div>
                <div>
                  {formatDate(startDate)}
                </div>
              </div>
              <div className={styles.saleItem}>
                <div>End date</div>
                <div>{formatDate(endDate)}</div>
              </div>
              <div className={styles.saleItem}>
                <div>Coupon Id</div>
                <div>{couponId}</div>
              </div>
            </div>
            <Button
              onClick={() => toggleModal()}
              color="danger"
              className={styles.deleteBtn}
            >
              Delete
            </Button>
          </div>
        </CardBody>
      </Card>

      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={toggleModal}
        bodyClassName={styles.deleteModalBody}
        onConfirm={handleConfirm}
        altBtnStyle
      />
    </>
  );
};

export default connect(null, { deleteSale })(SaleDetails);
