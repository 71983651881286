import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { stringify } from 'query-string';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { Button, Card, CardBody, Label, Row } from "reactstrap";
import classNames from 'classnames';

import datePickerDateFormater from "../../../../../helpers/datePickerDateFormater";
import queryParser from '../../../../../helpers/queryParser';
import { getAnalytics, getAnalyticsReset } from "../../../../../store/dashboard/DjHubAnal/actions";
import { getStartOfMonth, getStartOfYear } from '../../../../../helpers/date';

const AnalyticsHeader = ({ isLoading }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { from, to } = queryParser(location?.search);

  const [startDate, setStartDate] = useState(from ? new Date(from) : new Date());
  const [endDate, setEndDate] = useState(to ? new Date(to) : new Date());

  const isMonthToDaySelected = from === datePickerDateFormater(getStartOfMonth()) && to === datePickerDateFormater(new Date());
  const isTodaySelected = from === datePickerDateFormater(new Date()) && to === datePickerDateFormater(new Date());
  const isYearToDaySelected = from === datePickerDateFormater(getStartOfYear()) && to === datePickerDateFormater(new Date());

  const onConfirm = (start, end) => history.replace({
    pathname: location.pathname,
    search: stringify({
      from: datePickerDateFormater(start),
      to: datePickerDateFormater(end),
    })
  });

  const onChange = (dates) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);
  };


  const onButtonDateChange = (start, end) => {
    onChange([start, end]);
    onConfirm(start, end);
  }

  useEffect(() => {
    onConfirm(startDate, endDate);

    return () => {
      dispatch(getAnalyticsReset());
    };
  }, []);

  useEffect(() => {
    if (from && to) {
      dispatch(
        getAnalytics({
          startDate: from,
          endDate: to,
        })
      );
    }
  }, [dispatch, from, to]);

  return (
    <Card className={`styles.card`}>
      <CardBody>
        <Row>
          <div className="col-3">
            <Label>Start - end date</Label>
            <DatePicker
              // renderCustomHeader={}
              disabled={isLoading}
              dateFormat="dd MMM, yyyy"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              minDate={new Date("01-01-2021")}
              maxDate={new Date()}
              endDate={endDate}
              className="form-control"
              calendarClassName="customDatepicker"
              disabledKeyboardNavigation
              selectsRange
            />
          </div>

          <Button disabled={isLoading || !endDate} className="mt-auto" color="primary" onClick={() => onConfirm(startDate, endDate)}>
            Confirm
          </Button>

          <div className="ml-auto mt-auto">
            <Button
              disabled={isLoading}
              className={classNames("mt-auto rounded-pill", isTodaySelected && "selected")}
              size="mld"
              color="inverted"
              onClick={() => onButtonDateChange(new Date(), new Date())}
            >
              Today
            </Button>
            <Button
              disabled={isLoading}
              className={classNames("mt-auto ml-2 rounded-pill", isMonthToDaySelected && "selected")}
              color="inverted"
              size="mld"
              onClick={() => onButtonDateChange(getStartOfMonth(), new Date())}
            >
              Month to date
            </Button>
            <Button
              disabled={isLoading}
              className={classNames("mt-auto ml-2 rounded-pill", isYearToDaySelected && "selected")}
              size="mld"
              color="inverted"
              onClick={() => onButtonDateChange(getStartOfYear(), new Date())}
            >
              Year to date
            </Button>
          </div>
        </Row>
      </CardBody>
    </Card >
  );
};

export default AnalyticsHeader;
