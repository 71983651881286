import axios from "axios";

import STATUS_CODE from "../constants/statusCodes";
import { sendRefreshToken } from "./AuthService";
import StorageService from "./StorageService";
import { AUTH, LANGUAGE, SKIP_AUTH } from "../constants/headers";
import ToastrService from "./ToastrService";
import { NOT_FOUND_SALE_MESSAGE } from "../constants/errorMessages";

const interceptorsResponse = [
  (response) => response.data,
  (error) => {
    let dataObject = {},
      statusCode;
    const { response } = error;
    const { UNAUTHORIZED } = STATUS_CODE;
    const { refreshToken } = StorageService.session.value;
    if (response) {
      dataObject = response.data;
    }

    statusCode =
      dataObject.code || dataObject.statusCode || (response && response.status);
    const isUnauth =
      statusCode === UNAUTHORIZED || statusCode === "Unauthorized";

    if (isUnauth && refreshToken) {
      let isRefreshed = false;
      return sendRefreshToken({ refreshToken })
        .then(({ data }) => {
          const session = data.data;
          error.config.headers[AUTH] = `Bearer ${session.accessToken}`;
          isRefreshed = true;
          // retry request
          return axios.request(error.config).then((res) => res.data);
        })
        .catch((err) => {
          if (!isRefreshed) {
            logoutAndRedirect();
          }
          throw err;
        });
    } else if (isUnauth && !refreshToken) {
      logoutAndRedirect("/login");
    }

    const { error: errorObj } = dataObject;

    if (
      errorObj &&
      errorObj.length > 0 &&
      errorObj[0].message !== "Token invalid" &&
      errorObj[0].key !== "custom"
    ) {
      // TODO: invalid token message
    }

    if (
      !response.config.disableToast &&
      dataObject.error.message !== NOT_FOUND_SALE_MESSAGE
    ) {
      ToastrService.error(dataObject.error.message);
    }

    return Promise.reject(errorObj);
  },
];

function logoutAndRedirect(path = "/logout") {
  let location = window.location;
  location.href = location.origin + path;
  StorageService.user.clear();
  StorageService.session.clear();
}

const interceptorsRequest = [
  (request) => {
    const pattern = /^((http|https):\/\/s3)/;
    const skipAuthorization = request.headers[SKIP_AUTH] || false;
    if (!(pattern.test(request.url) || skipAuthorization)) {
      const { accessToken } = StorageService.session.value;
      request.headers = {
        [AUTH]: accessToken ? `Bearer ${accessToken}` : "",
        [LANGUAGE]: "en",
      };
    }

    delete request.headers[SKIP_AUTH];
    return request;
  },
  (err) => Promise.reject(err),
];

class Http {
  constructor() {
    this.instance = Http.createInstance({
      baseURL: `${Http.api.common}/${Http.versions.v1}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    this.instance.interceptors.request.use(...interceptorsRequest);
    this.instance.interceptors.response.use(...interceptorsResponse);
  }

  static createInstance() {
    return axios.create.apply(axios, arguments);
  }

  get() {
    return this.instance.get.apply(this.instance, arguments);
  }

  patch() {
    return this.instance.patch.apply(this.instance, arguments);
  }

  put() {
    return this.instance.put.apply(this.instance, arguments);
  }

  post() {
    return this.instance.post.apply(this.instance, arguments);
  }

  delete() {
    return this.instance.delete.apply(this.instance, arguments);
  }
}

Http.api = {
  common: process.env.REACT_APP_BASE_URL,
};

Http.versions = {
  v1: process.env.REACT_APP_API_VERSION,
};

export default Http;
