import Http from "./HttpService";

class SaleService extends Http {
  BASE_URL = "/admins/sale";

  getOne() {
    return this.get(this.BASE_URL);
  }

  remove() {
    return this.delete(this.BASE_URL);
  }

  create(model) {
    return this.post(this.BASE_URL, model);
  }
}

export default new SaleService();
