import {
  GET_LIST_PREMIUM_ARTICLES,
  GET_LIST_PREMIUM_ARTICLES_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_ERROR,
  GET_PREMIUM_ARTICLE,
  GET_PREMIUM_ARTICLE_SUCCESS,
  GET_PREMIUM_ARTICLE_CLEAR,
  GET_PREMIUM_ARTICLE_ERROR,
  UPDATE_PREMIUM_ARTICLE,
  UPDATE_PREMIUM_ARTICLE_SUCCESS,
  UPDATE_PREMIUM_ARTICLE_CLEAR,
  UPDATE_PREMIUM_ARTICLE_ERROR,
  CREATE_PREMIUM_ARTICLE,
  CREATE_PREMIUM_ARTICLE_SUCCESS,
  CREATE_PREMIUM_ARTICLE_CLEAR,
  CREATE_PREMIUM_ARTICLE_ERROR,
  CHANGE_IS_NEW_MARK,
  CHANGE_IS_NEW_MARK_SUCCESS,
  CHANGE_IS_NEW_MARK_ERROR,
  REMOVE_PREMIUM_ARTICLE,
  REMOVE_PREMIUM_ARTICLE_SUCCESS,
  REMOVE_PREMIUM_ARTICLE_ERROR,
  REMOVE_PREMIUM_ARTICLE_CLEAR,
  SAVE_PREMIUM_ARTICLES_FILTERS,
  REORDER_ARTICLE,
  REORDER_ARTICLE_ERROR,
  REORDER_ARTICLE_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL,
  GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS,
} from "./actionTypes";

export const getListPremiumArticles = (payload) => {
  return {
    payload,
    type: GET_LIST_PREMIUM_ARTICLES,
  };
};

export const getListPremiumArticlesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_PREMIUM_ARTICLES_SUCCESS,
  };
};

export const getListPremiumArticlesInfiniteScroll = (payload) => ({
  payload,
  type: GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL,
});

export const getListPremiumArticlesInfiniteScrollSuccess = ({ data, pagination }) => ({
  payload: { data, pagination },
  type: GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS,
});

export const getListPremiumArticlesError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_LIST_PREMIUM_ARTICLES_ERROR,
  };
};

export const getPremiumArticle = (id, noFilePreload) => {
  return {
    payload: { id, noFilePreload },
    type: GET_PREMIUM_ARTICLE,
  };
};

export const getPremiumArticleSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_PREMIUM_ARTICLE_SUCCESS,
  };
};

export const getPremiumArticleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_PREMIUM_ARTICLE_ERROR,
  };
};

export const clearPremiumArticleFetched = () => {
  return {
    type: GET_PREMIUM_ARTICLE_CLEAR,
  };
};

export const updatePremiumArticle = (data, history) => {
  return {
    payload: { data, history },
    type: UPDATE_PREMIUM_ARTICLE,
  };
};

export const updatePremiumArticleSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_PREMIUM_ARTICLE_SUCCESS,
  };
};

export const updatePremiumArticleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_PREMIUM_ARTICLE_ERROR,
  };
};

export const clearPremiumArticleUpdated = () => {
  return {
    type: UPDATE_PREMIUM_ARTICLE_CLEAR,
  };
};

export const createPremiumArticle = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_PREMIUM_ARTICLE,
  };
};

export const createPremiumArticleSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_PREMIUM_ARTICLE_SUCCESS,
  };
};

export const createPremiumArticleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_PREMIUM_ARTICLE_ERROR,
  };
};

export const clearPremiumArticleCreated = () => {
  return {
    type: CREATE_PREMIUM_ARTICLE_CLEAR,
  };
};

export const changeIsNewMark = (payload) => {
  return {
    payload,
    type: CHANGE_IS_NEW_MARK,
  };
};

export const changeIsNewMarkSuccess = ({ data }) => {
  return {
    payload: data,
    type: CHANGE_IS_NEW_MARK_SUCCESS,
  };
};

export const changeIsNewMarkError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CHANGE_IS_NEW_MARK_ERROR,
  };
};

export const removePremiumArticle = (id) => {
  return {
    payload: { id },
    type: REMOVE_PREMIUM_ARTICLE,
  };
};

export const removePremiumArticleSuccess = (id) => {
  return {
    payload: { id },
    type: REMOVE_PREMIUM_ARTICLE_SUCCESS,
  };
};

export const removePremiumArticleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_PREMIUM_ARTICLE_ERROR,
  };
};

export const clearPremiumArticleRemove = () => {
  return {
    type: REMOVE_PREMIUM_ARTICLE_CLEAR,
  };
};

export const savePremiumArticlesFilters = (sectionFilter, categoryFilter) => {
  return {
    payload: { sectionFilter, categoryFilter },
    type: SAVE_PREMIUM_ARTICLES_FILTERS,
  };
};

export const reorderArticle = (articleId, ordinalNumber, premiumArticleCategoryId, data) => {
  return {
    payload: { articleId, ordinalNumber, premiumArticleCategoryId, data },
    type: REORDER_ARTICLE,
  };
};

export const reorderSuccess = (payload) => {
  return {
    payload,
    type: REORDER_ARTICLE_SUCCESS,
  };
};

export const reorderError = () => {
  return {
    type: REORDER_ARTICLE_ERROR,
  };
};

