import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Alert, Col, Container, Row } from "reactstrap";
import ChangeAdminEmail from "../../../components/Forms/ChangeAdminEmail";
import ChangeAdminPassword from "../../../components/Forms/ChangeAdminPassword";
import UpdateAdminProfileFrom from "../../../components/Forms/UpdateAdminProfileFrom";
import useSnackbarState from "../../../hooks/useSnackbarState";
import { verifyChangeEmailClear } from "../../../store/dashboard/Settings/actions";
import { forgetPasswordClearError } from "../../../store/auth/forgetpwd/actions";
import Toggle10MonthSubscriptionPlanFrom from '../../../components/Forms/Toggle10MonthSubscriptionPlanFrom';

const Settings = ({
  settings,
  verifyChangeEmailClear,
  forgetPassword,
  forgetPasswordClearError,
}) => {
  const { changedEmailVerify, error } = settings;
  const { error: forgetPasswordError } = forgetPassword;

  const [isChangedEmailVerify] = useSnackbarState(
    changedEmailVerify,
    verifyChangeEmailClear
  );

  const [isForgetPasswordError] = useSnackbarState(
    forgetPasswordError,
    forgetPasswordClearError
  );
  const [isError] = useSnackbarState(error, verifyChangeEmailClear);

  useEffect(() => {
    return () => {
      verifyChangeEmailClear();
      forgetPasswordClearError();
    };
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-16">Settings</h4>
              </div>
            </Col>
          </Row>
          {isChangedEmailVerify && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  The new email address has been successfully verified. From now
                  you can use it to sign in to your account
                </Alert>
              </Col>
            </Row>
          )}
          {(isError || isForgetPasswordError) && (
            <Row>
              <Col xs="12">
                <Alert color="danger">{error || forgetPasswordError}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs="4">
              <UpdateAdminProfileFrom />
            </Col>
            <Col xs="4">
              <ChangeAdminEmail />
            </Col>
            <Col xs="4">
              <ChangeAdminPassword />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col xs='4'>
              <Toggle10MonthSubscriptionPlanFrom />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = ({
  Settings: settings,
  ForgetPassword: forgetPassword,
}) => ({ settings, forgetPassword });

export default connect(mapStateToProps, {
  verifyChangeEmailClear,
  forgetPasswordClearError,
})(Settings);
