import moment from "moment";

const datePickerDateFormater = (date, showTimeSelect = false) => {
  if (!date) return "";
  return showTimeSelect
    ? moment.utc(date).toISOString(true)
    : moment(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
        .utcOffset(0)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString(true);
};

export default datePickerDateFormater;
