import { LESSON_MAX_BLOCK_COUNT } from "../../../constants/lessonsDefaults";

export const getInitialBlockState = (isVisible = false) => ({
  isVisible,
  id: null,
  text: "",
  images: [],
  videos: [],
  imagesUploaded: [],
  videosUploaded: [],
});

export const getInitialState = () => {
  const modelArray = Object.fromEntries(
    new Array(LESSON_MAX_BLOCK_COUNT)
      .fill(null)
      .map((item, index) => [
        `block${index + 1}`,
        getInitialBlockState(index === 0),
      ])
  );
  return {
    title: "",
    urlPart: "",
    description: "",
    premiumArticleSectionId: "",
    category: "",
    tags: "",
    ...modelArray,
  };
};
