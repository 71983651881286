import ReactPaginate from "react-paginate";
import React from "react";
import classNames from "classnames";

import { DEFAULT_ARTICLES_LIMIT } from "../../../constants/pagination";

import styles from "./CustomReactPaginate.module.scss";

const pageRange = 4;
const marginPages = 0;

const CustomReactPaginate = ({
  pagination,
  pageChange,
  pageCount,
  className,
  limit = DEFAULT_ARTICLES_LIMIT,
  needForcePage = false,
}) => {
  return (
    <ReactPaginate
      containerClassName={classNames(
        "pagination justify-content-center",
        styles.articlesPagination,
        className
      )}
      pageCount={pageCount || Math.ceil(pagination.totalCount / limit)}
      pageRangeDisplayed={pageRange}
      activeClassName={styles.articlesPaginationSelected}
      disabledClassName={styles.articlesPaginationDisabled}
      onPageChange={pageChange}
      marginPagesDisplayed={marginPages}
      breakLabel={null}
      previousLabel="‹"
      nextLabel="›"
      disableInitialCallback={true}
      forcePage={needForcePage && pagination.nextPage - 2}
    />
  );
};

export default CustomReactPaginate;
