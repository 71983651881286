import React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation";
import useAvForm from "../../../../hooks/useAvForm";
import formsValidators from "../../../../validators/form.validators";

export default function ChangeEmailModal({ toggle, submit }) {

    const [
        formRef,
        hasFormError,
        handleFormChange,
        isDirty,
    ] = useAvForm();

    return (
        <AvForm
            onValidSubmit={(event, { email, forceUpdate }) => {
                    const toForceUpdate = forceUpdate.length === 1
                    return submit(email, toForceUpdate)
                }
            }
            ref={formRef}
        >
            <ModalHeader toggle={toggle}>Change email</ModalHeader>
            <ModalBody className='user-details--basic-info__modal-body pb-0'>
                <section className="d-flex flex-column">
                    The verification link will be sent to the entered email address

                    <section className="mt-3">
                        <AvField
                            id='email'
                            name='email'
                            label="New email address"
                            type="text"
                            className="form-control"
                            placeholder="Enter new email"
                            onChange={() => handleFormChange()}
                            onBlur={() => handleFormChange()}
                            validate={formsValidators.email}
                        />
                    </section>
                    <section className="mt-3">
                      <AvCheckboxGroup className='' name={'forceUpdate'}>
                            <AvCheckbox
                            onChange={handleFormChange}
                            className="cursor-pointer"
                            customInput
                            label="Force Update Email (Verification Email will not be sent to new email)"
                            value={'ForceUpdate'}
                            />
                      </AvCheckboxGroup>
                    </section>
                </section>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" size="lg" onClick={toggle} className='user-details--basic-info__secondary-button'>
                    Cancel
                </Button>
                <Button
                    className='user-details--basic-info__primary-button'
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={!isDirty || hasFormError}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </AvForm >
    );
}