export const GET_LIST_PREMIUM_ARTICLES_TAGS = "GET_LIST_PREMIUM_ARTICLES_TAGS";
export const GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS =
  "GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS";
export const GET_LIST_PREMIUM_ARTICLES_TAGS_ERROR =
  "GET_PREMIUM_ARTICLES_TAGS_ERROR";

export const UPDATE_PREMIUM_ARTICLES_TAG = "UPDATE_PREMIUM_ARTICLES_TAG";
export const UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS =
  "UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS";
export const UPDATE_PREMIUM_ARTICLES_TAG_CLEAR =
  "UPDATE_PREMIUM_ARTICLES_TAG_CLEAR";
export const UPDATE_PREMIUM_ARTICLES_TAG_ERROR =
  "UPDATE_PREMIUM_ARTICLES_TAG_ERROR";
