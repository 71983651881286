import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import SEO from "../../../components/Common/SEO";
import CreateSaleForm from "../../../components/Forms/CreateSaleForm";
import { getSale, getSaleClear } from "../../../store/dashboard/Sale/actions";
import SaleDetails from "./SaleDetails";

const Sale = ({ sale, getSale, getSaleClear }) => {
  const { item, loading, needGetSale } = sale;

  useEffect(() => {
    if (needGetSale) {
      getSale();
      getSaleClear();
    }
  }, [needGetSale, getSale, getSaleClear]);

  return (
    <Fragment>
      <SEO title="Sale management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-16">Sale management</h4>
              </div>
            </Col>
          </Row>
          <Row>
            {!loading && !!item && (
              <Col xs="12">
                <SaleDetails {...item} />
              </Col>
            )}
            {!loading && !item && (
              <Col xs="12">
                <CreateSaleForm />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ Sale }) => {
  return {
    sale: Sale,
  };
};

export default connect(mapStateToProps, { getSale, getSaleClear })(Sale);
