export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export const GET_ADMIN_PROFILE_SUCCESS = "GET_ADMIN_PROFILE_SUCCESS";
export const GET_ADMIN_PROFILE_ERROR = "GET_ADMIN_PROFILE_ERROR";
export const CLEAR_ADMIN_PROFILE = "CLEAR_ADMIN_PROFILE";

export const UPDATE_ADMIN_PROFILE = "UPDATE_ADMIN_PROFILE";
export const UPDATE_ADMIN_PROFILE_SUCCESS = "UPDATE_ADMIN_PROFILE_SUCCESS";
export const UPDATE_ADMIN_PROFILE_ERROR = "UPDATE_ADMIN_PROFILE_ERROR";

export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD";
export const CHANGE_ADMIN_PASSWORD_SUCCESS = "CHANGE_ADMIN_PASSWORD_SUCCESS";
export const CHANGE_ADMIN_PASSWORD_ERROR = "CHANGE_ADMIN_PASSWORD_ERROR";
export const CHANGE_ADMIN_PASSWORD_CLEAR = "CHANGE_ADMIN_PASSWORD_CLEAR";

export const CHANGE_ADMIN_EMAIL = "CHANGE_ADMIN_EMAIL";
export const CHANGE_ADMIN_EMAIL_SUCCESS = "CHANGE_ADMIN_EMAIL_SUCCESS";
export const CHANGE_ADMIN_EMAIL_ERROR = "CHANGE_ADMIN_EMAIL_ERROR";
export const CHANGE_ADMIN_EMAIL_CLEAR = "CHANGE_ADMIN_EMAIL_CLEAR";

export const VERIFY_CHANGE_EMAIL = "VERIFY_CHANGE_EMAIL";
export const VERIFY_CHANGE_EMAIL_SUCCESS = "VERIFY_CHANGE_EMAIL_SUCCESS";
export const VERIFY_CHANGE_EMAIL_ERROR = "VERIFY_CHANGE_EMAIL_ERROR";
export const VERIFY_CHANGE_EMAIL_CLEAR = "VERIFY_CHANGE_EMAIL_CLEAR";

export const CHANGE_ADMIN_AVATAR = "CHANGE_ADMIN_AVATAR";
export const CHANGE_ADMIN_AVATAR_SUCCESS = "CHANGE_ADMIN_AVATAR_SUCCESS";
export const CHANGE_ADMIN_AVATAR_ERROR = "CHANGE_ADMIN_AVATAR_ERROR";
