import Axios from "axios";

import Http from "./HttpService";
import StorageService from "./StorageService";
import waitPromiseResolve from "../helpers/waitPromiseResolved";

const USER_PLACEHOLDER = {
  firstName: "FirstName",
  lastName: "LastName",
};

const REMEMBER_ME_DEFAULT = true;

class AuthService extends Http {
  getUser() {
    const user = StorageService.user.value;
    if (user) {
      return user;
    } else {
      return USER_PLACEHOLDER;
    }
  }

  login(model, remember = REMEMBER_ME_DEFAULT) {
    this.clearUser();
    return this.post("admins/sessions", model, { disableToast: true }).then(
      (authUser) => {
        this.storeUser(authUser, remember);
        return authUser;
      }
    );
  }

  signOut() {
    return this.delete("admins/sessions").then(() => {
      this.clearUser();
    });
  }

  restorePassword(model) {
    return this.put("/admins/verifications/password", model);
  }

  forgotPassword(model) {
    return this.post("/admins/verifications/password", model);
  }

  validateRestorePasswordToken(model) {
    return this.put("/admins/verifications/token/restoration-password", model, {
      disableToast: true,
    });
  }

  storeUser(userData, remember = false) {
    const { user, session } = userData.data;
    const storage = remember ? localStorage : sessionStorage;
    StorageService.user.storage = storage;
    StorageService.session.storage = storage;

    StorageService.user.value = user;
    StorageService.session.value = session;
  }

  clearUser() {
    StorageService.user.clear();
    StorageService.session.clear();
  }
}

export default new AuthService();

export const sendRefreshToken = waitPromiseResolve(async (refreshToken) => {
  const response = await Axios.put(
    `${Http.api.common}/${Http.versions.v1}/admins/sessions`,
    refreshToken
  );
  StorageService.session.value = response.data.data;
  return response;
});
