import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_PAYMENTS_LIST, GET_PAYMENT } from "./actionTypes";

import {
  getPaymentsListSuccess,
  getPaymentsListError,
  getPaymentSuccess,
  getPaymentError,
} from "./actions";

import PaymentsService from "../../../services/PaymentsService";

const getPaymentsListAsync = async (searchParams) => {
  return await PaymentsService.getList(searchParams);
};

const getPaymentAsync = async (id) => {
  return await PaymentsService.getOne(id);
};

function* getPaymentsList({ payload }) {
  try {
    const response = yield call(getPaymentsListAsync, payload);
    yield put(getPaymentsListSuccess(response));
  } catch (error) {
    yield put(getPaymentsListError(error));
  }
}

function* getPayment({ payload: { id } }) {
  try {
    const response = yield call(getPaymentAsync, id);
    yield put(getPaymentSuccess(response));
  } catch (error) {
    yield put(getPaymentError(error));
  }
}

export function* watchPaymentsList() {
  yield takeEvery(GET_PAYMENTS_LIST, getPaymentsList);
}

export function* watchGetPayment() {
  yield takeEvery(GET_PAYMENT, getPayment);
}

function* paymentsSaga() {
  yield all([fork(watchPaymentsList), fork(watchGetPayment)]);
}

export default paymentsSaga;
