import Http from "./HttpService";

class ConverterKitTagsService extends Http {
  BASE_URL = "/admins/convertkit-tags";

  getList() {
    return this.get(`${this.BASE_URL}`);
  }
}

export default new ConverterKitTagsService();
