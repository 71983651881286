import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  getSaleSuccess,
  getSaleError,
  deleteSaleSuccess,
  deleteSaleError,
  createSaleSuccess,
  createSaleError,
} from "./actions";

import { GET_SALE, DELETE_SALE, CREATE_SALE } from "./actionTypes";

import SaleService from "../../../services/SaleService";

const getSaleAsync = async () => {
  return await SaleService.getOne();
};

const deleteSaleAsync = async () => {
  return await SaleService.remove();
};

const createSaleAsync = async (model) => {
  return await SaleService.create(model);
};

function* getSale() {
  try {
    const response = yield call(getSaleAsync);
    yield put(getSaleSuccess(response));
  } catch (error) {
    yield put(getSaleError(error));
  }
}

function* deleteSale() {
  try {
    const response = yield call(deleteSaleAsync);
    yield put(deleteSaleSuccess(response));
  } catch (error) {
    yield put(deleteSaleError(error));
  }
}

function* createSale({ payload: { model } }) {
  try {
    const response = yield call(createSaleAsync, model);
    yield put(createSaleSuccess(response));
  } catch (error) {
    yield put(createSaleError(error));
  }
}

export function* watchGetSale() {
  yield takeEvery(GET_SALE, getSale);
}

export function* watchDeleteSale() {
  yield takeEvery(DELETE_SALE, deleteSale);
}

export function* watchCreateSale() {
  yield takeEvery(CREATE_SALE, createSale);
}

function* saleSaga() {
  yield all([fork(watchGetSale), fork(watchDeleteSale), fork(watchCreateSale)]);
}

export default saleSaga;
