import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class CoursesService extends Http {
  BASE_URL = "/admins/course-categories";
  BASE_URL_COURSES = "/admins/courses";

  getList(id, pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}/${id}/courses`, {
      params: {
        limit,
        offset,
      },
    });
  }

  create(id, model) {
    return this.post(`${this.BASE_URL}/${id}/courses`, model);
  }

  async getOne(id) {
    const { data } = await this.get(`${this.BASE_URL_COURSES}/${id}`);
    if (!data.video) {
      return { data };
    }
    const initialFile = data.video;
    initialFile.preview = data.video.videoPreview || null;
    initialFile.size = null;

    if (initialFile?.thumbnailImage) {
      initialFile.thumbnailImage.preview = initialFile.thumbnailImage.link;
      initialFile.thumbnailImage.size = null;
    }

    return { data: { ...data, initialFile } };
  }

  update(id, model) {
    return this.put(`${this.BASE_URL_COURSES}/${id}`, model);
  }

  changeStatus(id) {
    return this.patch(`${this.BASE_URL_COURSES}/${id}/status`);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL_COURSES}/${id}`);
  }

  addVideo(id, model) {
    return this.post(`${this.BASE_URL_COURSES}/${id}/video`, model);
  }

  changeVideoThumbnail(courseId, videoThumbnailId) {
    return this.put(
      `${this.BASE_URL_COURSES}/${courseId}/video/thumbnail`,
      {
        videoThumbnailId,
      },
      {
        disableToast: true,
      }
    );
  }

  setRecommendations(courseId, model) {
    return this.put(`${this.BASE_URL_COURSES}/${courseId}/recommendations`, model)
  }

  getAllCoursesList(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`/courses`, {
      params: {
        limit,
        offset,
      },
    });
  }
}

export default new CoursesService();
