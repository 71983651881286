import React, { useEffect, useMemo, useState } from "react";
import { Button, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";

import {
  getSubscriptionCoupon,
  updateSubscriptionCoupon,
} from "../../store/dashboard/Coupons/actions";
import useAvForm from "../../hooks/useAvForm";
import formsValidators from "../../validators/form.validators";

import styles from "./forms.module.scss";
import usePrevious from "../../hooks/usePrevious";

const SubscriptionCouponForm = ({
  coupons,
  getSubscriptionCoupon,
  updateSubscriptionCoupon,
}) => {
  const { loading, subscriptionCoupon: model, error } = coupons;
  const previousModel = usePrevious(model);
  const [isEditing, updateIsEditing] = useState(false);
  const [isReady, updateIsReady] = useState(false);
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(
    () => loading || hasFormError || !isDirty,
    [loading, isDirty, hasFormError]
  );
  const btnSaveLabel = useMemo(
    () => (isEditing ? "Update" : "Edit"),
    [isEditing]
  );
  const handleFormSubmit = (event, values) => {
    updateSubscriptionCoupon(values);
  };

  useEffect(() => {
    if (!model && !error) {
      getSubscriptionCoupon();
    } else {
      updateIsReady(true);
    }
  }, [model, error]);

  useEffect(() => {
    if (!isEditing && formRef.current) {
      formRef.current.reset();
      handleFormChange();
    }
  }, [isEditing, formRef]);

  useEffect(() => {
    if (isReady && !!model && model !== previousModel) {
      updateIsEditing(false);
      formRef.current.reset();
      handleFormChange();
    }
  }, [isReady, model, previousModel, formRef]);

  return (
    isReady && (
      <>
        <Row className="align-items-center">
          <Col xs={"auto"} className="flex-fill justify-content-center">
            <CardTitle>Subscription coupon - First month promo</CardTitle>
          </Col>
          <Col xs={"auto"}>Number of uses - {model?.usageCount}</Col>
        </Row>
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          model={model || { body: "-" }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <Row>
            <Col xl={4} lg={6}>
              <FormGroup className={"mb-0"} row>
                <Col xs={"auto"}>
                  <Label htmlFor={"body"} className="col-form-label">
                    Current coupon
                  </Label>
                </Col>
                <Col>
                  <AvField
                    type="text"
                    id="body"
                    name="body"
                    className="form-control"
                    placeholder="Enter current code"
                    onChange={() => handleFormChange()}
                    onBlur={() => handleFormChange()}
                    readOnly={!isEditing}
                    validate={formsValidators.courseCoupon.body}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col>
              {!isEditing ? (
                <Button
                  type="submit"
                  color="primary"
                  className={styles.btnSave}
                  onClick={(e) => {
                    e.preventDefault();
                    updateIsEditing(true);
                  }}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={disableState}
                    className={styles.btnSave}
                  >
                    {btnSaveLabel}
                  </Button>
                  <Button
                    color="secondary"
                    className={classNames("ml-3", styles.cancelBtn)}
                    onClick={(e) => {
                      e.preventDefault();
                      updateIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </AvForm>
      </>
    )
  );
};

const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, {
  getSubscriptionCoupon,
  updateSubscriptionCoupon,
})(SubscriptionCouponForm);
