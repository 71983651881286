import React, { useEffect } from "react";
import { Alert, Card, Col, FormGroup, Label, Row } from "reactstrap";
import Dropzone from "react-dropzone";

import formsValidators from "../../../validators/form.validators";
import useFilesToRemove from "../../../hooks/useFilesToRemove";
import noop from "../../../helpers/noop";
import {
  ERROR_MAX_IMAGES,
  MAX_FILES_COUNT_IMAGES,
} from "../../../constants/dropzone";

import validateDropzoneImage from "../../../validators/imageValidator";
import UploadedImage from "../../Common/UploadedImage";

const FormGroupImages = ({
  error,
  selectedImages,
  handleAcceptedImages,
  setSelectedImages,
  initialFile = null,
  imagesUploaded = null,
  label = "",
  multiple = false,
  updateDeleteImagesIds = noop,
  setError = noop,
}) => {
  const [filesLoaded, remove, deleteImagesIds] = useFilesToRemove(
    imagesUploaded
  );
  const handleRemoveClicked = (id) => {
    remove(id);
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    const {validatedAcceptedFiles,  validatedFileRejections} = await validateDropzoneImage(acceptedFiles, fileRejections);
    handleAcceptedImages(validatedAcceptedFiles, validatedFileRejections, multiple);
  }

  useEffect(() => {
    if (deleteImagesIds.length) {
      updateDeleteImagesIds(deleteImagesIds);
    }
  }, [deleteImagesIds]);

  const sliceImagesToLimit = (selectedImages) => {
    setSelectedImages(
      selectedImages.slice(0, MAX_FILES_COUNT_IMAGES - filesLoaded.length)
    );
  };

  useEffect(() => {
    if (
      multiple &&
      selectedImages.length > MAX_FILES_COUNT_IMAGES - filesLoaded.length
    ) {
      sliceImagesToLimit(selectedImages);
      setError(ERROR_MAX_IMAGES);
    }
  }, [selectedImages, multiple]);

  return (
    <FormGroup className="mb-4" row>
      <Label className="col-form-label col-lg-2">{label}</Label>
      <Col lg="10">
        {filesLoaded && (
          <Row>
            {filesLoaded.map((imageUploaded) => (
              <Col
                xs="auto"
                className="mb-4"
                key={"image-uploaded-" + imageUploaded.id}
              >
                <UploadedImage imageUploaded={imageUploaded} onDelete={() => handleRemoveClicked(imageUploaded.id)}/>
              </Col>
            ))}
          </Row>
        )}
        <Dropzone
          multiple={multiple}
          maxFiles={MAX_FILES_COUNT_IMAGES - filesLoaded.length}
          maxSize={formsValidators.file.image.maxSize.value}
          accept={formsValidators.file.image.formats.value
            .map((format) => "image/" + format)
            .join(",")}
          onDrop={onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div className="dz-message needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="dz-message needsclick">
                  <div className="mb-3">
                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h4>Drop files here or click to upload.</h4>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {error && error ? (
          <Alert className="mt-3" color="danger">
            {error}
          </Alert>
        ) : null}
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedImages.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <p className="text-muted font-weight-bold mb-0">
                        {f.name}
                      </p>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    {multiple ? (
                      <Col className="col-auto">
                        <i
                          className="bx bx-trash text-grey font-size-20 mr-3 cursor-pointer"
                          onClick={() =>
                            setSelectedImages(
                              selectedImages.filter((image) => image !== f)
                            )
                          }
                        />
                      </Col>
                    ) : (
                      <>
                        {initialFile !== selectedImages[0] && (
                          <Col className="col-auto">
                            <i
                              className="bx bx-trash text-grey font-size-20 mr-3 cursor-pointer"
                              onClick={() => setSelectedImages([])}
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </div>
              </Card>
            );
          })}
        </div>
      </Col>
    </FormGroup>
  );
};

export default FormGroupImages;
