import React, { useState, useMemo, useEffect } from 'react';
import {
    FILTER_BY_INACTIVE,
    FILTER_BY_ACTIVE,
    FILTER_BY_BLOCKED,
} from '../../../../constants/paginationConstants';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Button,
} from "reactstrap";
import { useLocation, useHistory } from 'react-router-dom';
import queryParser from '../../../../helpers/queryParser';
import qs from 'querystring';
import Checkbox from '../../../../components/Common/checkbox';
import getArrayFromQuery from '../../../../helpers/getArrayQuery';
import SearchBar from '../../../../components/Common/searchBar';
import useDebounce from '../../../../hooks/useDebounce';
import { MAX_SEARCH_LENGTH } from '../../../../constants/leghtValidity';

const FILTER_QUERY = "statuses";

export default function UsersFilters({ toolkitProps }) {
    const history = useHistory();
    const location = useLocation();

    const query = useMemo(() => queryParser(location.search), [location]);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filterOption, setFilterOption] = useState(query[FILTER_QUERY] ?
        getArrayFromQuery(query[FILTER_QUERY]) : []);

    const [searchQuery, setSearchQuery] = useState(query.q);

    const debouncedSearchTerm = useDebounce(searchQuery);

    function handleChangeFilters(filter) {
        setFilterOption(prevState => {
            if (prevState.includes(filter)) {
                return prevState.filter(option => option !== filter);
            }
            return [...prevState, filter];
        })
    }

    function handleSubmitFilters() {
        setIsFilterOpen(prevState => !prevState);
        history.push({
            search: qs.stringify({
                ...query,
                [FILTER_QUERY]: [...filterOption],
            })
        })
    }

    function clearSearch() {
        setFilterOption([]);
        history.replace({
            search: '',
        })
    }

    function handleInputSearch(event) {
        const search = event.target.value;

        if (search.length > MAX_SEARCH_LENGTH) {
            return;
        }
        setSearchQuery(search);
    }

    useEffect(() => {
        toolkitProps.searchProps.onSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm, toolkitProps]);

    return (
        <section className="d-flex flex-row justify-content-between">
            <div className="search-box ms-2 mb-2 d-inline-block">
                <SearchBar
                    handleEnterSearch={handleInputSearch}
                    searchQuery={searchQuery}
                    inputClassName="user-list__search"
                />
            </div>
            <section className="d-flex flex-row">
                {!!query.statuses &&
                    <Button color="primary" className="user-list__clear-button" onClick={clearSearch}>
                        Reset filters
                    </Button>
                }
                <Dropdown
                    isOpen={isFilterOpen}
                    toggle={() => setIsFilterOpen(prevState => !prevState)}
                >
                    <DropdownToggle tag="button" className="btn user-list--dropdown__toggle">
                        Filter <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-right user-list--dropdown">
                        <span className="user-list--dropdown__title ms-2 mb-2">Status</span>

                        <Checkbox
                            value={"active"}
                            onChange={() => { handleChangeFilters(FILTER_BY_ACTIVE) }}
                            checked={filterOption.includes(FILTER_BY_ACTIVE)}
                            label={"Active"}
                        />

                        <Checkbox
                            value={"Inactive"}
                            onChange={() => { handleChangeFilters(FILTER_BY_INACTIVE) }}
                            checked={filterOption.includes(FILTER_BY_INACTIVE)}
                            label={"Inactive"}
                        />

                        <Checkbox
                            value={"blocked"}
                            onChange={() => { handleChangeFilters(FILTER_BY_BLOCKED) }}
                            checked={filterOption.includes(FILTER_BY_BLOCKED)}
                            label={"Blocked"}
                        />

                        <Button
                            color="success"
                            className="w-100 mt-4"
                            onClick={handleSubmitFilters}
                        >
                            Apply
                    </Button>
                    </DropdownMenu>
                </Dropdown>
            </section>
        </section>
    );
}
