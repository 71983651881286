export default function getSubscriptionStatus(subscription) {
    const status = {
        label: 'Active',
        color: 'success',
    }

    if (!subscription) {
        status.label = "Inactive";
        status.color = "primary user-list__blue font-size-12";

        return status;
    }

    if (subscription.canceledAt) {
        status.label = "Cancelled";
        status.color = "danger";

        return status;
    }

    return status;
}