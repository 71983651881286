import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import classNames from "classnames";
import { connect } from "react-redux";

import formatDate from "../../../helpers/formatDate";
import getSubscriptionType from "../../../helpers/getSubscriptionType";

import getBraintreePaymentUrl from "../../../helpers/getBraintreePaymentUrl";
import formatTabelCell from "../../../helpers/formatTabelCell";
import getPaidCurrency from "../../../helpers/getPaidCurrency";

import styles from "./index.module.scss";
import getChargebeeInvoiceUrl from '../../../helpers/getChargebeeInvoiceUrl';

const PaymentDetailsModalBlock = ({ title, content }) => (
  <div className={styles.modalBlock}>
    <div>{title}</div>
    <div>{formatTabelCell(content)}</div>
  </div>
);

const PaymentDetailsModal = ({ isOpen, onCancel, payments }) => {
  const { item } = payments;
  return (
    <>
      {item && (
        <Modal
          isOpen={isOpen}
          centered
          className={classNames("confirmation-modal ", styles.modalContainer)}
          toggle={() => onCancel()}
        >
          <ModalHeader className={styles.modalHeader} toggle={() => onCancel()}>
            Payment info
          </ModalHeader>
          <ModalBody className="pt-0">
            <div className="d-flex flex-row justify-content-between flex-wrap">
              <PaymentDetailsModalBlock
                title="Transaction ID"
                content={item.transactionId}
              />
              <PaymentDetailsModalBlock
                title="User’s email address"
                content={item.user?.email}
              />
              <PaymentDetailsModalBlock
                title="Date of payment"
                content={formatDate(item.createdAt)}
              />
              <PaymentDetailsModalBlock
                title="Amount"
                content={getPaidCurrency(item.currency, item.paidAmount)}
              />
              <PaymentDetailsModalBlock
                title="Used coupon:"
                content={item.couponCode}
              />
            </div>
            <div className={styles.purchasedTitle}>Purchased product/s:</div>
            {!!item.paymentTransactionDetails && (
              <ul className={styles.purchasedList}>
                {item.paymentTransactionDetails.map((paymentDetail, index) => (
                  <li key={`paymentDetail-${index}`}>
                    {paymentDetail.courseTitle}
                  </li>
                ))}
              </ul>
            )}
            {!!item.userSubscription && (
              <div>{getSubscriptionType(item.userSubscription)}</div>
            )}
            <a
              href={getChargebeeInvoiceUrl(item.transactionId)}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.modalLink}
            >
              Link to the Chargebee Dashboard
            </a>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({ Payments: payments }) => ({ payments });

export default connect(mapStateToProps)(PaymentDetailsModal);
