import { DEFAULT_OFFSET } from "../../../constants/pagination";
import {
  GET_NOTIFICATIONS_LIST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_NOTIFICATIONS_LIST_ERROR,
  GET_REMINDER_NOTIFICATION,
  GET_REMINDER_NOTIFICATION_SUCCESS,
  GET_REMINDER_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_ERROR,
  CREATE_NOTIFICATION_CLEAR,
} from "./actionTypes";

const initialState = {
  created: null,
  reminderNotification: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  reminderNotificationLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_NOTIFICATIONS_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_NOTIFICATIONS_LIST_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_REMINDER_NOTIFICATION:
      state = {
        ...state,
        reminderNotificationLoading: true,
      };
      break;
    case GET_REMINDER_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        reminderNotification: action.payload.data[0],
        reminderNotificationLoading: false,
      };
      break;
    case GET_REMINDER_NOTIFICATION_ERROR:
      state = {
        ...state,
        reminderNotificationLoading: false,
      };
      break;
    case UPDATE_NOTIFICATION:
      state = {
        ...state,
        reminderNotificationLoading: true,
      };
      break;
    case UPDATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        reminderNotification: action.payload.data,
        reminderNotificationLoading: false,
      };
      break;
    case UPDATE_NOTIFICATION_ERROR:
      state = {
        ...state,
        reminderNotificationLoading: false,
      };
      break;
    case CREATE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_NOTIFICATION_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_NOTIFICATION_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
