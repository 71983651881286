import FileHelper from "../helpers/FileHelper";
import Http from "./HttpService";

class PremiumArticlesService extends Http {
  BASE_URL = "/admins/premium-articles";

  getList({ limit, offset, q, premiumArticleCategoryId, onlyDraft, filterType }) {
    return this.get(this.BASE_URL, {
      params: {
        limit,
        offset,
        q,
        filterType,
        premiumArticleCategoryId,
        onlyDraft,
      },
    });
  }

  async getOne(id, noFilePreload = false) {
    const { data } = await this.get(`${this.BASE_URL}/${id}`);
    if (!data.image) {
      return { data };
    }
    const { name, link } = data.image;
    // This try required for CORS fail case
    if (noFilePreload) {
      return { data };
    }
    try {
      const image = await FileHelper.fileUrlToFile(link);
      const initialFile = FileHelper.dataUrlToFile(image, name);
      FileHelper.updateFileMeta(initialFile);
      return { data: { ...data, initialFile } };
    } catch (e) {
      // Simply return data in case if image loading failed
      return { data };
    }
  }

  create(model) {
    return this.post(this.BASE_URL, PremiumArticlesService.getCreateDto(model));
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }

  addFiles(id, model) {
    return this.post(`${this.BASE_URL}/${id}/files`, model, {
      disableToast: true,
    });
  }

  changeVideoThumbnails(id, model) {
    return this.put(`${this.BASE_URL}/${id}/videos/thumbnails`, model, {
      disableToast: true,
    });
  }

  addOverview(id, model) {
    return this.post(`${this.BASE_URL}/${id}/overview`, model, {
      disableToast: true,
    });
  }

  static getArticleBlockDto(block)  {
    const obj = {};

    if (block.text) {
      obj.text = block.text;
    }

    if (block.typeFormId) {
      obj.typeFormId = block.typeFormId;
    } else if (block.typeFormId === null) {
      obj.typeFormId = null; // To allow removal of typeFormId on update
    }

    if (block.ordinalNumber) {
      obj.ordinalNumber = block.ordinalNumber;
    } else if (block.ordinalNumber === null) {
      obj.ordinalNumber = null; // To allow removal of ordinalNumber on update
    }

    return obj;
  }

  static getCreateDto(model) {
    const {
      premiumArticleSectionId,
      category,
      tags,
      title,
      urlPart,
      description,
      blocks,
      scheduledDate,
    } = model;
    return {
      premiumArticleSectionId,
      category,
      tags,
      title,
      urlPart,
      scheduledDate,
      description,
      blocks: blocks.map((block) => this.getArticleBlockDto(block)),
    };
  }

  changeIsNewMark(id, isNew) {
    return this.patch(`${this.BASE_URL}/${id}/is-new`, { isNew });
  }

  remove(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }

  changeOrdinalNumber(articleId, ordinalNumber, premiumArticleCategoryId) {
    return this.put(`${this.BASE_URL}/${articleId}/order`, {
      ordinalNumber,
      premiumArticleCategoryId,
    });
  }

  changeScheduledDate(articleId, scheduledDate) {
    return this.put(`${this.BASE_URL}/${articleId}/scheduled-date`, {
      scheduledDate,
    });
  }
}

export default new PremiumArticlesService();
