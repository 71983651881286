import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  getListCommentsSuccess,
  getListCommentsError,
  readCommentSuccess,
  readCommentError,
  approveCommentSuccess,
  approveCommentError,
  deleteCommentSuccess,
  deleteCommentError,
  getUnreadCountSuccess,
  getUnreadCountError,
  replyToCommentSuccess,
  replyToCommentError,
  readAllCommentsSuccess,
  readAllCommentsError,
} from "./actions";

import {
  GET_LIST_COMMENTS,
  READ_COMMENT,
  APPROVE_COMMENT,
  DELETE_COMMENT,
  GET_UNREAD_COUNT,
  REPLY_TO_COMMENT,
  READ_ALL_COMMENTS,
} from "./actionTypes";

import CommentsService from "../../../services/CommentsService";

const getListCommentsAsync = async (data) => {
  return await CommentsService.getList(data);
};

const readCommentAsync = async (id) => {
  return await CommentsService.readComment(id);
};

const approveCommentAsync = async (id) => {
  return await CommentsService.approveComment(id);
};

const getUnreadCountAsync = async () => {
  return await CommentsService.getUnreadCount();
};

const deleteCommentAsync = async (id) => {
  return await CommentsService.deleteComment(id);
};

const replyToCommentAsync = async (id, comment) => {
  return await CommentsService.createReply(id, { comment });
};

const readAllCommentsAsync = async (commentProductType) => {
  return await CommentsService.readAllComments(commentProductType);
};

function* getListComments({ payload }) {
  try {
    const response = yield call(getListCommentsAsync, payload);
    yield put(getListCommentsSuccess(response));
  } catch (error) {
    yield put(getListCommentsError(error));
  }
}

function* readComment({ payload: { id } }) {
  try {
    const response = yield call(readCommentAsync, id);
    yield put(readCommentSuccess(response));
  } catch (error) {
    yield put(readCommentError(error));
  }
}

function* approveComment({ payload: { id } }) {
  try {
    const response = yield call(approveCommentAsync, id);
    yield put(approveCommentSuccess(response));
  } catch (error) {
    yield put(approveCommentError(error));
  }
}

function* getUnreadCount() {
  try {
    const response = yield call(getUnreadCountAsync);
    yield put(getUnreadCountSuccess(response));
  } catch (error) {
    yield put(getUnreadCountError(error));
  }
}

function* deleteComment({ payload: { id } }) {
  try {
    const response = yield call(deleteCommentAsync, id);
    yield put(deleteCommentSuccess(response));
  } catch (error) {
    yield put(deleteCommentError(error));
  }
}

function* replyToComment({ payload: { id, comment } }) {
  try {
    const response = yield call(replyToCommentAsync, id, comment);
    yield put(replyToCommentSuccess(response));
  } catch (error) {
    yield put(replyToCommentError(error));
  }
}

function* readAllComments({ payload: { commentProductType } }) {
  try {
    const response = yield call(readAllCommentsAsync, commentProductType);
    yield put(readAllCommentsSuccess(response));
  } catch (error) {
    yield put(readAllCommentsError(error));
  }
}

export function* watchGetListComments() {
  yield takeEvery(GET_LIST_COMMENTS, getListComments);
}

export function* watchReadComment() {
  yield takeEvery(READ_COMMENT, readComment);
}

export function* watchApproveComment() {
  yield takeEvery(APPROVE_COMMENT, approveComment);
}

export function* watchGetUnreadCount() {
  yield takeEvery(GET_UNREAD_COUNT, getUnreadCount);
}

export function* watchDeleteComment() {
  yield takeEvery(DELETE_COMMENT, deleteComment);
}

export function* watchReplyToComment() {
  yield takeEvery(REPLY_TO_COMMENT, replyToComment);
}

export function* watchReadAllComments() {
  yield takeEvery(READ_ALL_COMMENTS, readAllComments);
}

function* commentsSaga() {
  yield all([
    fork(watchGetListComments),
    fork(watchReadComment),
    fork(watchApproveComment),
    fork(watchDeleteComment),
    fork(watchGetUnreadCount),
    fork(watchReplyToComment),
    fork(watchReadAllComments),
  ]);
}

export default commentsSaga;
