import React, { useCallback, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveBundle from "../../../components/Forms/SaveBundle";
import RouteLeavingGuard from "../../../routes/middleware/RouteLeavingGuard";
import { createReward } from "../../../store/dashboard/Rewards/actions";
import ProgressWatcher from "../../../components/Common/ProgressWatcher";
import SEO from "../../../components/Common/SEO";
import SaveReward from '../../../components/Forms/SaveReward';

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const Create = ({ createReward, rewards, filesError }) => {
  const { created, loading } = rewards;
  const [isDirty, updateDirty] = useState(false);

  const history = useHistory();

  const saveRewardCallback = useCallback((model) => {
    const createRewardFunc = () => {
      createReward({ model }, history);
    };
    createRewardFunc();
  }, []);
  const isBlocked = (created ? false : isDirty) && !filesError;

  return (
    <>
      <SEO title="Create reward" />
      <RouteLeavingGuard
        contentText="Added data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Create reward
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                <SaveReward
                  saveRewardFunc={saveRewardCallback}
                  updateDirtyState={updateDirty}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = ({ Rewards, Progress }) => {
  return { rewards: Rewards, filesError: Progress.error };
};

export default connect(mapStateToProps, { createReward })(Create);
