import React from "react";
import { Container, Row, Col } from "reactstrap";

import error from "../../assets/images/error-img.png";

import styles from "./index.module.scss";

const ExpiredLink = () => {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <div className={styles.container}>
            <div className={styles.title}>This link HAS expired</div>
            <div className={styles.subtitle}>
              Your old email address is still valid
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div>
            <img src={error} alt="error" className={styles.image} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ExpiredLink;
