import { useCallback, useEffect, useRef, useState } from "react";
import { generateOptions } from "../helpers/reactSelectHelper";

const useSelect = (value, name, handleFormChange, optionValueName, isMulti) => {
  const [resultOptions, setResultOptions] = useState([]);
  const selectRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (value && selectRef.current) {
      selectRef.current.select.select
        ? selectRef.current.select.select.setValue(
            generateOptions(value, optionValueName)
          )
        : isMulti ? selectRef.current.select.setValue(generateOptions(value))
        : selectRef.current.select.setValue(...generateOptions([value]));
    }
  }, [value]);

  const handlerBlur = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.context.FormCtrl.setTouched(name);
      inputRef.current.context.FormCtrl.setDirty(name);
      handleFormChange();
    }
  }, [name]);

  return [resultOptions, setResultOptions, selectRef, inputRef, handlerBlur];
};

export default useSelect;
