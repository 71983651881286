import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  FORGET_PASSWORD_SEND_LINK,
  VALIDATE_RESTORE_PASSWORD_TOKEN,
  RESTORE_PASSWORD,
} from "./actionTypes";
import {
  forgetPasswordSendLinkSuccess,
  forgetPasswordSendLinkError,
  validateRestorePasswordTokenSuccess,
  validateRestorePasswordTokenError,
  restorePasswordSuccess,
  restorePasswordError,
} from "./actions";
import AuthService from "../../../services/AuthService";
import StorageService from "../../../services/StorageService";

const forgetPasswordSendLinkAsync = async (model) => {
  return await AuthService.forgotPassword(model);
};

const validateRestorePasswordTokenAsync = async (model) => {
  return await AuthService.validateRestorePasswordToken(model);
};

const restorePasswordAsync = async (model) => {
  return await AuthService.restorePassword(model);
};

function* forgetPasswordSendLink({ payload }) {
  try {
    yield call(forgetPasswordSendLinkAsync, payload);
    yield put(forgetPasswordSendLinkSuccess());
  } catch (error) {
    yield put(forgetPasswordSendLinkError(error));
  }
}

function* validateRestorePasswordToken({ payload: { token, history } }) {
  try {
    yield call(validateRestorePasswordTokenAsync, { token });
    yield put(validateRestorePasswordTokenSuccess());
  } catch (error) {
    yield put(validateRestorePasswordTokenError(error));
    const user = StorageService.user.value;
    history.push(user ? "/settings" : "/login");
  }
}

function* restorePassword({ payload: { model, history } }) {
  try {
    yield call(restorePasswordAsync, model);
    yield put(restorePasswordSuccess());
    history.push("/login");
  } catch (error) {
    yield put(restorePasswordError(error));
  }
}

export function* watchForgetPasswordSendLink() {
  yield takeEvery(FORGET_PASSWORD_SEND_LINK, forgetPasswordSendLink);
}

export function* watchValidateRestorePasswordToken() {
  yield takeEvery(
    VALIDATE_RESTORE_PASSWORD_TOKEN,
    validateRestorePasswordToken
  );
}

export function* watchRestorePassword() {
  yield takeEvery(RESTORE_PASSWORD, restorePassword);
}

function* forgetPasswordSaga() {
  yield all([
    fork(watchForgetPasswordSendLink),
    fork(watchValidateRestorePasswordToken),
    fork(watchRestorePassword),
  ]);
}

export default forgetPasswordSaga;
