export const EMPTY_PARAGRAPH = "<p></p>";
export const NON_BREAKING_SPACE = "&nbsp;";
export const EMPTY_PARAGRAPH_REG_EXP = /<p>|<\/p>/gm;
export const EMPTY_PARAGRAPH_WITH_LINK_REG_EXP = /<a(.+?)>|<\/a>|<p>|<\/p>/gm;

export const EDITOR_ONLY_LINK_CONFIGURATION = {
  options: ["link"],
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    linkCallback: undefined,
  },
};
