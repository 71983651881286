import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class BundlesService extends Http {
  BASE_URL = "/admins/bundles";

  getList(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}`, {
      params: {
        limit,
        offset,
      },
    });
  }

  create(model) {
    return this.post(`${this.BASE_URL}`, model);
  }

  async getOne(id) {
    const { data } = await this.get(`${this.BASE_URL}/${id}`);
    if (!data.image) {
      return { data };
    }
    const initialFile = data.image;
    initialFile.preview = data.image.resizedImages
      ? data.image.resizedImages[Object.keys(data.image.resizedImages)[0]]
      : data.image.link;
    initialFile.size = null;

    return { data: { ...data, initialFile } };
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }

  changeStatus(id) {
    return this.patch(`${this.BASE_URL}/${id}/status`);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }

  addImageToBundle(id, model) {
    return this.post(`${this.BASE_URL}/${id}/image`, model);
  }


  // addVideo(id, model) {
  //   return this.post(`${this.BASE_URL}/${id}/video`, model);
  // }

  // changeVideoThumbnail(bundleId, videoThumbnailId) {
  //   return this.put(
  //     `${this.BASE_URL}/${bundleId}/video/thumbnail`,
  //     {
  //       videoThumbnailId,
  //     },
  //     {
  //       disableToast: true,
  //     }
  //   );
  // }

  // getAllBundlesList(pagination = {}) {
  //   const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

  //   return this.get(`/bundles`, {
  //     params: {
  //       limit,
  //       offset,
  //     },
  //   });
  // }
}

export default new BundlesService();
