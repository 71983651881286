import React from "react";

import formatDate from "../../../../helpers/formatDate";
import CellWithTooltip from "../../../../components/Table/CellWithTooltip";
import formatTabelCell from "../../../../helpers/formatTabelCell";

const rowStyles = {
  minWidth: "40%",
  width: "40%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const rowStylesTitle = {
  ...rowStyles,
  maxWidth: "450px",
};

const usagesTableStructure = () => [
  {
    dataField: "paymentTransactionDetails",
    text: "Course title",
    style: rowStylesTitle,
    formatter: (cellContent) => (
      <CellWithTooltip>
        {cellContent.map(({ courseTitle }) => courseTitle)}
      </CellWithTooltip>
    ),
  },
  {
    dataField: "user",
    text: "Username",
    style: rowStyles,
    formatter: (cellContent) => (
      <>{formatTabelCell(cellContent?.userName)}</>
    ),
  },
  {
    dataField: "createdAt",
    text: "Purchase date",
    formatter: (cellContent) => <>{formatDate(cellContent)}</>,
  },
];

export default usagesTableStructure;
