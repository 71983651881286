import React from "react";
import { Link } from "react-router-dom";
import queryParser from "../../../helpers/queryParser";
import formatDate from "../../../helpers/formatDate";
import getBraintreePaymentUrl from "../../../helpers/getBraintreePaymentUrl";

// import styles from "./index.module.scss";
import SortElement from "../../../components/Table/SortElement";
import getPaidCurrency from "../../../helpers/getPaidCurrency";
import formatTabelCell from "../../../helpers/formatTabelCell";
import getChargebeeInvoiceUrl from '../../../helpers/getChargebeeInvoiceUrl';
import dashboardStyles from "../dashboard.module.scss";
import componentStyles from "./components/index.module.scss";
import useModal from '../../../hooks/useModal';
import classNames from "classnames";
import ConfirmationDialog from '../../../components/Modals/ConfirmationDialog';

const DELETE_MODAL_TITLE = "Delete Product Activation Code";
const DELETE_MODAL_DESCRIPTION =
  "The Product activation code will be deleted. Are you sure you want to delete it?";

const ProductActivationCodeAction = ({ productActivationCode, removeProductActivationCode }) => {
  const [modalVisible, toggleModal] = useModal();
  const handleToggleModal = () => toggleModal((prevState) => !prevState);

  // console.log(modalVisible);

  const handleConfirmRemove = () => {
    toggleModal();
    removeProductActivationCode(productActivationCode.id);
  };

  return (
    <>
     <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <div className="d-flex justify-content-end w-220px font-size-20 pr-5 ">
        {/* <div className='cursor-pointer' onClick={handleToggleModal}>
          <i className="bx bx-play-circle text-grey mr-3" />
        </div>
        <div className='cursor-pointer' onClick={handleToggleModalVideoWhereUsed}>
          <i className="bx bx-show text-grey mr-3" />
        </div>
        <Link to={`/video-library/${productActivationCode.id}`} onClick={(event) => event.stopPropagation()}>
          <i className="bx bx-edit text-grey mr-3" />
        </Link> */}
        <div className='cursor-pointer' onClick={() => toggleModal()}>
          <i className="bx bx-trash text-grey" />
        </div>
     </div>
    </>
   
  )
}

export default function productActivationCodesStructure(removeProductActivationCode, location) {
  const { orderType } = queryParser(location.search);

  return [
    {
      dataField: "id",
      text: "PA Code Id",
      formatter: (cellContent) => (
        <>{formatTabelCell(cellContent)}</>
      ),
    },
    {
      dataField: "activationCode",
      text: "PA Code",
      formatter: (cellContent) => (
        <>{formatTabelCell(cellContent)}</>
      ),
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cellContent) => (
        <>{formatTabelCell(cellContent)}</>
      ),
    },
    {
      text: "Course",
      formatter: (cellContent, rowContent) => (
        <>{rowContent.course ? rowContent.course.title : '-'}</>
      ),
    },
    {
      text: "Redeemed By",
      formatter: (cellContent, rowContent) => (
        <>{rowContent.user ? rowContent.user.userName : '-'}</>
      ),
    },
    {
      dataField: "redeemedAt",
      text: "Is Redeemed",
      formatter: (cellContent) => (
        <>{cellContent ? 'Yes' : 'No'}</>
      ),
    },
    {
      text: "Action",
      formatter: (cellContent, rowContent) => <ProductActivationCodeAction productActivationCode={rowContent} removeProductActivationCode={removeProductActivationCode} />,
    },
  ];
}
