import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { MAX_SEARCH_LENGTH } from "../../constants/leghtValidity";
import queryParser from "../../helpers/queryParser";
import useDebounce from "../../hooks/useDebounce";
import SearchBar from "../Common/searchBar";

export const TabelSearch = ({ toolkitProps }) => {
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const [searchQuery, setSearchQuery] = useState(query.q);

  const debouncedSearchTerm = useDebounce(searchQuery);

  const handleInputSearch = ({ target: { value } }) => {
    const trimmedSearch = value.trimLeft();
    if (trimmedSearch.length <= MAX_SEARCH_LENGTH) {
      setSearchQuery(trimmedSearch);
    }
  };

  useEffect(() => {
    toolkitProps.searchProps.onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm, toolkitProps]);

  return (
    <section className="d-flex flex-row justify-content-between">
      <div className="search-box ms-2 mb-2 d-inline-block">
        <SearchBar
          handleEnterSearch={handleInputSearch}
          searchQuery={searchQuery}
          inputClassName="user-list__search"
        />
      </div>
    </section>
  );
};

export default TabelSearch;
