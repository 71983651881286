import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveBundle from "../../../components/Forms/SaveBundle";
import RouteLeavingGuard from "../../../routes/middleware/RouteLeavingGuard";

import {
  clearBundleFetched,
  getBundle,
  updateBundle,
} from "../../../store/dashboard/Bundles/actions";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import ProgressWatcher from "../../../components/Common/ProgressWatcher";
import SEO from "../../../components/Common/SEO";

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const Update = ({
  filesError,
  clearBundleFetched,
  getBundle,
  updateBundle,
  bundles,
}) => {
  const { updated, item, loading } = bundles;
  const [isDirty, updateDirty] = useState(false);
  const [ready, updateReadyState] = useState(false);
  const {
    params: { bundleId },
  } = useRouteMatch("/bundles/:bundleId");
  const history = useHistory();
  const saveBundleCallback = useCallback(
    (model) => {
      updateBundle({ bundleId, model }, history);
    },
    [item]
  );
  const isBlocked = (updated ? false : isDirty) && !filesError;

  useEffect(() => {
    if (!ready && bundleId && !loading) {
      getBundle(bundleId);
      updateReadyState(true);
    }
  }, [bundleId, ready, loading]);

  useEffect(() => {
    return () => {
      clearBundleFetched();
    };
  }, []);

  return (
    <>
      <SEO title="Edit Bundle" />
      <RouteLeavingGuard
        contentText="Changed data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <ScrollbarVertical>
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Edit Bundle
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                {item && (
                  <SaveBundle
                    model={item}
                    saveBundleFunc={saveBundleCallback}
                    updateDirtyState={updateDirty}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </ScrollbarVertical>
    </>
  );
};

const mapStateToProps = ({ Progress, Bundles }) => {
  return { filesError: Progress.error, bundles: Bundles };
};

export default connect(mapStateToProps, {
  clearBundleFetched,
  getBundle,
  updateBundle,
})(Update);
