import {
  GET_LIST_ADMINS,
  GET_LIST_ADMINS_SUCCESS,
  GET_LIST_ADMINS_ERROR,
  CHANGE_ADMIN_STATUS,
  CHANGE_ADMIN_STATUS_SUCCESS,
  CHANGE_ADMIN_STATUS_ERROR,
  NEED_UPDATE_ADMIN_LIST_CLEAR,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  CREATE_ADMIN_CLEAR,
} from "./actionTypes";

export const getListAdmins = (payload) => {
  return {
    payload,
    type: GET_LIST_ADMINS,
  };
};

export const getListAdminsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_ADMINS_SUCCESS,
  };
};

export const getListAdminsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_ADMINS_ERROR,
  };
};

export const changeAdminStatus = (id, status) => {
  return {
    payload: { id, status },
    type: CHANGE_ADMIN_STATUS,
  };
};

export const changeAdminStatusSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CHANGE_ADMIN_STATUS_SUCCESS,
  };
};

export const changeAdminStatusError = ({ message }) => {
  return {
    payload: { message },
    type: CHANGE_ADMIN_STATUS_ERROR,
  };
};

export const createAdmin = (payload) => {
  return {
    payload,
    type: CREATE_ADMIN,
  };
};

export const createAdminSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CREATE_ADMIN_SUCCESS,
  };
};

export const createAdminError = ({ message }) => {
  return {
    payload: { message },
    type: CREATE_ADMIN_ERROR,
  };
};

export const createAdminClear = () => {
  return {
    type: CREATE_ADMIN_CLEAR,
  };
};

export const needUpdateAdminListClear = () => {
  return {
    type: NEED_UPDATE_ADMIN_LIST_CLEAR,
  };
};
