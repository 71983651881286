import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import noop from "../../helpers/noop";
import CreateCouponForm from "../Forms/CreateCouponForm";

const CreateCourseCouponModal = ({
  onCancel = noop,
  onConfirm = noop,
  isOpen,
}) => {
  const saveCouponHandler = (model) => onConfirm(model);

  return (
    <Modal isOpen={isOpen} centered toggle={() => onCancel()}>
      <ModalHeader toggle={() => onCancel()}>
        Create a course coupon
      </ModalHeader>
      <ModalBody>
        <CreateCouponForm saveCouponFunc={saveCouponHandler} />
      </ModalBody>
    </Modal>
  );
};

export default CreateCourseCouponModal;
