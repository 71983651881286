export const GET_LIST_LESSONS = "GET_LIST_LESSONS";
export const GET_LIST_LESSONS_SUCCESS = "GET_LIST_LESSONS_SUCCESS";
export const GET_LIST_LESSONS_ERROR = "GET_LIST_LESSONS_ERROR";
export const CLEAR_LESSONS = 'CLEAR_LESSONS';
export const REFETCH_LESSONS_SUCCESS = 'REFETCH_LESSONS_SUCCESS';
export const REFETCH_LESSONS = 'REFETCH_LESSONS';

export const CREATE_LESSON = "CREATE_LESSON";
export const CREATE_LESSON_SUCCESS = "CREATE_LESSON_SUCCESS";
export const CREATE_LESSON_CLEAR = "CREATE_LESSON_CLEAR";
export const CREATE_LESSON_ERROR = "CREATE_LESSON_ERROR";

export const GET_LESSON = "GET_LESSON";
export const GET_LESSON_SUCCESS = "GET_LESSON_SUCCESS";
export const GET_LESSON_CLEAR = "GET_LESSON_CLEAR";
export const GET_LESSON_ERROR = "GET_LESSON_ERROR";

export const UPDATE_LESSON = "UPDATE_LESSON";
export const UPDATE_LESSON_SUCCESS = "UPDATE_LESSON_SUCCESS";
export const UPDATE_LESSON_CLEAR = "UPDATE_LESSON_CLEAR";
export const UPDATE_LESSON_ERROR = "UPDATE_LESSON_ERROR";

export const REMOVE_LESSON = "REMOVE_LESSON";
export const REMOVE_LESSON_SUCCESS = "REMOVE_LESSON_SUCCESS";
export const REMOVE_LESSON_CLEAR = "REMOVE_LESSON_CLEAR";
export const REMOVE_LESSON_ERROR = "REMOVE_LESSON_ERROR";

export const GET_LESSONS_COUNT = "GET_LESSONS_COUNT";
export const GET_LESSONS_COUNT_SUCCESS = "GET_LESSONS_COUNT_SUCCESS";
export const GET_LESSONS_COUNT_CLEAR = "GET_LESSONS_COUNT_CLEAR";
export const GET_LESSONS_COUNT_ERROR = "GET_LESSONS_COUNT_ERROR";
