import React from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import SEO from "../../../components/Common/SEO";
import useTable from "../../../hooks/useTable";
import {
  getNotificationsList,
  createNotification,
  createNotificationClear,
  updateNotification,
} from "../../../store/dashboard/Notifications/actions";
import { NOTIFICATIONS_LIST_LIMIT } from "../../../constants/paginationConstants";
import Table from "../../../components/Table";
import tableStructure from "./tableStructure";
import HeaderComponent from "./headerComponent";
import CreateNotificationForm from "../../../components/Forms/CreateNotificationForm";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import {
  NOTIFICATIONS_RADIO,
  NOTIFICATIONS_REMINDER_RADIO,
  NOTIFICATIONS_TYPES,
} from "../../../constants/notifications";

const Notifications = ({
  notifications,
  getNotificationsList,
  createNotification,
  createNotificationClear,
  updateNotification,
}) => {
  const { data, pagination, loading, handleTableChange, query } = useTable(
    notifications,
    getNotificationsList,
    NOTIFICATIONS_LIST_LIMIT,
    createNotificationClear
  );

  return (
    <ScrollbarVertical>
      <SEO title="Notifications management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box">
                <h4 className="mb-0 font-size-16">
                  Notifications management
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="6">
              <CreateNotificationForm
                saveFunc={createNotification}
                cardTitle="Create notification"
                defaultNotificationType={NOTIFICATIONS_TYPES.EVERYONE_ACTIVE}
                notificationRadioBtns={NOTIFICATIONS_RADIO}
              />
            </Col>
            <Col xs="6">
              <CreateNotificationForm
                isReminderNotification
                saveFunc={updateNotification}
                cardTitle="Reminder push-notifications"
                defaultNotificationType={NOTIFICATIONS_TYPES.STUDENTS_INACTIVE}
                notificationRadioBtns={NOTIFICATIONS_REMINDER_RADIO}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table
                HeaderComponent={HeaderComponent}
                dataStructure={tableStructure}
                data={data}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={NOTIFICATIONS_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                isRowClick={false}
                placeholderText="There are no created notifications yet"
                hoverRows={false}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Notifications: notifications }) => ({
  notifications,
});

export default connect(mapStateToProps, {
  getNotificationsList,
  createNotification,
  createNotificationClear,
  updateNotification,
})(Notifications);
