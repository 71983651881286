import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";

import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import useModal from "../../hooks/useModal";

const RouteLeavingGuard = ({
  when,
  navigate,
  shouldBlockNavigation,
  titleText = "Cancel",
  contentText = "",
}) => {
  const [modalVisible, toggleModal] = useModal(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      toggleModal(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    toggleModal(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={titleText}
        contentText={contentText}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmNavigationClick}
        altBtnStyle
      />
    </>
  );
};
export default RouteLeavingGuard;
