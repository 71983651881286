export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";

export const GET_USERS_PROFILE = 'GET_USERS_PROFILE';
export const GET_USERS_PROFILE_SUCCESS = 'GET_USERS_PROFILE_SUCCESS';
export const GET_USERS_PROFILE_ERROR = 'GET_USERS_PROFILE_ERROR';

export const CHANGE_USERS_STATUS = 'CHANGE_USERS_STATUS';
export const CHANGE_USERS_STATUS_SUCCESS = 'CHANGE_USERS_STATUS_SUCCESS';
export const CHANGE_USERS_STATUS_ERROR = 'CHANGE_USERS_STATUS_ERROR';

export const SEND_RECOVERY_LINK = 'SEND_RECOVERY_LINK';
export const SEND_RECOVERY_LINK_SUCCESS = 'SEND_RECOVERY_LINK_SUCCESS';
export const SEND_RECOVERY_LINK_ERROR = 'SEND_RECOVERY_LINK_ERROR';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_ERROR = 'SET_PASSWORD_ERROR';

export const PROVIDE_SUBSCRIPTION = 'PROVIDE_SUBSCRIPTION';
export const PROVIDE_SUBSCRIPTION_SUCCESS = 'PROVIDE_SUBSCRIPTION_SUCCESS';
export const PROVIDE_SUBSCRIPTION_ERROR = 'PROVIDE_SUBSCRIPTION_ERROR';

export const GET_USER_COURSES = 'GET_USER_COURSES';
export const GET_USER_COURSES_SUCCESS = 'GET_USER_COURSES_SUCCESS';
export const GET_USER_COURSES_ERROR = 'GET_USER_COURSES_ERROR';

export const GET_NOT_PURCHASED_USER_COURSES = "GET_NOT_PURCHASED_USER_COURSES";
export const GET_NOT_PURCHASED_USER_COURSES_SUCCESS = "GET_NOT_PURCHASED_USER_COURSES_SUCCESS";
export const GET_NOT_PURCHASED_USER_COURSES_ERROR = "GET_NOT_PURCHASED_USER_COURSES_ERROR";

export const ADD_COURSE_TO_USER = "ADD_COURSE_TO_USER";
export const ADD_COURSE_TO_USER_SUCCESS = "ADD_COURSE_TO_USER_SUCCESS";
export const ADD_COURSE_TO_USER_ERROR = "ADD_COURSE_TO_USER_ERROR";

export const REMOVE_COURSE_FROM_USER = "REMOVE_COURSE_FROM_USER";
export const REMOVE_COURSE_FROM_SUCCESS = "REMOVE_COURSE_FROM_USER_SUCCESS";
export const REMOVE_COURSE_FROM_USER_ERROR = "REMOVE_COURSE_FROM_USER_ERROR";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

export const REVOKE_SUBSCRIPTION = "REVOKE_SUBSCRIPTION";
export const REVOKE_SUBSCRIPTION_ERROR = "REVOKE_SUBSCRIPTION_ERROR";
export const REVOKE_SUBSCRIPTION_SUCCESS = "REVOKE_SUBSCRIPTION_SUCCESS";

export const UPDATE_COURSE_LIST_CLEAR = "UPDATE_COURSE_LIST_CLEAR"
export const CLEAR_STATE = 'CLEAR_STATE';
