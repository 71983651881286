import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";

import { getUnreadCount } from "../../store/dashboard/Comments/actions";
import { UPDATE_UNREAD_COMMENTS_DELAY } from "../../constants/defaultParameters";
import StorageService from "../../services/StorageService";
import { SUPER_ADMIN } from "../../constants/userRoles";

const DASHBOARD_LINKS = [
  {
    name: "Users",
    link: "/users",
    icon: "bx-user-circle",
    isSuperAdminRoute: true,
  },
  {
    name: "Payments",
    link: "/payments",
    icon: "bx-wallet",
    isSuperAdminRoute: true,
  },
  {
    name: "Comments",
    link: "/comments",
    icon: "bx-comment-edit",
    withBadge: true,
    exact: false,
    associations: ["/individual-content"],
  },
  {
    name: "Coupons",
    link: "/coupons",
    icon: "bx-store",
    isSuperAdminRoute: true,
  },
  {
    name: "Sale",
    link: "/sale",
    icon: "bx-cart",
    isSuperAdminRoute: true,
  },
  {
    name: "Notifications",
    link: "/notifications",
    icon: "bx-receipt",
    isSuperAdminRoute: true,
  },
  {
    name: "Training",
    link: "/training",
    icon: "bx-briefcase",
    exact: false,
    associations: ["/lessons", "/courses"],
  },
  {
    name: "Bundle",
    link: "/bundles",
    icon: "bx-box",
    exact: false,
    // associations: ["/lessons", "/courses"],
  },
  {
    name: "DJ Hub",
    link: "/dj-hub",
    icon: "bx-task",
  },
  {
    name: "Admins",
    link: "/admins",
    icon: "bxs-user-detail",
    isSuperAdminRoute: true,
  },
  {
    name: "Settings",
    link: "/settings",
    icon: "bx-wrench",
  },
  {
    name: "DJ Hub analytics",
    link: "/djhub-analytics",
    icon: "bx-bar-chart-square",
    isSuperAdminRoute: true,
  },
  {
    name: "Reward",
    link: "/rewards",
    icon: "bx-gift",
    exact: false,
    // associations: ["/lessons", "/courses"],
  },
  {
    name: "Product Activation Codes",
    link: "/product-activation-codes",
    icon: "bx-key",
    exact: false,
    // associations: ["/lessons", "/courses"],
  },
];

/**
 * This menu now supports only one level with additional parameters for active state
 * */
const SidebarContent = ({ t, location, comments, getUnreadCount }) => {
  /**
   * Function for active link state control
   * @param {string} link - relative link for navigation
   * @param {boolean} exact - if `true` - full path match mode (default)
   * @param {Array | null} associations - array of relative routes for navigation item, automatically negates exact parameter
   * */
  const isActiveLink = (link, exact = true, associations = null) => {
    if (exact) {
      return location.pathname.includes(link);
    }
    if (associations && Array.isArray(associations)) {
      const compareToArray = [...associations, link];
      return compareToArray.reduce(
        (acc, val) => acc || location.pathname.startsWith(val),
        false
      );
    }
    return location.pathname.startsWith(link);
  };

  const { unreadCount } = comments;

  const user = StorageService.user.value;

  useEffect(() => {
    if (!unreadCount) getUnreadCount();

    const timerId = setInterval(() => {
      getUnreadCount();
    }, UPDATE_UNREAD_COMMENTS_DELAY);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const getDashboardLinks = useMemo(() => {
    if (user?.role === SUPER_ADMIN) {
      return DASHBOARD_LINKS;
    }
    return DASHBOARD_LINKS.filter((link) => !link.isSuperAdminRoute);
  }, [user]);

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li className="menu-title">{t("Menu")} </li>
        {getDashboardLinks.map(
          ({ name, link, icon, exact, associations, withBadge }, index) => (
            <li key={"sidebar-menu-item-" + index}>
              <NavLink
                to={link}
                className="waves-effect d-flex flex-row"
                activeClassName="mm-active"
                isActive={() => isActiveLink(link, exact, associations)}
              >
                <i className={"bx " + (icon ? icon : "bx-home-circle")} />
                <span>{name}</span>
                {withBadge && !!unreadCount && (
                  <div className="d-flex ml-auto badge">
                    <span className="m-auto">{unreadCount}</span>
                  </div>
                )}
              </NavLink>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ Comments }) => {
  return {
    comments: Comments,
  };
};

export default withRouter(
  withNamespaces()(connect(mapStateToProps, { getUnreadCount })(SidebarContent))
);
