/**
 * This function could be used for index formation in
 * */

const formatIndex = (value) => {
  if (!value) {
    throw new Error("No value performed");
  }
  const index = parseInt(value, 10);

  return index >= 0 && index <= 9 ? `0${index}` : index;
};

export default formatIndex;
