import Http from "./HttpService";

class CommentsService extends Http {
  BASE_URL = "/admins/comments";
  BASE_URL_LESSONS = "/admins/lessons";
  BASE_URL_PREMIUM_ARTICLES = "/admins/premium-articles";

  getList({
    limit,
    offset,
    q,
    filters,
    isPremiumArticlesComments = false,
    id,
  }) {
    return this.get(
      `${
        isPremiumArticlesComments
          ? this.BASE_URL_PREMIUM_ARTICLES
          : this.BASE_URL_LESSONS
      }${id ? "/" + id : ""}/comments`,
      {
        params: {
          limit,
          offset,
          q,
          filters,
        },
      }
    );
  }

  getListIndividualComments({
    limit,
    offset,
    q,
    filters,
    isPremiumArticlesComments = false,
    id,
  }) {
    return this.get(
      `${
        isPremiumArticlesComments
          ? this.BASE_URL_PREMIUM_ARTICLES
          : this.BASE_URL_LESSONS
      }/${id}/comments`,
      {
        params: {
          limit,
          offset,
          q,
          filters,
        },
      }
    );
  }

  createReply(id, model) {
    return this.post(`${this.BASE_URL}/${id}/replies`, model);
  }

  getUnreadCount() {
    return this.get(`${this.BASE_URL}/unread-count`);
  }

  readComment(id) {
    return this.patch(`${this.BASE_URL}/${id}/read`);
  }

  readAllComments(commentProductType) {
    return this.put(`${this.BASE_URL}/is-admin-read`, null, {
      params: {
        commentProductType,
      },
    });
  }

  approveComment(id) {
    return this.patch(`${this.BASE_URL}/${id}/approve`);
  }

  deleteComment(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }
}

export default new CommentsService();
