import React from "react";
import { Card } from "reactstrap";

import getLabel from "../../../../helpers/getLabel";
import equipmentConst from "../../../../constants/equipment";
import djSoftwareConst from "../../../../constants/djSoftware";
import genres from "../../../../constants/genres";

export default function Preferenses({
  equipment,
  djSoftware,
  favouriteGenres,
}) {
  const questions = [
    {
      question: "What equipment do you play on?",
      answer: getLabel(equipment, equipmentConst),
    },
    {
      question: "What DJ software do you use?",
      answer: getLabel(djSoftware, djSoftwareConst),
    },
    {
      question: "What is your favourite genre to mix?",
      answer:
        !favouriteGenres || !favouriteGenres.length
          ? "-"
          : favouriteGenres
              .map(({ genre }) => getLabel(genre, genres))
              .join(", "),
    },
  ];

  return (
    <Card className="user-details--preferences">
      <span className="user-details--preferences__title">User preferences</span>

      {questions.map((item, index) => (
        <section className="d-flex flex-column" key={index}>
          <span className="user-details--preferences__question">
            {item.question}
          </span>
          <p className="user-details--preferences__reply mb-0">{item.answer}</p>
        </section>
      ))}
    </Card>
  );
}
