import {
  COUPON_BODY_REGEX,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  USERNAME_FALSE_UNDERSCORE,
  USERNAME_REGEX,
} from "./regex";
import moment from "moment";
import { DEFAULT_DATE_INPUT_FORMAT } from "../constants/defaultParameters";
import {
  EMPTY_PARAGRAPH,
  EMPTY_PARAGRAPH_REG_EXP,
  EMPTY_PARAGRAPH_WITH_LINK_REG_EXP,
} from "../constants/editorConstants";
import stringToDate from "../helpers/stringToDate";

const spaceOnlyValidator =
  (message = "Can’t contain spaces only") =>
    (value, ctx, input, cb) => {
      if (value && value.length > 0 && value.trim() === "") {
        cb(message);
      } else {
        cb(true);
      }
    };

export const requiredIfOtherExist =
  (targetField, message = "This field is required") =>
    (value, ctx, input, cb) => {
      if (ctx[targetField] !== "" && value === "") {
        cb(message);
      } else {
        cb(true);
      }
    };

const firstLetterDigit =
  (message = "The first symbol must be a digit") =>
    (value, ctx, input, cb) => {
      cb(
        value === "" ||
        (typeof value === "string" && /^\d/.test(value.trim())) ||
        message
      );
    };

const multiSelectValidator =
  (message, uniqueMessage, maxLength) => (value, ctx, input, cb) => {
    if (value.length > 0) {
      const currentVales = [...value];
      let lastValue = currentVales.pop();

      lastValue = typeof lastValue === "string" ? lastValue.trim() : lastValue;

      if (currentVales.includes(lastValue)) cb(uniqueMessage);
      value.forEach((item) => {
        if (item.length > maxLength) cb(message);
      });
    }

    cb(true);
  };

const dateRangeValidator =
  (
    start,
    end,
    messageStart = "Invalid date",
    messageEnd = "Invalid date",
    comparisonOnlyByDay = true
  ) =>
    (value, ctx, input, cb) => {
      if (value === "") {
        cb(true);
      } else {
        const targetDate = comparisonOnlyByDay
          ? moment(value).utcOffset(0)
          : moment(stringToDate(value), DEFAULT_DATE_INPUT_FORMAT);
        const startDate = moment(start);
        const endDate = moment(end);

        if (targetDate.isBefore(startDate, comparisonOnlyByDay && "day")) {
          cb(messageStart);
        } else if (targetDate.isAfter(endDate, comparisonOnlyByDay && "day")) {
          cb(messageEnd);
        } else {
          cb(true);
        }
      }
    };
const notLaterThen =
  (
    fieldName,
    params,
    message = "Invalid date",
    earlierMessage = "Invalid date"
  ) =>
    (value, ctx, input, cb) => {
      if (value === "") {
        cb(true);
      } else {
        const targetDate = moment(stringToDate(value), DEFAULT_DATE_INPUT_FORMAT);
        const contextDate = ctx[fieldName]
          ? moment(stringToDate(ctx[fieldName]))
          : moment();

        if (targetDate.isBefore(contextDate, "day")) {
          cb(earlierMessage);
        } else if (
          targetDate.isAfter(
            !!params ? contextDate.add(params.amount, params.unit) : contextDate,
            "day"
          )
        ) {
          cb(message);
        } else {
          cb(true);
        }
      }
    };

const usernameValidator =
  (falseCaseMessage, falseFormatMessage, falseUnderscoreMessage) =>
    (value, ctx, input, cb) => {
      if (!value) cb(true);
      if (value !== value.toLowerCase()) {
        cb(falseCaseMessage);
      } else if (!USERNAME_REGEX.test(value)) {
        cb(falseFormatMessage);
      } else if (!USERNAME_FALSE_UNDERSCORE.test(value)) {
        cb(falseUnderscoreMessage);
      } else {
        cb(true);
      }
    };

const twoFieldsMatchValitator =
  (message, otherField) => (value, ctx, input, cb) => {
    value === ctx[otherField] ? cb(message) : cb(true);
  };

const twoFieldsDontMatchValitator =
  (message, otherField) => (value, ctx, input, cb) => {
    value === ctx[otherField] ? cb(true) : cb(message);
  };

const spaceOnlyValidatorForTextEditor =
  (
    maxLengthMessage,
    maxLengthValue,
    requiredMessage = "This field is required",
    spaceOnlyMessage = "Can’t contain spaces only"
  ) =>
    (value, ctx, input, cb) => {
      if (value === EMPTY_PARAGRAPH || !value) {
        cb(requiredMessage);
      } else if (!value.replace(EMPTY_PARAGRAPH_REG_EXP, "").trim()) {
        cb(spaceOnlyMessage);
      } else if (
        value.replace(EMPTY_PARAGRAPH_WITH_LINK_REG_EXP, "").length >=
        maxLengthValue
      ) {
        cb(maxLengthMessage);
      } else {
        cb(true);
      }
    };

const unlimitedFieldValidator =
  (otherField, fieldName) => (value, ctx, input, cb) => {
    if (!(ctx[otherField]?.length === 0)) {
      cb(true);
      return;
    }

    const tempValue = String(value)?.trim();

    if (value && value.length > 0 && tempValue === "")
      cb("Can’t contain spaces only");
    if (!tempValue) cb(`${fieldName} cycles is required`);
    if (!tempValue.match(/^[0-9]\d*$/))
      cb(`${fieldName} cycles can contain digits only`);
    if (+tempValue === 0) cb(`${fieldName} cycles can’t be equal to 0`);
    if (tempValue > 100000) cb(`${fieldName} can’t be bigger than 100000`);

    cb(true);
  };

export default {
  courseCategory: {
    name: {
      async: spaceOnlyValidator(
        "Course category name can’t contain spaces only"
      ),
      required: { value: true, errorMessage: "Name is required" },
      minLength: {
        value: 1,
        errorMessage: "Course category name can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Course category name can be up to 100 symbols",
      },
    },
  },
  course: {
    title: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "Course title is required" },
      minLength: {
        value: 1,
        errorMessage: "Course title can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Course title can be up to 100 symbols",
      },
    },
    overview: {
      required: { value: true, errorMessage: "“Overview” field is required" },
      async: spaceOnlyValidator("“Overview” field can’t contain spaces only"),
    },
    lessons: {
      required: { value: true, errorMessage: "Lessons is required" },
      async: spaceOnlyValidator("“Lessons” field can’t contain spaces only"),
    },
    equipment: {
      required: { value: true, errorMessage: "“Equipment” field is required" },
      async: spaceOnlyValidator("“Equipment” field can’t contain spaces only"),
    },
    included: {
      async: spaceOnlyValidator(
        "“What’s included” field can’t contain spaces only"
      ),
      required: {
        value: true,
        errorMessage: "“What’s included” field is required",
      },
      // minLength: {
      //   value: 1,
      //   errorMessage: "“What’s included” text can be up to 2000 symbols",
      // },
      // maxLength: {
      //   value: 2000,
      //   errorMessage: "“What’s included” text can be up to 2000 symbols",
      // },
    },
    aboutTutor: {
      async: spaceOnlyValidator(
        "“About the tutor” field can’t contain spaces only"
      ),
      required: {
        value: true,
        errorMessage: "“About the tutor” field is required",
      },
      minLength: {
        value: 1,
        errorMessage: "“About the tutor” text can be up to 2000 symbols",
      },
      maxLength: {
        value: 2000,
        errorMessage: "“About the tutor” text can be up to 2000 symbols",
      },
    },
    convertkitTagId: {
      required: { value: true, errorMessage: "This field is required" },
    },
    author: {
      async: spaceOnlyValidator("Author name can’t contain spaces only"),
      minLength: {
        value: 1,
        errorMessage: "Author name can be up to 50 symbols",
      },
      maxLength: {
        value: 50,
        errorMessage: "Author name can be up to 50 symbols",
      },
    },
    feedback: {
      async: spaceOnlyValidator("“Feedback” field can’t contain spaces only"),
      minLength: {
        value: 1,
        errorMessage: "Feedback text can be up to 400 symbols",
      },
      maxLength: {
        value: 400,
        errorMessage: "Feedback text can be up to 400 symbols",
      },
    },
    prices: {
      required: { value: true, errorMessage: "This field is required" },
      maxLength: {
        value: 11,
        errorMessage: "Price value can be up to 11 symbols",
      },
      number: {
        value: true,
        errorMessage: "This field can contain digits and 1 period",
      },
      max: {
        value: 99999999.99,
        errorMessage: "Must be less than or equal to 99999999.99",
      },
      min: {
        value: 0.01,
        errorMessage: "Price must be a positive number",
      },
      pattern: {
        value: /^\d+(?:[,.]\d{0,2})?$/,
        errorMessage: "Price can not contain more 2 digits in period",
      },
      async: firstLetterDigit(),
    },
    urlPart: {
      required: {
        value: true,
        errorMessage: "Course “Editable URL part” is required",
      },
      pattern: {
        value: /^[a-zA-Z0-9~_.-]*$/,
        errorMessage:
          "Course “Editable URL part” can contain digits, letters, “-”, “.”, “_”, “~”",
      },
      minLength: {
        value: 1,
        errorMessage: "Course “Editable URL part” can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Course “Editable URL part” can be up to 100 symbols",
      },
    },
  },
  reward: {
    title: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "Reward Name is required" },
    },
    subTitle: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "Reward Title is required" },
    },
    description: {
      required: { value: true, errorMessage: "“Reward Copy” field is required" },
      async: spaceOnlyValidator("“Reward Copy” field can’t contain spaces only"),
    },
    benefitDescription: {
      required: { value: true, errorMessage: "Benefit Copy” field is required" },
      async: spaceOnlyValidator("Benefit Copy” field can’t contain spaces only"),
    },
    firstButtonText: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "First Button text is required" },
    },
    firstButtonLink: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "First Button link is required" },
    },
    ordinalNumber: {
      async: spaceOnlyValidator(),
      pattern: {
        value: /^[-+]?[0-9]\d*$/,
        errorMessage: "You can enter digits only",
      },
      min: {
        value: 1,
        errorMessage: "The ordinal number is incorrect",
      },
    },
  },
  bundle: {
    courses: {
      async: multiSelectValidator(
        "Course can be up to 30 symbols",
        "Course must be unique",
        30
      ),
      required: {
        value: true,
        errorMessage: "Course is required",
      },
    },
  },
  lessons: {
    title: {
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "Lesson title is required" },
      minLength: {
        value: 1,
        errorMessage: "Lesson title can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Lesson title can be up to 100 symbols",
      },
    },
    urlPart: {
      required: {
        value: true,
        errorMessage: "Lesson “Editable URL part” is required",
      },
      pattern: {
        value: /^[a-zA-Z0-9~_.-]*$/,
        errorMessage:
          "Lesson “Editable URL part” can contain digits, letters, “-”, “.”, “_”, “~”",
      },
      minLength: {
        value: 1,
        errorMessage: "Lesson “Editable URL part” can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Lesson “Editable URL part” can be up to 100 symbols",
      },
    },
    ordinalNumber: (max) => ({
      async: spaceOnlyValidator(),
      required: { value: true, errorMessage: "Ordinal number is required" },
      pattern: {
        value: /^[-+]?[0-9]\d*$/,
        errorMessage: "You can enter digits only",
      },
      min: {
        value: 1,
        errorMessage: "The ordinal number is incorrect",
      },
      max: {
        value: max,
        errorMessage: "The ordinal number is too big",
      },
    }),
  },
  file: {
    image: {
      maxSize: {
        value: 30 * 1000000, // 30 Mb
        errorMessage: "File is too big. Maximum image size is 30Mb",
      },
      formats: {
        value: ["jpg", "jpeg", "png"],
        errorMessage:
          "Image format is invalid. Please select JPG, JPEG or PNG file",
      },
    },
    video: {
      maxDuration: {
        value: 60, // 60 minutes,
        errorMessage: "Video is too long. It can be up to 60 minutes",
      },
      maxSize: {
        value: 1024 * 1024 * 1024 * 15, // 15 Gb
        errorMessage: "File is too big. Maximum image size is 15Gb",
      },
      formats: {
        value: ["mp4"],
        errorMessage: "Video format is invalid. Please select MP4 file",
      },
    },
  },
  category: {
    async: spaceOnlyValidator("Category name can’t contain spaces only"),
    required: {
      value: true,
      errorMessage: "Category name is required",
    },
    minLength: {
      value: 1,
      errorMessage: "Category can be up to 50 symbols",
    },
    maxLength: {
      value: 50,
      errorMessage: "Category can be up to 50 symbols",
    },
  },
  tag: {
    async: spaceOnlyValidator("Tag can’t contain spaces only"),
    required: {
      value: true,
      errorMessage: "Tag name is required",
    },
    minLength: {
      value: 1,
      errorMessage: "Tag can be up to 30 symbols",
    },
    maxLength: {
      value: 30,
      errorMessage: "Tag can be up to 30 symbols",
    },
  },
  premiumArticle: {
    title: {
      async: spaceOnlyValidator("Title can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "Title is required",
      },
      minLength: {
        value: 1,
        errorMessage: "Title can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage: "Title can be up to 100 symbols",
      },
    },
    description: {
      async: spaceOnlyValidator("Description can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "Description is required",
      },
      minLength: {
        value: 1,
        errorMessage: "Description can be up to 500 symbols",
      },
      maxLength: {
        value: 500,
        errorMessage: "Description can be up to 500 symbols",
      },
    },
    premiumArticleSectionId: {
      required: { value: true, errorMessage: "This field is required" },
    },
    categories: {
      async: multiSelectValidator(
        "Category can be up to 50 symbols",
        "Category must be unique",
        50
      ),
      required: {
        value: true,
        errorMessage: "Category is required",
      },
    },
    tags: {
      async: multiSelectValidator(
        "Tag can be up to 30 symbols",
        "Tag must be unique",
        30
      ),
      required: {
        value: true,
        errorMessage: "Tag is required",
      },
    },
    urlPart: {
      required: {
        value: true,
        errorMessage: "Premium article “Editable URL part” is required",
      },
      pattern: {
        value: /^[a-zA-Z0-9~_.-]*$/,
        errorMessage:
          "Premium article “Editable URL part” can contain digits, letters, “-”, “.”, “_”, “~”",
      },
      minLength: {
        value: 1,
        errorMessage:
          "Premium article “Editable URL part” can be up to 100 symbols",
      },
      maxLength: {
        value: 100,
        errorMessage:
          "Premium article “Editable URL part” can be up to 100 symbols",
      },
    },
    scheduledDate: {
      async: dateRangeValidator(
        new Date(),
        moment().add(1, "year"),
        "Date&time can't be earlier than today date and current time",
        "Date&time can’t be later than today date + 1 year",
        false
      ),
    },
  },
  email: {
    required: {
      value: true,
      errorMessage: "Email address is required",
    },
    email: {
      value: true,
      errorMessage: "Email address is invalid",
    },
    pattern: {
      value: EMAIL_REGEX,
      errorMessage: "Email address is invalid",
    },
    maxLength: {
      value: 129,
      errorMessage: "Email address is invalid",
    },
  },
  confirmEmail: {
    async: twoFieldsMatchValitator(
      "Email addresses must be different",
      "email"
    ),
    required: {
      value: true,
      errorMessage: "Email address is required",
    },
    email: {
      value: true,
      errorMessage: "Email address is invalid",
    },
    pattern: {
      value: EMAIL_REGEX,
      errorMessage: "Email address is invalid",
    },
    maxLength: {
      value: 129,
      errorMessage: "Email address is invalid",
    },
  },
  userName: {
    async: usernameValidator(
      "Username can contain only small letters",
      "Username can contain only letters, digits and underscores",
      "Username can’t contain 2 underscores in a row"
    ),
    required: {
      value: true,
      errorMessage: "Username is required",
    },
    minLength: {
      value: 3,
      errorMessage: "Username must be from 3 to 20 symbols",
    },
    maxLength: {
      value: 20,
      errorMessage: "CommentUsername must be from 3 to 20 symbols",
    },
  },
  password: {
    async: spaceOnlyValidator("Password can’t contain spaces only"),
    required: {
      value: true,
      errorMessage: "Password is required",
    },
    minLength: {
      value: 8,
      errorMessage: "Password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
    maxLength: {
      value: 50,
      errorMessage: "Password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
    pattern: {
      value: PASSWORD_REGEX,
      errorMessage: "Password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
  },
  currentPassword: {
    required: {
      value: true,
      errorMessage: "Password is required",
    },
  },
  newPassword: {
    async: twoFieldsMatchValitator("Passwords must be different", "password"),
    required: {
      value: true,
      errorMessage: "New password is required",
    },
    minLength: {
      value: 8,
      errorMessage: "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
    maxLength: {
      value: 50,
      errorMessage: "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
    pattern: {
      value: PASSWORD_REGEX,
      errorMessage: "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
    },
  },
  confirmNewPassword: {
    async: twoFieldsDontMatchValitator("Passwords don’t match", "newPassword"),
    required: {
      value: true,
      errorMessage: "Confirm new password is required",
    },
  },
  restorePassword: {
    password: {
      async: spaceOnlyValidator("New password can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "New password is required",
      },
      minLength: {
        value: 8,
        errorMessage: "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
      },
      maxLength: {
        value: 50,
        errorMessage: "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
      },
      pattern: {
        value: PASSWORD_REGEX,
        errorMessage:
          "New password must be a minimum of 8 characters and contain 1 uppercase letter and 1 digit",
      },
    },
    confirmPassword: {
      async: twoFieldsDontMatchValitator("Passwords don’t match", "password"),
      required: {
        value: true,
        errorMessage: "Repeat new password is required",
      },
      minLength: {
        value: 8,
        errorMessage: "New password must be from 8 to 50 symbols",
      },
      maxLength: {
        value: 50,
        errorMessage: "New password must be from 8 to 50 symbols",
      },
      pattern: {
        value: PASSWORD_REGEX,
        errorMessage:
          "New password should contain at least 1 letter and 1 digit",
      },
    },
  },
  comment: {
    async: spaceOnlyValidatorForTextEditor(
      "Comment can't contain more than 1000 symbols",
      1001,
      "Comment field is required",
      "Comment can't contain only spaces"
    ),
  },
  courseCoupon: {
    body: {
      required: {
        value: true,
        errorMessage: "Coupon body is required",
      },
      pattern: {
        value: COUPON_BODY_REGEX,
        errorMessage:
          "Coupon body can contain digits, capitalized/non-capitalized letters only",
      },
      maxLength: {
        value: 15,
        errorMessage: "Coupon body can be up to 15 symbols",
      },
    },
    name: {
      async: spaceOnlyValidator("Coupon name can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "Coupon name is required",
      },
      maxLength: {
        value: 35,
        errorMessage: "Coupon name can be up to 35 symbols",
      },
    },
    subscriptionPlanType: {
      required: {
        value: true,
        errorMessage: "Please, choose the subscription plan",
      },
    },
    billingCycleCount: {
      async: unlimitedFieldValidator(
        "unlimitedBilling",
        "Number of billing cycles"
      ),
    },
    maxTotalUsesNumber: {
      async: unlimitedFieldValidator(
        "unlimitedTotalUses",
        "Max number of total uses"
      ),
    },
    discount: {
      async: spaceOnlyValidator(),
      required: {
        value: true,
        errorMessage: "Discount is required",
      },
      pattern: {
        value: /^[0-9]\d*$/,
        errorMessage: "Discount can contain digits only",
      },
      min: {
        value: 1,
        errorMessage: "Discount can’t be equal 0",
      },
      max: {
        value: 99,
        errorMessage: "Discount can’t be bigger than 99%",
      },
    },
    discountSubscriptions: {
      async: spaceOnlyValidator(),
      required: {
        value: true,
        errorMessage: "Discount is required",
      },
      pattern: {
        value: /^[0-9]\d*$/,
        errorMessage: "Discount can contain digits only",
      },
      min: {
        value: 1,
        errorMessage: "Discount can’t be equal 0",
      },
      max: {
        value: 100,
        errorMessage: "Discount can’t be bigger than 100%",
      },
    },
    startDate: {
      required: {
        value: true,
        errorMessage: "Start date is required",
      },
      async: dateRangeValidator(
        new Date(),
        moment().add(1, "year"),
        "“Start date” can’t be earlier than today date",
        "“Start date” can’t be later than today date + 1 year"
      ),
    },
    endDate: {
      required: {
        value: true,
        errorMessage: "End date is required",
      },
      async: notLaterThen(
        "startDate",
        { amount: 1, unit: "year" },
        "“End date” can’t be later than “Start date” + 1 year",
        "“End date” can’t be earlier than Start date”"
      ),
    },
    couponId: {
      required: {
        value: true,
        errorMessage: "Coupon Id is required",
      },
    },
  },
  notification: {
    title: {
      async: spaceOnlyValidator("Notification title can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "Notification title is required",
      },
      minLength: {
        value: 1,
        errorMessage: "Notification title can be up to 55 symbols",
      },
      maxLength: {
        value: 55,
        errorMessage: "Notification title can be up to 55 symbols",
      },
    },
    body: {
      async: spaceOnlyValidator("Notification body can’t contain spaces only"),
      required: {
        value: true,
        errorMessage: "Notification body is required",
      },
      minLength: {
        value: 1,
        errorMessage: "Notification body can be up to 150 symbols",
      },
      maxLength: {
        value: 150,
        errorMessage: "Notification body can be up to 150 symbols",
      },
    },
    premiumArticleId: {
      async: spaceOnlyValidator("Article link can't contain spaces only"),
      required: {
        value: false,
        errorMessage: "Please, select an article from the list of suggestions"
      }
    },
  },
  chargebeeSubscriptionId: {
    async: spaceOnlyValidator("Chargebee subscription id can't contain spaces only"),
    required: {
      value: true,
      errorMessage: "Please provide Chargebee subscription id"
    }
  },
  chargebeeInvoiceId: {
    async: spaceOnlyValidator("Chargebee invoice id can't contain spaces only"),
    required: {
      value: true,
      errorMessage: "Please provide Chargebee invoice id"
    }
  }
};
