import { useState } from "react";

const useModal = (isOpen = false) => {
  const [modalVisible, setModalVisible] = useState(isOpen);
  const toggleModal = (state = null) => {
    setModalVisible(state !== null ? state : !modalVisible);
  };

  return [modalVisible, toggleModal];
};

export default useModal;
