import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import ConverterKitTagsService from "../../../services/ConverterKitTagsService";
import {
  getConverterKitTagsError,
  getConverterKitTagsSuccess,
} from "./actions";
import { GET_LIST_CONVERTER_KIT_TAGS } from "./actionTypes";

const getConverterKitTagsAsync = async () => {
  return await ConverterKitTagsService.getList();
};

function* getConverterKitTags() {
  try {
    const response = yield call(getConverterKitTagsAsync);
    yield put(getConverterKitTagsSuccess(response));
  } catch (error) {
    yield put(getConverterKitTagsError(error));
  }
}

export function* watchGetCourseCategoriesList() {
  yield takeEvery(GET_LIST_CONVERTER_KIT_TAGS, getConverterKitTags);
}

function* converterKitSaga() {
  yield all([fork(watchGetCourseCategoriesList)]);
}

export default converterKitSaga;
