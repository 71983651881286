import {
  FORGET_PASSWORD_SEND_LINK,
  FORGET_PASSWORD_SEND_LINK_SUCCESS,
  FORGET_PASSWORD_SEND_LINK_ERROR,
  FORGET_PASSWORD_SEND_LINK_CLEAR,
  VALIDATE_RESTORE_PASSWORD_TOKEN,
  VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR,
  VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_ERROR,
  RESTORE_PASSWORD_CLEAR,
  FORGET_PASSWORD_CLEAR_ERROR,
} from "./actionTypes";

export const forgetPasswordSendLink = (payload) => {
  return {
    payload,
    type: FORGET_PASSWORD_SEND_LINK,
  };
};

export const forgetPasswordSendLinkSuccess = () => {
  return {
    type: FORGET_PASSWORD_SEND_LINK_SUCCESS,
  };
};

export const forgetPasswordSendLinkError = ({ message = "Unknown error" }) => {
  return {
    type: FORGET_PASSWORD_SEND_LINK_ERROR,
    payload: message,
  };
};

export const forgetPasswordSendLinkClear = () => {
  return {
    type: FORGET_PASSWORD_SEND_LINK_CLEAR,
  };
};

export const validateRestorePasswordToken = (payload) => {
  return {
    payload,
    type: VALIDATE_RESTORE_PASSWORD_TOKEN,
  };
};

export const validateRestorePasswordTokenSuccess = () => {
  return {
    type: VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS,
  };
};

export const validateRestorePasswordTokenError = ({
  message = "Unknown error",
}) => {
  return {
    type: VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR,
    payload: message,
  };
};

export const validateRestorePasswordTokenClear = () => {
  return {
    type: VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR,
  };
};

export const restorePassword = (payload) => {
  return {
    payload,
    type: RESTORE_PASSWORD,
  };
};

export const restorePasswordSuccess = () => {
  return {
    type: RESTORE_PASSWORD_SUCCESS,
  };
};

export const restorePasswordError = ({ message = "Unknown error" }) => {
  return {
    type: RESTORE_PASSWORD_ERROR,
    payload: message,
  };
};

export const restorePasswordClear = () => {
  return {
    type: RESTORE_PASSWORD_CLEAR,
  };
};

export const forgetPasswordClearError = () => {
  return {
    type: FORGET_PASSWORD_CLEAR_ERROR,
  };
};
