import {
  GET_PAYMENTS_LIST,
  GET_PAYMENTS_LIST_ERROR,
  GET_PAYMENTS_LIST_SUCCESS,
  GET_PAYMENT,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_CLEAR,
} from "./actionTypes";

export const getPaymentsList = (payload) => {
  return {
    payload,
    type: GET_PAYMENTS_LIST,
  };
};

export const getPaymentsListSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_PAYMENTS_LIST_SUCCESS,
  };
};

export const getPaymentsListError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_PAYMENTS_LIST_ERROR,
  };
};

export const getPayment = (id) => {
  return {
    payload: { id },
    type: GET_PAYMENT,
  };
};

export const getPaymentSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_PAYMENT_SUCCESS,
  };
};

export const getPaymentError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_PAYMENT_ERROR,
  };
};

export const getPaymentClear = () => {
  return {
    type: GET_PAYMENT_CLEAR,
  };
};
