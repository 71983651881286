import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import formatSeoTitle from '../helpers/formatSeoTitle';
import SEO from './Common/SEO';

class NonAuthLayout extends Component {
    constructor(props) {
        super(props);
        this.state={};
        this.capitalizeFirstLetter.bind(this);
    }
    
    capitalizeFirstLetter = string => {
        return string.charAt(1).toUpperCase() + string.slice(2);
      };

    render() {
        return <>
            <SEO title={formatSeoTitle(this.props.location.pathname)} />
            {this.props.children}
        </>;
    }
}

export default (withRouter(NonAuthLayout));