import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import Table from "../../../components/Table";
import { ACTIVE, BLOCKED } from "../../../constants/adminStatuses";
import { ADMINS_LIST_LIMIT } from "../../../constants/paginationConstants";
import useTable from "../../../hooks/useTable";
import {
  getListAdmins,
  changeAdminStatus,
  needUpdateAdminListClear,
} from "../../../store/dashboard/Admins/actions";
import CreateAdminBtn from "./CreateAdminBtn";
import TableHeader from "./TableHeader";
import useModal from "../../../hooks/useModal";
import ConfirmationDialog from "../../../components/Modals/ConfirmationDialog";

import styles from "./index.module.scss";
import formatTabelCell from "../../../helpers/formatTabelCell";
import SEO from "../../../components/Common/SEO";

const BLOCK_MODAL_TITLE = "Block";
const BLOCK_MODAL_DESCRIPTION =
  "This admin will be blocked forever. It will be impossible to unblock this Admin. Are you sure that you want to block them?";

const Admins = ({
  admins,
  getListAdmins,
  changeAdminStatus,
  needUpdateAdminListClear,
}) => {
  const { data, pagination, loading, handleTableChange, query } = useTable(
    admins,
    getListAdmins,
    ADMINS_LIST_LIMIT,
    null,
    needUpdateAdminListClear
  );

  const [modalVisible, toggleModal] = useModal();

  const [currentAdmin, setCurrentAdmin] = useState(null);

  useEffect(() => {
    if (!!currentAdmin) {
      toggleModal();
    }
  }, [currentAdmin]);

  const adminsListStructure = () => [
    {
      dataField: "userName",
      text: "Username",
      formatter: (cellContent) => <>{formatTabelCell(cellContent)}</>,
    },
    {
      text: "Email address",
      dataField: "email",
      formatter: (cellContent) => <>{formatTabelCell(cellContent)}</>,
    },
    {
      text: "Action",
      dataField: "status",
      formatter: (cellContent, rowContent) => (
        <button
          type="button"
          className={styles.blockBtn}
          onClick={() => setCurrentAdmin(rowContent)}
          disabled={cellContent !== ACTIVE}
        >
          {cellContent === ACTIVE ? "Block" : "Blocked"}
        </button>
      ),
    },
  ];

  const handleConfirm = () => {
    !!currentAdmin && changeAdminStatus(currentAdmin.id, BLOCKED);
    setCurrentAdmin(null);
    toggleModal();
  };

  const handleCancel = () => {
    setCurrentAdmin(null);
    toggleModal()
  }

  return (
    <ScrollbarVertical>
      <SEO title="Admins management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-16">Admins management</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table
                HeaderComponent={TableHeader}
                dataStructure={adminsListStructure}
                data={data}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={ADMINS_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                placeholderText="There are no admins yet"
                isRowClick={false}
                PlaceholderButtonComponent={CreateAdminBtn}
                hoverRows={false}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={BLOCK_MODAL_TITLE}
        contentText={BLOCK_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        altBtnStyle
      />
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Admins: admins }) => ({ admins });

export default connect(mapStateToProps, {
  getListAdmins,
  changeAdminStatus,
  needUpdateAdminListClear,
})(Admins);
