import { useEffect, useRef, useState } from "react";

const useAvForm = () => {
  const formRef = useRef(null);
  const [hasFormError, updateErrorState] = useState(false);
  const [isDirty, updateDirtyState] = useState(false);
  const [values, updateValues] = useState(null);
  
  useEffect(() => {
    formRef.current && updateValues(formRef.current.getValues());
  }, [formRef.current]);

  const handleFormChange = () => {
    setTimeout(() => {
      const { invalidInputs, dirtyInputs } = formRef.current.state;
      updateErrorState(!!Object.keys(invalidInputs).length);
      updateDirtyState(!!Object.keys(dirtyInputs).length);
      updateValues(formRef.current.getValues());
    }, 50);
  };

  return [formRef, hasFormError, handleFormChange, isDirty, values];
};

export default useAvForm;
