import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import {
  clearLessonsCount,
  createLesson,
  getLessonsCount,
} from "../../../../store/dashboard/Lessons/actions";
import SaveLesson from "../../../../components/Forms/SaveLesson";
import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import ProgressWatcher from "../../../../components/Common/ProgressWatcher";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const CreateLesson = ({
  createLesson,
  lessons,
  getLessonsCount,
  clearLessonsCount,
  filesError,
}) => {
  const { created, loading, ordinalNumber } = lessons;
  const {
    params: { courseId },
  } = useRouteMatch("/courses/:courseId/create");
  const [isDirty, updateDirty] = useState(false);
  const history = useHistory();
  const saveLessonCallback = useCallback((model) => {
    createLesson({ courseId, model }, history);
  }, []);
  const isBlocked = (created ? false : isDirty) && !filesError;

  useEffect(() => {
    getLessonsCount({ courseId: Number(courseId) });

    return () => {
      clearLessonsCount();
    };
  }, []);

  return (
    <>
      <SEO title="Create lesson" />
      <RouteLeavingGuard
        contentText="Added data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <ScrollbarVertical>
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Create lesson
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                {ordinalNumber !== null && (
                  <SaveLesson
                    courseId={courseId}
                    saveLessonFunc={saveLessonCallback}
                    updateDirtyState={updateDirty}
                    ordinalNumber={ordinalNumber + 1}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </ScrollbarVertical>
    </>
  );
};

const mapStateToProps = ({ Lessons, Progress }) => {
  return { lessons: Lessons, filesError: Progress.error };
};

export default connect(mapStateToProps, {
  createLesson,
  getLessonsCount,
  clearLessonsCount,
})(CreateLesson);
