import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Checkbox from "../../Common/checkbox";
import moment from "moment";
import { MOMENT_MINUTE } from "../../../constants/dateFormats";
import FormGroupReactDatepicker from "./FormGroupReactDatePicker";
import {
  SCHEDULE_DATE_FORMAT,
  SCHEDULE_TIME_FORMAT,
} from "../../../constants/datePickerConstants";

const DEFAULT_MINUTES_OFFSET = 10;

const FormGroupScheduleDate = ({
  name,
  placeholder,
  validator,
  handleFormChange,
  labelClassName,
  defaultValue,
  isLiveStatus = false,
}) => {
  const [date, setDate] = useState(defaultValue || "");

  const handleCheckChange = () => {
    if (!date) {
      const minutes = moment().get(MOMENT_MINUTE);
      const defaultDate = moment
        .utc()
        .set(MOMENT_MINUTE, minutes + DEFAULT_MINUTES_OFFSET)
        .toISOString(true);

      setDate(defaultDate);
      return;
    }
    setDate("");
  };

  return (
    <>
      <FormGroupReactDatepicker
        name={name}
        label={
          isLiveStatus ? "Date & time of post" : "Schedule date & time of post"
        }
        placeholder={placeholder}
        validator={!isLiveStatus ? validator : null}
        handleFormChange={handleFormChange}
        className="mb-0"
        labelClassName={labelClassName}
        dateFormat={SCHEDULE_DATE_FORMAT}
        timeFormat={SCHEDULE_TIME_FORMAT}
        disabled={!date || isLiveStatus}
        showTimeSelect
        onChange={setDate}
        dateValue={date}
      />
      {!isLiveStatus && (
        <Row>
          <Col lg={{ size: 10, offset: 2 }}>
            <Checkbox
              checked={!date}
              onChange={handleCheckChange}
              label="Undefined"
              value="date"
              className="clear-checkbox mb-3"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormGroupScheduleDate;
