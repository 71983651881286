import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import formsValidators, {
  requiredIfOtherExist,
} from "../../validators/form.validators";
import useDropzoneFacade from "../../hooks/useDropzoneFacade";
import useAvForm from "../../hooks/useAvForm";
import FormGroupText from "./inputs/FormGroupText";
import WYSISYGComponent from "./inputs/WYSISYGComponent";
import FormGroupReactSelect from "./inputs/FormGroupReactSelect";
import noop from "../../helpers/noop";
import useMountedState from "../../hooks/useMountedState";
import FormGroupImages from "./inputs/FormGroupImages";
import InputWithReadOnlyTextPart from "./inputs/InputWithReadOnlyTextPart";
import useInputWithReadOnlyTextPart from "../../hooks/useInputWithReadOnlyTextPart";
import getReadOnlyUrlPart from "../../helpers/getReadOnlyUrlPart";
import {
  getCoursesForOption,
} from "../../store/dashboard/Bundles/actions";

import styles from "./forms.module.scss";
import FormGroupMultiSelect from './inputs/FormGroupMultiSelect';
import { MAX_TAG_LENGTH } from '../../constants/leghtValidity';
import { VALUE_FIELD_ID } from '../../constants/selectConstants';

const currencies = ["EUR", "USD", "GBP", "NZD", "AUD", "CAD", "INR"];
const applicableToSubscriptionValue = 'ApplicableToSubscription';

const initialState = {
  title: "",
  urlPart: "",
  overview: "",
  productInfo: "",
  courses: "",
  EUR: "",
  USD: "",
  GBP: "",
  NZD: "",
  AUD: "",
  CAD: "",
  INR: "",
};

const SaveBundle = ({
  model,
  saveBundleFunc,
  bundles,
  updateDirtyState = noop,
  getCoursesForOption,
}) => {
  const [formData, updateFormData] = useState(initialState);
  const [initialFile, updateInitialFile] = useMountedState(null);
  const { loading, dataCoursesForOption: coursesData } = bundles;

  const [ready, updateReadyState] = useMountedState(false);
  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();
    const [
      error,
      selectedFiles,
      handleAcceptedFiles,
      setSelectedFiles,
    ] = useDropzoneFacade();

  const urlPart = useInputWithReadOnlyTextPart(
    values?.title,
    formRef,
    model?.urlPart
  );

  // console.log('Courses: ', model.courses);

  useEffect(() => {
    getCoursesForOption({});
  }, []);

  const handleFormSubmit = (event, values) => {
    const {
      title,
      urlPart,
      overview,
      productInfo,
      courses,
    } = values;
    const model = {
      title,
      urlPart,
      overview,
      productInfo,
      courseIds: courses,
      prices: currencies.map((currency) => ({
        currency,
        price: Number(values[currency]),
      })),
    };
  
    if (initialFile !== selectedFiles[0]) {
      model.files = selectedFiles;
    }

    saveBundleFunc(model);
  };

  const pageTitle = useMemo(
    () => (model ? "Edit bundle" : "Create bundle"),
    [model]
  );
  const btnSaveLabel = useMemo(
    () => (model ? "Save" : "Create draft"),
    [model]
  );
  const disableState = useMemo(
    () =>
      !(
        isDirty ||
        initialFile !== selectedFiles[0]
      ) ||
      loading ||
      !selectedFiles.length ||
      hasFormError,
    [
      loading,
      selectedFiles,
      hasFormError,
      isDirty,
      initialFile,
      model,
    ]
  );

  useEffect(() => {
    updateDirtyState(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (!!model) {
      const {
        title,
        overview,
        productInfo,
        prices,
        initialFile,
        courses
      } = model;
      const formData = {
        ...initialState,
        title,
        overview,
        productInfo,
        courses,
      };

      if (prices) {
        prices.forEach(
          ({ currency, price }) => (formData[currency] = String(price))
        );
      }

      if (initialFile) {
        updateInitialFile(initialFile);
        handleAcceptedFiles([initialFile], []);
      }

      updateFormData(formData);
    }

    updateReadyState(true);
  }, [model]);

  useEffect(() => {
    if (ready && selectedFiles.length === 0 && initialFile) {
      setSelectedFiles([initialFile]);
    }
  }, [selectedFiles]);

  const getCoursesValue = useMemo(() => {
    if (!!model) {
      return model.courses.map(({ id, title }) => {
        return {
          id: id,
          name: title,
        }
      });
    }
  }, [model]);

  return (
    ready && (
      <>
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          model={formData}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{pageTitle}</CardTitle>
              <FormGroupText
                name="title"
                label="Bundle title"
                placeholder="Enter bundle title"
                validator={formsValidators.course.title}
                handleFormChange={handleFormChange}
              />
              <InputWithReadOnlyTextPart
                name="urlPart"
                label="Bundle URL"
                readOnlyPart={getReadOnlyUrlPart("dj-course-bundles")}
                validator={formsValidators.course.urlPart}
                handleFormChange={handleFormChange}
                value={urlPart}
              />
              <FormGroupMultiSelect
                name="courses"
                allowCreate={false}
                label="Courses"
                placeholder="Select courses"
                value={getCoursesValue}
                options={coursesData.map(({ id, title }) => ({
                  id: id,
                  name: title,
                }))}
                validator={formsValidators.bundle.courses}
                handleFormChange={handleFormChange}
                formState={formData}
                updateFormState={updateFormData}
                maxCreateLength={MAX_TAG_LENGTH}
                isMulti
                optionValueName={VALUE_FIELD_ID}
            />
              <FormGroupImages
                multiple={false}
                label="Bundle Image"
                error={error}
                selectedImages={selectedFiles}
                initialFile={initialFile}
                handleAcceptedImages={handleAcceptedFiles}
                setSelectedImages={setSelectedFiles}
             />
              <CardTitle className="mb-4">Bundle info</CardTitle>
              <WYSISYGComponent
                name="overview"
                label="Overview"
                validator={formsValidators.course.overview}
                handleFormChange={handleFormChange}
              />

              <WYSISYGComponent
                name="productInfo"
                label="Product Info"
                // validator={formsValidators.course.overview}
                handleFormChange={handleFormChange}
              />
              
              <CardTitle className="mb-4">Prices</CardTitle>
              {currencies.map((currency, index) => (
                <Fragment key={"currency-" + index}>
                  <FormGroupText
                    name={currency}
                    label={currency}
                    placeholder={`Enter ${currency}`}
                    validator={formsValidators.course.prices}
                    handleFormChange={handleFormChange}
                  />
                </Fragment>
              ))}
            </CardBody>
          </Card>
          <Row className="justify-content-end">
            <Col xs="12">
              <Link
                to={`/bundles`}
              >
                <Button color="secondary" className="mr-2">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                color="primary"
                disabled={disableState}
                className={styles.btnSave}
              >
                {btnSaveLabel}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </>
    )
  );
};

const mapStateToProps = ({ Bundles, Courses }) => {
  return {
    bundles: Bundles,
    courses: Courses,
  };
};

export default connect(mapStateToProps, { getCoursesForOption })(SaveBundle);
