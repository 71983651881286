export const GET_LIST_PREMIUM_ARTICLES = "GET_LIST_PREMIUM_ARTICLES";
export const GET_LIST_PREMIUM_ARTICLES_SUCCESS =
  "GET_LIST_PREMIUM_ARTICLES_SUCCESS";
export const GET_LIST_PREMIUM_ARTICLES_ERROR =
  "GET_LIST_PREMIUM_ARTICLES_ERROR";

export const GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL = 'GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL';
export const GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS = 'GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS';

export const GET_PREMIUM_ARTICLE = "GET_PREMIUM_ARTICLE";
export const GET_PREMIUM_ARTICLE_SUCCESS = "GET_PREMIUM_ARTICLE_SUCCESS";
export const GET_PREMIUM_ARTICLE_CLEAR = "GET_PREMIUM_ARTICLE_CLEAR";
export const GET_PREMIUM_ARTICLE_ERROR = "GET_PREMIUM_ARTICLE_ERROR";

export const UPDATE_PREMIUM_ARTICLE = "UPDATE_PREMIUM_ARTICLE";
export const UPDATE_PREMIUM_ARTICLE_SUCCESS = "UPDATE_PREMIUM_ARTICLE_SUCCESS";
export const UPDATE_PREMIUM_ARTICLE_CLEAR = "UPDATE_PREMIUM_ARTICLE_CLEAR";
export const UPDATE_PREMIUM_ARTICLE_ERROR = "UPDATE_PREMIUM_ARTICLE_ERROR";

export const CREATE_PREMIUM_ARTICLE = "CREATE_PREMIUM_ARTICLE";
export const CREATE_PREMIUM_ARTICLE_SUCCESS = "CREATE_PREMIUM_ARTICLE_SUCCESS";
export const CREATE_PREMIUM_ARTICLE_CLEAR = "CREATE_PREMIUM_ARTICLE_CLEAR";
export const CREATE_PREMIUM_ARTICLE_ERROR = "CREATE_PREMIUM_ARTICLE_ERROR";

export const CHANGE_IS_NEW_MARK = "CHANGE_IS_NEW_MARK";
export const CHANGE_IS_NEW_MARK_SUCCESS = "CHANGE_IS_NEW_MARK_SUCCESS";
export const CHANGE_IS_NEW_MARK_ERROR = "CHANGE_IS_NEW_MARK_ERROR";

export const REMOVE_PREMIUM_ARTICLE = "REMOVE_PREMIUM_ARTICLE";
export const REMOVE_PREMIUM_ARTICLE_SUCCESS = "REMOVE_PREMIUM_ARTICLE_SUCCESS";
export const REMOVE_PREMIUM_ARTICLE_ERROR = "REMOVE_PREMIUM_ARTICLE_ERROR";
export const REMOVE_PREMIUM_ARTICLE_CLEAR = "REMOVE_PREMIUM_ARTICLE_CLEAR";

export const SAVE_PREMIUM_ARTICLES_FILTERS = "SAVE_PREMIUM_ARTICLES_FILTERS";

export const REORDER_ARTICLE = "REORDER_ARTICLE";
export const REORDER_ARTICLE_SUCCESS = "REORDER_ARTICLE_SUCCESS";
export const REORDER_ARTICLE_ERROR = "REORDER_ARTICLE_ERROR";
