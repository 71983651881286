import {
  CREATE_BUNDLE,
  CREATE_BUNDLE_CLEAR,
  CREATE_BUNDLE_ERROR,
  CREATE_BUNDLE_SUCCESS,
  GET_LIST_BUNDLE,
  GET_LIST_BUNDLE_ERROR,
  GET_LIST_BUNDLE_SUCCESS,
  GET_BUNDLE,
  GET_BUNDLE_SUCCESS,
  GET_BUNDLE_CLEAR,
  GET_BUNDLE_ERROR,
  UPDATE_BUNDLE,
  UPDATE_BUNDLE_SUCCESS,
  UPDATE_BUNDLE_CLEAR,
  UPDATE_BUNDLE_ERROR,
  REMOVE_BUNDLE,
  REMOVE_BUNDLE_SUCCESS,
  REMOVE_BUNDLE_CLEAR,
  REMOVE_BUNDLE_ERROR,
  CHANGE_BUNDLE_STATUS,
  CHANGE_BUNDLE_STATUS_SUCCESS,
  CHANGE_BUNDLE_STATUS_ERROR,
  CHANGE_BUNDLE_STATUS_CLEAR,
  CLEAR_LIST_BUNDLE,
  GET_COURSES_FOR_OPTION,
  GET_COURSES_FOR_OPTION_SUCCESS,
  GET_COURSES_FOR_OPTION_ERROR,
  GET_COURSES_FOR_OPTION_CLEAR,
} from "./actionTypes";

export const getListBundles = (payload) => {
  return {
    payload,
    type: GET_LIST_BUNDLE,
  };
};
export const getListBundlesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_BUNDLE_SUCCESS,
  };
};
export const getListBundlesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_BUNDLE_ERROR,
  };
};
export const clearListBundles = () => ({
  type: CLEAR_LIST_BUNDLE,
})

export const createBundle = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_BUNDLE,
  };
};

export const createBundleSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_BUNDLE_SUCCESS,
  };
};

export const createBundleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_BUNDLE_ERROR,
  };
};

export const clearBundleCreated = () => {
  return {
    type: CREATE_BUNDLE_CLEAR,
  };
};

export const getBundle = (bundleId) => {
  return {
    payload: { bundleId },
    type: GET_BUNDLE,
  };
};

export const getBundleSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_BUNDLE_SUCCESS,
  };
};

export const getBundleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_BUNDLE_ERROR,
  };
};

export const clearBundleFetched = () => {
  return {
    type: GET_BUNDLE_CLEAR,
  };
};

export const updateBundle = (data, history, courseCategoryId) => {
  return {
    payload: { data, history, courseCategoryId },
    type: UPDATE_BUNDLE,
  };
};

export const updateBundleSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_BUNDLE_SUCCESS,
  };
};

export const updateBundleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_BUNDLE_ERROR,
  };
};

export const clearBundleUpdated = () => {
  return {
    type: UPDATE_BUNDLE_CLEAR,
  };
};

export const removeBundle = (data, history) => {
  return {
    payload: { data, history },
    type: REMOVE_BUNDLE,
  };
};

export const removeBundleSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_BUNDLE_SUCCESS,
  };
};

export const removeBundleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_BUNDLE_ERROR,
  };
};

export const clearBundleRemoved = () => {
  return {
    type: REMOVE_BUNDLE_CLEAR,
  };
};

export const changeBundleStatus = (id) => {
  return {
    payload: { id },
    type: CHANGE_BUNDLE_STATUS,
  };
};

export const changeBundleStatusSuccess = ({ data }) => {
  return {
    payload: data,
    type: CHANGE_BUNDLE_STATUS_SUCCESS,
  };
};

export const changeBundleStatusError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CHANGE_BUNDLE_STATUS_ERROR,
  };
};

export const changeBundleStatusClear = () => {
  return {
    type: CHANGE_BUNDLE_STATUS_CLEAR,
  };
};

export const getCoursesForOption = (pagination) => {
  return {
    payload: pagination,
    type: GET_COURSES_FOR_OPTION,
  };
};

export const getCoursesForOptionSuccess = (data) => {
  return {
    payload: data,
    type: GET_COURSES_FOR_OPTION_SUCCESS,
  };
};

export const getCoursesForOptionError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_COURSES_FOR_OPTION_ERROR,
  };
};

export const getCoursesForOptionClear = () => {
  return {
    type: GET_COURSES_FOR_OPTION_CLEAR,
  };
};