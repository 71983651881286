import React, { useMemo } from "react";
import { Card, CardBody, Label, Input, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import formatDate from "../../../../../helpers/formatDate";
import capitilizeFirstLetter from "../../../../../helpers/capitilizeFirstLetter";
import useModal from "../../../../../hooks/useModal";
import ConfirmationDialog from "../../../../../components/Modals/ConfirmationDialog";
import { DATE_AND_TIME_FORMAT } from "../../../../../constants/dateFormats";
import { ARTICLE_STATUSES } from "../../../../../constants/articleStatuses";
import Icon from "../../../../../components/Common/Icon";

import styles from "./PremiumArticleCard.module.scss";

const DELETE_MODAL_TITLE = "Delete";
const DELETE_MODAL_DESCRIPTION =
  "This premium article and all included files will be deleted. Are you sure you want to delete it?";

const PremiumArticleCard = ({
  premiumArticle,
  whatsNewChange,
  removePremiumArticle,
  isDraftCard = false,
  isDragging,
  filters,
}) => {
  const {
    id,
    isNew,
    premiumArticleSection,
    createdAt,
    title,
    premiumArticleCategory,
    likesCount
  } = premiumArticle;

  const [modalVisible, toggleModal] = useModal();
  const handleConfirmRemove = () => {
    toggleModal();
    removePremiumArticle(id);
  };

  const getStatusClassName = useMemo(
    () =>
      classNames(
        "badge",
        premiumArticle.status === ARTICLE_STATUSES.LIVE && "badge-soft-success",
        premiumArticle.status === ARTICLE_STATUSES.SCHEDULED &&
          "badge-soft-info",
        premiumArticle.status === ARTICLE_STATUSES.DRAFT &&
          `badge-soft-dark ${styles.darkBadge}`,
        styles.articleStatus
      ),
    [premiumArticle.status]
  );

  return (
    <Card className={isDraftCard ? "mb-3" : "mb-0"}>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <CardBody className={styles.cardBody}>
        <Row className="align-items-center">
          {!!filters && (
            <Icon
              icon={isDragging ? "dragActive" : "dragInActive"}
              className={styles.startIcon}
            />
          )}
          {!isDraftCard && (
            <Col xs="1">
              <div
                className={classNames(
                  "custom-control custom-checkbox",
                  !!filters && "ml-3"
                )}
              >
                <Input
                  type="checkbox"
                  className="custom-control-input"
                  id={`article-new-${id}`}
                  checked={isNew}
                  onChange={() => whatsNewChange(id, !isNew)}
                />
                <Label
                  className="custom-control-label cursor-pointer custom-control-label--hovered"
                  for={`article-new-${id}`}
                >
                  Show
                </Label>
              </div>
            </Col>
          )}

          <Col xs={isDraftCard ? 6 : 4}>
            <div className={styles.cardTitle}>{title}</div>
          </Col>
          <Col xs={isDraftCard ? 3 : 2}>
            <div>
              <h5 className={styles.cardSection}>
                {premiumArticleSection.name}
              </h5>
              {premiumArticleCategory.name}
            </div>
          </Col>
          {!isDraftCard && (
            <Col xs="1">
              <span className={getStatusClassName}>
                {capitilizeFirstLetter(premiumArticle.status)}
              </span>
            </Col>
          )}

          {!isDraftCard && <Col xs="1">{likesCount}</Col>}
          <Col xs="2">{formatDate(createdAt, DATE_AND_TIME_FORMAT)}</Col>

          <Col xs="1">
            <div className="d-flex flex-row font-size-20">
              <Link to="#" onClick={() => toggleModal()}>
                <i className="bx bx-trash text-grey mr-4" />
              </Link>
              <Link to={`/dj-hub/articles/${id}/update`}>
                <i className="bx bx-edit text-grey" />
              </Link>
            </div>
          </Col>
          {!!filters && (
            <Icon
              icon={isDragging ? "dragActive" : "dragInActive"}
              className={styles.endIcon}
            />
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default PremiumArticleCard;
