import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_ANALYTICS } from "./actionTypes";

import { getAnalyticsFailed, getAnalyticsSuccess } from "./actions";

import DjHubAnalyticsService from "../../../services/DjHubAnalyticsService";

/**
 * @typedef {Object} DjHubAnalyticsRequestSchema
 * @property {string} allTotalSubscribersCount
 * @property {string} blackFridayAnnualNewUnsubscribersCount
 */

/**
 * Async request to get Dj Hub Analytics
 * @param {DjHubAnalyticsRequestSchema}
 * @returns
 */
const getAnalyticsAsync = async ({ startDate, endDate }) => await DjHubAnalyticsService.getAnalytics(startDate, endDate);

function* getAnalytics({ payload }) {
  try {
    const { data } = yield call(getAnalyticsAsync, payload);
    yield put(getAnalyticsSuccess(data));
  } catch (error) {
    yield put(getAnalyticsFailed());
  }
}

function* watchAnalytics() {
  yield takeEvery(GET_ANALYTICS, getAnalytics);
}

function* analyticsSaga() {
  yield all([
    fork(watchAnalytics),
  ]);
}

export default analyticsSaga;
