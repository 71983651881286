import React, { useMemo } from "react";
import { Button } from "reactstrap";

import { UNLIMITED } from "../../../../constants/courses";
import capitalizeString from "../../../../helpers/capitalizeString";
import CouponSubscriptionModal from "../../../../components/Modals/CouponSubscriptionModal/CouponSubscriptionModal";
import useModal from "../../../../hooks/useModal";
import { useSelector } from "react-redux";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";

import styles from "../../dashboard.module.scss";

const MODAL_TITLE = "Delete";
const MODAL_DESCRIPTION = "This coupon will be deleted";

const ActionButtons = ({ onEdit, onDelete, id }) => {
  const { dataSubscription } = useSelector((state) => state.Coupons);

  const computedItem = useMemo(
    () => dataSubscription?.find((item) => item.id === id),
    [dataSubscription]
  );

  const [modalVisible, toggleModal] = useModal();
  const handleToggleModal = () => toggleModal((prevState) => !prevState);

  const [modalVisibleConfirm, toggleModalConfirm] = useModal();
  const handleToggleModalConfirm = () =>
    toggleModalConfirm((prevState) => !prevState);

  return (
    <>
      <Button
        color="link"
        onClick={handleToggleModalConfirm}
        className="py-0 pr-2 text-decoration-none"
      >
        <i className="bx bx-trash text-danger font-size-20 d-flex align-items-center" />
      </Button>
      <Button
        color="link"
        onClick={handleToggleModal}
        className="py-0 pl-2 text-decoration-none"
      >
        <i className="bx bx-edit text-grey font-size-20 d-flex align-items-center" />
      </Button>
      <CouponSubscriptionModal

        isOpen={modalVisible}
        onConfirm={onEdit}
        onCancel={handleToggleModal}
        defaultData={computedItem}
        isEdit
      />
      <ConfirmationDialog
        isOpen={modalVisibleConfirm}
        titleText={MODAL_TITLE}
        contentText={MODAL_DESCRIPTION}
        confirmButtonText="Yes"
        cancelButtonText="No"
        confirmBtnClassName={styles.modalConfirm}
        cancelBtnClassName={styles.modalConfirm}
        headerClassName={styles.modalHeader}
        bodyClassName="mb-3"
        onCancel={handleToggleModalConfirm}
        onConfirm={onDelete}
      />
    </>
  );
};

const couponsListStructure = ({ onEdit, onDelete }) => {
  return [
    {
      dataField: "name",
      text: "Coupon name",
    },
    {
      dataField: "body",
      text: "Coupon body",
    },
    {
      dataField: "subscriptionPlanType",
      text: "Subscription plan",
      formatter: (cellContent) => capitalizeString(cellContent),
    },
    {
      dataField: "billingCycleCount",
      text: "Billing cycles",
      formatter: (cellContent) => cellContent || UNLIMITED,
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cellContent) => <>{cellContent}%</>,
    },
    {
      dataField: "usageCount",
      text: "Actual / Max number of uses",
      formatter: (cellContent, rowContent) => (
        <>
          {cellContent} / {rowContent?.maxTotalUsesNumber || UNLIMITED}
        </>
      ),
    },
    {
      dataField: "id",
      text: "Actions",
      formatter: (cellContent) => (
        <ActionButtons
          onEdit={({ model }) => onEdit({ model, id: cellContent })}
          onDelete={() => onDelete({ id: cellContent })}
          id={cellContent}
        />
      ),
    },
  ];
};

export default couponsListStructure;
