import {
  GET_ADMIN_PROFILE,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_ERROR,
  CLEAR_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE_SUCCESS,
  UPDATE_ADMIN_PROFILE_ERROR,
  CHANGE_ADMIN_PASSWORD,
  CHANGE_ADMIN_PASSWORD_SUCCESS,
  CHANGE_ADMIN_PASSWORD_ERROR,
  CHANGE_ADMIN_PASSWORD_CLEAR,
  CHANGE_ADMIN_EMAIL,
  CHANGE_ADMIN_EMAIL_SUCCESS,
  CHANGE_ADMIN_EMAIL_ERROR,
  CHANGE_ADMIN_EMAIL_CLEAR,
  VERIFY_CHANGE_EMAIL,
  VERIFY_CHANGE_EMAIL_SUCCESS,
  VERIFY_CHANGE_EMAIL_ERROR,
  VERIFY_CHANGE_EMAIL_CLEAR,
  CHANGE_ADMIN_AVATAR,
  CHANGE_ADMIN_AVATAR_SUCCESS,
  CHANGE_ADMIN_AVATAR_ERROR
} from "./actionTypes";
import StorageService from "../../../services/StorageService";

const initialState = {
  profile: StorageService.user.value,
  passwordHasBeenChanged: false,
  changedEmailVerify: false,
  email: "",
  error: "",
  profileLoading: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_PROFILE:
      state = {
        ...state,
        profileLoading: true,
      };
      break;
    case GET_ADMIN_PROFILE_SUCCESS:
      state = {
        ...state,
        profile: action.payload.data,
        profileLoading: false,
      };
      break;
    case GET_ADMIN_PROFILE_ERROR:
      state = {
        ...state,
        profileLoading: false,
      };
      break;
    case CLEAR_ADMIN_PROFILE:
      state = {
        ...state,
        profile: null,
      };
      break;
    case UPDATE_ADMIN_PROFILE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_ADMIN_PROFILE_SUCCESS:
      state = {
        ...state,
        profile: action.payload.data,
        loading: false,
      };
      break;
    case UPDATE_ADMIN_PROFILE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_ADMIN_PASSWORD_SUCCESS:
      state = {
        ...state,
        passwordHasBeenChanged: true,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_PASSWORD_CLEAR:
      state = {
        ...state,
        passwordHasBeenChanged: false,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_EMAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_ADMIN_EMAIL_SUCCESS:
      state = {
        ...state,
        email: action.payload.email,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_EMAIL_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_EMAIL_CLEAR:
      state = {
        ...state,
        email: "",
        loading: false,
      };
      break;
    case VERIFY_CHANGE_EMAIL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VERIFY_CHANGE_EMAIL_SUCCESS:
      state = {
        ...state,
        changedEmailVerify: true,
        loading: false,
      };
      break;
    case VERIFY_CHANGE_EMAIL_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      };
      break;
    case VERIFY_CHANGE_EMAIL_CLEAR:
      state = {
        ...state,
        loading: false,
        changedEmailVerify: false,
        error: "",
      };
      break;
    case CHANGE_ADMIN_AVATAR: {
      state = {
        ...state,
        loading: true,
      }
      break;
    }
    case CHANGE_ADMIN_AVATAR_SUCCESS: {
      state = {
        ...state,
        loading: false,
        profile: action.payload.data
      }
      break;
    }
    case CHANGE_ADMIN_AVATAR_ERROR: {
      state = {
        ...state,
        loading: false,
        error: action.payload.message
      }
      break;
    }
    default:
      state = { ...state };
      break;
  }
  return state;
};
