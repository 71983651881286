import { DEFAULT_OFFSET } from "../../../constants/pagination";
import {
  GET_PAYMENTS_LIST,
  GET_PAYMENTS_LIST_ERROR,
  GET_PAYMENTS_LIST_SUCCESS,
  GET_PAYMENT,
  GET_PAYMENT_SUCCESS,
  GET_PAYMENT_ERROR,
  GET_PAYMENT_CLEAR,
} from "./actionTypes";

const initialState = {
  item: null,
  data: [],
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  getPaymentLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENTS_LIST:
      state = {
        ...state,
        data: [],
        loading: true,
      };
      break;
    case GET_PAYMENTS_LIST_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_PAYMENTS_LIST_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_PAYMENT:
      state = {
        ...state,
        getPaymentLoading: true,
      };
      break;
    case GET_PAYMENT_SUCCESS:
      state = {
        ...state,
        item: action.payload.data,
        getPaymentLoading: false,
      };
      break;
    case GET_PAYMENT_ERROR:
      state = {
        ...state,
        getPaymentLoading: false,
      };
      break;
    case GET_PAYMENT_CLEAR:
      state = {
        ...state,
        item: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
