import React from "react";
import { Modal, ModalBody, ModalHeader, Progress } from "reactstrap";
import { useSelector } from "react-redux";

const ProgressDialog = ({ isOpen, titleText, contentText }) => {
  const { progress } = useSelector(({ Progress: progress }) => progress);

  return (
    <Modal
      isOpen={isOpen && progress !== null}
      centered
      className={"confirmation-modal"}
    >
      <ModalHeader>{titleText}</ModalHeader>
      <ModalBody>
        <p className="mb-4">{contentText}</p>
        <Progress
          striped
          animated
          color="primary"
          value={progress}
          className="mb-2"
        />
      </ModalBody>
    </Modal>
  );
};

export default ProgressDialog;
