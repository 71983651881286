import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import {
  removeReward,
} from "../../../../store/dashboard/Rewards/actions";

import styles from "../../dashboard.module.scss";

const DELETE_MODAL_TITLE = "Delete a reward";
const DELETE_MODAL_DESCRIPTION =
  "Reward info will be deleted. Are you sure you want to delete this reward?";

const RewardTableRow = ({ reward, removeReward }) => {
  const [modalVisible, toggleModal] = useModal();

  const handleConfirmRemove = () => {
    toggleModal();
    removeReward(reward.id);
  };

  return (
    <>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <tr>
        <td className={classNames(styles.tableTitle, "align-middle")}>
          <p
            className={styles.tableTitle}
          >
            {reward.title}
          </p>
        </td>
        <td className="d-flex justify-content-end w-220px font-size-20 pr-5 ">
          
          <Link to={`/rewards/${reward.id}`}>
            <i className="bx bx-edit text-grey mr-3" />
          </Link>
          <Link to="#" onClick={() => toggleModal()}>
            <i className="bx bx-trash text-grey" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default connect(() => ({}), { removeReward })(
  RewardTableRow
);
