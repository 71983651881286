export const FORGET_PASSWORD_SEND_LINK = "FORGET_PASSWORD_SEND_LINK";
export const FORGET_PASSWORD_SEND_LINK_SUCCESS =
  "FORGET_PASSWORD_SEND_LINK_SUCCESS";
export const FORGET_PASSWORD_SEND_LINK_ERROR =
  "FORGET_PASSWORD_SEND_LINK_ERROR";
export const FORGET_PASSWORD_SEND_LINK_CLEAR =
  "FORGET_PASSWORD_SEND_LINK_CLEAR";

export const VALIDATE_RESTORE_PASSWORD_TOKEN =
  "VALIDATE_RESTORE_PASSWORD_TOKEN";
export const VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS =
  "VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS";
export const VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR =
  "VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR";
export const VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR =
  "VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR";

export const RESTORE_PASSWORD = "RESTORE_PASSWORD";
export const RESTORE_PASSWORD_SUCCESS = "RESTORE_PASSWORD_SUCCESS";
export const RESTORE_PASSWORD_ERROR = "RESTORE_PASSWORD_ERROR";
export const RESTORE_PASSWORD_CLEAR = "RESTORE_PASSWORD_CLEAR";

export const FORGET_PASSWORD_CLEAR_ERROR = "FORGET_PASSWORD_CLEAR_ERROR";
