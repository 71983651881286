import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

const options = { suppressScrollX: true, wheelPropagation: false };

const ScrollbarVertical = ({ children }) => {
  return <PerfectScrollbar options={options}>{children}</PerfectScrollbar>;
};

export default ScrollbarVertical;
