export const GET_LIST_COURSE = "GET_LIST_COURSE";
export const GET_LIST_COURSE_SUCCESS = "GET_LIST_COURSE_SUCCESS";
export const GET_LIST_COURSE_ERROR = "GET_LIST_COURSE_ERROR";
export const CLEAR_LIST_COURSE = 'CLEAR_LIST_COURSE';

export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_CLEAR = "CREATE_COURSE_CLEAR";
export const CREATE_COURSE_ERROR = "CREATE_COURSE_ERROR";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_CLEAR = "GET_COURSE_CLEAR";
export const GET_COURSE_ERROR = "GET_COURSE_ERROR";

export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_CLEAR = "UPDATE_COURSE_CLEAR";
export const UPDATE_COURSE_ERROR = "UPDATE_COURSE_ERROR";

export const REMOVE_COURSE = "REMOVE_COURSE";
export const REMOVE_COURSE_SUCCESS = "REMOVE_COURSE_SUCCESS";
export const REMOVE_COURSE_CLEAR = "REMOVE_COURSE_CLEAR";
export const REMOVE_COURSE_ERROR = "REMOVE_COURSE_ERROR";

export const CHANGE_COURSE_STATUS = "CHANGE_COURSE_STATUS";
export const CHANGE_COURSE_STATUS_SUCCESS = "CHANGE_COURSE_STATUS_SUCCESS";
export const CHANGE_COURSE_STATUS_ERROR = "CHANGE_COURSE_STATUS_ERROR";
export const CHANGE_COURSE_STATUS_CLEAR = "CHANGE_COURSE_STATUS_CLEAR";

export const GET_COURSES_FOR_RECOMMENDATIONS = "GET_COURSES_FOR_RECOMMENDATIONS";
export const GET_COURSES_FOR_RECOMMENDATIONS_CLEAR = "GET_COURSES_FOR_RECOMMENDATIONS_CLEAR";
export const GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS = "GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS";
export const GET_COURSES_FOR_RECOMMENDATIONS_ERROR = "GET_COURSES_FOR_RECOMMENDATIONS_ERROR";

export const SET_COURSE_RECOMMENDATIONS = "SET_COURSE_RECOMMENDATIONS";
export const SET_COURSE_RECOMMENDATIONS_SUCCESS = "SET_COURSE_RECOMMENDATIONS_SUCCESS";
export const SET_COURSE_RECOMMENDATIONS_ERROR = "SET_COURSE_RECOMMENDATIONS_ERROR";
export const SET_COURSE_RECOMMENDATIONS_CLEAR = "SET_COURSE_RECOMMENDATIONS_CLEAR";