import React, { useCallback, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveBundle from "../../../components/Forms/SaveBundle";
import RouteLeavingGuard from "../../../routes/middleware/RouteLeavingGuard";
import { createBundle } from "../../../store/dashboard/Bundles/actions";
import ProgressWatcher from "../../../components/Common/ProgressWatcher";
import SEO from "../../../components/Common/SEO";
// import { createVideo } from '../../../store/dashboard/VideoLibrary/actions';
// import SaveVideo from '../../../components/Forms/SaveVideo';
import SaveBulkProductActivationCodes from '../../../components/Forms/SaveBulkProductActivationCodes';
import { createBulkProductActivationCode } from '../../../store/dashboard/ProductActivationCodes/actions';

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const CreateBulk = ({ createBulkProductActivationCode, productActivationCodes, filesError }) => {
  const { created, loading } = productActivationCodes;
  const [isDirty, updateDirty] = useState(false);

  const history = useHistory();

  const saveBulkProductActivationCodesCallback = useCallback((model) => {
    const createBulkProductActivationCodesFunc = () => {
      createBulkProductActivationCode({ model }, history);
    };
    createBulkProductActivationCodesFunc();
  }, []);
  const isBlocked = (created ? false : isDirty) && !filesError;

  return (
    <>
      <SEO title="Upload Product Activation Codes" />
      {/* <RouteLeavingGuard
        contentText="Added data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      /> */}
      {/* <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} /> */}
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Upload Product Activation Codes
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                <SaveBulkProductActivationCodes
                  saveBulkProductActivationCodesFunc={saveBulkProductActivationCodesCallback}
                  // updateDirtyState={updateDirty}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ProductActivationCodes } = state;

  return { productActivationCodes: ProductActivationCodes };
};

export default connect(mapStateToProps, { createBulkProductActivationCode })(CreateBulk);
