import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, Form, FormGroup, Label } from "reactstrap";

import { getPremiumArticlesSections } from "../../../../store/dashboard/PremiumArticlesSections/actions";
import {
  getPremiumArticlesCategories,
  clearPremiumArticlesCategories,
} from "../../../../store/dashboard/PremiumArticlesCategories/actions";
import { savePremiumArticlesFilters } from "../../../../store/dashboard/PremiumArticles/actions";
import {
  customStyles,
  DropdownIndicator,
  MenuList,
} from "../../../../helpers/reactSelectHelper";

import styles from "./Articles.module.scss";

const ArticlesFilters = ({
  premiumArticlesSections,
  premiumArticlesCategories,
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  clearPremiumArticlesCategories,
  savePremiumArticlesFilters,
  premiumArticles,
  needDisable,
}) => {
  const { sectionFilter, categoryFilter } = premiumArticles;
  const { data: premiumArticlesSectionsData } = premiumArticlesSections;
  const { data: premiumArticlesCategoriesData } = premiumArticlesCategories;

  const selectCategoryRef = useRef(null);

  const [selectEntity, setSelectEntity] = useState(false);
  const [section, updateSectionId] = useState(sectionFilter || null);
  const [category, updateCategory] = useState(categoryFilter || null);

  useEffect(() => {
    if (sectionFilter?.value) {
      getPremiumArticlesCategories(sectionFilter.value);
    } else {
      clearPremiumArticlesCategories();
    }

    getPremiumArticlesSections();

    return () => {
      clearPremiumArticlesCategories();
    };
  }, []);

  const generateOptions = useCallback(
    (values) =>
      values.map((option) => ({ value: option.id, label: option.name })),
    []
  );

  const getDefaultSectionValue = useMemo(
    () => ({ value: null, label: "All" }),
    []
  );

  const getSectionValues = useMemo(() => {
    if (!!premiumArticlesSectionsData?.length) {
      const options = generateOptions(premiumArticlesSectionsData);
      return [getDefaultSectionValue, ...options];
    }
  }, [premiumArticlesSectionsData, generateOptions, getDefaultSectionValue]);

  const getCategories = (option) => {
    updateSectionId(option);
    updateCategory(null);
    if (selectCategoryRef.current.select.getValue()?.length) {
      selectCategoryRef.current.select.clearValue();
    }
    option?.value
      ? getPremiumArticlesCategories(option.value)
      : clearPremiumArticlesCategories();
    !selectEntity && setSelectEntity(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    savePremiumArticlesFilters(section, category);
    setSelectEntity(false);
  };

  const updateCategoryHandler = (option) => {
    if (option) {
      updateCategory(option);
      !selectEntity && setSelectEntity(true);
    }
  };

  const disabledBtn = useMemo(
    () => (section?.value && !category) || needDisable || !selectEntity,
    [section, category, needDisable, selectEntity]
  );

  return (
    <Form className="d-flex flex-row align-items-end" onSubmit={handleSubmit}>
      <FormGroup className={styles.filterSelect}>
        <Label htmlFor="article-section">Section</Label>
        <Select
          defaultValue={sectionFilter || getDefaultSectionValue}
          className={styles.select}
          id="article-section"
          placeholder="Select section"
          options={getSectionValues}
          onChange={getCategories}
          isDisabled={!premiumArticlesSectionsData?.length || needDisable}
          styles={customStyles}
          components={{ DropdownIndicator, MenuList }}
        />
      </FormGroup>
      <FormGroup className={styles.filterSelect}>
        <Label htmlFor="article-category">Category</Label>
        <Select
          defaultValue={categoryFilter || null}
          className={styles.select}
          id="article-category"
          placeholder="Select category"
          options={generateOptions(premiumArticlesCategoriesData)}
          isDisabled={!section?.value}
          onChange={updateCategoryHandler}
          styles={customStyles}
          components={{ DropdownIndicator, MenuList }}
          ref={selectCategoryRef}
        />
      </FormGroup>
      <Button
        className={styles.articlesFiltersBtn}
        color="primary"
        type="submit"
        disabled={disabledBtn}
      >
        Apply
      </Button>
    </Form>
  );
};

const mapStateToProps = ({
  PremiumArticlesSections: premiumArticlesSections,
  PremiumArticlesCategories: premiumArticlesCategories,
  PremiumArticles: premiumArticles,
}) => ({
  premiumArticlesSections,
  premiumArticlesCategories,
  premiumArticles,
});

export default connect(mapStateToProps, {
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  clearPremiumArticlesCategories,
  savePremiumArticlesFilters,
})(ArticlesFilters);
