import React, { useMemo } from "react";
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvForm,
  AvRadio,
  AvRadioGroup,
} from "availity-reactstrap-validation";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import styles from "../forms.module.scss";
import useAvForm from "../../../hooks/useAvForm";
import FormGroupText from "../inputs/FormGroupText";
import formsValidators from "../../../validators/form.validators";

const initialState = {
  name: "",
  body: "",
  discount: "",
  billingCycleCount: "",
  maxTotalUsesNumber: "",
  unlimitedBilling: [],
  unlimitedTotalUses: [],
};

const CouponSubscriptionForm = ({ coupons, onSubmit, defaultData, isEdit }) => {
  const { loading } = coupons;

  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();

  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleFormSubmit = (event, values) => {
    values.maxTotalUsesNumber = +values.maxTotalUsesNumber;
    values.billingCycleCount = +values.billingCycleCount;
    if (values?.unlimitedBilling.length) delete values.billingCycleCount;
    if (values?.unlimitedTotalUses.length) delete values.maxTotalUsesNumber;

    delete values.unlimitedBilling;
    delete values.unlimitedTotalUses;

    onSubmit({ model: values });
  };

  const computedDefaultData = useMemo(() => {
    const data = { ...defaultData };

    data.unlimitedTotalUses = [];
    data.unlimitedBilling = [];

    if (!data.maxTotalUsesNumber && isEdit) {
      data.maxTotalUsesNumber = "";
      data.unlimitedTotalUses.push("Unlimited");
    }
    if (!data.billingCycleCount && isEdit) {
      data.billingCycleCount = "";
      data.unlimitedBilling.push("Unlimited");
    }

    return data;
  }, [defaultData]);

  return (
    <>
      {
        isEdit && (
            <p className={classNames("d-flex my-1", styles.updateText)}>
              If you update a subscription coupon, then the number of actual uses of this coupon will be reset to “0”
            </p>
        )
      }
      <AvForm
        onValidSubmit={handleFormSubmit}
        ref={formRef}
        model={computedDefaultData || initialState}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      >
        <FormGroupText
          vertical
          className="mb-0"
          name="name"
          label="Coupon name"
          placeholder="Enter coupon name"
          validator={formsValidators.courseCoupon.name}
          handleFormChange={handleFormChange}
        />
        <FormGroupText
          vertical
          className="mb-0"
          name="body"
          label="Coupon body"
          placeholder="Enter coupon body"
          validator={formsValidators.courseCoupon.body}
          handleFormChange={handleFormChange}
        />
        <AvRadioGroup
          name="subscriptionPlanType"
          className="custom-radio"
          required
          errorMessage="Please, choose the subscription plan"
        >
          <p className="font-weight-medium mb-2 mt-3">Subscription plan</p>
          <div className="d-flex">
            <AvRadio
              customInput
              className="mr-5 cursor-pointer"
              label="Monthly"
              value="MONTHLY"
              onChange={handleFormChange}
            />
            <AvRadio
              customInput
              className="cursor-pointer"
              label="Annual"
              value="ANNUAL"
              onChange={handleFormChange}
            />
          </div>
        </AvRadioGroup>
        <FormGroupText
          vertical
          className="mb-0"
          name="discount"
          label="Discount, %"
          placeholder="Enter discount, %"
          validator={formsValidators.courseCoupon.discountSubscriptions}
          handleFormChange={handleFormChange}
        />
        <FormGroupText
          vertical
          className="mb-2"
          name="billingCycleCount"
          label="Number of billing cycles"
          placeholder="Enter number of billing cycles"
          validator={formsValidators.courseCoupon.billingCycleCount}
          handleFormChange={handleFormChange}
          readOnly={!!values?.unlimitedBilling.length}
        />
        <AvCheckboxGroup name="unlimitedBilling">
          <AvCheckbox
            onChange={handleFormChange}
            className="cursor-pointer"
            customInput
            label="Unlimited"
            value="Unlimited"
          />
        </AvCheckboxGroup>
        <FormGroupText
          vertical
          className="mb-2"
          name="maxTotalUsesNumber"
          label="Max number of total uses"
          placeholder="Enter max number of total uses"
          validator={formsValidators.courseCoupon.maxTotalUsesNumber}
          handleFormChange={handleFormChange}
          readOnly={!!values?.unlimitedTotalUses.length}
        />
        <AvCheckboxGroup name="unlimitedTotalUses">
          <AvCheckbox
            onChange={handleFormChange}
            className="cursor-pointer"
            customInput
            label="Unlimited"
            value="Unlimited"
          />
        </AvCheckboxGroup>

        <Row className="justify-content-end mt-3">
          <Col xs="12">
            <Button
              type="submit"
              color="primary"
              disabled={disableState}
              className={classNames(styles.btnSave, "mw-100")}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};
const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps)(CouponSubscriptionForm);
