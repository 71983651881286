import React from "react";
import formatDate from "../../../helpers/formatDate";
import { DATE_AND_TIME_FORMAT } from "../../../constants/dateFormats";
import formatTabelCell from "../../../helpers/formatTabelCell";
import formatNotificationType from "../../../helpers/formatNotificationType";

import styles from "./index.module.scss";

export default () => [
  {
    dataField: "title",
    text: "Notification title",
    formatter: (cellContent) => <>{formatTabelCell(cellContent)}</>,
  },
  {
    dataField: "body",
    text: "Notification body",
    formatter: (cellContent) => (
      <div className={styles.cuoponBody}>{formatTabelCell(cellContent)}</div>
    ),
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (cellContent) => <>{formatNotificationType(cellContent)}</>,
  },
  {
    dataField: "createdAt",
    text: "Date & Time",
    formatter: (cellContent) => (
      <>{formatDate(cellContent, DATE_AND_TIME_FORMAT)}</>
    ),
  },
];
