import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ADD_COURSE_TO_USER,
  CHANGE_EMAIL,
  PROVIDE_SUBSCRIPTION,
  CHANGE_USERS_STATUS,
  CLEAR_STATE,
  DELETE_USER,
  GET_NOT_PURCHASED_USER_COURSES,
  GET_USER_COURSES,
  GET_USERS_LIST,
  GET_USERS_PROFILE,
  REMOVE_COURSE_FROM_USER,
  REVOKE_SUBSCRIPTION,
  SEND_RECOVERY_LINK,
  SET_PASSWORD,
} from "./actionTypes";
import toastr from "../../../components/Common/toastr";
import {
  COURSES_ADDED,
  COURSES_DELETED,
  PROVIDE_SUBSCRIPTION_SUCCESS,
  RECOVERY_SUCCESS,
  REVOKE_SUBSCRIPTION_SUCCESS,
  USER_DELETED,
} from "../../../constants/toastMessages";

import {
  addCourseToUserError,
  addCourseToUserSuccess,
  changeEmailError,
  changeEmailSuccess,
  provideSubscriptionError,
  provideSubscriptionSuccess,
  changeStatusError,
  changeStatusSuccess,
  deleteUserError,
  deleteUserSuccess,
  getNotPurchasedUsersCoursesError,
  getNotPurchasedUsersCoursesSuccess,
  getUserListError,
  getUserListSuccess,
  getUserProfile,
  getUserProfileError,
  getUserProfileSuccess,
  getUsersCoursesError,
  getUsersCoursesSuccess,
  removeCourseFromUserError,
  removeCourseFromUserSuccess,
  revokeSubscriptionError,
  revokeSubscriptionSuccess,
  sendRecoveryLinkError,
  sendRecoveryLinkSuccess,
  setPasswordSuccess,
  setPasswordError,
} from "./actions";
import UsersService from "../../../services/UsersService";
import ToastrService from "../../../services/ToastrService";

const getUsersListAsync = async (searchParams) => {
  return await UsersService.getList(searchParams);
};

const getUsersProfileAsync = async (id) => {
  return await UsersService.getProfile(id);
};

const changeUsersStatusAsync = async ({ id, status }) => {
  return await UsersService.changeStatus(id, status);
};

const sendRecoveryLinkAsync = async ({ id }) => {
  return await UsersService.sendRecoveryLink(id);
};

const changeEmailAsync = async ({ id, email, toForceUpdate }) => {
  return await UsersService.changeEmail(id, { email, toForceUpdate });
};

const setPasswordAsync = async ({ id, newPassword }) => {
  return await UsersService.setPassword(id, { newPassword });
};

const provideSubscriptionAsync = async (data) => {
  const { model, userId } = data;
  return await UsersService.provideSubscription(userId, model);
};

const getCoursesAsync = async (id, pagination) => {
  return await UsersService.getCourses(id, pagination);
};

const addCourseToUserAsync = async (id, model) => {
  return await UsersService.addCourses(id, model);
};

const removeCourseFromUserAsync = async (id, model) => {
  return await UsersService.removeCourses(id, model);
};

const deleteUserAsync = async (id) => {
  return await UsersService.deleteUser(id);
};

const revokeSubscriptionAsync = async ({ id }) => {
  return await UsersService.revokeSubscription(id);
};

function* getUsersList({ payload }) {
  try {
    const response = yield call(getUsersListAsync, payload);
    yield put(getUserListSuccess(response));
  } catch (error) {
    yield put(getUserListError(error));
  }
}

function* getUsersProfile({ payload }) {
  try {
    const response = yield call(getUsersProfileAsync, payload);
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    yield put(getUserProfileError(error));
  }
}

function* changeUsersStatus({ payload }) {
  try {
    const response = yield call(changeUsersStatusAsync, payload);
    yield put(changeStatusSuccess(response));
  } catch (error) {
    yield put(changeStatusError(error));
  }
}

function* changeEmail({ payload }) {
  try {
    const response = yield call(changeEmailAsync, payload);
    yield put(changeEmailSuccess(response));
    yield put(getUserProfile(payload.id));
  } catch (error) {
    yield put(changeEmailError(error));
  }
}

function* setPassword({ payload }) {
  try {
    const response = yield call(setPasswordAsync, payload);
    yield put(setPasswordSuccess(response));
    // yield put(getUserProfile(payload.id));
  } catch (error) {
    yield put(setPasswordError(error));
  }
}

function* provideSubscription({ payload: { data } }) {
  try {
    const response = yield call(provideSubscriptionAsync, data);
    yield put(provideSubscriptionSuccess(response));
    ToastrService.success(PROVIDE_SUBSCRIPTION_SUCCESS);
  } catch (error) {
    yield put(provideSubscriptionError(error));
  }
}


function* sendRecoveryLink({ payload }) {
  try {
    const response = yield call(sendRecoveryLinkAsync, payload);
    yield put(sendRecoveryLinkSuccess(response));
    toastr.success(RECOVERY_SUCCESS);
  } catch (error) {
    toastr.error(error.message);
    yield put(sendRecoveryLinkError(error));
  }
}

function* getCourses({ payload: { id, pagination } }) {
  try {
    const response = yield call(getCoursesAsync, id, pagination);
    yield put(getUsersCoursesSuccess(response));
  } catch (error) {
    yield put(getUsersCoursesError(error));
  }
}

function* getNotPurchasedUsersCourses({ payload: { id, pagination } }) {
  try {
    const response = yield call(getCoursesAsync, id, {
      ...pagination,
      isBought: false,
    });
    yield put(getNotPurchasedUsersCoursesSuccess(response));
  } catch (error) {
    yield put(getNotPurchasedUsersCoursesError(error));
  }
}

function* addCourseToUser({ payload: { id, coursesIds } }) {
  try {
    const response = yield call(addCourseToUserAsync, id, { coursesIds });
    yield put(addCourseToUserSuccess(response));
    ToastrService.success(COURSES_ADDED);
  } catch (error) {
    yield put(addCourseToUserError(error));
  }
}

function* removeCourseFromUser({ payload: { id, coursesIds } }) {
  try {
    const response = yield call(removeCourseFromUserAsync, id, { coursesIds });
    yield put(removeCourseFromUserSuccess(response));
    ToastrService.success(COURSES_DELETED);
  } catch (error) {
    yield put(removeCourseFromUserError(error));
  }
}

function* deleteUser({ payload: { id, history } }) {
  try {
    yield call(deleteUserAsync, id);
    
    history.push("/users");
    ToastrService.success(USER_DELETED);
    yield put(deleteUserSuccess());
    
  } catch (error) {
    yield put(deleteUserError(error));
  }
}

function* revokeSubscription({ payload: { id } }) {
  try {
    yield call(revokeSubscriptionAsync, { id });
    
    yield put(revokeSubscriptionSuccess());
    ToastrService.success(REVOKE_SUBSCRIPTION_SUCCESS);
    yield put(getUserProfile(id));
  } catch (error) {
    yield put(revokeSubscriptionError(error));
  }
}

function* clearState() {
  yield put(clearState());
}

export function* watchGetUsersList() {
  yield takeEvery(GET_USERS_LIST, getUsersList);
}

export function* watchGetUsersProfile() {
  yield takeEvery(GET_USERS_PROFILE, getUsersProfile);
}

export function* watchChangeUsersStatus() {
  yield takeEvery(CHANGE_USERS_STATUS, changeUsersStatus);
}

export function* watchSendRecoveryLink() {
  yield takeEvery(SEND_RECOVERY_LINK, sendRecoveryLink);
}

export function* watchChangeEmail() {
  yield takeEvery(CHANGE_EMAIL, changeEmail);
}

export function* watchSetPassword() {
  yield takeEvery(SET_PASSWORD, setPassword);
}

export function* watchProvideSubscription() {
  yield takeEvery(PROVIDE_SUBSCRIPTION, provideSubscription);
}

export function* watchGetCourses() {
  yield takeEvery(GET_USER_COURSES, getCourses);
}

export function* watchGetNotPurchasedUsersCourses() {
  yield takeEvery(GET_NOT_PURCHASED_USER_COURSES, getNotPurchasedUsersCourses);
}

export function* watchAddCourseToUser() {
  yield takeEvery(ADD_COURSE_TO_USER, addCourseToUser);
}

export function* watchRemoveCourseFromUser() {
  yield takeEvery(REMOVE_COURSE_FROM_USER, removeCourseFromUser);
}

export function* watchClearState() {
  yield takeEvery(CLEAR_STATE, clearState);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchRevokeSubscription() {
  yield takeEvery(REVOKE_SUBSCRIPTION, revokeSubscription);
}

function* usersSaga() {
  yield all([
    fork(watchGetUsersList),
    fork(watchGetUsersProfile),
    fork(watchChangeUsersStatus),
    fork(watchSendRecoveryLink),
    fork(watchChangeEmail),
    fork(watchSetPassword),
    fork(watchProvideSubscription),
    fork(watchGetCourses),
    fork(watchAddCourseToUser),
    fork(watchGetNotPurchasedUsersCourses),
    fork(watchRemoveCourseFromUser),
    fork(watchDeleteUser),
    fork(watchRevokeSubscription)
  ]);
}

export default usersSaga;
