import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class CourseCategoriesService extends Http {
  BASE_URL = "/admins/courses-coupons";
  BASE_URL_TRANSACTIONS = "/admins/payment-transactions";
  BASE_URL_SUBSCRIPTION = "/admins/subscription-coupons";

  getList(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}`, {
      params: {
        limit,
        offset,
      },
    });
  }
  
  getListSubscription(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL_SUBSCRIPTION}`, {
      params: {
        limit,
        offset,
      },
    });
  }

  getOne(id) {
    return this.get(`${this.BASE_URL}/${id}`);
  }

  create(model) {
    return this.post(`${this.BASE_URL}`, model);
  }
  
  createSubscription(model) {
    return this.post(`${this.BASE_URL_SUBSCRIPTION}`, model);
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }

  getListUsages(couponId, pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}/${couponId}/usings`, {
      params: {
        limit,
        offset,
      },
    });
  }

  getTransactions(pagination = {}, filters = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL_TRANSACTIONS}/`, {
      params: {
        limit,
        offset,
        ...filters,
      },
    });
  }

  getSubscriptionCoupon() {
    return this.get(`/admins/subscription-coupons/one-month`, { disableToast: true });
  }

  updateSubscriptionCoupon(model) {
    return this.patch(`/admins/subscription-coupons/one-month`, model);
  }
  
  editCouponSubscription({ id, model }) {
    return this.put(`/admins/subscription-coupons/${id}`, model);
  }
  
  deleteCouponSubscription({ id }) {
    return this.delete(`/admins/subscription-coupons/${id}`);
  }
}

export default new CourseCategoriesService();
