import React, { useEffect, useMemo } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { AvField, AvForm } from "availity-reactstrap-validation";

import {
  apiError,
  loginClear,
  loginUser,
  verifyChangeEmailClear,
  restorePasswordClear,
  forgetPasswordClearError,
} from "../../store/actions";
import authValidator from "../../validators/auth.validators";
import useAvForm from "../../hooks/useAvForm";
import logo from "../../assets/images/logo-dark.svg";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import useModal from "../../hooks/useModal";
import useSnackbarState from "../../hooks/useSnackbarState";

import styles from "./index.module.scss";
import classNames from "classnames";
import formValidators from "../../validators/form.validators";

const ADMIN_BLOCKED =
  "You have been blocked by the Super Admin. You don’t have access to the account";

const EXCLUDED_LOGIN_ERRORS = [ADMIN_BLOCKED];

const DELETE_MODAL_TITLE = "Oops! Sorry";

const Login = ({
  loginUser,
  history,
  error,
  loading,
  loginClear,
  changedEmailVerify,
  settingsError,
  verifyChangeEmailClear,
  newPasswordSuccess,
  restorePasswordClear,
  restorePasswordError,
  forgetPasswordClearError,
}) => {
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const [modalVisible, toggleModal] = useModal();

  const [isChangedEmailVerify] = useSnackbarState(
    changedEmailVerify,
    verifyChangeEmailClear
  );

  const [isNewPasswordSuccess] = useSnackbarState(
    newPasswordSuccess,
    restorePasswordClear
  );

  const [isForgetPasswordError] = useSnackbarState(
    restorePasswordError,
    forgetPasswordClearError
  );

  const [isSettingsError] = useSnackbarState(
    settingsError,
    verifyChangeEmailClear
  );

  const handleConfirmRemove = () => {
    toggleModal();
    loginClear();
  };

  const handleValidSubmit = (event, values) => {
    loginUser(values, history);
  };
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleChange = () => {
    loginClear();
    handleFormChange();
  };

  useEffect(() => {
    if (error && EXCLUDED_LOGIN_ERRORS.includes(error)) {
      toggleModal(true);
    }
  }, [error]);

  useEffect(() => {
    return () => {
      if (error) {
        loginClear();
      }
      verifyChangeEmailClear();
      forgetPasswordClearError();
    };
  }, []);

  return (
    <>
      <div className="home-btn d-none d-sm-block"></div>
      <div className={styles.wr}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div
                        className={classNames(
                          "text-primary",
                          styles.headerContent
                        )}
                      >
                        <h5 className="text-primary mb-3">Login</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 pb-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="" width="47" height="16" />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      ref={formRef}
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {!!(error && !EXCLUDED_LOGIN_ERRORS.includes(error)) && (
                        <Alert color="danger">{error}</Alert>
                      )}

                      {(isForgetPasswordError || isSettingsError) && (
                        <Alert color="danger">
                          {restorePasswordError || settingsError}
                        </Alert>
                      )}

                      {isNewPasswordSuccess && (
                        <Alert color="success">
                          New password has been successfully created
                        </Alert>
                      )}

                      {isChangedEmailVerify && (
                        <Alert color="success">
                          The new email address has been successfully verified.
                          You can now use it to sign in to your account
                        </Alert>
                      )}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="email"
                          onChange={() => handleChange()}
                          validate={formValidators.email}
                        />
                      </div>

                      <div
                        className={classNames("form-group", styles.passwordWr)}
                      >
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          onChange={() => handleChange()}
                          placeholder="Enter password"
                          validate={authValidator.password}
                        />
                      </div>

                      <div>
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          disabled={disableState}
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          to="/forgot-password"
                          className={classNames(
                            "text-muted d-flex flex-row align-items-center justify-content-center",
                            styles.footerLink
                          )}
                        >
                          <i className="mdi mdi-lock mr-1 font-size-24" />
                          Forgot your password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmationDialog
          isOpen={modalVisible}
          titleText={DELETE_MODAL_TITLE}
          contentText={error}
          confirmButtonText="Ok"
          onCancel={handleConfirmRemove}
          onConfirm={handleConfirmRemove}
          altBtnStyle
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  const { changedEmailVerify, error: settingsError } = state.Settings;
  const { newPasswordSuccess, error: restorePasswordError } =
    state.ForgetPassword;
  return {
    error,
    loading,
    changedEmailVerify,
    settingsError,
    newPasswordSuccess,
    restorePasswordError,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    loginClear,
    apiError,
    verifyChangeEmailClear,
    restorePasswordClear,
    forgetPasswordClearError,
  })(Login)
);
