import {
  GET_LIST_PREMIUM_ARTICLES,
  GET_LIST_PREMIUM_ARTICLES_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_ERROR,
  GET_PREMIUM_ARTICLE,
  GET_PREMIUM_ARTICLE_SUCCESS,
  GET_PREMIUM_ARTICLE_CLEAR,
  GET_PREMIUM_ARTICLE_ERROR,
  UPDATE_PREMIUM_ARTICLE,
  UPDATE_PREMIUM_ARTICLE_SUCCESS,
  UPDATE_PREMIUM_ARTICLE_CLEAR,
  UPDATE_PREMIUM_ARTICLE_ERROR,
  CREATE_PREMIUM_ARTICLE,
  CREATE_PREMIUM_ARTICLE_SUCCESS,
  CREATE_PREMIUM_ARTICLE_CLEAR,
  CREATE_PREMIUM_ARTICLE_ERROR,
  CHANGE_IS_NEW_MARK,
  CHANGE_IS_NEW_MARK_SUCCESS,
  CHANGE_IS_NEW_MARK_ERROR,
  REMOVE_PREMIUM_ARTICLE,
  REMOVE_PREMIUM_ARTICLE_SUCCESS,
  REMOVE_PREMIUM_ARTICLE_ERROR,
  REMOVE_PREMIUM_ARTICLE_CLEAR,
  SAVE_PREMIUM_ARTICLES_FILTERS,
  REORDER_ARTICLE_ERROR,
  REORDER_ARTICLE_SUCCESS,
  REORDER_ARTICLE,
  GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL,
  GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  item: null,
  sectionFilter: null,
  categoryFilter: null,
  created: null,
  updated: null,
  removed: null,
  markAsNew: null,
  data: [],
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  reorderLoading: false,
  reorderError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PREMIUM_ARTICLES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL_SUCCESS:
      state = {
        ...state,
        data: [...state?.data, ...action.payload?.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_PREMIUM_ARTICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PREMIUM_ARTICLE_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_PREMIUM_ARTICLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_PREMIUM_ARTICLE_CLEAR:
      state = {
        ...state,
        item: null,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PREMIUM_ARTICLE_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLE_CLEAR:
      state = {
        ...state,
        updated: null,
      };
      break;
    case CREATE_PREMIUM_ARTICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_PREMIUM_ARTICLE_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_PREMIUM_ARTICLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_PREMIUM_ARTICLE_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case CHANGE_IS_NEW_MARK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_IS_NEW_MARK_SUCCESS:
      state = {
        ...state,
        data: state.data.map((item) => item?.id === action.payload.id ? action.payload : item),
        markAsNew: action.payload,
        loading: false,
      };
      break;
    case CHANGE_IS_NEW_MARK_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_PREMIUM_ARTICLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_PREMIUM_ARTICLE_SUCCESS:
      state = {
        ...state,
        data: state.data.filter(({ id }) => id !== action.payload.id),
        removed: action.payload.id,
        loading: false,
        pagination: {
          totalCount: state.pagination.totalCount && state.pagination.totalCount - 1,
          nextOffset: state.pagination.nextOffset && state.pagination.nextOffset - 1,
        },
      };
      break;
    case REMOVE_PREMIUM_ARTICLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_PREMIUM_ARTICLE_CLEAR:
      state = {
        ...state,
        removed: null,
      };
      break;
    case SAVE_PREMIUM_ARTICLES_FILTERS:
      state = {
        ...state,
        sectionFilter: action.payload.sectionFilter,
        categoryFilter: action.payload.categoryFilter,
      };
      break;
    case REORDER_ARTICLE:
      state = {
        ...state,
        data: action.payload.data,
        reorderError: false,
        reorderLoading: true,
      };
      break;
    case REORDER_ARTICLE_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        reorderLoading: false,
      };
      break;
    case REORDER_ARTICLE_ERROR:
      state = {
        ...state,
        reorderError: true,
        reorderLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
