import React, { useState } from "react";
import getFirstLetter from "../../helpers/getFirstLetter";

export default function UserAvatar({ avatar, name }) {
  const [isImageValid, setIsImageValid] = useState(true);

  const onError = () => {
    setIsImageValid(false);
  }

  function getImage(avatar) {
    if (avatar.isResized) {
      return avatar.resizedImages[Object.keys(avatar.resizedImages)[0]];
    }
    return avatar.link;
  }

  return (
    <section>
      {avatar && isImageValid ? (
        <img src={getImage(avatar)} alt="avatar" className="custom-avatar" onError={onError}/>
      ) : (
        <span className="custom-avatar-placeholder">
          {getFirstLetter(name)}
        </span>
      )}
    </section>
  );
}
