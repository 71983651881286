import { END, eventChannel } from "@redux-saga/core";
import { compact } from "lodash";
import throwCustomError from "./throwCustomError";
import ImageService from "../services/FilesService";

class ObjectWithBlocksSagaHepler {
  async videosWithThumbnailHandler(block, onProgress) {
    const videoFiles = block.videos.map((videoBlock) => videoBlock.video);
    const thumbnailFiles = block.videos.map(
      (videoBlock) => videoBlock.thumbnail
    );

    const videoResponse = await ImageService.uploadVideoFile(
      { files: videoFiles },
      onProgress
    ).catch((error) => {
      throwCustomError(error);
    });

    const thumbnailResponse = await ImageService.uploadImageFile(
      { files: thumbnailFiles },
      onProgress
    ).catch((error) => {
      throwCustomError(error);
    });

    const videos = videoResponse.reduce((acc, val, index) => {
      acc.push({
        videoId: val.file.id,
        videoThumbnailId: thumbnailResponse[index].file.id,
      });
      return acc;
    }, []);

    return {
      videos,
      blockId: block.id,
    };
  }

  objectComposite(model) {
    const files = model.blocks.map(({ images, videos }) => ({
      images,
      videos,
    }));
    let filesTotalSize = files.reduce((acc, { images = [], videos = [] }) => {
      const imagesSize =
        images.reduce((sum, image) => sum + image.size, 0) || 0;
      const videosSize =
        videos.reduce(
          (sum, video) => sum + video.video.size + video.thumbnail.size,
          0
        ) || 0;
      return acc + imagesSize + videosSize;
    }, 0);
    let filesArray = files.reduce((acc, { images = [], videos = [] }) => {
      return [...acc, ...images, ...videos];
    }, []);
    const hasFiles = files.reduce((acc, { images, videos }) => {
      return (
        acc || (!!images && !!images.length) || (!!videos && !!videos.length)
      );
    }, false);

    return { files, hasFiles, filesTotalSize, filesArray };
  }

  objectUpdateComposite(model) {
    const {
      title,
      urlPart,
      description,
      premiumArticleSectionId,
      category,
      tags,
      updateBlocks = [],
      addBlocks = [],
      deleteBlockIds = [],
    } = model;
    const files = [...updateBlocks, ...addBlocks].map(
      ({ images, videos, updatedVideoThumbnails }) => ({
        images,
        videos,
        updatedVideoThumbnails,
      })
    );

    const updateBlocksDTO = updateBlocks.map(
      ({ images, videos, updatedVideoThumbnails, ...rest }) => ({
        ...rest,
      })
    );
    const addBlocksDTO = addBlocks.map(
      ({ images, videos, updatedVideoThumbnails, ...rest }) => ({
        ...rest,
      })
    );
    let filesTotalSize = files.reduce(
      (acc, { images = [], videos = [], updatedVideoThumbnails = [] }) => {
        const imagesSize =
          images.reduce((sum, image) => sum + image.size, 0) || 0;
        const videosSize =
          videos.reduce(
            (sum, video) => sum + video.video.size + video.thumbnail.size,
            0
          ) || 0;
        const updatedThumbnailSize =
          updatedVideoThumbnails.reduce(
            (sum, updatedThumbnail) => sum + updatedThumbnail.thumbnail.size,
            0
          ) || 0;
        return acc + imagesSize + videosSize + updatedThumbnailSize;
      },
      0
    );
    let filesArray = files.reduce(
      (acc, { images = [], videos = [], updatedVideoThumbnails = [] }) => {
        return [...acc, ...images, ...videos, ...updatedVideoThumbnails];
      },
      []
    );
    const hasFiles = files.reduce(
      (acc, { images, videos, updatedVideoThumbnails }) => {
        return (
          acc ||
          (!!images && !!images.length) ||
          (!!videos && !!videos.length) ||
          (!!updatedVideoThumbnails && !!updatedVideoThumbnails)
        );
      },
      false
    );
    const formData = {
      title,
      description,
      urlPart,
      premiumArticleSectionId,
      category,
      tags,
    };
    if (deleteBlockIds.length) {
      formData.deleteBlockIds = deleteBlockIds;
    }
    if (addBlocksDTO.length) {
      formData.addBlocks = addBlocksDTO;
    }
    if (updateBlocksDTO.length) {
      formData.updateBlocks = updateBlocksDTO;
    }

    return { formData, files, hasFiles, filesTotalSize, filesArray };
  }

  create(dto, createFunc, addFilesFunc, addImageFunc) {
    return eventChannel((emit) => {
      const { id, model } = dto;
      const createFuncParams = id ? [id, model] : [dto];
      const { files, hasFiles, filesTotalSize, filesArray } =
        this.objectComposite(model || dto);
      let obj = null;
      createFunc(...createFuncParams)
        .then(({ data }) => {
          obj = data;
          const onProgress = ({ loaded }, file, chunkIndex) => {
            emit({ file, loaded, chunkIndex });
          };
          let previewLoadingPromise;
          let previewSize = 0;
          if (!!dto.files && dto.files.length > 0 && !!addImageFunc) {
            previewSize = dto.files[0].size;
            previewLoadingPromise = ImageService.uploadImageFile(
              { files: dto.files },
              onProgress
            )
              .then((res) => {
                return addImageFunc(obj.id, {
                  fileId: res[0].file.id,
                }).catch((error) => throwCustomError(error));
              })
              .catch((error) => {
                throwCustomError(error);
              });
          }

          if (!hasFiles && !previewLoadingPromise) {
            return { data };
          } else if (!hasFiles && previewLoadingPromise) {
            emit({ files: dto.files, filesTotalSize: previewSize });
            return previewLoadingPromise.then(() => ({ data }));
          } else {
            emit({
              files: [...filesArray, dto?.model?.files || []],
              filesTotalSize: filesTotalSize + previewSize,
            });
            const blocksWithMappedFiles = obj.blocks.map(({ id }, index) => ({
              id,
              ...files[index],
            }));
            const blocksPromises = Promise.all(
              blocksWithMappedFiles.map(async (block) => {
                let result = null;

                if (!!block.images && !!block.images.length) {
                  const files = block.images;
                  result = await ImageService.uploadImageFile(
                    { files },
                    onProgress
                  )
                    .then((response) => {
                      const imagesIds = response.reduce((acc, val) => {
                        acc.push(val.file.id);
                        return acc;
                      }, []);
                      return {
                        imagesIds,
                        blockId: block.id,
                      };
                    })
                    .catch((error) => {
                      throwCustomError(error);
                    });
                }

                if (!!block.videos && !!block.videos.length) {
                  const res = await this.videosWithThumbnailHandler(
                    block,
                    onProgress
                  );

                  result = { ...result, ...res };
                }
                return result;
              })
            ).then((addFilesModel) =>
              addFilesFunc(obj.id, compact(addFilesModel))
            );

            return previewLoadingPromise
              ? previewLoadingPromise.then(() => blocksPromises)
              : blocksPromises;
          }
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });

      return () => {};
    });
  }

  update(dto, updateFunc, addFilesFunc, addImageFunc, updateThumbnailFunc) {
    return eventChannel((emit) => {
      const { id, model } = dto;
      const { formData, files, hasFiles, filesTotalSize, filesArray } =
        this.objectUpdateComposite(model);
      let obj = null;
      updateFunc(id, formData)
        .then(({ data }) => {
          obj = data;
          const onProgress = ({ loaded }, file, chunkIndex) => {
            emit({ file, loaded, chunkIndex });
          };
          let previewLoadingPromise;
          let previewSize = 0;

          if (
            !!dto.model.files &&
            dto.model.files.length > 0 &&
            !!addImageFunc
          ) {
            previewSize = dto.model.files[0].size;
            previewLoadingPromise = ImageService.uploadImageFile(
              { files: dto.model.files },
              onProgress
            )
              .then((res) => {
                return addImageFunc(obj.id, {
                  fileId: res[0].file.id,
                }).catch((error) => throwCustomError(error));
              })
              .catch((error) => {
                throwCustomError(error);
              });
          }

          if (!hasFiles && !previewLoadingPromise) {
            return { data };
          } else if (!hasFiles && previewLoadingPromise) {
            emit({ files: dto.model.files, filesTotalSize: previewSize });
            return previewLoadingPromise.then(() => ({ data }));
          } else {
            emit({
              files: [...filesArray, dto?.model?.files || []],
              filesTotalSize: filesTotalSize + previewSize,
            });
            const blocksWithMappedFiles = obj.blocks.map(({ id }, index) => ({
              id,
              ...files[index],
            }));

            const blocksPromises = Promise.all(
              blocksWithMappedFiles.map(async (block) => {
                let result = null;
                let updateThumbnailResult = null;
                if (!!block.images && !!block.images.length) {
                  const files = block.images;
                  result = await ImageService.uploadImageFile(
                    { files },
                    onProgress
                  )
                    .then((response) => {
                      const imagesIds = response.reduce((acc, val) => {
                        acc.push(val.file.id);
                        return acc;
                      }, []);
                      return {
                        imagesIds,
                        blockId: block.id,
                      };
                    })
                    .catch((error) => {
                      throwCustomError(error);
                    });
                }

                if (
                  !!block.updatedVideoThumbnails &&
                  !!block.updatedVideoThumbnails.length
                ) {
                  const files = block.updatedVideoThumbnails.map(
                    (thumbnailBlock) => thumbnailBlock.thumbnail
                  );

                  const videoIds = block.updatedVideoThumbnails.map(
                    (thumbnailBlock) => thumbnailBlock.videoId
                  );
                  const thumbnailResponse = await ImageService.uploadImageFile(
                    { files },
                    onProgress
                  ).catch((error) => {
                    throwCustomError(error);
                  });
                  const videos = thumbnailResponse.reduce((acc, val, index) => {
                    acc.push({
                      videoId: videoIds[index],
                      videoThumbnailId: val.file.id,
                    });
                    return acc;
                  }, []);
                  const updateThumbnailResultBlock = {
                    videos,
                    blockId: block.id,
                  };
                  updateThumbnailResult = {
                    ...updateThumbnailResult,
                    ...updateThumbnailResultBlock,
                  };
                }
                if (!!block.videos && !!block.videos.length) {
                  const res = await this.videosWithThumbnailHandler(
                    block,
                    onProgress
                  );

                  result = { ...result, ...res };
                }
                return { result, updateThumbnailResult };
              })
            ).then(async (addFilesModel) => {
              const files = compact(
                addFilesModel.map((fileResult) => fileResult.result)
              );
              const thumbnails = compact(
                addFilesModel.map(
                  (thumbnailsResult) => thumbnailsResult.updateThumbnailResult
                )
              );
              let result = null;
              if (files?.length) {
                const filesResult = await addFilesFunc(obj.id, files).catch(
                  (error) => {
                    throwCustomError(error);
                  }
                );

                result = { ...result, ...filesResult };
              }

              if (thumbnails?.length) {
                const ThumbnailResult = await updateThumbnailFunc(
                  obj.id,
                  thumbnails
                ).catch((error) => {
                  throwCustomError(error);
                });

                result = { ...result, ...ThumbnailResult };
              }

              return result;
            });

            return previewLoadingPromise
              ? previewLoadingPromise.then(() => blocksPromises)
              : blocksPromises;
          }
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });

      return () => {};
    });
  }
}

export default new ObjectWithBlocksSagaHepler();
