import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";

import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";

import {
  forgetPasswordSendLink,
  forgetPasswordSendLinkClear,
} from "../../store/actions";
import useAvForm from "../../hooks/useAvForm";
import formsValidators from "../../validators/form.validators";
import useModal from "../../hooks/useModal";
import ConfirmationDialog from "../../components/Modals/ConfirmationDialog";
import FormGroupText from "../../components/Forms/inputs/FormGroupText";

import logo from "../../assets/images/logo-dark.svg";

import styles from "./index.module.scss";

const MODAL_TITLE = "Recovery link";
const MODAL_DESCRIPTION =
  "If you have an account with the email address provided, we will send you a recovery link. You need to open a link to create a new password";

const initialState = {
  email: "",
};

const ForgetPasswordPage = ({
  forgetPassword,
  forgetPasswordSendLink,
  forgetPasswordSendLinkClear,
}) => {
  const { linkSend, loading } = forgetPassword;
  const history = useHistory();

  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const [modalVisible, toggleModal] = useModal();

  useEffect(() => {
    if (!loading && linkSend) toggleModal(true);
  }, [linkSend, loading]);

  const handleCancelModal = () => {
    forgetPasswordSendLinkClear();
    toggleModal(false);
    history.push("/login");
  };

  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleFormSubmit = (event, values) => {
    forgetPasswordSendLink(values);
  };

  return (
    <>
      <div className={styles.wr}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div
                        className={classNames(
                          "text-primary",
                          styles.headerContent
                        )}
                      >
                        <h5 className="text-primary mb-3">Reset password</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 pb-0">
                  <div>
                    <div className="avatar-md profile-user-wid">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="" width="47" height="16" />
                      </span>
                    </div>
                  </div>
                  <div className="p-2 pb-0">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={handleFormSubmit}
                      ref={formRef}
                      model={initialState}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          return false;
                        }
                      }}
                    >
                      <FormGroupText
                        vertical
                        className="mb-2"
                        labelClassName="pt-0"
                        name="email"
                        label="Email"
                        placeholder="Enter email address"
                        validator={formsValidators.email}
                        handleFormChange={handleFormChange}
                      />
                      <div className="text-right mb-4">
                        <Button
                          type="submit"
                          color="primary"
                          disabled={disableState}
                        >
                          Reset password
                        </Button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div
                className={classNames(
                  "text-center",
                  styles.resetPasswordFooter
                )}
              >
                <p className="d-flex flex-row justify-content-center mb-0">
                  <span className="mr-2">Remember it?</span>
                  <Link to="login" className="font-weight-medium text-primary">
                    Log in
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={MODAL_TITLE}
        contentText={MODAL_DESCRIPTION}
        confirmButtonText="OK"
        onCancel={handleCancelModal}
        onConfirm={handleCancelModal}
        containerClassname={styles.modalContainer}
        altBtnStyle
      />
    </>
  );
};

const mapStatetoProps = ({ ForgetPassword: forgetPassword }) => ({
  forgetPassword,
});

export default withRouter(
  connect(mapStatetoProps, {
    forgetPasswordSendLink,
    forgetPasswordSendLinkClear,
  })(ForgetPasswordPage)
);
