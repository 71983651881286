import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import {
  COMMENTS_STATUS,
  COMMENTS_TYPES,
  APPROVE_MODAL_TITLE,
  APPROVE_MODAL_DESCRIPTION,
  DELETE_MODAL_TITLE,
  DELETE_MODAL_DESCRIPTION,
  TEXT_EDITOR_MODAL_DATA,
} from "../../../../constants/comments";
import capitalizeString from "../../../../helpers/capitalizeString";
import formatDate from "../../../../helpers/formatDate";
import { ADMIN, SUPER_ADMIN } from "../../../../constants/userRoles";
import check from "../../../../assets/images/icons/ic_check_primary.svg";
import chevronDown from "../../../../assets/images/icons/ic_chevron-down_primary.svg";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import TextEditorModal from "../../../../components/Modals/TextEditorModal";
import getLessonCommentLinkTitle from "../../../../helpers/getLessonCommentLinkTitle";
import { EMPTY_PARAGRAPH_REG_EXP } from "../../../../constants/editorConstants";

import styles from "../index.module.scss";

const DATE_FORMAT = "DD MMM, YYYY";
const HOURS_FORMAT = "HH:mm";

const CommentCard = ({
  id,
  createdAt,
  user,
  comment,
  type,
  status,
  isAdminRead,
  lesson,
  premiumArticle,
  changedComment,
  readCommnetChange,
  approveCommnet,
  deleteComment,
  replyToComment,
  originalComment,
  isPremiumArticlesComments,
  displayViewBtn = true,
}) => {
  const [modalVisible, toggleModal] = useModal();
  const [deleteModalVisible, toggleDeleteModal] = useModal();
  const [textareaModalVisible, toggleTextareaModal] = useModal();

  const [viewOriginalComment, setViewOriginalComment] = useState(false);

  const handleApproveComment = () => {
    toggleModal();
    approveCommnet(id);
  };

  const handleDeleteComment = () => {
    toggleDeleteModal();
    deleteComment(id);
  };

  const handleReplyComment = useCallback(
    (data) => {
      if (data) {
        const comment = data.comment?.replace(EMPTY_PARAGRAPH_REG_EXP, "");
        replyToComment(id, comment);
        toggleTextareaModal();
      }
    },
    [id, replyToComment, toggleTextareaModal]
  );

  return (
    <>
      <Card
        className={
          (user.role === SUPER_ADMIN || user.role === ADMIN) && styles.adminCard
        }
      >
        <CardBody>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div
              className={classNames(
                !displayViewBtn && "pb-3",
                "d-flex flex-row align-items-center"
              )}
            >
              <span className={styles.commentType}>
                {capitalizeString(type)}
              </span>
              {!isPremiumArticlesComments && (
                <span
                  className={classNames(
                    "badge",
                    status === COMMENTS_STATUS.pending || !!changedComment
                      ? "badge-soft-info"
                      : "badge-soft-success",
                    styles.commentStatus
                  )}
                >
                  {capitalizeString(
                    !!changedComment ? COMMENTS_STATUS.pending : status
                  )}
                </span>
              )}
            </div>
            <div className="d-flex flex-row align-items-center">
              {type === COMMENTS_TYPES.reply && (
                <button
                  className={styles.commentOriginalBtn}
                  type="button"
                  onClick={() => setViewOriginalComment(!viewOriginalComment)}
                >
                  <img
                    src={chevronDown}
                    alt="chevron down"
                    className={classNames(
                      viewOriginalComment && styles.commentOriginalActiveImg
                    )}
                  />
                  <span>
                    {viewOriginalComment
                      ? "Hide original comment"
                      : "View original comment"}
                  </span>
                </button>
              )}
              <Link to="#" onClick={() => toggleDeleteModal()}>
                <i
                  className={classNames("bx bx-trash text-grey", styles.trash)}
                />
              </Link>
            </div>
          </div>
          {originalComment && (
            <div
              className={classNames(
                styles.commentOriginal,
                !!viewOriginalComment && styles.commentOriginalActive
              )}
            >
              {originalComment.comment}
            </div>
          )}
          {displayViewBtn && (
            <div
              className={classNames(
                styles.commentTitle,
                "d-flex flex-row align-items-center flex-wrap"
              )}
            >
              {lesson && (
                <>
                  <p className="mb-0">
                    {lesson.course && `${lesson.course.title}:`}
                  </p>
                  &nbsp;
                  <Link to={`/individual-content/lesson/${lesson.id}`}>
                    {getLessonCommentLinkTitle(
                      lesson.ordinalNumber,
                      lesson.title
                    )}
                  </Link>
                </>
              )}
              {premiumArticle && (
                <>
                  <p className="mb-0">
                    {premiumArticle.premiumArticleSection &&
                      premiumArticle.premiumArticleCategory &&
                      `${premiumArticle.premiumArticleSection.name} > ${premiumArticle.premiumArticleCategory.name}:`}
                    &nbsp;
                  </p>
                  <Link to={`/individual-content/article/${premiumArticle.id}`}>
                    {premiumArticle.title}
                  </Link>
                </>
              )}
            </div>
          )}
          <div
            className={styles.commentMain}
            dangerouslySetInnerHTML={{ __html: changedComment || comment }}
          />
          <div className={styles.commentInfo}>
            By <span>{user.userName}</span> •{" "}
            {`${formatDate(createdAt, DATE_FORMAT)} at ${formatDate(
              createdAt,
              HOURS_FORMAT
            )}`}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <a
                href={
                  lesson
                    ? `${process.env.REACT_APP_MAIN_SITE_URL}/lessons/${lesson.urlPart}`
                    : premiumArticle &&
                    `${process.env.REACT_APP_MAIN_SITE_URL}/dj-hub/premium-article/${premiumArticle.urlPart}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {displayViewBtn && (
                  <Button className={classNames(styles.commentButton, "mr-3")}>
                    {lesson ? "View lesson" : "View article"}
                  </Button>
                )}
              </a>
              <Button
                color="primary"
                className={classNames(styles.commentButton, "mr-3")}
                onClick={() => toggleTextareaModal()}
              >
                Reply
              </Button>
              {(status !== COMMENTS_STATUS.published || changedComment) &&
                !isPremiumArticlesComments && (
                  <Button
                    onClick={() => toggleModal()}
                    className={styles.commentButton}
                    color="success"
                  >
                    Publish
                  </Button>
                )}
            </div>
            <div>
              {isAdminRead ? (
                <div className={styles.commentRead}>
                  <img src={check} alt="check" />
                  <span>READ</span>
                </div>
              ) : (
                <Button
                  onClick={() => readCommnetChange(id)}
                  className={styles.commentButton}
                  color="primary"
                >
                  Mark as read
                </Button>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <ConfirmationDialog
        containerClassname={styles.modal}
        confirmBtnClassName={styles.modalOkBtn}
        isOpen={modalVisible}
        titleText={APPROVE_MODAL_TITLE}
        contentText={APPROVE_MODAL_DESCRIPTION}
        cancelButtonText="Cancel"
        confirmButtonText="OK"
        onCancel={toggleModal}
        onConfirm={handleApproveComment}
        altBtnStyle
      />

      <ConfirmationDialog
        containerClassname={styles.modal}
        confirmBtnClassName={styles.modalOkBtn}
        isOpen={deleteModalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={handleDeleteComment}
        altBtnStyle
      />

      <TextEditorModal
        {...TEXT_EDITOR_MODAL_DATA}
        containerClassname={styles.modal}
        confirmBtnClassName={styles.modalOkBtn}
        isOpen={textareaModalVisible}
        onCancel={toggleTextareaModal}
        contentText={changedComment || comment}
        onConfirm={handleReplyComment}
        altBtnStyle
      />
    </>
  );
};

export default CommentCard;
