import React, { useCallback, useMemo } from "react";
import { Alert, Col, FormGroup, Label } from "reactstrap";
import Dropzone from "react-dropzone";
import classNames from "classnames";

import formsValidators from "../../../../validators/form.validators";

import styles from "./index.module.scss";
import validateDropzoneImage from "../../../../validators/imageValidator";

const FormGroupFileImage = ({
  error,
  selectedImages,
  handleAcceptedImages,
  setSelectedImages,
  index,
  multiple = false,
  initialFile = null,
  label = "",
  errorIndex,
}) => {
  const getFileName = useMemo(() => {
    if (selectedImages[index]?.name) {
      return selectedImages[index]?.name;
    } else if (!!initialFile) {
      return initialFile.name;
    }

    return "No file chosen";
  }, [selectedImages, initialFile, index]);

  const deleteHandler = useCallback(() => {
    const currentSelectedFiles = [...selectedImages];
    delete currentSelectedFiles[index];
    setSelectedImages(currentSelectedFiles);
  }, [index, selectedImages, setSelectedImages]);

  const isFileSelected = useMemo(() => {
    return selectedImages[index] && selectedImages[index] !== initialFile;
  }, [index, initialFile, selectedImages]);

  const onDrop = async(acceptedFiles, fileRejections) => {
    const {validatedAcceptedFiles,  validatedFileRejections} = await validateDropzoneImage(acceptedFiles, fileRejections);
    handleAcceptedImages(
      validatedAcceptedFiles,
      validatedFileRejections,
      multiple,
      index
    );
  }

  return (
    <FormGroup className="mt-3 mb-4" row>
      <Col lg="12">
        <Label className={styles.label}>{label}</Label>
        <Dropzone
          noDrag
          multiple={false}
          maxSize={formsValidators.file.image.maxSize.value}
          accept={formsValidators.file.image.formats.value
            .map((format) => "image/" + format)
            .join(",")}
          onDrop={onDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={styles.fileInput}>
              <div className={styles.choseFile} {...getRootProps()}>
                <input {...getInputProps()} />
                <div>Choose file</div>
              </div>
              <div
                className={classNames(
                  styles.fileName,
                  isFileSelected && styles.fileNameActive
                )}
              >
                {getFileName}
              </div>
              {isFileSelected && (
                <i
                  className={classNames(
                    "bx bx-trash text-grey font-size-20 cursor-pointer",
                    styles.delete
                  )}
                  onClick={deleteHandler}
                />
              )}
            </div>
          )}
        </Dropzone>
        {error && index === errorIndex && (
          <Alert className="mt-3" color="danger">
            {error}
          </Alert>
        )}
      </Col>
    </FormGroup>
  );
};

export default FormGroupFileImage;
