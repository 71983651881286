import React, { useEffect, useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";
import {
  changeAdminPassword,
  changeAdminPasswordClear,
} from "../../store/dashboard/Settings/actions";

import styles from "./forms.module.scss";

const initialState = {
  password: "",
  newPassword: "",
  confirmNewPassword: "",
};

const ChangeAdminPassword = ({
  settings,
  changeAdminPassword,
  changeAdminPasswordClear,
}) => {
  const { loading, passwordHasBeenChanged } = settings;
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  useEffect(() => {
    if (!loading && passwordHasBeenChanged) {
      formRef.current.reset();
      handleFormChange();
      changeAdminPasswordClear();
    }
  }, [passwordHasBeenChanged, loading]);

  const handleFormSubmit = (event, values) => {
    const { password, newPassword } = values;
    changeAdminPassword({ password, newPassword });
  };

  return (
    <Card className={styles.card}>
      <CardBody>
        <div className={classNames(styles.cardTitle, "mb-3")}>
          Change your password
        </div>
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          model={initialState}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <FormGroupText
            vertical
            className="mb-0"
            labelClassName="pt-0"
            name="password"
            label="Current password"
            placeholder="Enter current password"
            type="password"
            validator={formsValidators.currentPassword}
            handleFormChange={handleFormChange}
            autoComplete="on"
          />

          <FormGroupText
            vertical
            className="mb-0"
            labelClassName="pt-0"
            name="newPassword"
            label="New password"
            placeholder="Enter new password"
            type="password"
            validator={formsValidators.newPassword}
            handleFormChange={handleFormChange}
            autoComplete="on"
          />

          <FormGroupText
            vertical
            className="mb-0"
            labelClassName="pt-0"
            name="confirmNewPassword"
            label="Confirm new password"
            placeholder="Enter new password"
            type="password"
            validator={formsValidators.confirmNewPassword}
            handleFormChange={handleFormChange}
            autoComplete="on"
          />
          <Button
            type="submit"
            color="primary"
            disabled={disableState}
            className={classNames(styles.cardBtn, "ml-auto d-block mt-2")}
          >
            Change password
          </Button>
        </AvForm>
      </CardBody>
    </Card>
  );
};
const mapStateToProps = ({ Settings: settings }) => ({ settings });

export default connect(mapStateToProps, {
  changeAdminPassword,
  changeAdminPasswordClear,
})(ChangeAdminPassword);
