import React, { useCallback, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { useRouteMatch, withRouter } from "react-router-dom";

import SaveCourseCategory from "../../../components/Forms/SaveCourseCategory";
import {
  clearFetchedCourseCategory,
  getCourseCategory,
  updateCourseCategory,
} from "../../../store/dashboard/Training/actions";
import ProgressWatcher from "../../../components/Common/ProgressWatcher";
import SEO from "../../../components/Common/SEO";

const PROGRESS_TEXT = "Image is uploading now. Please, don't quit this page";

const UpdateCourseCategory = ({
  history,
  updateCourseCategory,
  getCourseCategory,
  clearFetchedCourseCategory,
  loading,
  item,
}) => {
  const {
    params: { categoryId },
  } = useRouteMatch("/training/:categoryId/update");
  const [ready, updateReadyState] = useState(false);
  const saveCourse = useCallback(
    (values) => {
      if (!loading) {
        updateCourseCategory(categoryId, values, history);
      }
    },
    [categoryId, history, loading, updateCourseCategory]
  );

  useEffect(() => {
    if (!ready && categoryId && !loading) {
      getCourseCategory(categoryId);
      updateReadyState(true);
    }
  }, [categoryId, ready, loading, getCourseCategory]);

  useEffect(() => {
    return () => {
      clearFetchedCourseCategory();
    };
  }, [clearFetchedCourseCategory]);

  return (
    <>
      <SEO title="Edit course category" />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Edit course category
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {item && (
                      <SaveCourseCategory
                        model={item}
                        saveCategoryFunc={saveCourse}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    Training: { item, loading, error },
  } = state;
  return { item, loading, error };
};

export default withRouter(
  connect(mapStateToProps, {
    updateCourseCategory,
    getCourseCategory,
    clearFetchedCourseCategory,
  })(UpdateCourseCategory)
);
