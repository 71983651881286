import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_NOTIFICATIONS_LIST,
  GET_REMINDER_NOTIFICATION,
  UPDATE_NOTIFICATION,
  CREATE_NOTIFICATION,
} from "./actionTypes";

import {
  getNotificationsSuccess,
  getNotificationsError,
  getReminderNotificationSuccess,
  getReminderNotificationError,
  updateNotificationSuccess,
  updateNotificationError,
  createNotificationSuccess,
  createNotificationError,
} from "./actions";

import NotificationsService from "../../../services/NotificationsService";
import {
  getCreateNofificationSuccessText,
  NOTIFICATIONS_TYPES,
} from "../../../constants/notifications";
import ToastrService from "../../../services/ToastrService";

const getNotificationsListAsync = async (searchParams) => {
  return await NotificationsService.getList(searchParams);
};

const updateNotificationAsync = async (model) => {
  return await NotificationsService.updateNofification(model);
};

const createNotificationAsync = async (model) => {
  return await NotificationsService.create(model);
};

function* getNotificationsList({ payload }) {
  try {
    const response = yield call(getNotificationsListAsync, {
      ...payload,
      type: NOTIFICATIONS_TYPES.ACTIVE,
    });
    yield put(getNotificationsSuccess(response));
  } catch (error) {
    yield put(getNotificationsError(error));
  }
}

function* getReminderNotification({ payload: { type } }) {
  try {
    const response = yield call(getNotificationsListAsync, {
      type,
    });
    yield put(getReminderNotificationSuccess(response));
  } catch (error) {
    yield put(getReminderNotificationError(error));
  }
}

function* updateNotification({ payload }) {
  try {
    const response = yield call(updateNotificationAsync, payload);
    yield put(updateNotificationSuccess(response));
  } catch (error) {
    yield put(updateNotificationError(error));
  }
}

function* createNotification({ payload }) {
  try {
    const response = yield call(createNotificationAsync, payload);
    yield put(createNotificationSuccess(response));
    ToastrService.success(getCreateNofificationSuccessText(payload.type));
  } catch (error) {
    yield put(createNotificationError(error));
  }
}

export function* watchGetNotificationsList() {
  yield takeEvery(GET_NOTIFICATIONS_LIST, getNotificationsList);
}

export function* watchReminderNotification() {
  yield takeEvery(GET_REMINDER_NOTIFICATION, getReminderNotification);
}

export function* watchUpdateNotification() {
  yield takeEvery(UPDATE_NOTIFICATION, updateNotification);
}

export function* watchCreateNotification() {
  yield takeEvery(CREATE_NOTIFICATION, createNotification);
}

function* notificationsSaga() {
  yield all([
    fork(watchGetNotificationsList),
    fork(watchReminderNotification),
    fork(watchUpdateNotification),
    fork(watchCreateNotification),
  ]);
}

export default notificationsSaga;
