import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { merge } from "lodash";

import formsValidators from "../../../validators/form.validators";
import useAvForm from "../../../hooks/useAvForm";
import useMountedState from "../../../hooks/useMountedState";
import FormGroupText from "../inputs/FormGroupText";
import LessonBlock from "./LessonBlock";
import { getInitialState } from "./getInitialState";
import LessonsHelper from "../../../helpers/LessonsHelper";
import { EMPTY_PARAGRAPH } from "../../../constants/editorConstants";
import useInputWithReadOnlyTextPart from "../../../hooks/useInputWithReadOnlyTextPart";
import InputWithReadOnlyTextPart from "../inputs/InputWithReadOnlyTextPart";
import getReadOnlyUrlPart from "../../../helpers/getReadOnlyUrlPart";

import styles from "../forms.module.scss";
import { BLOCK_FOR } from '../../../constants/lessonsDefaults';

const previewLessonValue = 'PreviewLesson';
const previewLessonBlockValue = 'PreviewLessonBlock';

const SaveLesson = ({
  model,
  saveLessonFunc,
  updateDirtyState,
  loading,
  courseId,
  ordinalNumber = null,
}) => {
  const [formData, updateFormData] = useState(getInitialState(ordinalNumber));
  const [ready, updateReadyState] = useMountedState(false);
  const [thumbnailAndVideoSuccess, setThumbnailAndVideoSuccess] =
    useState(true);
  const [updatedThumbnails, setUpdatedThumbnails] = useState(false);
  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();

  const urlPart = useInputWithReadOnlyTextPart(
    values?.title,
    formRef,
    model?.urlPart
  );

  const extractedBlocksAsArray = useMemo(() => {
    return Object.keys(formData)
      .filter((key) => key.startsWith("block"))
      .map((blockName, index) => ({
        blockName,
        ableToAdd:
          !!formData[`block${index + 2}`] &&
          formData[`block${index + 1}`].isVisible &&
          !formData[`block${index + 2}`].isVisible,
        ...formData[blockName],
      }));
  }, [formData]);

  const hasBlocksFilled = useMemo(() => {
    const hasTexts =
      !!values &&
      !!Object.keys(values).filter(
        (key) =>
          key.startsWith("block") &&
          !!values[key].text &&
          values[key].text !== EMPTY_PARAGRAPH
      ).length;
    const hasFiles = !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter((block) => {
        const { images, videos, imagesUploaded, videosUploaded } = block;
        return (
          images.length > 0 ||
          videos.length > 0 ||
          (!!imagesUploaded && imagesUploaded.length > 0) ||
          (!!videosUploaded && videosUploaded.length > 0)
        );
      }).length;
    return hasTexts || hasFiles;
  }, [values, extractedBlocksAsArray]);

  const hasFilesToRemove = useMemo(() => {
    if (!model) {
      return false;
    }
    return !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter(({ deleteImagesIds, deleteVideosIds }) => {
        return (
          (!!deleteImagesIds && !!deleteImagesIds.length) ||
          (!!deleteVideosIds && !!deleteVideosIds.length)
        );
      }).length;
  }, [extractedBlocksAsArray, model]);

  const hasFilesToAdd = useMemo(() => {
    if (!model) {
      return false;
    }
    return !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter(({ images, videos }) => {
        return (!!images && !!images.length) || (!!videos && !!videos.length);
      }).length;
  }, [extractedBlocksAsArray, model]);

  const handleFormSubmit = (event, formValues) => {
    if (!model) {
      const blocksWithFiles = extractedBlocksAsArray
        .filter((block) => block.isVisible)
        .filter((block) => block.images.length > 0 || block.videos.length > 0)
        .reduce((acc, { blockName, images, videos }) => {
          acc = { ...acc, [blockName]: { images, videos } };
          return acc;
        }, {});
      const dataMerged = merge(blocksWithFiles, formValues);
      const dataToCreate = {
        title: values.title,
        ordinalNumber: values.ordinalNumber,
        urlPart: values.urlPart,
        isPreviewLesson: values.previewLesson.length === 1,
        blocks: LessonsHelper.getBlocksTransformed(dataMerged)
          .filter(
            ({ text, images, videos, typeFormId }) =>
              (text !== "" && text !== EMPTY_PARAGRAPH) ||
              (images && images.length) ||
              (videos && videos.length) ||
              (typeFormId !== "")
          )
          .map((block) => LessonsHelper.getBlockCreateDTO(block)),
      };
      saveLessonFunc(dataToCreate);
    } else {
      const dataToUpdate = {
        title: values.title,
        ordinalNumber: values.ordinalNumber,
        urlPart: values.urlPart,
        isPreviewLesson: values.previewLesson.length === 1,
        updateBlocks: [],
      };
      const blocksVisible = LessonsHelper.getBlocksVisible(
        extractedBlocksAsArray
      );
      const dataMerged = merge(blocksVisible, formValues);
      const blocksTransformed = LessonsHelper.getBlocksTransformed(dataMerged);
      const updateBlocks = blocksTransformed
        .filter(({ id }) => !!id)
        .filter(
          ({
            text,
            images,
            videos,
            deleteImagesIds,
            deleteVideosIds,
            imagesUploaded,
            videosUploaded,
            updatedVideoThumbnails,
            typeFormId,
            ordinalNumber,
          }) => {
            return (
              !!text ||
              !!typeFormId ||
              !!ordinalNumber ||
              (!!images && !!images.length) ||
              (!!videos && !!videos.length) ||
              (!!imagesUploaded && !!imagesUploaded.length) ||
              (!!videosUploaded && !!videosUploaded.length) ||
              (!!deleteImagesIds && !!deleteImagesIds.length) ||
              (!!deleteVideosIds && !!deleteVideosIds.length) ||
              (!!updatedVideoThumbnails && !!updatedVideoThumbnails.length)
            );
          }
        )
        .map((block) => LessonsHelper.getBlockUpdateDTO(block))
        .filter((item) => !!item);
      const addBlocks = blocksTransformed
        .filter(({ id }) => !id)
        .filter(
          ({ text, images, videos }) =>
            (text !== "" && text !== EMPTY_PARAGRAPH) ||
            (images && images.length) ||
            (videos && videos.length)
        )
        .map((block) => LessonsHelper.getBlockCreateDTO(block));
      const deleteBlockIds = blocksTransformed
        .filter(({ id }) => id)
        .filter((block) => LessonsHelper.checkEmptyBlock(block))
        .map(({ id }) => id);

      if (updateBlocks.length) {
        dataToUpdate.updateBlocks = updateBlocks;
      }
      if (addBlocks.length) {
        dataToUpdate.addBlocks = addBlocks;
      }
      if (deleteBlockIds.length) {
        dataToUpdate.deleteBlockIds = deleteBlockIds;
      }
      saveLessonFunc(dataToUpdate);
    }
  };

  const addBlockHandler = useCallback(() => {
    const yetInvisibleIndex = extractedBlocksAsArray.findIndex(
      ({ isVisible }) => !isVisible
    );
    if (yetInvisibleIndex >= 0) {
      const { blockName } = extractedBlocksAsArray[yetInvisibleIndex];
      const formDataUpdated = { ...formData };
      formDataUpdated[blockName].isVisible = true;
      updateFormData(formDataUpdated);
    }
  }, [formData]);

  const updateFilesHandler = useCallback(
    (files) => {
      const {
        blockName,
        images,
        videos,
        deleteImagesIds,
        deleteVideosIds,
        updatedVideoThumbnails,
      } = files;
      const formDataUpdated = { ...formData };
      formDataUpdated[blockName].images = images;
      formDataUpdated[blockName].videos = videos;
      formDataUpdated[blockName].deleteImagesIds = deleteImagesIds;
      formDataUpdated[blockName].deleteVideosIds = deleteVideosIds;
      formDataUpdated[blockName].updatedVideoThumbnails =
        updatedVideoThumbnails;
      updateFormData(formDataUpdated);
    },
    [formData]
  );

  const pageTitle = useMemo(
    () => (model ? "Edit lesson" : "Create lesson"),
    [model]
  );
  const btnSaveLabel = useMemo(() => (model ? "Save" : "Create"), [model]);
  const disableState = useMemo(
    () =>
      !(
        (isDirty && hasBlocksFilled) ||
        hasFilesToRemove ||
        hasFilesToAdd ||
        updatedThumbnails
      ) ||
      loading ||
      hasFormError ||
      !thumbnailAndVideoSuccess,
    [
      loading,
      hasFormError,
      isDirty,
      hasBlocksFilled,
      hasFilesToRemove,
      hasFilesToAdd,
      thumbnailAndVideoSuccess,
      updatedThumbnails,
    ]
  );

  useEffect(() => {
    if (!!model) {
      // Prepare model
      const { title, urlPart, ordinalNumber, isPreviewLesson, blocks } = model;
      const formData = { ...getInitialState(), 
        title, urlPart, ordinalNumber, 
        previewLesson: isPreviewLesson ? [previewLessonValue] : [],
      };
      blocks.forEach((block, index) => {
        const { id, images, videos, text, typeFormId, ordinalNumber, isPreviewLessonBlock } = block;
        const blockName = `block${index + 1}`;
        formData[blockName].id = id;
        formData[blockName].text = text || "";
        formData[blockName].typeFormId = typeFormId || "";
        formData[blockName].ordinalNumber = ordinalNumber || "";
        formData[blockName].isVisible = true;
        formData[blockName].imagesUploaded = images;
        formData[blockName].videosUploaded = videos;
        formData[blockName].previewLessonBlock = isPreviewLessonBlock ? [previewLessonBlockValue] : [];
      });
      updateFormData(formData);
      updateReadyState(true);
    } else {
      updateReadyState(true);
    }
  }, [model]);

  useEffect(() => {
    updateDirtyState(isDirty);
  }, [isDirty]);

  return (
    ready && (
      <>
        <AvForm
          model={formData}
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{pageTitle}</CardTitle>
              <FormGroupText
                name="title"
                label="Lesson title"
                placeholder="Enter lesson title"
                validator={formsValidators.lessons.title}
                handleFormChange={handleFormChange}
              />
              <InputWithReadOnlyTextPart
                name="urlPart"
                label="Lesson URL"
                readOnlyPart={getReadOnlyUrlPart("lessons")}
                validator={formsValidators.lessons.urlPart}
                handleFormChange={handleFormChange}
                value={urlPart}
              />
              <FormGroupText
                name="ordinalNumber"
                label="Ordinal number"
                placeholder="Enter ordinal number"
                validator={formsValidators.lessons.ordinalNumber(ordinalNumber)}
                handleFormChange={handleFormChange}
              />
              <FormGroup className='mb-4' row>
                <Col lg={2}>
                  <Label>Is Preview Lesson</Label>
                </Col>
                <Col lg={10}>
                  <AvCheckboxGroup className='' name={'previewLesson'}>
                    <AvCheckbox
                      onChange={handleFormChange}
                      className="cursor-pointer"
                      customInput
                      label="Is Preview Lesson"
                      value={previewLessonValue}
                    />
                    </AvCheckboxGroup>
                </Col>
              </FormGroup>
            </CardBody>
          </Card>
          {extractedBlocksAsArray.map((block, index) => {
            return (
              block.isVisible && (
                <Fragment key={`save-lesson-block_${index}`}>
                  <LessonBlock
                    index={index}
                    data={block}
                    handleFormChange={handleFormChange}
                    addBlockHandler={addBlockHandler}
                    updateFiles={updateFilesHandler}
                    setThumbnailAndVideoSuccess={setThumbnailAndVideoSuccess}
                    setUpdatedThumbnails={setUpdatedThumbnails}
                    isPreviewLesson={values?.previewLesson.length === 1}
                    blockFor={BLOCK_FOR.lesson}
                  />
                </Fragment>
              )
            );
          })}
          <Row className="justify-content-end">
            <Col xs="12">
              <Link
                to={`/courses/${!!model ? model.courseId : courseId}/lessons`}
              >
                <Button color="secondary" className="mr-2">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                color="primary"
                disabled={disableState}
                className={styles.btnSave}
              >
                {btnSaveLabel}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </>
    )
  );
};

const mapStateToProps = ({ Lessons }) => ({ loading: Lessons.loading });

export default connect(mapStateToProps)(SaveLesson);
