import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PremiumArticlesCategoriesService from "../../../services/PremiumArticlesCategoriesService";
import {
  getPremiumArticlesCategoriesSuccess,
  getPremiumArticlesCategoriesError,
  updatePremiumArticlesCategorySuccess,
  updatePremiumArticlesCategoryError,
} from "./actions";
import {
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES,
  UPDATE_PREMIUM_ARTICLES_CATEGORY,
} from "./actionTypes";
import downloadAllData from '../../../helpers/downloadAllData';

const getPremiumArticlesCategoriesAsync = async (premiumArticleSectionId) => {
  return await downloadAllData(PremiumArticlesCategoriesService.getList.bind(PremiumArticlesCategoriesService), {premiumArticleSectionId});
};

const updatePremiumArticlesCategoryAsync = async (id, data) => {
  return await PremiumArticlesCategoriesService.update(id, data);
};

function* getPremiumArticlesCategories({
  payload: { premiumArticleSectionId },
}) {
  try {
    const response = yield call(
      getPremiumArticlesCategoriesAsync,
      premiumArticleSectionId
    );
    yield put(getPremiumArticlesCategoriesSuccess({ data: response }));
  } catch (error) {
    yield put(getPremiumArticlesCategoriesError(error));
  }
}

function* updatePremiumArticlesCategory({ payload: { id, data } }) {
  try {
    const response = yield call(updatePremiumArticlesCategoryAsync, id, data);
    yield put(updatePremiumArticlesCategorySuccess(response));
  } catch (error) {
    yield put(updatePremiumArticlesCategoryError(error));
  }
}

export function* watchPremiumArticlesCategoriesList() {
  yield takeEvery(
    GET_LIST_PREMIUM_ARTICLES_CATEGORIES,
    getPremiumArticlesCategories
  );
}

export function* watchupdatePremiumArticlesCategory() {
  yield takeEvery(
    UPDATE_PREMIUM_ARTICLES_CATEGORY,
    updatePremiumArticlesCategory
  );
}

function* premiumArticlesCategoriesSaga() {
  yield all([
    fork(watchPremiumArticlesCategoriesList),
    fork(watchupdatePremiumArticlesCategory),
  ]);
}

export default premiumArticlesCategoriesSaga;
