import { isEmpty } from "lodash";
import { EMPTY_PARAGRAPH_REG_EXP } from "../constants/editorConstants";

class LessonsHelper {
  static getBlocksVisible(blocksAsArray) {
    return blocksAsArray
      .filter(({ isVisible }) => isVisible)
      .reduce(
        (
          acc,
          {
            id,
            blockName,
            images,
            videos,
            imagesUploaded,
            videosUploaded,
            deleteImagesIds,
            deleteVideosIds,
            updatedVideoThumbnails,
          }
        ) => {
          acc = {
            ...acc,
            [blockName]: {
              id,
              images,
              videos,
              imagesUploaded,
              videosUploaded,
              deleteImagesIds,
              deleteVideosIds,
              updatedVideoThumbnails,
            },
          };
          return acc;
        },
        {}
      );
  }

  static getBlocksTransformed(dataMerged) {
    return Object.keys(dataMerged)
      .filter((key) => key.startsWith("block"))
      .map((blockName) => ({
        ...dataMerged[blockName],
      }));
  }

  static getBlockCreateDTO(block) {
    const { text, images, videos, typeFormId, ordinalNumber, previewLessonBlock } = block;
    const obj = {};

    if (!!text && text !== "") {
      obj.text = text;
    }

    if (!!typeFormId && typeFormId !== "") {
      obj.typeFormId = typeFormId;
    }

    if (!!ordinalNumber && ordinalNumber !== "") {
      obj.ordinalNumber = ordinalNumber;
    }

    if (images && images.length) {
      obj.images = images;
    }
    if (videos && videos.length) {
      obj.videos = videos;
    }

    if (!isEmpty(obj)) {
      //We are adding here because previewLessonBlock is optional and if other data is provided then we will be adding it

      if (previewLessonBlock) {
        // We are calling getBlockCreateDTO from both Lesson and Premium Article and for now Premium article do not have support for preview lesson block, so we are checking condition here
        obj.isPreviewLessonBlock = previewLessonBlock.length === 1;
      }

      return obj;
    }
  }

  static getBlockUpdateDTO(block) {
    const {
      id: blockId,
      text,
      images,
      videos,
      deleteImagesIds,
      deleteVideosIds,
      updatedVideoThumbnails,
      typeFormId,
      ordinalNumber,
      previewLessonBlock,
    } = block;
    const obj = {
      typeFormId: null, // To allow removal of typeFormId
      ordinalNumber: null, // To allow removal of ordinalNumber
    };

    if (!!text && text !== "") {
      obj.text = text;
    }
    if (!!typeFormId && typeFormId !== "") {
      obj.typeFormId = typeFormId;
    }
    if (!!ordinalNumber && ordinalNumber !== "") {
      obj.ordinalNumber = ordinalNumber;
    }
    if (images && images.length) {
      obj.images = images;
    }
    if (images && videos.length) {
      obj.videos = videos;
    }
    if (deleteImagesIds && deleteImagesIds.length) {
      obj.deleteImagesIds = deleteImagesIds;
    }
    if (deleteVideosIds && deleteVideosIds.length) {
      obj.deleteVideosIds = deleteVideosIds;
    }
    if (updatedVideoThumbnails && updatedVideoThumbnails.length) {
      obj.updatedVideoThumbnails = updatedVideoThumbnails;
    }

    if (!LessonsHelper.checkEmptyBlock(block)) {
      //We are adding here because previewLessonBlock is optional and if other data is provided then we will be adding it

      if (previewLessonBlock) {
        // We are calling getBlockUpdateDTO from both Lesson and Premium Article and for now Premium article do not have support for preview lesson block, so we are checking condition here

        obj.isPreviewLessonBlock = previewLessonBlock.length === 1;
      }


      return { blockId, ...obj };
    }
  }

  static checkEmptyBlock(block) {
    const {
      text,
      images,
      videos,
      deleteImagesIds,
      deleteVideosIds,
      imagesUploaded,
      videosUploaded,
    } = block;

    const isImagesEmpty =
      !imagesUploaded ||
      (!!imagesUploaded && imagesUploaded.length === deleteImagesIds.length);
    const isVideosEmpty =
      !videosUploaded ||
      (!!videosUploaded && videosUploaded.length === deleteVideosIds.length);
    const isEmptyText = !text || !text.replace(EMPTY_PARAGRAPH_REG_EXP, "").trim();

    return (
      isEmptyText &&
      !images.length &&
      !videos.length &&
      isImagesEmpty &&
      isVideosEmpty
    );
  }
}

export default LessonsHelper;
