import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import {
  removeCourse,
  changeCourseStatus,
} from "../../../../store/dashboard/Courses/actions";
import {
  COURSES_STATUSES,
  getPublishModalDescription,
} from "../../../../constants/courses";
import RecommendedCoursesSelect from "./RecommendedCoursesSelect";

import styles from "../../dashboard.module.scss";
import componentStyles from "./index.module.scss";
import Icon from "../../../../components/Common/Icon";

const DELETE_MODAL_TITLE = "Delete a course";
const DELETE_MODAL_DESCRIPTION =
  "Course info and lessons attached to this course will be deleted. Are you sure you want to delete this course?";
const PUBLISH_MODAL_TITLE = "Publish course";

const CourseTableRow = ({ course, removeCourse, changeCourseStatus }) => {
  const [modalVisible, toggleModal] = useModal();
  const [publishModalVisible, togglePublishModal] = useModal();

  const handleConfirmRemove = () => {
    toggleModal();
    removeCourse(course.id);
  };

  const handleConfirmPublish = () => {
    togglePublishModal();
    changeCourseStatus(course.id);
  };
  return (
    <>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <ConfirmationDialog
        isOpen={publishModalVisible}
        titleText={PUBLISH_MODAL_TITLE}
        contentHtmlText={getPublishModalDescription(course.title)}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => togglePublishModal()}
        onConfirm={handleConfirmPublish}
        altBtnStyle
      />
      <tr>
        <td className={classNames(styles.tableTitle, "align-middle")}>
          <Link
            className={styles.tableTitle}
            to={`/courses/${course.id}/lessons`}
          >
            {course.title}
          </Link>
        </td>
        <td >
          <RecommendedCoursesSelect courseId={course.id} />
        </td>
        <td className="text-center">
          {course.recommendations?.length || 0}
        </td>
        <td className="d-flex justify-content-end w-220px font-size-20 pr-5 ">
          {course.status === COURSES_STATUSES.LIVE ? (
            <span
              className={classNames(
                componentStyles.courseStatus,
                componentStyles.courseStatusLive
              )}
            >
              <span>Live</span>
              <Icon icon="checkGray" />
            </span>
          ) : (
            <Link
              to="#"
              onClick={() => togglePublishModal()}
              className={classNames(
                componentStyles.courseStatus,
                componentStyles.courseStatusDraft
              )}
            >
              <span>Publish</span>
              <Icon icon="arrowPrimary" />
            </Link>
          )}
          <Link to={`/courses/${course.id}`}>
            <i className="bx bx-edit text-grey mr-3" />
          </Link>
          <Link to="#" onClick={() => toggleModal()}>
            <i className="bx bx-trash text-grey" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default connect(() => ({}), { removeCourse, changeCourseStatus })(
  CourseTableRow
);
