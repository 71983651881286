import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PremiumArticlesTagsService from "../../../services/PremiumArticlesTagsService";
import {
  getPremiumArticlesTagsSuccess,
  getPremiumArticlesTagsError,
  updatePremiumArticlesTagSuccess,
  updatePremiumArticlesTagError,
} from "./actions";
import {
  GET_LIST_PREMIUM_ARTICLES_TAGS,
  UPDATE_PREMIUM_ARTICLES_TAG,
} from "./actionTypes";

const getPremiumArticlesTagsAsync = async (pagination) => {
  return await PremiumArticlesTagsService.getList(pagination);
};

const updatePremiumArticlesTagAsync = async (id, data) => {
  return await PremiumArticlesTagsService.update(id, data);
};

function* getPremiumArticlesTags({ payload }) {
  try {
    const response = yield call(getPremiumArticlesTagsAsync, payload);
    yield put(getPremiumArticlesTagsSuccess(response));
  } catch (error) {
    yield put(getPremiumArticlesTagsError(error));
  }
}

function* updatePremiumArticlesTag({ payload: { id, data } }) {
  try {
    const response = yield call(updatePremiumArticlesTagAsync, id, data);
    yield put(updatePremiumArticlesTagSuccess(response));
  } catch (error) {
    yield put(updatePremiumArticlesTagError(error));
  }
}

export function* watchPremiumArticlesTagsList() {
  yield takeEvery(GET_LIST_PREMIUM_ARTICLES_TAGS, getPremiumArticlesTags);
}

export function* watchupdatePremiumArticlesTag() {
  yield takeEvery(UPDATE_PREMIUM_ARTICLES_TAG, updatePremiumArticlesTag);
}

function* premiumArticlesTagsSaga() {
  yield all([
    fork(watchPremiumArticlesTagsList),
    fork(watchupdatePremiumArticlesTag),
  ]);
}

export default premiumArticlesTagsSaga;
