import React from "react";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import Table from "../../../../components/Table";
import couponsListStructure from "./tableStructure";
import { COUPONS_LIST_LIMIT } from "../../../../constants/paginationConstants";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import { clearCouponCreated, getListCoupons, } from "../../../../store/dashboard/Coupons/actions";
import useTable from "../../../../hooks/useTable";
import TableHeader from "../components/TableHeader/TableHeader";
import CreateCouponButton from "../components/CreateCouponButton/CreateCouponButton";
import SEO from "../../../../components/Common/SEO";
import HeaderComponent from "../components/HeaderComponent";

const CouponsCourse = ({ coupons, getListCoupons }) => {
  const { data, pagination, loading, handleTableChange, query } = useTable(
    coupons,
    getListCoupons,
    COUPONS_LIST_LIMIT,
    clearCouponCreated
  );
  
  return (
    <ScrollbarVertical>
      <SEO title="Coupons management"/>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Coupons management</h4>
              </div>
            </Col>
          </Row>
          <HeaderComponent pageName='Course coupons'/>
          
          <Row>
            <Col xs="12">
              <Table
                HeaderComponent={TableHeader}
                dataStructure={couponsListStructure}
                data={data}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={COUPONS_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                placeholderText="There are no course coupons yet"
                PlaceholderButtonComponent={CreateCouponButton}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, { getListCoupons, clearCouponCreated })(
  CouponsCourse
);
