import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classNames from "classnames";

import styles from "./modal.module.scss";

const ConfirmationDialog = ({
  isOpen,
  titleText,
  contentText,
  contentHtmlText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
  confirmBtnClassName = "",
  cancelBtnClassName = "",
  containerClassname = "",
  headerClassName = "",
  bodyClassName = "",
  altBtnStyle = false,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      centered
      className={"confirmation-modal " + containerClassname}
      toggle={onCancel}
    >
      <ModalHeader className={headerClassName} toggle={onCancel}>{titleText}</ModalHeader>
      <ModalBody className={bodyClassName}>
        {contentHtmlText && (
          <div
            className={styles.htmlContent}
            dangerouslySetInnerHTML={{ __html: contentHtmlText }}
          />
        )}
        {contentText && <>{contentText}</>}
      </ModalBody>
      <ModalFooter>
        {cancelButtonText && (
          <div className="d-flex flex-row justify-content-end">
            <Button
              color="secondary"
              size="lg"
              onClick={onCancel}
              className={classNames(
                cancelBtnClassName,
                altBtnStyle && styles.btnStyle
              )}
            >
              {cancelButtonText}
            </Button>
          </div>
        )}
        <div className="d-flex flex-row justify-content-center">
          <Button
            className={classNames(
              confirmBtnClassName,
              altBtnStyle && styles.btnStyle
            )}
            color="primary"
            size="lg"
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationDialog;
