import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  getListAdminsSuccess,
  getListAdminsError,
  changeAdminStatusSuccess,
  changeAdminStatusError,
  createAdminSuccess,
  createAdminError,
} from "./actions";
import {
  GET_LIST_ADMINS,
  CHANGE_ADMIN_STATUS,
  CREATE_ADMIN,
} from "./actionTypes";
import AdminsSerivce from "../../../services/AdminsSerivce";
import ToastrService from "../../../services/ToastrService";
import { INVITATION_SUCCESS } from "../../../constants/toastMessages";

const getAdminListAsync = async (pagination) => {
  return await AdminsSerivce.getList(pagination);
};

const changeAdminStatusAsync = async (model) => {
  return await AdminsSerivce.changeAdminStatus(model);
};

const createAdminAsync = async (model) => {
  return await AdminsSerivce.create(model);
};

function* getAdminList({ payload }) {
  try {
    const response = yield call(getAdminListAsync, payload);
    yield put(getListAdminsSuccess(response));
  } catch (error) {
    yield put(getListAdminsError(error));
  }
}

function* changeAdminStatus({ payload }) {
  try {
    const response = yield call(changeAdminStatusAsync, payload);
    yield put(changeAdminStatusSuccess(response));
  } catch (error) {
    yield put(changeAdminStatusError(error));
  }
}

function* createAdmin({ payload }) {
  try {
    const response = yield call(createAdminAsync, payload);
    yield put(createAdminSuccess(response));
    ToastrService.success(INVITATION_SUCCESS);
  } catch (error) {
    yield put(createAdminError(error));
  }
}

export function* watchGetAdminList() {
  yield takeEvery(GET_LIST_ADMINS, getAdminList);
}

export function* watchChangeAdminStatus() {
  yield takeEvery(CHANGE_ADMIN_STATUS, changeAdminStatus);
}

export function* watchCreateAdmin() {
  yield takeEvery(CREATE_ADMIN, createAdmin);
}

function* adminsSaga() {
  yield all([
    fork(watchGetAdminList),
    fork(watchChangeAdminStatus),
    fork(watchCreateAdmin),
  ]);
}

export default adminsSaga;
