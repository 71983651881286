import Http from "./HttpService";

class PremiumArticlesTagsService extends Http {
  BASE_URL = "/admins/premium-article-tags";

  getList(pagination) {
    return this.get(this.BASE_URL, { params: pagination });
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }
}

export default new PremiumArticlesTagsService();
