import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getList10MonthSubscriptionPlanError, getList10MonthSubscriptionPlanSuccess, toggleShow10MonthSubscriptionPlanError, toggleShow10MonthSubscriptionPlanSuccess } from './actions';
import { GET_10_MONTH_SUBSCRIPTION_PLAN_LIST, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN } from './actionTypes';
import SubscriptionPlanService from '../../../services/SubscriptionPlanService';
import ToastrService from '../../../services/ToastrService';

const get10MonthSubscriptionPlanListAsync = async () => {
  return await SubscriptionPlanService.get10MonthSubscriptionPlanList();
};

const toggleShow10MonthSubscriptionPlanAsync = async (model) => {
  return await SubscriptionPlanService.toggle10MonthSubscriptionPlan(model);
};

function* get10MonthSubscriptionPlanList({ payload }) {
  try {
    const response = yield call(get10MonthSubscriptionPlanListAsync);
    yield put(getList10MonthSubscriptionPlanSuccess(response));
  } catch (error) {
    yield put(getList10MonthSubscriptionPlanError(error));
  }
}

function* toggleShow10MonthSubscriptionPlan({ payload: { data } }) {
  try {
    const response = yield call(toggleShow10MonthSubscriptionPlanAsync, data);
    yield put(toggleShow10MonthSubscriptionPlanSuccess(response));

    const { toShowSubscriptionPlan } = data;

    const message = toShowSubscriptionPlan ? "10 month Subscription plan UnHidden successfully" : "10 month Subscription plan hidden successfully" ;

    ToastrService.success(message);

  } catch (error) {
    yield put(toggleShow10MonthSubscriptionPlanError(error));
  }
}

export function* watchGet10MonthSubscriptionPlanList() {
  yield takeEvery(GET_10_MONTH_SUBSCRIPTION_PLAN_LIST, get10MonthSubscriptionPlanList);
}

export function* watchToggleShow10MonthSubscriptionPlan() {
  yield takeEvery(TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN, toggleShow10MonthSubscriptionPlan);
}

function* subscriptionPlansSaga() {
  yield all([
    fork(watchGet10MonthSubscriptionPlanList),
    fork(watchToggleShow10MonthSubscriptionPlan),
  ]);
}

export default subscriptionPlansSaga;