import { useEffect, useState } from "react";

import noop from "../helpers/noop";
import { MESSAGE_PAUSE_TIME } from "../constants/defaultParameters";

const useSnackbarState = (
  entity,
  clearFunction = noop,
  effectFunction = noop
) => {
  const [isExists, updateExistsState] = useState(false);

  useEffect(() => {
    updateExistsState(!!entity);
    // perform effect if entity appears
    if (entity) {
      effectFunction();

      setTimeout(() => {
        clearFunction();
      }, MESSAGE_PAUSE_TIME);
    }
  }, [entity]);

  // onInit: clear existing item
  useEffect(() => {
    return () => {
      clearFunction();
    };
  }, []);

  return [isExists];
};

export default useSnackbarState;
