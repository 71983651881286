import React, { useCallback, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveCourse from "../../../../components/Forms/SaveCourse";
import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import { createCourse } from "../../../../store/dashboard/Courses/actions";
import ProgressWatcher from "../../../../components/Common/ProgressWatcher";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const Create = ({ createCourse, courses, filesError }) => {
  const { created, loading } = courses;
  const [isDirty, updateDirty] = useState(false);
  const {
    params: { categoryId },
  } = useRouteMatch("/training/:categoryId");
  const history = useHistory();

  const saveCategoryCallback = useCallback((model) => {
    const createCourseFunc = () => {
      createCourse({ categoryId, model }, history);
    };
    createCourseFunc();
  }, []);
  const isBlocked = (created ? false : isDirty) && !filesError;

  return (
    <>
      <SEO title="Create course" />
      <RouteLeavingGuard
        contentText="Added data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Create course
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                <SaveCourse
                  categoryId={categoryId}
                  saveCourseFunc={saveCategoryCallback}
                  updateDirtyState={updateDirty}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = ({ Courses, Progress }) => {
  return { courses: Courses, filesError: Progress.error };
};

export default connect(mapStateToProps, { createCourse })(Create);
