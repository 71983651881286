import {
  CREATE_COURSE_CATEGORY,
  CREATE_COURSE_CATEGORY_CLEAR,
  CREATE_COURSE_CATEGORY_ERROR,
  CREATE_COURSE_CATEGORY_SUCCESS,
  GET_LIST_COURSE_CATEGORIES,
  GET_LIST_COURSE_CATEGORIES_ERROR,
  GET_LIST_COURSE_CATEGORIES_SUCCESS,
  GET_COURSE_CATEGORY,
  GET_COURSE_CATEGORY_SUCCESS,
  GET_COURSE_CATEGORY_ERROR,
  UPDATE_COURSE_CATEGORY,
  UPDATE_COURSE_CATEGORY_SUCCESS,
  UPDATE_COURSE_CATEGORY_ERROR,
  GET_COURSE_CATEGORY_CLEAR,
  REMOVE_COURSE_CATEGORY,
  REMOVE_COURSE_CATEGORY_SUCCESS,
  REMOVE_COURSE_CATEGORY_ERROR,
  REMOVE_COURSE_CATEGORY_CLEAR,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  item: null,
  created: null,
  updated: null,
  removed: null,
  data: [],
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_COURSE_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_COURSE_CATEGORIES_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_COURSE_CATEGORIES_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COURSE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_COURSE_CATEGORY_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_COURSE_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COURSE_CATEGORY_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case GET_COURSE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COURSE_CATEGORY_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_COURSE_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_COURSE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COURSE_CATEGORY_CLEAR:
      state = {
        ...state,
        item: null,
        loading: false,
      };
      break;
    case UPDATE_COURSE_CATEGORY_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_COURSE_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_COURSE_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_COURSE_CATEGORY_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_COURSE_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_COURSE_CATEGORY_CLEAR:
      state = {
        ...state,
        removed: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
