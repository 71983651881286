import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Import menuDropdown
import logo from '../../assets/images/logo.svg';
import logoLightSvg from '../../assets/images/logo-light.svg';
import logoDark from '../../assets/images/logo-dark.svg';

//i18n
import { withNamespaces } from 'react-i18next';

// Redux Store
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from '../../store/actions';

import StorageService from '../../services/StorageService';

const Header = (props) => {
    const user = useRef(StorageService.user.value);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if(props.leftSideBarType === 'default') {
            props.changeSidebarType('condensed', isMobile);
        } else if(props.leftSideBarType === 'condensed') {
            props.changeSidebarType('default', isMobile);
        }
    }

    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                    <div className="navbar-brand-box pr-2 pl-2">
                        <Link to="/" className="logo logo-dark">
                            <span className="logo-sm"><img className="img-fluid" src={ logo } alt="" height="22"/></span>
                            <span className="logo-lg"><img src={ logoDark } alt="" height="17"/></span>
                        </Link>
                        <Link to="/" className="logo logo-light">
                            <span className="logo-sm"><img className="img-fluid" src={ logoLightSvg } alt="" height="22"/></span>
                            <span className="logo-lg"><img src={ logoLightSvg } alt="" height="44"/></span>
                        </Link>
                    </div>

                    <button type="button"
                            onClick={ () => {tToggle();} }
                            className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars"/>
                    </button>
                </div>
                <div className="d-flex align-items-center">
                    {(props.profile || user.current) && (
                        <div className="username">
                            {props.profile?.userName || user.current?.userName}
                        </div>
                    )}
                    <div className="d-flex justify-content-end align-items-center">
                        <Link to="/logout">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"/>
                            <span className="text-logout">{ props.t('Logout') }</span>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};
const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    const { profile } = state.Settings
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType, profile };
};

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType
})(withNamespaces()(Header));
