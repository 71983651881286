import {
  GET_LIST_PREMIUM_ARTICLES_TAGS,
  GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_TAGS_ERROR,
  UPDATE_PREMIUM_ARTICLES_TAG,
  UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS,
  UPDATE_PREMIUM_ARTICLES_TAG_CLEAR,
  UPDATE_PREMIUM_ARTICLES_TAG_ERROR,
} from "./actionTypes";

export const getPremiumArticlesTags = (payload) => {
  return {
    payload,
    type: GET_LIST_PREMIUM_ARTICLES_TAGS,
  };
};
export const getPremiumArticlesTagsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS,
  };
};
export const getPremiumArticlesTagsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_PREMIUM_ARTICLES_TAGS_ERROR,
  };
};
export const updatePremiumArticlesTag = (id, data) => {
  return {
    payload: { id, data },
    type: UPDATE_PREMIUM_ARTICLES_TAG,
  };
};

export const updatePremiumArticlesTagSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS,
  };
};

export const updatePremiumArticlesTagError = ({
  message = "Unknown error",
}) => {
  return {
    payload: { message },
    type: UPDATE_PREMIUM_ARTICLES_TAG_ERROR,
  };
};

export const clearPremiumArticlesTagUpdated = () => {
  return {
    type: UPDATE_PREMIUM_ARTICLES_TAG_CLEAR,
  };
};
