import React, { useEffect, useMemo, useState } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";
import {
  getAdminProfile,
  updateAdminProfile,
  clearAdminProfile,
  changeAdminAvatar
} from "../../store/dashboard/Settings/actions";

import styles from "./forms.module.scss";
import UpdateAdminAvatarForm from "./UpdateAdminAvatarForm";
import FileHelper from "../../helpers/FileHelper";
import ProgressWatcher from "../Common/ProgressWatcher";
import keyEnterPrevent from "../../helpers/keyEnterPrevent";

const PROGRESS_TEXT = "Image is uploading now. Please, don't quit this page";

const UpdateAdminProfileFrom = ({
  settings,
  getAdminProfile,
  updateAdminProfile,
  clearAdminProfile,
  changeAdminAvatar
}) => {
  const { profile, profileLoading, loading } = settings;
  
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const [readyState, setReadyState] = useState(false);
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  useEffect(() => {
    getAdminProfile();
    setReadyState(true);
    return () => {
      clearAdminProfile();
    };
  }, []);

  const handleFormSubmit = (event, model) => {
    updateAdminProfile({ model });
  };

  const getInitialState = useMemo(() => {
    if (!!profile) {
      return {
        userName: profile.userName,
      };
    }
    return {
      userName: "",
    };
  }, [profile]);

  const avatarUpdated = (result, fileName, sizes) => {
    if (result) {
      const fileCropped = FileHelper.dataUrlToFile(result, fileName);
      fileCropped.sizes = [sizes];

      changeAdminAvatar({ file: fileCropped })
    }
  };

  const removeImage = () => {
    if (!profile.avatar) {
      return;
    }

    changeAdminAvatar({ file: null, toRemoveAvatar: true });
  }

  return (
    <>
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <div className="d-flex flex-column h-100">
        {!profileLoading && readyState && (
          <>
          <UpdateAdminAvatarForm 
            avatarUpdated={avatarUpdated} 
            initialAvatar={profile?.avatar} 
            removeImage={removeImage} 
            />
          <Card className={classNames(styles.card, styles.cardBottom, "mb-0")}>
            <CardBody>
              <div className={classNames(styles.cardTitle, "mb-3")}>
                Your username
              </div>
              <AvForm
                onValidSubmit={handleFormSubmit}
                ref={formRef}
                model={getInitialState}
                onKeyDown={keyEnterPrevent}
              >
                <FormGroupText
                  vertical
                  className="mb-0"
                  labelClassName="pt-0"
                  name="userName"
                  label="Username"
                  placeholder="Enter new username"
                  validator={formsValidators.userName}
                  handleFormChange={handleFormChange}
                />
                <Button
                  type="submit"
                  color="primary"
                  disabled={disableState}
                  className={classNames(styles.cardBtn, "ml-auto d-block mt-2")}
                >
                  Save
                </Button>
              </AvForm>
            </CardBody>
          </Card>
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = ({ Settings: settings }) => ({ settings });

export default connect(mapStateToProps, {
  getAdminProfile,
  updateAdminProfile,
  clearAdminProfile,
  changeAdminAvatar
})(UpdateAdminProfileFrom);
