export default async (files) => {
  const promises = [];
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    const promise = new Promise((resolve, reject) => {
      const image = new Image();
      let url;
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      url = URL.createObjectURL(file);
      image.src = url;
    }).catch(() => {});

    promises.push(promise);
  }
  return await Promise.all(promises);
};
