import Http from "./HttpService";

class NotificationsService extends Http {
  BASE_URL = "/admins/notifications";

  getList(params) {
    return this.get(this.BASE_URL, { params });
  }

  create(model) {
    return this.post(`${this.BASE_URL}/active`, model);
  }

  updateNofification(model) {
    return this.put(`${this.BASE_URL}/inactive`, model);
  }
}

export default new NotificationsService();
