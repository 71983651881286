export const GET_NOTIFICATIONS_LIST = "GET_NOTIFICATIONS_LIST";
export const GET_NOTIFICATIONS_LIST_SUCCESS = "GET_NOTIFICATIONS_LIST_SUCCESS";
export const GET_NOTIFICATIONS_LIST_ERROR = "GET_NOTIFICATIONS_LIST_ERROR";

export const GET_REMINDER_NOTIFICATION = "GET_REMINDER_NOTIFICATION";
export const GET_REMINDER_NOTIFICATION_SUCCESS =
  "GET_REMINDER_NOTIFICATION_SUCCESS";
export const GET_REMINDER_NOTIFICATION_ERROR =
  "GET_REMINDER_NOTIFICATION_ERROR";

export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_ERROR = "UPDATE_NOTIFICATION_ERROR";

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_SUCCESS = "CREATE_NOTIFICATION_SUCCESS";
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR";
export const CREATE_NOTIFICATION_CLEAR = "CREATE_NOTIFICATION_CLEAR";
