import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { generateOptions, MenuList } from "../../../helpers/reactSelectHelper";
import useSelect from "../../../hooks/useSelect";
import { VALUE_FIELD_ID } from '../../../constants/selectConstants';

const FormGroupReactSelect = ({
  name,
  label,
  placeholder,
  validator,
  value,
  handleFormChange,
  getElemetsByValue,
  getValue,
  options = [],
  disabled = false,
}) => {
  const [resultOptions, setResultOptions, selectRef, inputRef, handlerBlur] =
    useSelect(value, name, handleFormChange, VALUE_FIELD_ID, false );

  const generateResultOption = ({ value }) => {
    setResultOptions(value || "");
    getValue && getValue(value);
    getElemetsByValue && getElemetsByValue(value);
    handleFormChange();
  };

  return (
    <FormGroup row>
      <Label htmlFor={name} className="col-form-label col-lg-2">
        {label}
      </Label>
      <Col lg="10">
        <Select
          ref={selectRef}
          isMulti={false}
          isDisabled={disabled}
          id={name}
          placeholder={placeholder}
          options={generateOptions(options)}
          onChange={generateResultOption}
          components={{ MenuList }}
          onBlur={handlerBlur}
        />
        <AvField
          hidden
          ref={inputRef}
          name={name}
          type="select"
          className="form-control"
          placeholder={placeholder}
          value={resultOptions}
          onChange={handleFormChange}
          onBlur={handleFormChange}
          validate={validator}
        />
      </Col>
    </FormGroup>
  );
};

export default FormGroupReactSelect;
