import {
  GET_LIST_PREMIUM_ARTICLES_SECTIONS,
  GET_LIST_PREMIUM_ARTICLES_SECTIONS_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_SECTIONS_ERROR,
} from "./actionTypes";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PREMIUM_ARTICLES_SECTIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_SECTIONS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_SECTIONS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
