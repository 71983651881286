import React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Col, Label, Row } from 'reactstrap';
import { AvForm, AvField, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import useAvForm from "../../../../hooks/useAvForm";
import formsValidators from "../../../../validators/form.validators";

export default function ProvideSubscriptionModal({ toggle, submit }) {

    const [
        formRef,
        hasFormError,
        handleFormChange,
        isDirty,
        values
    ] = useAvForm();

    const handleFormSubmit = (event, values) => {
        const { chargebeeSubscriptionId, chargebeeInvoiceId, oneTimePaymentCharge } = values;

        const model = {
            chargebeeSubscriptionId,
            chargebeeInvoiceId,
            isOneTimePaymentCharge: oneTimePaymentCharge.length === 1
        }

        submit(model);
    }

    return (
        <AvForm
            onValidSubmit={handleFormSubmit}
            ref={formRef}
        >
            <ModalHeader toggle={toggle}>Provide Subscription</ModalHeader>
            <ModalBody className='user-details--basic-info__modal-body pb-0'>
                
                <section className="d-flex flex-column">
                    <Row>
                        <Col lg={12}>
                            <AvCheckboxGroup className='' name={'oneTimePaymentCharge'}>
                                <AvCheckbox
                                    onChange={handleFormChange}
                                    className="cursor-pointer"
                                    customInput
                                    label="Is One Time Payment Charge"
                                    value="OneTimePaymentCharge"
                                />
                            </AvCheckboxGroup>
                        </Col>
                    </Row>
                    
                    {values?.oneTimePaymentCharge?.length === 0 && <span>User should have subscription in chargebee, then it can be process here</span>}
                    {values?.oneTimePaymentCharge?.length > 0 && <span>User should have invoice in chargebee, then it can be process here</span>}

                    {values?.oneTimePaymentCharge?.length === 0 && <section className="mt-3">
                        <AvField
                            id='chargebeeSubscriptionId'
                            name='chargebeeSubscriptionId'
                            label="Chargebee Subscription Id"
                            type="text"
                            className="form-control"
                            placeholder="Enter Chargebee Subscription Id"
                            onChange={() => handleFormChange()}
                            onBlur={() => handleFormChange()}
                            validate={formsValidators.chargebeeSubscriptionId}
                        />
                    </section>}
                    {values?.oneTimePaymentCharge?.length > 0 && <section className="mt-3">
                        <AvField
                            id='chargebeeInvoiceId'
                            name='chargebeeInvoiceId'
                            label="Chargebee Invoice Id"
                            type="text"
                            className="form-control"
                            placeholder="Enter Chargebee Invoice Id"
                            onChange={() => handleFormChange()}
                            onBlur={() => handleFormChange()}
                            validate={formsValidators.chargebeeInvoiceId}
                        />
                    </section>}
                    
                </section>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" size="lg" onClick={toggle} className='user-details--basic-info__secondary-button'>
                    Cancel
                </Button>
                <Button
                    className='user-details--basic-info__primary-button'
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={!isDirty || hasFormError}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </AvForm >
    );
}