import {
    ADD_COURSE_TO_USER,
    ADD_COURSE_TO_USER_ERROR,
    ADD_COURSE_TO_USER_SUCCESS,
    CHANGE_EMAIL,
    CHANGE_EMAIL_ERROR,
    CHANGE_EMAIL_SUCCESS,
    CHANGE_USERS_STATUS,
    CHANGE_USERS_STATUS_ERROR,
    CHANGE_USERS_STATUS_SUCCESS,
    CLEAR_STATE,
    DELETE_USER,
    DELETE_USER_ERROR,
    DELETE_USER_SUCCESS,
    GET_NOT_PURCHASED_USER_COURSES,
    GET_NOT_PURCHASED_USER_COURSES_ERROR,
    GET_NOT_PURCHASED_USER_COURSES_SUCCESS,
    GET_USER_COURSES,
    GET_USER_COURSES_ERROR,
    GET_USER_COURSES_SUCCESS,
    GET_USERS_LIST,
    GET_USERS_LIST_ERROR,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_PROFILE,
    GET_USERS_PROFILE_ERROR,
    GET_USERS_PROFILE_SUCCESS,
    PROVIDE_SUBSCRIPTION,
    PROVIDE_SUBSCRIPTION_ERROR,
    PROVIDE_SUBSCRIPTION_SUCCESS,
    REMOVE_COURSE_FROM_SUCCESS,
    REMOVE_COURSE_FROM_USER,
    REMOVE_COURSE_FROM_USER_ERROR,
    REVOKE_SUBSCRIPTION,
    REVOKE_SUBSCRIPTION_ERROR,
    REVOKE_SUBSCRIPTION_SUCCESS,
    SEND_RECOVERY_LINK,
    SEND_RECOVERY_LINK_ERROR,
    SEND_RECOVERY_LINK_SUCCESS,
    SET_PASSWORD,
    SET_PASSWORD_ERROR,
    SET_PASSWORD_SUCCESS,
    UPDATE_COURSE_LIST_CLEAR,
} from './actionTypes';

const initialState = {
    data: [],
    pagination: { totalCount: 0, nextOffset: 0 },
    error: "",
    loading: false,
    profile: null,
    disabled: false,
    needUpdateCourseList: false,
    coursesLoading: false,
    coursesData: [],
    notPurchasedCoursesLoading: false,
    notPurchasedCoursesData: [],
    coursesPagination: { totalCount: 0, nextOffset: 0 },
    notPurchasedCoursesPagination: { totalCount: 0, nextOffset: 0 },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS_LIST:
            state = {
                ...state,
                coursesData: [],
                loading: true,
            };
            break;
        case GET_USERS_LIST_SUCCESS:
            state = {
                ...state,
                data: action.payload.data,
                pagination: action.payload.pagination,
                loading: false,
            };
            break;
        case GET_USERS_LIST_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload.error,
            };
            break;
        case GET_USERS_PROFILE:
            state = {
                ...state,
                coursesData: [],
                loading: true,
            };
            break;
        case GET_USERS_PROFILE_SUCCESS:
            state = {
                ...state,
                profile: action.payload.data,
                loading: false,
            };
            break;
        case GET_USERS_PROFILE_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload.error,
            };
            break;
        case CHANGE_USERS_STATUS:
            state = {
                ...state,
                loading: true,
            };
            break;
        case CHANGE_USERS_STATUS_SUCCESS:
            state = {
                ...state,
                profile: action.payload.data,
                loading: false,
            };
            break;
        case CHANGE_USERS_STATUS_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload.error,
            };
            break;
        case SEND_RECOVERY_LINK:
            state = {
                ...state,
                disabled: true,
            };
            break;
        case SEND_RECOVERY_LINK_SUCCESS:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case SEND_RECOVERY_LINK_ERROR:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case CHANGE_EMAIL:
            state = {
                ...state,
                disabled: true,
            };
            break;
        case CHANGE_EMAIL_SUCCESS:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case CHANGE_EMAIL_ERROR:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case SET_PASSWORD:
            state = {
                ...state,
                disabled: true,
            };
            break;
        case SET_PASSWORD_SUCCESS:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case SET_PASSWORD_ERROR:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case PROVIDE_SUBSCRIPTION:
            state = {
                ...state,
                disabled: true,
            };
            break;
        case PROVIDE_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case PROVIDE_SUBSCRIPTION_ERROR:
            state = {
                ...state,
                disabled: false,
            };
            break;
        case GET_USER_COURSES:
            state = {
                ...state,
                coursesLoading: true,
            };
            break;
        case GET_USER_COURSES_SUCCESS:
            state = {
                ...state,
                coursesData: action.payload.data,
                coursesPagination: action.payload.pagination,
                coursesLoading: false,
            };
            break;
        case GET_USER_COURSES_ERROR:
            state = {
                ...state,
                coursesLoading: false,
            };
            break;
        case GET_NOT_PURCHASED_USER_COURSES:
            state = {
                ...state,
                notPurchasedCoursesLoading: true,
            };
            break;
        case GET_NOT_PURCHASED_USER_COURSES_SUCCESS:
            state = {
                ...state,
                notPurchasedCoursesData: action.payload.data,
                notPurchasedCoursesPagination: action.payload.pagination,
                notPurchasedCoursesLoading: false,
            };
            break;
        case GET_NOT_PURCHASED_USER_COURSES_ERROR:
            state = {
                ...state,
                notPurchasedCoursesLoading: false,
            };
            break;
        case ADD_COURSE_TO_USER:
            state = {
                ...state,
                coursesLoading: true,
            };
            break;
        case ADD_COURSE_TO_USER_SUCCESS:
            state = {
                ...state,
                needUpdateCourseList: true,
                coursesLoading: false,
            };
            break;
        case ADD_COURSE_TO_USER_ERROR:
            state = {
                ...state,
                coursesLoading: false,
            };
            break;
        case REMOVE_COURSE_FROM_USER:
            state = {
                ...state,
                coursesLoading: true,
            };
            break;
        case REMOVE_COURSE_FROM_SUCCESS:
            state = {
                ...state,
                needUpdateCourseList: true,
                coursesLoading: false,
            };
            break;
        case REMOVE_COURSE_FROM_USER_ERROR:
            state = {
                ...state,
                coursesLoading: false,
            };
            break;
        case DELETE_USER:
            state = {
                ...state,
                loading: true,
            };
            break;
        case DELETE_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;
        case DELETE_USER_ERROR:
            state = {
                ...state,
                loading: false,
            };
            break;
        case REVOKE_SUBSCRIPTION:
            state = {
                ...state,
                loading: true,
            };
            break;
        case REVOKE_SUBSCRIPTION_ERROR:
            state = {
                ...state,
                loading: false,
            };
            break;
        case REVOKE_SUBSCRIPTION_SUCCESS:
            state = {
                ...state,
                loading: false,
            };
            break;
        case UPDATE_COURSE_LIST_CLEAR:
            state = {
                ...state,
                needUpdateCourseList: false,
            };
            break;
        case CLEAR_STATE:
            state = {
                ...state,
                coursesData: [],
                notPurchasedCoursesData: [],
                coursesPagination: { totalCount: 0, nextOffset: 0},
                notPurchasedCoursesPagination: { totalCount: 0, nextOffset: 0},
            }
            break;
            
        default:
            state = { ...state };
            break;
    }
    return state;
};
