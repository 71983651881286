import {
  GET_NOTIFICATIONS_LIST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_NOTIFICATIONS_LIST_ERROR,
  GET_REMINDER_NOTIFICATION,
  GET_REMINDER_NOTIFICATION_SUCCESS,
  GET_REMINDER_NOTIFICATION_ERROR,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_ERROR,
  CREATE_NOTIFICATION,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_ERROR,
  CREATE_NOTIFICATION_CLEAR,
} from "./actionTypes";

export const getNotificationsList = (payload) => {
  return {
    payload,
    type: GET_NOTIFICATIONS_LIST,
  };
};

export const getNotificationsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_NOTIFICATIONS_LIST_SUCCESS,
  };
};

export const getNotificationsError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_NOTIFICATIONS_LIST_ERROR,
  };
};

export const getReminderNotification = (type) => {
  return {
    payload: { type },
    type: GET_REMINDER_NOTIFICATION,
  };
};

export const getReminderNotificationSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_REMINDER_NOTIFICATION_SUCCESS,
  };
};

export const getReminderNotificationError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_REMINDER_NOTIFICATION_ERROR,
  };
};

export const updateNotification = (payload) => {
  return {
    payload,
    type: UPDATE_NOTIFICATION,
  };
};

export const updateNotificationSuccess = ({ data }) => {
  return {
    payload: { data },
    type: UPDATE_NOTIFICATION_SUCCESS,
  };
};

export const updateNotificationError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_NOTIFICATION_ERROR,
  };
};

export const createNotification = (payload) => {
  return {
    payload,
    type: CREATE_NOTIFICATION,
  };
};

export const createNotificationSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_NOTIFICATION_SUCCESS,
  };
};

export const createNotificationError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_NOTIFICATION_ERROR,
  };
};

export const createNotificationClear = () => {
  return {
    type: CREATE_NOTIFICATION_CLEAR,
  };
};
