export const GET_LIST_PREMIUM_ARTICLES_CATEGORIES =
  "GET_LIST_PREMIUM_ARTICLES_CATEGORIES";
export const GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS =
  "GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS";
export const GET_LIST_PREMIUM_ARTICLES_CATEGORIES_ERROR =
  "GET_PREMIUM_ARTICLES_CATEGORIES_ERROR";

export const UPDATE_PREMIUM_ARTICLES_CATEGORY =
  "UPDATE_PREMIUM_ARTICLES_CATEGORY";
export const UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS =
  "UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS";
export const UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR =
  "UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR";
export const UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR =
  "UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR";

export const CLEAR_PREMIUM_ARTICLES_CATEGORIES =
  "CLEAR_PREMIUM_ARTICLES_CATEGORIES";
