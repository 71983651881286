import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
  createCouponError,
  createCouponSubscriptionError,
  createCouponSubscriptionSuccess,
  createCouponSuccess,
  deleteCouponSubscriptionError,
  deleteCouponSubscriptionSuccess,
  editCouponSubscriptionError,
  editCouponSubscriptionSuccess,
  getCouponError,
  getCouponSuccess,
  getListCouponsError,
  getListCouponsSubscriptionError,
  getListCouponsSubscriptionSuccess,
  getListCouponsSuccess,
  getListCouponUsagesError,
  getListCouponUsagesSuccess,
  getSubscriptionCouponError,
  getSubscriptionCouponSuccess,
  removeCouponError,
  removeCouponSuccess,
  updateSubscriptionCouponError,
  updateSubscriptionCouponSuccess,
} from "./actions";
import {
  CREATE_COUPON,
  CREATE_COUPON_SUBSCRIPTION,
  DELETE_COUPON_SUBSCRIPTION,
  EDIT_COUPON_SUBSCRIPTION,
  GET_COUPON,
  GET_LIST_COUPON_USAGES,
  GET_LIST_COUPONS,
  GET_LIST_COUPONS_SUBSCRIPTION,
  GET_SUBSCRIPTION_COUPON,
  REMOVE_COUPON,
  UPDATE_SUBSCRIPTION_COUPON,
} from "./actionTypes";
import CouponsService from "../../../services/CouponsService";
import ToastrService from "../../../services/ToastrService";
import {
  COUPON_CREATED,
  COUPON_DELETED,
  COUPON_SUBSCRIPTION_CREATED,
} from "../../../constants/toastMessages";

const getCouponListAsync = async (pagination) => {
  return await CouponsService.getList(pagination);
};

const getCouponSubscriptionListAsync = async ({ pagination }) => {
  return await CouponsService.getListSubscription(pagination);
};

const getCouponAsync = async (id) => {
  return await CouponsService.getOne(id);
};

const createCouponAsync = async (model) => {
  return await CouponsService.create(model);
};

const createCouponSubscriptionAsync = ({ model }) => CouponsService.createSubscription(model);

const removeCouponAsync = async (id) => {
  return await CouponsService.remove(id);
};
const getListCouponUsagesAsync = async (couponId, pagination) => {
  return await CouponsService.getTransactions(pagination, { couponId });
};

const getSubscriptionCouponAsync = async () => {
  return await CouponsService.getSubscriptionCoupon();
};

const updateSubscriptionCouponAsync = async (model) => {
  return await CouponsService.updateSubscriptionCoupon(model);
};

const editCouponSubscriptionAsync = ({ id, model }) => CouponsService.editCouponSubscription({ id, model })

const deleteCouponSubscriptionAsync = async ({ id }) => CouponsService.deleteCouponSubscription({ id })

function* getCouponList({ payload }) {
  try {
    const response = yield call(getCouponListAsync, payload);
    yield put(getListCouponsSuccess(response));
  } catch (error) {
    yield put(getListCouponsError(error));
  }
}

function* getCouponSubscriptionList({ payload }) {
  try {
    const response = yield call(getCouponSubscriptionListAsync, { pagination: payload });
    yield put(getListCouponsSubscriptionSuccess(response));
  } catch (error) {
    yield put(getListCouponsSubscriptionError(error));
  }
}

function* getCoupon({ payload: { categoryId } }) {
  try {
    const response = yield call(getCouponAsync, categoryId);
    yield put(getCouponSuccess(response));
  } catch (error) {
    yield put(getCouponError(error));
  }
}

function* createCoupon({ payload: { model } }) {
  try {
    const response = yield call(createCouponAsync, model);
    yield put(createCouponSuccess(response));
    ToastrService.success(COUPON_CREATED);
  } catch (error) {
    yield put(createCouponError(error));
  }
}

function* createCouponSubscription({ payload: { model } }) {
  try {
    const response = yield call(createCouponSubscriptionAsync, { model });
    yield put(createCouponSubscriptionSuccess(response));
    ToastrService.success(COUPON_SUBSCRIPTION_CREATED);
  } catch (error) {
    yield put(createCouponSubscriptionError(error));
  }
}

function* removeCoupon({ payload: { categoryId } }) {
  try {
    const response = yield call(removeCouponAsync, categoryId);
    yield put(removeCouponSuccess(response));
    ToastrService.success(COUPON_DELETED);
  } catch (error) {
    yield put(removeCouponError(error));
  }
}

function* getListCouponUsages({ payload }) {
  try {
    const { couponId, pagination } = payload;
    const response = yield call(getListCouponUsagesAsync, couponId, pagination);
    yield put(getListCouponUsagesSuccess(response));
  } catch (error) {
    yield put(getListCouponUsagesError(error));
  }
}

function* getSubscriptionCoupon() {
  try {
    const response = yield call(getSubscriptionCouponAsync);
    yield put(getSubscriptionCouponSuccess(response));
  } catch (error) {
    yield put(getSubscriptionCouponError(error));
  }
}

function* updateSubscriptionCoupon({ payload }) {
  try {
    const response = yield call(updateSubscriptionCouponAsync, payload);
    yield put(updateSubscriptionCouponSuccess(response));
    ToastrService.success("Subscription coupon has been successfully updated");
  } catch (error) {
    yield put(updateSubscriptionCouponError(error));
  }
}

function* editCouponSubscription({ payload: { id, model } }) {
  try {
    const response = yield call(editCouponSubscriptionAsync, { id, model });
    yield put(editCouponSubscriptionSuccess(response));
    ToastrService.success("The subscription coupon has been successfully updated");
  } catch (error) {
    yield put(editCouponSubscriptionError(error));
  }
}

function* deleteCouponSubscription({ payload: { id } }) {
  try {
    yield call(deleteCouponSubscriptionAsync, { id });
    // Trigger reload Coupons Subscription
    yield put(deleteCouponSubscriptionSuccess({ data: { } }));
    ToastrService.success("The coupon has been deleted");
  } catch (error) {
    yield put(deleteCouponSubscriptionError(error));
  }
}

export function* watchGetCouponList() {
  yield takeEvery(GET_LIST_COUPONS, getCouponList);
}

export function* watchGetCouponSubscriptionList() {
  yield takeEvery(GET_LIST_COUPONS_SUBSCRIPTION, getCouponSubscriptionList);
}

export function* watchCreateCoupon() {
  yield takeEvery(CREATE_COUPON, createCoupon);
}

export function* watchCreateCouponSubscription() {
  yield takeEvery(CREATE_COUPON_SUBSCRIPTION, createCouponSubscription);
}

export function* watchGetCoupon() {
  yield takeEvery(GET_COUPON, getCoupon);
}

export function* watchRemoveCoupon() {
  yield takeEvery(REMOVE_COUPON, removeCoupon);
}

export function* watchGetListCouponUsages() {
  yield takeEvery(GET_LIST_COUPON_USAGES, getListCouponUsages);
}

export function* watchGetSubscriptionCoupon() {
  yield takeEvery(GET_SUBSCRIPTION_COUPON, getSubscriptionCoupon);
}

export function* watchUpdateSubscriptionCoupon() {
  yield takeEvery(UPDATE_SUBSCRIPTION_COUPON, updateSubscriptionCoupon);
}

export function* watchEditCouponSubscription() {
  yield takeEvery(EDIT_COUPON_SUBSCRIPTION, editCouponSubscription);
}

export function* watchDeleteCouponSubscription() {
  yield takeEvery(DELETE_COUPON_SUBSCRIPTION, deleteCouponSubscription);
}

function* couponsSaga() {
  yield all([
    fork(watchGetCouponList),
    fork(watchGetCouponSubscriptionList),
    fork(watchCreateCoupon),
    fork(watchCreateCouponSubscription),
    fork(watchGetCoupon),
    fork(watchRemoveCoupon),
    fork(watchGetListCouponUsages),
    fork(watchGetSubscriptionCoupon),
    fork(watchUpdateSubscriptionCoupon),
    fork(watchEditCouponSubscription),
    fork(watchDeleteCouponSubscription),
  ]);
}

export default couponsSaga;
