import React, { useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";

import styles from "./forms.module.scss";

const initialState = {
  userName: "",
  email: "",
};

const CreateAdminForm = ({ admins, saveAdminFunc }) => {
  const { loading } = admins;
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleFormSubmit = (event, values) => {
    saveAdminFunc(values);
  };

  return (
    <>
      <AvForm
        onValidSubmit={handleFormSubmit}
        ref={formRef}
        model={initialState}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      >
        <FormGroupText
          vertical
          className="mb-0"
          labelClassName="pt-0"
          name="userName"
          label="Username"
          placeholder="Enter username"
          validator={formsValidators.userName}
          handleFormChange={handleFormChange}
        />
        <FormGroupText
          vertical
          className="mb-0"
          labelClassName="pt-0"
          name="email"
          label="Email address"
          placeholder="Enter email address"
          validator={formsValidators.email}
          handleFormChange={handleFormChange}
        />
        <Row className="justify-content-end mt-3">
          <Col xs="12">
            <Button
              type="submit"
              color="primary"
              disabled={disableState}
              className={classNames(styles.btnSave, "mw-100")}
            >
              Create
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};
const mapStateToProps = ({ Admins: admins }) => ({ admins });

export default connect(mapStateToProps)(CreateAdminForm);
