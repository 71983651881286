import React, { Fragment, useCallback, useEffect, useRef } from "react";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import {
  clearBundleCreated,
  clearBundleRemoved,
  clearBundleUpdated,
  getListBundles,
  changeBundleStatusClear,
  clearListBundles,
} from "../../../store/dashboard/Bundles/actions";

import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from "../../../constants/pagination";
import Placeholder from "../../../components/Common/Placeholder";
import InfiniteScroll from "../../../components/Common/InfinitScroll";
import useSnackbarState from "../../../hooks/useSnackbarState";
import BundleTableRow from "./components/BundleTableRow";
import withUploadingError from "../../../HOCs/withUploadingError";

import styles from "../dashboard.module.scss";
import SEO from "../../../components/Common/SEO";

const Bundles = ({
  clearBundleCreated,
  bundles,
  getListBundles,
  clearBundleUpdated,
  clearBundleRemoved,
  changeBundleStatusClear,
  clearListBundles,
}) => {
  const { data, created, updated, removed, pagination, loading, changedStatus } = bundles;
  const scrollRef = useRef(null);
  const fetchList = (nextPagination = {}) => {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = nextPagination;
    getListBundles({ model: { limit, offset } });
  };

  const [isUpdated] = useSnackbarState(updated, clearBundleUpdated);
  const [isCreated] = useSnackbarState(created, clearBundleCreated);

  const loadMore = useCallback((offset) => fetchList({ offset }), []);

  useEffect(() => {
    // perform effect if entity appears
    if (removed) {
      fetchList({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET });
      clearBundleRemoved();
    }
  }, [removed]);

  useEffect(() => {
    // Fetch data
    fetchList();

    return () => {
      clearBundleRemoved();
      clearListBundles();
    };
  }, []);

  useEffect(() => {
    if (!loading && !!changedStatus) {
      changeBundleStatusClear();
    }
  }, [changedStatus, loading]);

  return (
    <>
      <SEO title="Bundles" />
      <Row className="page-content h-100">
        <Container fluid className="h-100">
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">BUNDLES</h4>
              </div>
            </Col>
          </Row>
          {isCreated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  New bundle has been successfully created
                </Alert>
              </Col>
            </Row>
          )}
          {isUpdated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  The bundle has been successfully updated
                </Alert>
              </Col>
            </Row>
          )}
          <Card className={styles.cardFullsize}>
            <CardBody>
              <CardTitle className="d-flex flex-row justify-content-between pb-3">
                <div className={styles.header}>
                  {/* Category: {item && item.name} */}
                </div>
                <Link to={`/bundles/create`}>
                  <Button color="primary">Create bundle</Button>
                </Link>
              </CardTitle>
              <CardText tag={"div"}>
                <Row className="h-100 scrollbar-wrapper">
                  {!loading && !data.length && (
                    <Placeholder text="no bundle created" />
                  )}
                  <InfiniteScroll
                    className="w-100 h-100"
                    ref={scrollRef}
                    disabled={data?.length === 0 || loading}
                    offset={pagination.nextOffset}
                    nextOffset={pagination.nextOffset}
                    totalCount={pagination.totalCount}
                    loadMore={loadMore}
                  >
                    {!!data && !!data.length > 0 && (
                      <div className="table-responsive col-12">
                        <Table className="table mt-0 mb-0 border-bottom">
                          <thead className="thead-light">
                            <tr>
                              <th>Bundle title</th>
                              <th className="w-220px text-right pr-5">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((bundle, index) => (
                              <Fragment key={"table-item-" + index}>
                                <BundleTableRow bundle={bundle} />
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {loading &&
                      !!data.length &&
                      data.length < pagination.totalCount && (
                        <Col xs={12}>
                          <div className="text-center my-3">
                            <div className="text-success">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                              Load more
                            </div>
                          </div>
                        </Col>
                      )}
                  </InfiniteScroll>
                </Row>
              </CardText>
            </CardBody>
          </Card>
        </Container>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const { Bundles } = state;

  return { bundles: Bundles };
};

export default withUploadingError(
  connect(mapStateToProps, {
    clearBundleCreated,
    clearBundleUpdated,
    getListBundles,
    clearBundleRemoved,
    changeBundleStatusClear,
    clearListBundles,
  })(Bundles)
);
