import {
  GET_LIST_LESSONS,
  GET_LIST_LESSONS_SUCCESS,
  GET_LIST_LESSONS_ERROR,
  CREATE_LESSON,
  CREATE_LESSON_SUCCESS,
  CREATE_LESSON_CLEAR,
  CREATE_LESSON_ERROR,
  GET_LESSON,
  GET_LESSON_SUCCESS,
  GET_LESSON_CLEAR,
  GET_LESSON_ERROR,
  UPDATE_LESSON,
  UPDATE_LESSON_SUCCESS,
  UPDATE_LESSON_CLEAR,
  UPDATE_LESSON_ERROR,
  REMOVE_LESSON,
  REMOVE_LESSON_SUCCESS,
  REMOVE_LESSON_CLEAR,
  REMOVE_LESSON_ERROR,
  GET_LESSONS_COUNT,
  GET_LESSONS_COUNT_SUCCESS,
  GET_LESSONS_COUNT_CLEAR,
  GET_LESSONS_COUNT_ERROR,
  CLEAR_LESSONS,
  REFETCH_LESSONS_SUCCESS,
  REFETCH_LESSONS,
} from "./actionTypes";

export const getListLessons = (payload) => {
  return {
    payload,
    type: GET_LIST_LESSONS,
  };
};

export const clearLessons = () => ({
  type: CLEAR_LESSONS,
});

export const refetchLessons = (payload) => ({
  payload,
  type: REFETCH_LESSONS,
});

export const refetchLessonsSuccess = ({ data, pagination }) => ({
  payload: { data, pagination },
  type: REFETCH_LESSONS_SUCCESS,
})

export const getListLessonsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_LESSONS_SUCCESS,
  };
};
export const getListLessonsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_LESSONS_ERROR,
  };
};

export const createLesson = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_LESSON,
  };
};

export const createLessonSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_LESSON_SUCCESS,
  };
};

export const createLessonError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_LESSON_ERROR,
  };
};

export const clearLessonCreated = () => {
  return {
    type: CREATE_LESSON_CLEAR,
  };
};

export const getLesson = (courseId) => {
  return {
    payload: { courseId },
    type: GET_LESSON,
  };
};

export const getLessonSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_LESSON_SUCCESS,
  };
};

export const getLessonError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_LESSON_ERROR,
  };
};

export const clearLessonFetched = () => {
  return {
    type: GET_LESSON_CLEAR,
  };
};

export const updateLesson = (data, history, courseId) => {
  return {
    payload: { data, history, courseId },
    type: UPDATE_LESSON,
  };
};

export const updateLessonSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_LESSON_SUCCESS,
  };
};

export const updateLessonError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_LESSON_ERROR,
  };
};

export const clearLessonUpdated = () => {
  return {
    type: UPDATE_LESSON_CLEAR,
  };
};

export const removeLesson = (data, history) => {
  return {
    payload: { data, history },
    type: REMOVE_LESSON,
  };
};

export const removeLessonSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_LESSON_SUCCESS,
  };
};

export const removeLessonError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_LESSON_ERROR,
  };
};

export const clearLessonRemoved = () => {
  return {
    type: REMOVE_LESSON_CLEAR,
  };
};
export const getLessonsCount = ({ courseId }) => {
  return {
    payload: { courseId },
    type: GET_LESSONS_COUNT,
  };
};

export const getLessonsCountSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_LESSONS_COUNT_SUCCESS,
  };
};

export const getLessonsCountError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_LESSONS_COUNT_ERROR,
  };
};

export const clearLessonsCount = () => {
  return {
    type: GET_LESSONS_COUNT_CLEAR,
  };
};
