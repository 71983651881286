const genres = [
  { label: "House", value: "HOUSE" },
  { label: "Techno", value: "TECHNO" },
  { label: "Drum n Bass", value: "DRUM_N_BASS" },
  { label: "Disco", value: "DISCO" },
  { label: "Hip Hop/R&B", value: "HIP_HOP_R_N_B" },
  { label: "EDM", value: "EDM" },
  { label: "Tech House", value: "TECH_HOUSE" },
  { label: "Deep House", value: "DEEP_HOUSE" },
  { label: "Pop/Mainstream", value: "POP_MAINSTREAM" },
  { label: "Trance", value: "TRANCE" },
  { label: "Latin/Reggaeton", value: "LATIN_REGGAETON" },
  { label: "Garage", value: "GARAGE" },
  { label: "Bass", value: "BASS" },
  { label: "Trap", value: "TRAP" },
  { label: "Afrobeat/Dancehall", value: "AFROBEAT_DANCEHALL" },
  { label: "Moombahton", value: "MOOMBAHTON" },
  { label: "Progressive House", value: "PROGRESSIVE_HOUSE" },
  { label: "Other", value: "OTHER" },
];

export default genres;
