import {
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES,
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES_ERROR,
  UPDATE_PREMIUM_ARTICLES_CATEGORY,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR,
  CLEAR_PREMIUM_ARTICLES_CATEGORIES,
} from "./actionTypes";

const initialState = {
  data: [],
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PREMIUM_ARTICLES_CATEGORIES:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_CATEGORIES_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_CATEGORY:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR:
      state = {
        ...state,
        updated: null,
        loading: false,
      };
      break;
    case CLEAR_PREMIUM_ARTICLES_CATEGORIES:
      state = {
        ...state,
        data: [],
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
