import { CLEAR_LIST_10_MONTH_SUBSCRIPTION_PLAN, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_ERROR, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_SUCCESS, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_CLEAR, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_ERROR, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_SUCCESS } from './actionTypes';

export const getList10MonthSubscriptionPlan = (payload) => {
  return {
    payload,
    type: GET_10_MONTH_SUBSCRIPTION_PLAN_LIST,
  };
};
export const getList10MonthSubscriptionPlanSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_SUCCESS,
  };
};
export const getList10MonthSubscriptionPlanError = ({ message }) => {
  return {
    payload: { message },
    type: GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_ERROR,
  };
};
export const clearList10MonthSubscriptionPlan = () => ({
  type: CLEAR_LIST_10_MONTH_SUBSCRIPTION_PLAN,
})

export const toggleShow10MonthSubscriptionPlan = (data) => {
  return {
    payload: { data },
    type: TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN,
  };
};

export const toggleShow10MonthSubscriptionPlanSuccess = ({ data }) => {
  return {
    payload: data,
    type: TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_SUCCESS,
  };
};

export const toggleShow10MonthSubscriptionPlanError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_ERROR,
  };
};

export const clearToggleShow10MonthSubscriptionPlan = () => {
  return {
    type: TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_CLEAR,
  };
};
