import formatBytes from "./formatBytes";

const MIN_SIZE_FOR_RESIZE = 300;

const FileHelper = class BaseFileHelper {
  static openAsDataUrl(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      if (reader.error) {
        return reject("Invalid file");
      }
      reader.onload = () => {
        return resolve(reader.result);
      };
    });
  }

  static dataUrlToFile(dataUrl, fileName) {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }

  static fileUrlToFile = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  static updateFileMeta = (file, addSize = false) => {
    if (addSize) {
      const width = file.width;
      const height = file.height;

      const sizes = [
        {
          width: Math.floor(width / 2),
          height: Math.floor(height / 2),
        }
      ]
      if (width <= MIN_SIZE_FOR_RESIZE || height <= MIN_SIZE_FOR_RESIZE) {
        sizes[0] = {
          width: Math.floor(width),
          height: Math.floor(height),
        }
      }
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        sizes,
      });
    } else {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
    }
  };

  static createChunks = (file, maxChunkSize) => {
    const fileSize = file.size;
    const chunksCount = Math.ceil(fileSize / maxChunkSize);
    const chunkSize = fileSize / chunksCount;
    let currentIndex = 0;
    const chunks = [];

    while (currentIndex < chunksCount) {
      chunks.push(
        file.slice(currentIndex * chunkSize, (currentIndex + 1) * chunkSize)
      );
      currentIndex = currentIndex + 1;
    }
    return chunks;
  };

  /**
   * Gets the intrinsic width and height of a video in CSS pixels
   * @param {File} file 
   * @returns {{height: number, width: number}} video dimensions
   */
  static getDimesnsions = async (file) => {
    const { height, width } = await new Promise(resolve => {
      const video = document.createElement('video');

      video.addEventListener("loadedmetadata", function () {
        resolve({
          height: this.videoHeight,
          width: this.videoWidth
        });
      }, false);

      video.src = URL.createObjectURL(file);
    });

    return { height, width };
  }
};

export default FileHelper;
