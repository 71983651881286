import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import {
  getPremiumArticlesCategories,
  updatePremiumArticlesCategory,
  clearPremiumArticlesCategoryUpdated,
} from "../../../../store/dashboard/PremiumArticlesCategories/actions";
import { getPremiumArticlesSections } from "../../../../store/dashboard/PremiumArticlesSections/actions";
import PremiumArticleSectionCard from "../components/PremiumArticleSectionCard";
import BlueSpinner from "../../../../components/Common/Spinner";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import HeaderComponent from "../components/HeaderComponent";
import djHubStates from "../../../../constants/djHubStates";
import useSnackbarState from "../../../../hooks/useSnackbarState";

import styles from "./Categories.module.scss";

const DJHubCategories = ({
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  premiumArticlesSections,
  premiumArticlesCategories,
  updatePremiumArticlesCategory,
  clearPremiumArticlesCategoryUpdated,
}) => {
  const { loading } = premiumArticlesSections;
  const { data, updated } = premiumArticlesCategories;

  const [needUpdateCategoriesState, setNeedUpdateCategoriesState] =
    useState(false);
  const [isUpdatedCategory] = useSnackbarState(
    updated,
    clearPremiumArticlesCategoryUpdated,
    () => setNeedUpdateCategoriesState(true)
  );

  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getPremiumArticlesSections();
  }, [getPremiumArticlesSections]);

  useEffect(() => {
    getPremiumArticlesCategories();
  }, [getPremiumArticlesCategories]);

  useEffect(() => {
    if (updated) {
      getPremiumArticlesCategories();
    }
  }, [updated, getPremiumArticlesCategories]);

  return (
    <ScrollbarVertical>
      <div className="page-content">
        <Container fluid>
          <HeaderComponent
            pageName={djHubStates.categories}
            alertText={
              isUpdatedCategory && "Changes have been successfully saved"
            }
          />
          <div className={styles.title}>Premium article categories</div>
          <Row>
            {loading && <BlueSpinner />}
            {!loading &&
              premiumArticlesSections.data.map((premiumSection, index) => (
                <Col
                  xs={12}
                  sm={4}
                  lg={4}
                  key={"premium-article-categories" + index}
                >
                  <PremiumArticleSectionCard
                    data={data.filter(
                      (item) =>
                        item.premiumArticleSectionId === premiumSection.id
                    )}
                    {...premiumSection}
                    needUpate={needUpdateCategoriesState}
                    setNeedUpdate={setNeedUpdateCategoriesState}
                    updateFunc={updatePremiumArticlesCategory}
                    editId={editId}
                    setEditId={setEditId}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({
  PremiumArticlesSections,
  PremiumArticlesCategories,
}) => {
  return {
    premiumArticlesSections: PremiumArticlesSections,
    premiumArticlesCategories: PremiumArticlesCategories,
  };
};

export default connect(mapStateToProps, {
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  updatePremiumArticlesCategory,
  clearPremiumArticlesCategoryUpdated,
})(DJHubCategories);
