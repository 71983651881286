import React, { useState } from "react";
import PlaceholderImage from "./PlaceholderImage";
import styles from "./index.module.scss";

const UploadedImage = ({ imageUploaded, onDelete }) => {
  const [isValidImage, setIsValidImage] = useState(true);

  const onError = () => {
    setIsValidImage(false);
  };

  return (
    <>
      <div className={styles.blockImageWr}>
        {(imageUploaded.link || imageUploaded.resizedImages) && isValidImage ? (
          <img
            src={
              imageUploaded.resizedImages
                ? imageUploaded.resizedImages[Object.keys(imageUploaded.resizedImages)[0]]
                : imageUploaded.link
            }
            alt=""
            onError={onError}
          />
        ) : (
          <PlaceholderImage />
        )}
      </div>
      <p className="text-danger mb-0 cursor-pointer" onClick={onDelete}>
        Delete image
      </p>
    </>
  );
};

export default UploadedImage;
