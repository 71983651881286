import React from "react";
import classNames from "classnames";
import { Col, Row } from "reactstrap";

import SearchBar from "../../../../components/Common/searchBar";
import { DEFAULT_COMMENTS_LIMIT } from "../../../../constants/pagination";
import CustomReactPaginate from "../../../../components/Common/CustomReactPaginate";
import CommentFiltres from "./CommentFiltres";
import {
  COMMENTS_PRODUCT_TYPES,
  getReadAllCommentsModalDescription,
  READ_ALL_COMMENTS_MODAL_TITLE,
} from "../../../../constants/comments";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";

import styles from "../index.module.scss";

const CommnetsTopContent = ({
  handleEnterSearch,
  searchQuery,
  items,
  pagination,
  pageChange,
  showResetFilters,
  handleResetFilters,
  isFilterOpen,
  setIsFilterOpen,
  isPremiumArticlesComments,
  filterOption,
  handleChangeFilters,
  handleSubmitFilters,
  readAllCommentsFunc,
}) => {
  const [modalVisible, toggleModal] = useModal();
  return (
    <>
      <Row className="align-items-center mb-4">
        <Col lg="6">
          <SearchBar
            handleEnterSearch={handleEnterSearch}
            searchQuery={searchQuery}
          />
        </Col>
        <Col lg="6" className="d-flex justify-content-end">
          {!!items.length && (
            <CustomReactPaginate
              pagination={pagination}
              pageChange={pageChange}
              limit={DEFAULT_COMMENTS_LIMIT}
              className="mb-0"
              needForcePage
            />
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col lg="6">
          <button
            className={classNames("btn", styles.allReadBtn)}
            onClick={toggleModal}
          >
            Mark all as read
          </button>
        </Col>
        <Col lg="6" className="d-flex align-items-center justify-content-end">
          <CommentFiltres
            showResetFilters={showResetFilters}
            handleResetFilters={handleResetFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            isPremiumArticlesComments={isPremiumArticlesComments}
            filterOption={filterOption}
            handleChangeFilters={handleChangeFilters}
            handleSubmitFilters={handleSubmitFilters}
          />
        </Col>
      </Row>
      <ConfirmationDialog
        containerClassname={styles.modal}
        confirmBtnClassName={styles.modalOkBtn}
        isOpen={modalVisible}
        titleText={READ_ALL_COMMENTS_MODAL_TITLE}
        contentText={getReadAllCommentsModalDescription(
          isPremiumArticlesComments
        )}
        cancelButtonText="Cancel"
        confirmButtonText="Yes"
        onCancel={toggleModal}
        onConfirm={() => {
          readAllCommentsFunc(
            isPremiumArticlesComments
              ? COMMENTS_PRODUCT_TYPES.article
              : COMMENTS_PRODUCT_TYPES.lesson
          );
          toggleModal();
        }}
        altBtnStyle
      />
    </>
  );
};

export default CommnetsTopContent;
