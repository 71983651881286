import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import CreateAdminModal from "../../../components/Modals/CreateAdminModal";
import useModal from "../../../hooks/useModal";
import {
  createAdmin,
  createAdminClear,
} from "../../../store/dashboard/Admins/actions";

import styles from "./index.module.scss";

const CreateAdminBtn = ({ admins, createAdmin, createAdminClear }) => {
  const { loading, created } = admins;
  const [modalVisible, toggleModal] = useModal();

  const handleCreateAdminConfirmed = (model) => {
    createAdmin(model);
  };

  useEffect(() => {
    if (!loading && !!created) {
      toggleModal(false);
      createAdminClear();
    }
  }, [loading, created]);

  return (
    <>
      <Button
        color="primary"
        className={styles.createAdminBtn}
        onClick={() => toggleModal(true)}
      >
        Create new admin
      </Button>
      <CreateAdminModal
        isOpen={modalVisible}
        onConfirm={handleCreateAdminConfirmed}
        onCancel={() => toggleModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({ Admins: admins }) => ({ admins });

export default connect(mapStateToProps, { createAdmin, createAdminClear })(
  CreateAdminBtn
);
