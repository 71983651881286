import useIsMountedRef from "./useIsMountedRef";
import { useState } from "react";

const useMountedState = (initialValue) => {
  const ref = useIsMountedRef();
  const [state, setState] = useState(initialValue);
  const updateState = (value) => {
    if (ref.current) {
      setState(value);
    }
  };
  return [state, updateState];
};

export default useMountedState;
