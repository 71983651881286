import {
  GET_LIST_LESSONS,
  GET_LIST_LESSONS_SUCCESS,
  GET_LIST_LESSONS_ERROR,
  CREATE_LESSON,
  CREATE_LESSON_SUCCESS,
  CREATE_LESSON_CLEAR,
  CREATE_LESSON_ERROR,
  GET_LESSON,
  GET_LESSON_SUCCESS,
  GET_LESSON_CLEAR,
  GET_LESSON_ERROR,
  UPDATE_LESSON,
  UPDATE_LESSON_SUCCESS,
  UPDATE_LESSON_CLEAR,
  UPDATE_LESSON_ERROR,
  REMOVE_LESSON,
  REMOVE_LESSON_SUCCESS,
  REMOVE_LESSON_CLEAR,
  REMOVE_LESSON_ERROR,
  GET_LESSONS_COUNT,
  GET_LESSONS_COUNT_SUCCESS,
  GET_LESSONS_COUNT_CLEAR,
  GET_LESSONS_COUNT_ERROR,
  CLEAR_LESSONS,
  REFETCH_LESSONS_SUCCESS,
  REFETCH_LESSONS,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  data: [],
  item: null,
  created: null,
  updated: null,
  removed: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  ordinalNumber: null,
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_LESSONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_LESSONS_SUCCESS:
      state = {
        ...state,
        data: [...state.data, ...action.payload.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_LESSONS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_LESSON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_LESSON_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_LESSON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_LESSON_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case UPDATE_LESSON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_LESSON_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_LESSON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_LESSON_CLEAR:
      state = {
        ...state,
        updated: null,
      };
      break;
    case GET_LESSON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LESSON_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_LESSON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_LESSON_CLEAR:
      state = {
        ...state,
        item: null,
        loading: false,
      };
      break;
    case REMOVE_LESSON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_LESSON_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_LESSON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_LESSON_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case GET_LESSONS_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LESSONS_COUNT_SUCCESS:
      state = {
        ...state,
        ordinalNumber: action.payload.lessonsCount,
        loading: false,
      };
      break;
    case GET_LESSONS_COUNT_CLEAR:
      state = {
        ...state,
        ordinalNumber: null,
        loading: false,
      };
      break;
    case GET_LESSONS_COUNT_ERROR:
      state = {
        ...state,
        ordinalNumber: null,
        loading: false,
      };
      break;
    case CLEAR_LESSONS:
      state = { ...initialState };
      break;
    case REFETCH_LESSONS:
      state = {
        ...state,
        data: [],
        loading: true
      };
      break;
    case REFETCH_LESSONS_SUCCESS:
      state = {
        ...state,
        data: [...action.payload.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
