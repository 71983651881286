import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveCourse from "../../../../components/Forms/SaveCourse";
import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import {
  clearCourseFetched,
  getCourse,
  updateCourse,
} from "../../../../store/dashboard/Courses/actions";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import ProgressWatcher from "../../../../components/Common/ProgressWatcher";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const Update = ({
  courses,
  getCourse,
  updateCourse,
  clearCourseFetched,
  filesError,
}) => {
  const { updated, item, loading } = courses;
  const [isDirty, updateDirty] = useState(false);
  const [ready, updateReadyState] = useState(false);
  const {
    params: { courseId },
  } = useRouteMatch("/courses/:courseId");
  const history = useHistory();
  const saveCourseCallback = useCallback(
    (model) => {
      updateCourse({ courseId, model }, history, item.courseCategoryId);
    },
    [item]
  );
  const isBlocked = (updated ? false : isDirty) && !filesError;

  useEffect(() => {
    if (!ready && courseId && !loading) {
      getCourse(courseId);
      updateReadyState(true);
    }
  }, [courseId, ready, loading]);

  useEffect(() => {
    return () => {
      clearCourseFetched();
    };
  }, []);

  return (
    <>
      <SEO title="Edit course" />
      <RouteLeavingGuard
        contentText="Changed data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <ScrollbarVertical>
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Edit course
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                {item && (
                  <SaveCourse
                    model={item}
                    saveCourseFunc={saveCourseCallback}
                    updateDirtyState={updateDirty}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </ScrollbarVertical>
    </>
  );
};

const mapStateToProps = ({ Courses, Progress }) => {
  return { courses: Courses, filesError: Progress.error };
};

export default connect(mapStateToProps, {
  getCourse,
  updateCourse,
  clearCourseFetched,
})(Update);
