import React from "react";
import formatDate from "../../../../helpers/formatDate";

export default function couponsListStructure() {
  return [
    {
      dataField: "body",
      text: "Coupon body",
    },
    {
      text: "Start date",
      dataField: "startDate",
      sort: true,
      formatter: (cellContent) => <>{formatDate(cellContent)}</>,
    },
    {
      text: "End date",
      dataField: "endDate",
      sort: true,
      formatter: (cellContent) => <>{formatDate(cellContent)}</>,
    },
    {
      dataField: "discount",
      text: "Discount",
      formatter: (cellContent) => <>{cellContent}%</>,
    },
  ];
}
