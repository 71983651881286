import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import CouponSubscriptionForm from "../../Forms/CouponSubscriptionForm/CouponSubscriptionForm";
import noop from "../../../helpers/noop";

import './styles.scss';

const CouponSubscriptionModal = ({
  isOpen,
  onCancel = noop,
  onConfirm = noop,
  isEdit,
  defaultData
}) => {
  const handleSaveCoupon = (model) => onConfirm(model);
  
  return (
    <Modal isOpen={isOpen} centered toggle={onCancel} className='subscription-modal'>
      <ModalHeader className='border-bottom-0 pb-0' toggle={onCancel}>
          {
              isEdit
                  ? <p className='font-weight-bold mb-0'>Edit a subscription coupon</p>
                  : <p className='font-weight-bold mb-0'>Create a subscription coupon</p>
          }

      </ModalHeader>
      <ModalBody className='pt-2'>
        <CouponSubscriptionForm isEdit={isEdit}
                                defaultData={defaultData}
                                onSubmit={handleSaveCoupon}/>
      </ModalBody>
    </Modal>
  );
};

export default CouponSubscriptionModal;
