import React, { useEffect, useRef, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { AvField } from "availity-reactstrap-validation";
import classNames from "classnames";

import noop from "../../../helpers/noop";
import { EMPTY_PARAGRAPH } from "../../../constants/editorConstants";

const editorConfiguration = {
  options: ["blockType", "inline", "link", "list"],
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "strikethrough"],
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["unordered"],
  },
  link: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    dropdownClassName: undefined,
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    linkCallback: undefined,
  },
};

const editorLabels = {
  // BlockType
  "components.controls.blocktype.h1": "Heading 1",
};

const WYSISYGComponent = ({
  name,
  editorClassName,
  validator = null,
  label = "test",
  handleFormChange = noop,
  valueReflectFunction = noop,
  editorSettings = editorConfiguration,
  vertical = false,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [innerValue, updateValue] = useState("");
  const [isReady, updateReadyState] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    const node = inputRef.current;
    if (!!node && !!node.getChildContext() && node.getChildContext().FormCtrl) {
      // reflects default value to delegator
      const value = node.getChildContext().FormCtrl.getDefaultValue(name) || "";
      const contentBlock = htmlToDraft(value);
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      updateValue(value);
      valueReflectFunction(value);
    }
  }, [inputRef, name]);

  const onEditorStateChange = (editorState) => {
    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      .replace(/(\r\n|\n|\r)/gm, "")
      .replace(/&nbsp;/gm, " ");
    updateValue(newValue);
    valueReflectFunction(newValue);
    setEditorState(editorState);
    handleFormChange();
    if (newValue !== innerValue && newValue !== EMPTY_PARAGRAPH) {
      const input = inputRef.current;
      if (input.context) {
        input.context.FormCtrl.setDirty(input.props.name);
        input.context.FormCtrl.setTouched(input.props.name);
      }
    }
  };

  useEffect(() => {
    updateReadyState(inputRef.current);
  }, [inputRef]);

  return (
    <FormGroup className="mb-4" row>
      <Col lg={2 + vertical * 10}>
        <Label htmlFor={name} className="col-form-label">
          {label}
        </Label>
      </Col>
      <Col lg={10 + vertical * 2}>
        {isReady && (
          <Editor
            toolbar={editorSettings}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName={classNames("editorClassName", editorClassName)}
            onEditorStateChange={onEditorStateChange}
            stripPastedStyles
            localization={{ locale: "en", translations: editorLabels }}
          />
        )}

        <AvField
          hidden
          ref={inputRef}
          id={name}
          name={name}
          value={innerValue}
          type="text"
          validate={validator}
        />
      </Col>
    </FormGroup>
  );
};

export default WYSISYGComponent;
