import {
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES,
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_CATEGORIES_ERROR,
  UPDATE_PREMIUM_ARTICLES_CATEGORY,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR,
  UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR,
  CLEAR_PREMIUM_ARTICLES_CATEGORIES,
} from "./actionTypes";

export const getPremiumArticlesCategories = (premiumArticleSectionId) => {
  return {
    payload: { premiumArticleSectionId },
    type: GET_LIST_PREMIUM_ARTICLES_CATEGORIES,
  };
};
export const getPremiumArticlesCategoriesSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_LIST_PREMIUM_ARTICLES_CATEGORIES_SUCCESS,
  };
};
export const getPremiumArticlesCategoriesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_PREMIUM_ARTICLES_CATEGORIES_ERROR,
  };
};

export const updatePremiumArticlesCategory = (id, data) => {
  return {
    payload: { id, data },
    type: UPDATE_PREMIUM_ARTICLES_CATEGORY,
  };
};

export const updatePremiumArticlesCategorySuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_PREMIUM_ARTICLES_CATEGORY_SUCCESS,
  };
};

export const updatePremiumArticlesCategoryError = ({
  message = "Unknown error",
}) => {
  return {
    payload: { message },
    type: UPDATE_PREMIUM_ARTICLES_CATEGORY_ERROR,
  };
};

export const clearPremiumArticlesCategoryUpdated = () => {
  return {
    type: UPDATE_PREMIUM_ARTICLES_CATEGORY_CLEAR,
  };
};

export const clearPremiumArticlesCategories = () => {
  return {
    type: CLEAR_PREMIUM_ARTICLES_CATEGORIES,
  };
};
