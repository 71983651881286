import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";

import {
  createCourseError,
  createCourseSuccess,
  getCourseError,
  getCourseSuccess,
  getListCoursesError,
  getListCoursesSuccess,
  removeCourseError,
  removeCourseSuccess,
  updateCourseError,
  updateCourseSuccess,
  changeCourseStatusSuccess,
  changeCourseStatusError,
  setCourseRecommendationsSuccess,
  setCourseRecommendationsError,
  getCoursesForRecommendationsError,
  getCoursesForRecommendationsSuccess,
} from "./actions";
import {
  CREATE_COURSE,
  GET_COURSE,
  GET_LIST_COURSE,
  REMOVE_COURSE,
  UPDATE_COURSE,
  CHANGE_COURSE_STATUS,
  SET_COURSE_RECOMMENDATIONS,
  GET_COURSES_FOR_RECOMMENDATIONS,
} from "./actionTypes";
import CourseService from "../../../services/CourseService";
import FilesService from "../../../services/FilesService";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import errorCodes from "../../../constants/errorCodes";
import throwCustomError from "../../../helpers/throwCustomError";
import { MAX_COURSES_LIMIT } from "../../../constants/courses";

const getCourseListAsync = async (data) => {
  const { categoryId, model } = data;
  return await CourseService.getList(categoryId, model);
};

const getCoursesForRecommendationsListAsync = async (coursesCount = 0, coursesList = []) => {

  const { pagination: { totalCount }, data } = await CourseService.getAllCoursesList({
    limit: MAX_COURSES_LIMIT,
    offset: coursesCount,
  });
  coursesList = [...coursesList, ...data.map(({ id, title }) => ({ id, title }))]

  if (totalCount > coursesList.length) {
    coursesList = await getCoursesForRecommendationsListAsync(coursesList.length, coursesList)
  }

  return coursesList;
};

const getCourseAsync = async (id) => {
  return await CourseService.getOne(id);
};

const courseComposite = (model) => {
  const {
    aboutTutor,
    convertkitTagId,
    equipment,
    included,
    lessons,
    overview,
    prices,
    title,
    urlPart,
    files,
    videoThumbnail,
    studentsSays,
    isApplicableToSubscription,
    isCollaborationCourse
  } = model;

  return {
    files,
    videoThumbnail,
    courseData: {
      aboutTutor,
      convertkitTagId,
      equipment,
      included,
      lessons,
      overview,
      prices,
      title,
      urlPart,
      studentsSays,
      isApplicableToSubscription,
      isCollaborationCourse
    },
  };
};

const getFilesArray = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return [...files, ...thumbnailFiles];
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles;
  }
};

const getFilesTotalSize = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return files[0].size + thumbnailFiles[0].size;
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles[0].size;
  }
};

/**
 * Course files channel for files uploading watching
 * @param data<Object>
 * @param mode<"create"|"update"> - mode of files progress channel (create by default)
 */
const courseFilesChannel = (data, mode = "create") => {
  return eventChannel((emit) => {
    const { model } = data;
    const { files, videoThumbnail, courseData } = courseComposite(model);
    const id = mode === "create" ? data.categoryId : data.courseId;
    const actionFunc =
      mode === "create"
        ? CourseService.create(id, courseData)
        : CourseService.update(id, courseData);
    if (
      (!files || !files.length) &&
      (!videoThumbnail || !videoThumbnail.length)
    ) {
      // here is only one file and it's mandatory
      actionFunc
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    } else {
      const onProgress = ({ loaded }, file, chunkIndex) => {
        emit({ file, loaded, chunkIndex });
      };
      let course;
      actionFunc
        .then(async ({ data }) => {
          course = data;
          emit({
            files: getFilesArray(files, videoThumbnail) || [],
            filesTotalSize: getFilesTotalSize(files, videoThumbnail) || 0,
          });
          const filesResponse =
            !!files &&
            !!files.length &&
            (await FilesService.uploadVideoFile({ files }, onProgress).catch(
              (error) => {
                throwCustomError(error);
              }
            ));
          const videoThumbnaiResponse = await FilesService.uploadImageFile(
            { files: videoThumbnail },
            onProgress
          ).catch((error) => {
            throwCustomError(error);
          });
          return { filesResponse, videoThumbnaiResponse };
        })
        .then(({ filesResponse, videoThumbnaiResponse }) => {
          if (!!filesResponse) {
            return CourseService.addVideo(course.id, {
              fileId: filesResponse[0].file.id,
              videoThumbnailId: videoThumbnaiResponse[0].file.id,
            }).catch((error) => {
              throwCustomError(error);
            });
          } else if (!!videoThumbnaiResponse) {
            return CourseService.changeVideoThumbnail(
              course.id,
              videoThumbnaiResponse[0].file.id
            ).catch((error) => {
              throwCustomError(error);
            });
          }
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    }
  });
};

const removeCourseAsync = async (id) => {
  return await CourseService.remove(id);
};

const changeCourseStatusAsync = async (id) => {
  return await CourseService.changeStatus(id);
};

const setCourseRecommendationsAsync = async (id, data) => {
  return await CourseService.setRecommendations(id, data);
}

function* getCourseList({ payload }) {
  try {
    const response = yield call(getCourseListAsync, payload);
    yield put(getListCoursesSuccess(response));
  } catch (error) {
    yield put(getListCoursesError(error));
  }
}

function* getCourse({ payload: { courseId } }) {
  try {
    const response = yield call(getCourseAsync, courseId);
    yield put(getCourseSuccess(response));
  } catch (error) {
    yield put(getCourseError(error));
  }
}

function* changeCourseStatus({ payload: { id } }) {
  try {
    const response = yield call(changeCourseStatusAsync, id);
    yield put(changeCourseStatusSuccess(response));
  } catch (error) {
    yield put(changeCourseStatusError(error));
  }
}

function* createCourseProgressListener(chan, categoryId, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(createCourseError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(createCourseError(err));
        history.push(`/training/${categoryId}`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createCourseSuccess(success));
      history.push(`/training/${categoryId}`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* createCourse({ payload: { data, history } }) {
  try {
    const { categoryId } = data;
    yield fork(
      createCourseProgressListener,
      courseFilesChannel(data),
      categoryId,
      history
    );
  } catch (error) {
    yield put(createCourseError(error));
  }
}

function* updateCourseProgressListener(chan, history, courseCategoryId) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(updateCourseError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(updateCourseError(err));
        history.push(`/training/${courseCategoryId}`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updateCourseSuccess(success));
      history.push(`/training/${courseCategoryId}`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* updateCourse({ payload: { data, history, courseCategoryId } }) {
  try {
    yield fork(
      updateCourseProgressListener,
      courseFilesChannel(data, "update"),
      history,
      courseCategoryId
    );
  } catch (error) {
    yield put(updateCourseError(error));
  }
}

function* removeCourse({ payload: { data } }) {
  try {
    const response = yield call(removeCourseAsync, data);
    yield put(removeCourseSuccess(response));
  } catch (error) {
    yield put(removeCourseError(error));
  }
}

function* updateCurseRecommendations({ payload: { data, courseId } }) {
  try {
    const response = yield call(setCourseRecommendationsAsync, courseId, data);
    yield put(setCourseRecommendationsSuccess(response));
  } catch (error) {
    yield put(setCourseRecommendationsError(error));
  }
}

function* getCoursesForRecommendations({payload: { limit = 0, offset = 0 }}) {
  try {
    const response = yield call(getCoursesForRecommendationsListAsync);
    yield put(getCoursesForRecommendationsSuccess(response));
  } catch (error) {
    yield put(getCoursesForRecommendationsError(error))
  }
}

export function* watchGetCourseList() {
  yield takeEvery(GET_LIST_COURSE, getCourseList);
}

export function* watchCreateCourse() {
  yield takeEvery(CREATE_COURSE, createCourse);
}

export function* watchUpdateCourse() {
  yield takeEvery(UPDATE_COURSE, updateCourse);
}

export function* watchRemoveCourse() {
  yield takeEvery(REMOVE_COURSE, removeCourse);
}

export function* watchGetCourse() {
  yield takeEvery(GET_COURSE, getCourse);
}

export function* watchChangeCourseStatus() {
  yield takeEvery(CHANGE_COURSE_STATUS, changeCourseStatus);
}

export function* watchUpdateCurseRecommendations() {
  yield takeEvery(SET_COURSE_RECOMMENDATIONS, updateCurseRecommendations);
}

export function* watchGetCoursesForRecommendations() {
  yield takeEvery(GET_COURSES_FOR_RECOMMENDATIONS, getCoursesForRecommendations)
}

function* coursesSaga() {
  yield all([
    fork(watchGetCourseList),
    fork(watchCreateCourse),
    fork(watchUpdateCourse),
    fork(watchRemoveCourse),
    fork(watchGetCourse),
    fork(watchChangeCourseStatus),
    fork(watchUpdateCurseRecommendations),
    fork(watchGetCoursesForRecommendations)
  ]);
}

export default coursesSaga;
