import { useState } from "react";

import {
  INVALID_FILE_COUNT_CODE,
  INVALID_FILE_SIZE_CODE,
  INVALID_FILE_TYPE_CODE,
} from "../constants/dropzone";
import formsValidators from "../validators/form.validators";
import FileHelper from "../helpers/FileHelper";
import formatImageFiles from "../helpers/formatImageFiles";

const useDropzoneFacade = ({
  type = "image",
  initialData = [],
  validationErrors = {},
} = {}) => {
  const [selectedFiles, setSelectedFiles] = useState([...initialData]);
  const [error, setError] = useState(null);
  const [errorFileId, setErrorFileId] = useState(null);

  const handleAcceptedFiles = async (
    files,
    fileRejections,
    multiple = false,
    index = null
  ) => {
    if (fileRejections.length) {
     setErrorFileId(index)
      fileRejections.forEach(({ errors }) => {
        switch (errors[0].code) {
          case INVALID_FILE_TYPE_CODE:
            setError(formsValidators.file[type].formats.errorMessage);
            break;
          case INVALID_FILE_SIZE_CODE:
            setError(formsValidators.file[type].maxSize.errorMessage);
            break;
          case INVALID_FILE_COUNT_CODE:
            setError(validationErrors.maxFiles || "Too many files");
            break;
          default:
            setError(errors[0].message);
        }
      });
      if(!multiple){
        setSelectedFiles([]);
      }
    } else {
      setError(null);
      if (type === "image") {
        await formatImageFiles(files);
      }

      files.map((file) => {
        return file.preview === undefined
          ? FileHelper.updateFileMeta(file, type === "image")
          : file;
      });
      if ((!!index || index === 0) && multiple) {
        const currentSelectedFiles = [...selectedFiles];
        currentSelectedFiles[index] = files[0];
        setSelectedFiles(currentSelectedFiles);
      } else if (!index) {
        setSelectedFiles(multiple ? [...selectedFiles, ...files] : [...files]);
      }
    }
  };
  
  return [
    error,
    selectedFiles,
    handleAcceptedFiles,
    setSelectedFiles,
    setError,
    errorFileId,
  ];
};

export default useDropzoneFacade;
