import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { withNamespaces } from 'react-i18next';

import SidebarContent from './SidebarContent';

const Sidebar = (props) => {
    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">
                { props.type !== 'condensed' ? (
                    <SimpleBar style={ { maxHeight: '100%' } }>
                        <SidebarContent/>
                    </SimpleBar>
                ) : <SidebarContent/> }
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, {})(withRouter(withNamespaces()(Sidebar)));
