import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useAvForm from "../../../../../hooks/useAvForm";
import formsValidators from "../../../../../validators/form.validators";

import styles from "./PremiumArticleTags.module.scss";

import checkMark from "../../../../../assets/images/ic-check-mark-green.svg";
import closeRed from "../../../../../assets/images/ic-close-red.svg";

const PremiumArticleTags = ({ id, name, isCategory, updateFunc, editId, setEditId }) => {
  const [formRef, hasFormError, handleFormChange] = useAvForm();

  const [errorState, setErrorState] = useState(false);

  const inputWrRef = useRef(null);

  useEffect(() => {
    if (errorState) {
      const inputError = inputWrRef.current.childNodes[0].childNodes[1];
      inputError.setAttribute(
        "style",
        "margin: 0; position: relative; z-index: 10; top: -2px;"
      );
      setErrorState(false);
    }
  }, [errorState]);

  const customErrors = useCallback(() => {
    if (hasFormError) {
      const input = inputWrRef.current.childNodes[0];
      input.setAttribute("style", "height: 36px");
      setErrorState(true);
    }
  }, [hasFormError]);

  const handleFormSubmit = (event, values) => {
    formRef.current.reset();
    setEditId(null);
    updateFunc(id, values);
  };

  return (
    <div className={styles.wr}>
      {editId === id ? (
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          className={styles.form}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <div ref={inputWrRef}>
            <AvField
              value={name}
              id="name"
              name="name"
              type="text"
              className="form-control"
              placeholder={isCategory ? "Enter category name" : "Enter tag name"}
              onChange={() => handleFormChange()}
              onBlur={(e) => {
                customErrors();
                handleFormChange();
              }}
              validate={
                isCategory ? formsValidators.category : formsValidators.tag
              }
            />
          </div>
          <div className={styles.btnContainer}>
            <button onClick={() => setEditId(null)}>
              <img alt="close" width="24" height="24" src={closeRed} />
            </button>
            <button type="submit">
              <img alt="accept" width="24" height="24" src={checkMark} />
            </button>
          </div>
        </AvForm>
      ) : (
        <div className={styles.card}>
          <div>{name}</div>
          <button onClick={() => setEditId(id)}>
            <i className="bx bx-edit text-grey" />
          </button>
        </div>
      )}
    </div>
  );
};

export default PremiumArticleTags;
