import React from "react";
import { components } from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";

import { VALUE_FIELD_ID } from "../constants/selectConstants";

export const generateOptions = (values, valueField = VALUE_FIELD_ID) =>
  values.map((option) => ({
    value: option[valueField],
    label: option.name,
  }));

export const customStyles = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 8px",
    fontSize: "24px",
  }),
};

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <i className="ri-arrow-down-s-fill" />
  </components.DropdownIndicator>
);

export const MenuList = (props) => (
  <div style={{ height: props.children.length >= 7 ? 250 : "unset" }}>
    <PerfectScrollbar>{props.children}</PerfectScrollbar>
  </div>
);
