import React, { useState } from 'react'
import { Card, Button, Modal } from 'reactstrap';
import UserAvatar from '../../../../components/Common/userAvatar';
import statusColors from '../../../../constants/statusColors';
import capitalizeFirstLetter from '../../../../helpers/capitilizeFirstLetter';
import { ACTIVE_STATUS, BLOCKED_STATUS } from '../../../../constants/accountStatuses';
import ConfirmationDialog from '../../../../components/Modals/ConfirmationDialog';
import ChangeEmailModal from './changeEmailModal';
import Icon from "../../../../components/Common/Icon";
import ProvideSubscriptionModal from './provideSubscriptionModal';
import SetPasswordModal from './setPasswordlModal';

const BLOCK_MODAL = 'BLOCK_USER_MODAL';
const RECOVER_MODAL = 'RECOVER_PASSWORD_MODAL';
const SET_PASSWORD_MODAL = 'SET_PASSWORD_MODAL';
const CHANGE_MODAL = "CHANGE_EMAIL_MODAL";
const PROVIDE_SUBSCRIPTION_MODAL = "PROVIDE_SUBSCRIPTION_MODAL";
const DELETE_MODAL = "DELETE_USER_MODAL";
const REVOKE_SUBSCRIPTION_MODAL = "REVOKE_SUBSCRIPTION_MODAL";

export default function BasicInfo({
    avatar,
    status,
    username,
    changeStatus,
    email,
    handleSendRecoveryLink,
    disabled,
    isDisabledRevokeSubscription,
    handleChangeEmail,
    handleSetPassword,
    handleProvideSubscription,
    handleDeleteUser,
    handleRevokeSubscription,
}) {
    const [showModal, setShowModal] = useState(null);

    const isBlocked = status === BLOCKED_STATUS;

    function sendRecoveryLink() {
        handleSendRecoveryLink();
        setShowModal(null);
    }

    function handleSubmitChangeEmail(email, toForceUpdate){
        handleChangeEmail(email, toForceUpdate);
        setShowModal(null);
    }

    function handleSubmitSetPassword(newPassword){
        handleSetPassword(newPassword);
        setShowModal(null);
    }

    function handleSubmitProvideSubscription(model) {
        handleProvideSubscription(model);
        setShowModal(null);
    }

    const onDeleteUser = () => {
      handleDeleteUser()
      setShowModal(null);
    }
    
    const onRevokeSubscription = () => {
      handleRevokeSubscription()
      setShowModal(null);
    }

    return (
        <>
            <Card className="user-details--basic-info d-flex flex-row justify-content-between">
                <section className="d-flex flex-row">
                    <UserAvatar avatar={avatar} name={username} />
                    <section className="d-flex flex-column justify-content-center ml-4">
                        <span className="user-details--basic-info__name">{username}</span>
                        <span className="user-details--basic-info__status">
                            User status:
                        <span className={"ml-2 user-list" + statusColors[status]}>
                                {capitalizeFirstLetter(status)}
                            </span>
                        </span>
                    </section>
                </section>

                <section className="d-flex flex-row align-items-center">
                    <div className="cursor-pointer d-flex align-items textDanger trash-button-hover" onClick={() => setShowModal(DELETE_MODAL)}>
                        <Icon icon="trash" className="mr-1"/>
                        Delete user
                    </div>
                    <Button
                      className="user-details--basic-info__button ml-4"
                      color="primary"
                      onClick={() => setShowModal(REVOKE_SUBSCRIPTION_MODAL)}
                      disabled={disabled || isBlocked || isDisabledRevokeSubscription}
                    >
                      Revoke subscription
                    </Button>

                    <Button
                        className="user-details--basic-info__button"
                        color="primary"
                        onClick={() => setShowModal(SET_PASSWORD_MODAL)}
                        disabled={disabled || isBlocked}
                    >
                        Set password
                    </Button>
                    <Button
                        className="user-details--basic-info__button"
                        color="primary"
                        onClick={() => setShowModal(RECOVER_MODAL)}
                        disabled={disabled || isBlocked}
                    >
                        Recover password
                    </Button>
                    <Button
                        className="user-details--basic-info__button"
                        color="primary"
                        onClick={() => setShowModal(CHANGE_MODAL)}
                        disabled={disabled || isBlocked}
                    >
                        Change email
                    </Button>
                    <Button
                        className="user-details--basic-info__button"
                        color="primary"
                        onClick={() => setShowModal(PROVIDE_SUBSCRIPTION_MODAL)}
                        disabled={disabled || isBlocked}
                    >
                        Provide Subscription
                    </Button>
                    {isBlocked ?
                        <Button
                            className="user-details--basic-info__button"
                            color="warning"
                            onClick={() => setShowModal(BLOCK_MODAL)}
                        >
                            Unblock
                        </Button>

                        :
                        <Button
                            className="user-details--basic-info__button"
                            color="danger"
                            onClick={() => setShowModal(BLOCK_MODAL)}
                        >
                            Block
                        </Button>
                    }
                </section>
            </Card>
            <ConfirmationDialog
                isOpen={showModal === BLOCK_MODAL}
                titleText={isBlocked ? 'Unblock' : 'Block'}
                contentText={isBlocked ? 'This user will be unblocked' : 'This user will be blocked'}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                onCancel={() => setShowModal(null)}
                onConfirm={() => changeStatus(isBlocked ? ACTIVE_STATUS : BLOCKED_STATUS)}
                bodyClassName="user-details--basic-info__modal-body"
                confirmBtnClassName="user-details--basic-info__primary-button"
                cancelBtnClassName="user-details--basic-info__secondary-button"
                containerClassname="user-details--basic-info__modal-container"
            />
            <ConfirmationDialog
                isOpen={showModal === RECOVER_MODAL}
                titleText='Send recovery link'
                contentText={(<span>The recovery link will be sent to <b>{email}</b>. The user will need to open it to create a new password</span>)}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                onCancel={() => setShowModal(null)}
                onConfirm={sendRecoveryLink}
                bodyClassName="user-details--basic-info__modal-body"
                confirmBtnClassName="user-details--basic-info__primary-button"
                cancelBtnClassName="user-details--basic-info__secondary-button"
                containerClassname="user-details--basic-info__modal-container"
            />
            <ConfirmationDialog
                isOpen={showModal === DELETE_MODAL}
                titleText='Delete'
                contentText={(<span>This user along with their personal info will be deleted. You will not be able to cancel this action. Are you sure that you want to delete this user?</span>)}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                onCancel={() => setShowModal(null)}
                onConfirm={onDeleteUser}
                bodyClassName="user-details--basic-info__modal-body"
                confirmBtnClassName="user-details--basic-info__primary-button"
                cancelBtnClassName="user-details--basic-info__secondary-button"
                containerClassname="user-details--basic-info__modal-container"
            />
            <ConfirmationDialog
                isOpen={showModal === REVOKE_SUBSCRIPTION_MODAL}
                titleText='Revoke subscription'
                contentText={(<span>This option makes the DJ Hub inactive for the user. Are you sure that the refund is done and you want to revoke the DJ Hub subscription?</span>)}
                cancelButtonText="No"
                confirmButtonText="Yes"
                onCancel={() => setShowModal(null)}
                onConfirm={onRevokeSubscription}
                bodyClassName="user-details--basic-info__modal-body"
                confirmBtnClassName="user-details--basic-info__primary-button"
                cancelBtnClassName="user-details--basic-info__secondary-button"
                containerClassname="user-details--basic-info__modal-container"
            />
            <Modal
                isOpen={showModal === CHANGE_MODAL}
                toggle={() => setShowModal(null)}
                className="user-details--basic-info__modal-container"
                centered
            >
                <ChangeEmailModal toggle={() => setShowModal(null)} submit={handleSubmitChangeEmail} />
            </Modal>

            <Modal
                isOpen={showModal === SET_PASSWORD_MODAL}
                toggle={() => setShowModal(null)}
                className="user-details--basic-info__modal-container"
                centered
            >
                <SetPasswordModal toggle={() => setShowModal(null)} submit={handleSubmitSetPassword} />
            </Modal>

            <Modal
                isOpen={showModal === PROVIDE_SUBSCRIPTION_MODAL}
                toggle={() => setShowModal(null)}
                className="user-details--basic-info__modal-container"
                centered
            >
                <ProvideSubscriptionModal toggle={() => setShowModal(null)} submit={handleSubmitProvideSubscription} />
            </Modal>
        </>
    )
}
