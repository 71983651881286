import {components} from "react-select";
import {Col} from "reactstrap";
import React from "react";

export default (props) => {
    const {data: { premiumArticleCategory, premiumArticleSection, title}} = props;
    return (
        <components.Option {...props} className="d-flex flex-column">
            <Col lg={12} className="d-flex justify-content-start p-0 m-0">
                <p className="d-flex font-weight-bold my-0 mr-2">{premiumArticleSection?.name}</p>
                <i style={{width: '6px', height: '6px'}} className="d-flex bg-primary mt-2 rounded-circle"/>
                <p className="d-flex font-weight-bold my-0 ml-2">{premiumArticleCategory?.name}</p>
            </Col>
            <Col lg={12} className="p-0 m-0">
                <p className="d-flex my-0 mr-2">{title}</p>
            </Col>
        </components.Option>
    )
}