import { DEFAULT_OFFSET } from "../../../constants/pagination";
import {
  GET_LIST_PREMIUM_ARTICLES_TAGS,
  GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_TAGS_ERROR,
  UPDATE_PREMIUM_ARTICLES_TAG,
  UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS,
  UPDATE_PREMIUM_ARTICLES_TAG_CLEAR,
  UPDATE_PREMIUM_ARTICLES_TAG_ERROR,
} from "./actionTypes";

const initialState = {
  data: [],
  error: "",
  loading: false,
  updated: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PREMIUM_ARTICLES_TAGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_TAGS_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_PREMIUM_ARTICLES_TAGS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_TAG:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_TAG_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_TAG_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_PREMIUM_ARTICLES_TAG_CLEAR:
      state = {
        ...state,
        updated: null,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
