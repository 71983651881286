import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import {
  getProfileSuccess,
  getAdminProfileError,
  updateAdminProfileSuccess,
  updateAdminProfileError,
  changeAdminPasswordSuccess,
  changeAdminPasswordError,
  changeAdminEmailSuccess,
  changeAdminEmailError,
  verifyChangeEmailSuccess,
  verifyChangeEmailError,
  changeAdminAvatarSuccess,
  changeAdminAvatarError,
} from "./actions";
import {
  GET_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE,
  CHANGE_ADMIN_PASSWORD,
  CHANGE_ADMIN_EMAIL,
  VERIFY_CHANGE_EMAIL,
  CHANGE_ADMIN_AVATAR,
} from "./actionTypes";

import AdminsSerivce from "../../../services/AdminsSerivce";
import ToastrService from "../../../services/ToastrService";
import {
  AVATAR_CHANGED_SUCCESS,
  AVATAR_DELETED_SUCCESS,
  PASSWORD_CHANGED_SUCCESS,
  USERNAME_CHANGED_SUCCESS,
} from "../../../constants/toastMessages";
import StorageService from "../../../services/StorageService";
import FilesService from "../../../services/FilesService";
import { uploadFailure, uploadInitiate, uploadProgress, uploadSuccess } from "../../progress/actions";
import errorCodes from "../../../constants/errorCodes";
import { END, eventChannel } from "redux-saga";
import throwCustomError from "../../../helpers/throwCustomError";

const getAdminProfileAsync = async () => {
  return await AdminsSerivce.getProfile();
};

const deleteAdminAvatarAsync = async () => {
  return await AdminsSerivce.removeAvatar();
};

const changeAdminAvatarFilesChannel = (file) => {
  return eventChannel((emit) => {
    const request = Promise.resolve({});
    if (file) {
      const onProgress = ({ loaded }, file) => {
        emit({ file, loaded });
      };
      request
        .then(() => {
          emit({ files: [file], filesTotalSize: file.size });

          return FilesService.uploadImageFile({ files: [file] }, onProgress).catch(
            (error) => {
              throwCustomError(error);
            }
          );
        })
        .then((filesResponse) => {
          return AdminsSerivce.addAvatar({
            fileId: filesResponse[0].file.id,
          }).catch((error) => {
            throwCustomError(error);
          });
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    }
  });
};

const updateAdminProfileAsync = async ({ model }) => {
  return AdminsSerivce.updateAdminProfile(model);
};

const changeAdminPasswordAsync = async (model) => {
  return await AdminsSerivce.changeAdminPassword(model);
};

const changeAdminEmailAsync = async (model) => {
  return await AdminsSerivce.changeAdminEmail(model);
};

const verifyChangeEmailAsync = async (token) => {
  return await AdminsSerivce.verifyChangeEmail(token);
};

function* changeAdminAvatarProgressListener(chan) {
  while (true) {
    const { files, filesTotalSize, file, loaded, err, success } = yield take(
      chan
    );
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        ToastrService.error(err.message);
        yield put(changeAdminAvatarError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(changeAdminAvatarError(err));
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(changeAdminAvatarSuccess(success));
      ToastrService.success(AVATAR_CHANGED_SUCCESS);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded }));
    }
  }
}

function* getAdminProfile() {
  try {
    const response = yield call(getAdminProfileAsync);
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getAdminProfileError(error));
  }
}

function* changeAdminAvatar({ payload: { file, toRemoveAvatar } }) {
  try {
    if (toRemoveAvatar) {
      const response = yield call(deleteAdminAvatarAsync);
      yield put(changeAdminAvatarSuccess(response));

      ToastrService.success(AVATAR_DELETED_SUCCESS);
      return;
    }

    yield fork(
      changeAdminAvatarProgressListener,
      changeAdminAvatarFilesChannel(file)
    )
  } catch (error) {
    yield put(changeAdminAvatarError(error));
  }
}

function* updateAdminProfile({ payload }) {
  try {
    const response = yield call(updateAdminProfileAsync, payload);
    yield put(updateAdminProfileSuccess(response));
    ToastrService.success(USERNAME_CHANGED_SUCCESS);
  } catch (error) {
    yield put(updateAdminProfileError(error));
  }
}

function* changeAdminPassword({ payload }) {
  try {
    const response = yield call(changeAdminPasswordAsync, payload);
    yield put(changeAdminPasswordSuccess(response));
    ToastrService.success(PASSWORD_CHANGED_SUCCESS);
  } catch (error) {
    yield put(changeAdminPasswordError(error));
  }
}

function* changeAdminEmail({ payload }) {
  try {
    yield call(changeAdminEmailAsync, payload);
    const { newEmail } = payload;
    yield put(changeAdminEmailSuccess(newEmail));
  } catch (error) {
    yield put(changeAdminEmailError(error));
  }
}

function* verifyChangeEmail({ payload: { token, history } }) {
  const user = StorageService.user.value;
  try {
    yield call(verifyChangeEmailAsync, token);
    yield put(verifyChangeEmailSuccess());
    history.push(user ? "/settings" : "/login");
  } catch (error) {
    yield put(verifyChangeEmailError(error));
    history.push(user ? "/settings" : "/login");
  }
}

export function* watchGetAdminProfile() {
  yield takeEvery(GET_ADMIN_PROFILE, getAdminProfile);
}

export function* watchUpdateAdminProfile() {
  yield takeEvery(UPDATE_ADMIN_PROFILE, updateAdminProfile);
}

export function* watchChangeAdminPassword() {
  yield takeEvery(CHANGE_ADMIN_PASSWORD, changeAdminPassword);
}

export function* watchChangeAdminEmail() {
  yield takeEvery(CHANGE_ADMIN_EMAIL, changeAdminEmail);
}

export function* watchVerifyChangeEmail() {
  yield takeEvery(VERIFY_CHANGE_EMAIL, verifyChangeEmail);
}

export function* watchChangeAdminAvatar() {
  yield takeEvery(CHANGE_ADMIN_AVATAR, changeAdminAvatar);
}

function* settingsSaga() {
  yield all([
    fork(watchGetAdminProfile),
    fork(watchUpdateAdminProfile),
    fork(watchChangeAdminPassword),
    fork(watchChangeAdminEmail),
    fork(watchVerifyChangeEmail),
    fork(watchChangeAdminAvatar)
  ]);
}

export default settingsSaga;
