export const NOTIFICATIONS_TYPES = {
  ACTIVE: "ACTIVE",
  EVERYONE_ACTIVE: "EVERYONE_ACTIVE",
  STUDENTS_ACTIVE: "STUDENTS_ACTIVE",
  SUBSCRIBERS_ACTIVE: "SUBSCRIBERS_ACTIVE",
  STUDENTS_INACTIVE: "STUDENTS_INACTIVE",
  SUBSCRIBERS_INACTIVE: "SUBSCRIBERS_INACTIVE",
};

export const NOTIFICATIONS_RADIO = [
  {
    label: "For everyone",
    value: NOTIFICATIONS_TYPES.EVERYONE_ACTIVE,
  },
  {
    label: "For students",
    value: NOTIFICATIONS_TYPES.STUDENTS_ACTIVE,
  },
  {
    label: "For subscribers",
    value: NOTIFICATIONS_TYPES.SUBSCRIBERS_ACTIVE,
  },
];

export const NOTIFICATIONS_REMINDER_RADIO = [
  {
    label: "For students",
    value: NOTIFICATIONS_TYPES.STUDENTS_INACTIVE,
  },
  {
    label: "For subscribers",
    value: NOTIFICATIONS_TYPES.SUBSCRIBERS_INACTIVE,
  },
];

export const getCreateNofificationSuccessText = (type) => {
  const forText =
    NOTIFICATIONS_RADIO.find(
      ({ value }) => value === type
    )?.label.toLowerCase() || "";

  return `New notification ${forText} has been successfully created and sent to users`;
};
