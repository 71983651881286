import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import classNames from "classnames";
import { connect } from "react-redux";

import { getNotPurchasedUsersCourses } from "../../../store/dashboard/Users/actions";
import Checkbox from "../../Common/checkbox";
import {
  DEFAULT_COURSES_LIMIT,
  DEFAULT_OFFSET,
} from "../../../constants/pagination";
import InfiniteScroll from "../../Common/InfinitScroll";

import styles from "./index.module.scss";

const AddCourseModal = ({
  isOpen,
  onCancel,
  onConfirm,
  id,
  getNotPurchasedUsersCourses,
  coursesState,
}) => {
  const {
    notPurchasedCoursesLoading,
    notPurchasedCoursesData,
    notPurchasedCoursesPagination,
  } = coursesState;

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [items, setItems] = useState([]);

  const scrollRef = useRef(null);

  const disableState = useMemo(
    () => notPurchasedCoursesLoading || !selectedCourses.length,
    [notPurchasedCoursesLoading, selectedCourses]
  );

  const disableLoadState = useMemo(
    () =>
      notPurchasedCoursesLoading ||
      notPurchasedCoursesPagination.nextOffset >
        notPurchasedCoursesPagination.totalCount,
    [notPurchasedCoursesLoading, notPurchasedCoursesPagination]
  );

  useEffect(() => {
    if (isOpen) {
      setSelectedCourses([]);
      setItems([]);
      getNotPurchasedUsersCourses(id, {
        limit: DEFAULT_COURSES_LIMIT,
        offset: DEFAULT_OFFSET,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (notPurchasedCoursesData.length && !notPurchasedCoursesLoading) {
      setItems((prevState) => [...prevState, ...notPurchasedCoursesData]);
    }
  }, [notPurchasedCoursesData, notPurchasedCoursesLoading]);

  const handleChangeSelectedCourses = (courseId) => {
    setSelectedCourses((prevState) => {
      if (prevState.includes(courseId)) {
        return prevState.filter((option) => option !== courseId);
      }
      return [...prevState, courseId];
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      className={classNames("confirmation-modal", styles.modalContainer)}
      toggle={onCancel}
    >
      <ModalHeader className={styles.modalHead} toggle={onCancel}>
        <div className={styles.modalTitle}>Add course</div>
      </ModalHeader>
      <div className={styles.modalSubtitle}>
        Selected courses will become open to the user and they will be able to
        learn included lessons
      </div>
      <ModalBody className={styles.modalBody}>
        {!!items.length && (
          <InfiniteScroll
            loadMore={() =>
              getNotPurchasedUsersCourses(id, {
                limit: DEFAULT_COURSES_LIMIT,
                offset: notPurchasedCoursesPagination.nextOffset,
              })
            }
            limit={DEFAULT_COURSES_LIMIT}
            totalCount={notPurchasedCoursesPagination.totalCount}
            nextOffset={notPurchasedCoursesPagination.nextOffset}
            ref={scrollRef}
            disabled={disableLoadState}
          >
            {items.map((course) => (
              <Checkbox
                value={course.id}
                label={course.title}
                key={`course-${course.id}`}
                onChange={() => handleChangeSelectedCourses(course.id)}
                checked={selectedCourses.includes(course.id)}
              />
            ))}
          </InfiniteScroll>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row justify-content-end">
          <Button
            color="secondary"
            onClick={onCancel}
            className={styles.cancelBtn}
          >
            Cancel
          </Button>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <Button
            className={styles.confirmBtn}
            color="primary"
            onClick={() => onConfirm(selectedCourses)}
            disabled={disableState}
          >
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = ({ Users: coursesState }) => ({
  coursesState,
});

export default connect(mapStateToProps, { getNotPurchasedUsersCourses })(
  AddCourseModal
);
