import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";

import formatIndex from "../../../../helpers/formatIndex";
import useModal from "../../../../hooks/useModal";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import { removeLesson } from "../../../../store/dashboard/Lessons/actions";

import styles from "../../dashboard.module.scss";

const DELETE_MODAL_TITLE = "Delete lessons";
const DELETE_MODAL_DESCRIPTION =
  "This lesson will be deleted from the course. Are you sure you want to delete it?";

const LessonTableRow = ({ lesson, index, removeLesson }) => {
  const [modalVisible, toggleModal] = useModal();

  const handleConfirmRemove = () => {
    toggleModal();
    removeLesson(lesson.id);
  };
  return (
    <>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <tr>
        <th
          scope="row"
          className={classNames(styles.tableTitle, "align-middle")}
        >
          {formatIndex(index + 1)}
        </th>
        <td className={classNames(styles.tableTitle, "align-middle")}>
          {lesson.title}
        </td>
        <td className="align-middle font-size-20 w-100px">
          <Link className=" mr-3" to={`/lessons/${lesson.id}`}>
            <i className="bx bx-edit text-grey" />
          </Link>
          <Link to="#" onClick={() => toggleModal()}>
            <i className="bx bx-trash text-grey" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default connect(() => ({}), { removeLesson })(LessonTableRow);
