import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Badge, Button, Card } from "reactstrap";

import {
  addCourseToUser,
  getUsersCourses,
  removeCourseFromUser,
  updateCourseListClear,
} from "../../../../store/actions";
import Table from "../../../../components/Table";
import { USER_LIST_LIMIT } from "../../../../constants/paginationConstants";
import useModal from "../../../../hooks/useModal";
import AddCourseModal from "../../../../components/Modals/AddCourseModal";
import Icon from "../../../../components/Common/Icon";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import getDeleteCourseModalDescription from "../../../../helpers/getDeleteCourseModalDescription";
import formatTabelCell from "../../../../helpers/formatTabelCell";
import getCouponStatus from "../helpers/getCouponStatus";

const rowStyles = {
  minWidth: "70%",
  width: "70%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const DELETE_MODAL_TITLE = "Delete";

function Courses({
  coursesState,
  getUsersCourses,
  id,
  addCourseToUser,
  updateCourseListClear,
  removeCourseFromUser,
}) {
  const [offset, setOffset] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [modalVisible, toggleModal] = useModal();
  const [deleteModalVisible, toggleDeleteModal] = useModal();
  const {
    coursesData,
    coursesLoading,
    coursesPagination,
    notPurchasedCoursesLoading,
    needUpdateCourseList,
  } = coursesState;

  function handleTableChange(type, { page }) {
    setOffset(USER_LIST_LIMIT * (page - 1));
  }

  const tableStructure = () => {
    return [
      {
        dataField: "title",
        text: "Course title",
        style: rowStyles,
        formatter: (cellContent) => <span>{formatTabelCell(cellContent)}</span>,
      },
      {
        style: {
          width: "20%",
        },
        dataField: "addingType",
        text: "Status",
        formatter: (cellContent) => {
          const status = getCouponStatus({ addingType: cellContent });
          const color = status === "Added" ? "primary" : "success";

          return (
            <Badge
              className={`badge-soft-${color} ${
                color === "primary"
                  ? "user-list__blue user-list--list__badge font-size-12"
                  : "user-list--list__badge"
              }`}
            >
              {status}
            </Badge>
          );
        },
      },
      {
        text: "",
        dataField: "id",
        headerFormatter: () => (
          <section className="d-flex flex-row justify-content-center">
            Action
          </section>
        ),
        formatter: (cellContent, rowContent) => (
          <>
            <Button
              onClick={() => setSelectedCourse(rowContent)}
              color="outline"
              type="button"
              className="w-100 user-details--courses__trash-icon reset-button trash-button-hover"
            >
              <Icon icon="trash" />
            </Button>
          </>
        ),
      },
    ];
  };

  useEffect(() => {
    if (!!selectedCourse) {
      toggleDeleteModal(true);
    }
  }, [selectedCourse]);

  const handleCancelRemove = () => {
    toggleDeleteModal(false);
    setSelectedCourse(null);
  };

  const handleConfirmRemove = () => {
    removeCourseFromUser(id, [selectedCourse.id]);
    handleCancelRemove();
  };

  const fetchList = () => {
    getUsersCourses(id, { offset, limit: USER_LIST_LIMIT });
  };

  useEffect(() => {
    fetchList();
  }, [offset, id]);

  useEffect(() => {
    if (needUpdateCourseList) {
      fetchList();
      updateCourseListClear();
    }
  }, [needUpdateCourseList]);

  const handleAddCourseModalConfirm = useCallback(
    (coursesIds) => {
      if (!notPurchasedCoursesLoading) {
        addCourseToUser(id, coursesIds);
        toggleModal(false);
      }
    },
    [notPurchasedCoursesLoading]
  );

  return (
    <>
      <Card className="user-details--courses">
        <section className="d-flex flex-row">
          <span className="user-details--courses__title">
            Purchased courses
          </span>
          <Button
            disabled={notPurchasedCoursesLoading || coursesLoading}
            className="reset-button"
            color="outline"
            type="button"
            onClick={() => toggleModal(true)}
          >
            <span className="user-details--courses__add add-button-hover">
              +Add
            </span>
          </Button>
        </section>

        <Table
          dataStructure={tableStructure}
          data={coursesData}
          FiltersComponent={() => <></>}
          handleTableChange={handleTableChange}
          totalCount={coursesPagination.totalCount}
          limit={USER_LIST_LIMIT}
          offset={offset}
          loading={coursesLoading}
          isRowClick={false}
          placeholderText="The user doesn't have access to any courses"
          placeholderClassName="user-details--courses--placeholder"
          containerClassName="m-0 p-0"
        />
      </Card>
      <AddCourseModal
        id={id}
        isOpen={modalVisible}
        onCancel={() => toggleModal(false)}
        onConfirm={handleAddCourseModalConfirm}
      />
      <ConfirmationDialog
        isOpen={deleteModalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={getDeleteCourseModalDescription(selectedCourse)}
        cancelButtonText="Cancel"
        confirmButtonText="Yes"
        onCancel={handleCancelRemove}
        onConfirm={handleConfirmRemove}
        containerClassname="delete-course-modal__container"
        confirmBtnClassName="delete-course-modal__confirm-btn"
        altBtnStyle
      />
    </>
  );
}

const mapStateToProps = ({ Users }) => {
  return {
    coursesState: Users,
  };
};

export default connect(mapStateToProps, {
  getUsersCourses,
  addCourseToUser,
  updateCourseListClear,
  removeCourseFromUser,
})(Courses);
