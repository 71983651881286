import {
  GET_LIST_ADMINS,
  GET_LIST_ADMINS_SUCCESS,
  GET_LIST_ADMINS_ERROR,
  CHANGE_ADMIN_STATUS,
  CHANGE_ADMIN_STATUS_SUCCESS,
  CHANGE_ADMIN_STATUS_ERROR,
  NEED_UPDATE_ADMIN_LIST_CLEAR,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  CREATE_ADMIN_CLEAR,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  data: [],
  created: null,
  needUpdateList: false,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_ADMINS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_ADMINS_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_ADMINS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_ADMIN_STATUS_SUCCESS:
      state = {
        ...state,
        needUpdateList: true,
        loading: false,
      };
      break;
    case CHANGE_ADMIN_STATUS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_ADMIN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_ADMIN_SUCCESS:
      state = {
        ...state,
        created: action.payload.data,
        needUpdateList: true,
        loading: false,
      };
      break;
    case CREATE_ADMIN_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_ADMIN_CLEAR:
      state = {
        ...state,
        created: null,
        loading: false,
      };
      break;
    case NEED_UPDATE_ADMIN_LIST_CLEAR:
      state = {
        ...state,
        needUpdateList: false,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
