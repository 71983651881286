import {
  CREATE_COURSE,
  CREATE_COURSE_CLEAR,
  CREATE_COURSE_ERROR,
  CREATE_COURSE_SUCCESS,
  GET_LIST_COURSE,
  GET_LIST_COURSE_ERROR,
  GET_LIST_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_CLEAR,
  GET_COURSE_ERROR,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_CLEAR,
  UPDATE_COURSE_ERROR,
  REMOVE_COURSE,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_CLEAR,
  REMOVE_COURSE_ERROR,
  CHANGE_COURSE_STATUS,
  CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_COURSE_STATUS_ERROR,
  CHANGE_COURSE_STATUS_CLEAR,
  CLEAR_LIST_COURSE,
  SET_COURSE_RECOMMENDATIONS,
  SET_COURSE_RECOMMENDATIONS_SUCCESS,
  SET_COURSE_RECOMMENDATIONS_ERROR,
  SET_COURSE_RECOMMENDATIONS_CLEAR,
  GET_COURSES_FOR_RECOMMENDATIONS,
  GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS,
  GET_COURSES_FOR_RECOMMENDATIONS_ERROR,
  GET_COURSES_FOR_RECOMMENDATIONS_CLEAR,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  data: [],
  dataRecommendations: [],
  item: null,
  created: null,
  updated: null,
  removed: null,
  changedStatus: null,
  settedRecommendations: null,
  loadingTriggerCourse: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  loadingRecommendations: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_COURSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_COURSE_SUCCESS:
      state = {
        ...state,
        data: [...state.data, ...action.payload.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COURSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_COURSE_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COURSE_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case UPDATE_COURSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_COURSE_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_COURSE_CLEAR:
      state = {
        ...state,
        updated: null,
      };
      break;
    case GET_COURSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COURSE_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_COURSE_CLEAR:
      state = {
        ...state,
        data: [],
        item: null,
        loading: false,
      };
      break;
    case REMOVE_COURSE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_COURSE_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_COURSE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_COURSE_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case CHANGE_COURSE_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_COURSE_STATUS_SUCCESS:
      state = {
        ...state,
        data: state.data.map((item) => item.id === action.payload.id
          ? action.payload
          : item
        ),
        changedStatus: action.payload,
        loading: false,
      };
      break;
    case CLEAR_LIST_COURSE:
      state = { ...initialState };
      break;
    case CHANGE_COURSE_STATUS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_COURSE_STATUS_CLEAR:
      state = {
        ...state,
        changedStatus: null,
      };
      break;
    case SET_COURSE_RECOMMENDATIONS:
      state = {
        ...state,
        loadingTriggerCourse: action.payload.courseId,
        loading: true,
      };
      break;
    case SET_COURSE_RECOMMENDATIONS_SUCCESS:
      state = {
        ...state,
        data: state.data.map((item) => item.id === action.payload.id
          ? action.payload
          : item
        ),
        settedRecommendations: action.payload,
        loading: false,
      };
      break;
    case SET_COURSE_RECOMMENDATIONS_ERROR:
      state = {
        ...state,
        loadingTriggerCourse: null,
        loading: false,
      };
      break;
    case SET_COURSE_RECOMMENDATIONS_CLEAR:
      state = {
        ...state,
        settedRecommendations: null,
        loadingTriggerCourse: null,
      };
      break;
    case GET_COURSES_FOR_RECOMMENDATIONS:
      state = {
        ...state,
        loadingRecommendations: true,
      }
      break;
    case GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS:
      state = {
        ...state,
        dataRecommendations: action.payload,
        loadingRecommendations: false
      }
      break
    case GET_COURSES_FOR_RECOMMENDATIONS_ERROR:
      state = {
        ...state,
        loadingRecommendations: false,
      };
      break;
    case GET_COURSES_FOR_RECOMMENDATIONS_CLEAR:
      state = {
        ...state,
        dataRecommendations: []
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
