export const GET_LIST_COUPONS = "GET_LIST_COUPONS";
export const GET_LIST_COUPONS_SUCCESS = "GET_LIST_COUPONS_SUCCESS";
export const GET_LIST_COUPONS_ERROR = "GET_LIST_COUPONS_ERROR";

export const GET_LIST_COUPONS_SUBSCRIPTION = "GET_LIST_COUPONS_SUBSCRIPTION";
export const GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS = "GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS";
export const GET_LIST_COUPONS_SUBSCRIPTION_ERROR = "GET_LIST_COUPONS_SUBSCRIPTION_ERROR";

export const CREATE_COUPON = "CREATE_COUPON";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_CLEAR = "CREATE_COUPON_CLEAR";
export const CREATE_COUPON_ERROR = "CREATE_COUPON_ERROR";

export const CREATE_COUPON_SUBSCRIPTION = "CREATE_COUPON_SUBSCRIPTION";
export const CREATE_COUPON_SUBSCRIPTION_SUCCESS = "CREATE_COUPON_SUBSCRIPTION_SUCCESS";
export const CREATE_COUPON_SUBSCRIPTION_CLEAR = "CREATE_COUPON_SUBSCRIPTION_CLEAR";
export const CREATE_COUPON_SUBSCRIPTION_ERROR = "CREATE_COUPON_SUBSCRIPTION_ERROR";

export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SUCCESS = "GET_COUPON_SUCCESS";
export const GET_COUPON_ERROR = "GET_COUPON_ERROR";
export const GET_COUPON_CLEAR = "GET_COUPON_CLEAR";

export const REMOVE_COUPON = "REMOVE_COUPON";
export const REMOVE_COUPON_SUCCESS = "REMOVE_COUPON_SUCCESS";
export const REMOVE_COUPON_ERROR = "REMOVE_COUPON_ERROR";
export const REMOVE_COUPON_CLEAR = "REMOVE_COUPON_CLEAR";

export const GET_LIST_COUPON_USAGES = "GET_LIST_COUPON_USAGES";
export const GET_LIST_COUPON_USAGES_SUCCESS = "GET_LIST_COUPON_USAGES_SUCCESS";
export const GET_LIST_COUPON_USAGES_ERROR = "GET_LIST_COUPON_USAGES_ERROR";

export const GET_SUBSCRIPTION_COUPON = "GET_SUBSCRIPTION_COUPON";
export const GET_SUBSCRIPTION_COUPON_SUCCESS = "GET_SUBSCRIPTION_COUPON_SUCCESS";
export const GET_SUBSCRIPTION_COUPON_ERROR = "GET_SUBSCRIPTION_COUPON_ERROR";
export const GET_SUBSCRIPTION_COUPON_CLEAR = "GET_SUBSCRIPTION_COUPON_CLEAR";

export const UPDATE_SUBSCRIPTION_COUPON = "UPDATE_SUBSCRIPTION_COUPON";
export const UPDATE_SUBSCRIPTION_COUPON_SUCCESS = "UPDATE_SUBSCRIPTION_COUPON_SUCCESS";
export const UPDATE_SUBSCRIPTION_COUPON_ERROR = "UPDATE_SUBSCRIPTION_COUPON_ERROR";
export const UPDATE_SUBSCRIPTION_COUPON_CLEAR = "UPDATE_SUBSCRIPTION_COUPON_CLEAR";

export const EDIT_COUPON_SUBSCRIPTION = "EDIT_COUPON_SUBSCRIPTION";
export const EDIT_COUPON_SUBSCRIPTION_SUCCESS = "EDIT_COUPON_SUBSCRIPTION_SUCCESS";
export const EDIT_COUPON_SUBSCRIPTION_ERROR = "EDIT_COUPON_SUBSCRIPTION_ERROR";

export const DELETE_COUPON_SUBSCRIPTION = "DELETE_COUPON_SUBSCRIPTION";
export const DELETE_COUPON_SUBSCRIPTION_SUCCESS = "DELETE_COUPON_SUBSCRIPTION_SUCCESS";
export const DELETE_COUPON_SUBSCRIPTION_ERROR = "DELETE_COUPON_SUBSCRIPTION_ERROR";
