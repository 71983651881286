import {
  CREATE_COURSE_CATEGORY,
  CREATE_COURSE_CATEGORY_CLEAR,
  CREATE_COURSE_CATEGORY_ERROR,
  CREATE_COURSE_CATEGORY_SUCCESS,
  GET_COURSE_CATEGORY,
  GET_COURSE_CATEGORY_ERROR,
  GET_COURSE_CATEGORY_SUCCESS,
  UPDATE_COURSE_CATEGORY,
  UPDATE_COURSE_CATEGORY_ERROR,
  UPDATE_COURSE_CATEGORY_SUCCESS,
  GET_LIST_COURSE_CATEGORIES,
  GET_LIST_COURSE_CATEGORIES_ERROR,
  GET_LIST_COURSE_CATEGORIES_SUCCESS,
  GET_COURSE_CATEGORY_CLEAR,
  REMOVE_COURSE_CATEGORY,
  REMOVE_COURSE_CATEGORY_SUCCESS,
  REMOVE_COURSE_CATEGORY_ERROR,
  REMOVE_COURSE_CATEGORY_CLEAR,
} from "./actionTypes";

export const getListCoursesCategories = (payload) => {
  return {
    payload,
    type: GET_LIST_COURSE_CATEGORIES,
  };
};
export const getListCoursesCategoriesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COURSE_CATEGORIES_SUCCESS,
  };
};
export const getListCoursesCategoriesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_COURSE_CATEGORIES_ERROR,
  };
};

export const createCourseCategory = (category, history) => {
  return {
    payload: { category, history },
    type: CREATE_COURSE_CATEGORY,
  };
};

export const createCourseCategorySuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_COURSE_CATEGORY_SUCCESS,
  };
};

export const createCourseCategoryError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_COURSE_CATEGORY_ERROR,
  };
};

export const clearCourseCategoryCreated = () => {
  return {
    type: CREATE_COURSE_CATEGORY_CLEAR,
  };
};

export const getCourseCategory = (categoryId) => {
  return {
    payload: { categoryId },
    type: GET_COURSE_CATEGORY,
  };
};

export const getCourseCategorySuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_COURSE_CATEGORY_SUCCESS,
  };
};

export const getCourseCategoryError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_COURSE_CATEGORY_ERROR,
  };
};

export const clearFetchedCourseCategory = () => {
  return {
    type: GET_COURSE_CATEGORY_CLEAR,
  };
};

export const updateCourseCategory = (categoryId, model, history) => {
  return {
    payload: { categoryId, model, history },
    type: UPDATE_COURSE_CATEGORY,
  };
};

export const updateCourseCategorySuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_COURSE_CATEGORY_SUCCESS,
  };
};

export const updateCourseCategoryError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_COURSE_CATEGORY_ERROR,
  };
};

export const removeCourseCategory = (categoryId) => {
  return {
    payload: { categoryId },
    type: REMOVE_COURSE_CATEGORY,
  };
};

export const removeCourseCategorySuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_COURSE_CATEGORY_SUCCESS,
  };
};

export const removeCourseCategoryError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_COURSE_CATEGORY_ERROR,
  };
};

export const removeCourseCategoryClear = () => {
  return {
    type: REMOVE_COURSE_CATEGORY_CLEAR,
  };
};
