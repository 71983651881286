import React from 'react'
import { ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { AvForm, AvField, AvCheckbox, AvCheckboxGroup } from "availity-reactstrap-validation";
import useAvForm from "../../../../hooks/useAvForm";
import formsValidators from "../../../../validators/form.validators";

export default function SetPasswordModal({ toggle, submit }) {

    const [
        formRef,
        hasFormError,
        handleFormChange,
        isDirty,
    ] = useAvForm();

    return (
        <AvForm
            onValidSubmit={(event, { newPassword }) => {
                    return submit(newPassword)
                }
            }
            ref={formRef}
        >
            <ModalHeader toggle={toggle}>Set New Password</ModalHeader>
            <ModalBody className='user-details--basic-info__modal-body pb-0'>
                <section className="d-flex flex-column">
                    

                    <section className="mt-3">
                        <AvField
                            id='newPassword'
                            name='newPassword'
                            label="Set New Password"
                            type="password"
                            className="form-control"
                            placeholder="Set New Password"
                            onChange={() => handleFormChange()}
                            onBlur={() => handleFormChange()}
                            validate={formsValidators.password}
                        />
                    </section>
                </section>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" size="lg" onClick={toggle} className='user-details--basic-info__secondary-button'>
                    Cancel
                </Button>
                <Button
                    className='user-details--basic-info__primary-button'
                    color="primary"
                    size="lg"
                    type="submit"
                    disabled={!isDirty || hasFormError}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </AvForm >
    );
}