import React from "react";
import { CardTitle } from "reactstrap";
import CreateAdminBtn from "./CreateAdminBtn";

const TableHeader = () => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
      <CardTitle className="font-size-16 mb-0">Admins management</CardTitle>
      <CreateAdminBtn />
    </div>
  );
};

export default TableHeader;
