import {
  CREATE_COURSE,
  CREATE_COURSE_CLEAR,
  CREATE_COURSE_ERROR,
  CREATE_COURSE_SUCCESS,
  GET_LIST_COURSE,
  GET_LIST_COURSE_ERROR,
  GET_LIST_COURSE_SUCCESS,
  GET_COURSE,
  GET_COURSE_SUCCESS,
  GET_COURSE_CLEAR,
  GET_COURSE_ERROR,
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_CLEAR,
  UPDATE_COURSE_ERROR,
  REMOVE_COURSE,
  REMOVE_COURSE_SUCCESS,
  REMOVE_COURSE_CLEAR,
  REMOVE_COURSE_ERROR,
  CHANGE_COURSE_STATUS,
  CHANGE_COURSE_STATUS_SUCCESS,
  CHANGE_COURSE_STATUS_ERROR,
  CHANGE_COURSE_STATUS_CLEAR,
  CLEAR_LIST_COURSE,
  SET_COURSE_RECOMMENDATIONS,
  SET_COURSE_RECOMMENDATIONS_SUCCESS,
  SET_COURSE_RECOMMENDATIONS_ERROR,
  SET_COURSE_RECOMMENDATIONS_CLEAR,
  GET_COURSES_FOR_RECOMMENDATIONS,
  GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS,
  GET_COURSES_FOR_RECOMMENDATIONS_ERROR,
  GET_COURSES_FOR_RECOMMENDATIONS_CLEAR,
} from "./actionTypes";

export const getListCourses = (payload) => {
  return {
    payload,
    type: GET_LIST_COURSE,
  };
};
export const getListCoursesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COURSE_SUCCESS,
  };
};
export const getListCoursesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_COURSE_ERROR,
  };
};
export const clearListCourses = () => ({
  type: CLEAR_LIST_COURSE,
})

export const createCourse = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_COURSE,
  };
};

export const createCourseSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_COURSE_SUCCESS,
  };
};

export const createCourseError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_COURSE_ERROR,
  };
};

export const clearCourseCreated = () => {
  return {
    type: CREATE_COURSE_CLEAR,
  };
};

export const getCourse = (courseId) => {
  return {
    payload: { courseId },
    type: GET_COURSE,
  };
};

export const getCourseSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_COURSE_SUCCESS,
  };
};

export const getCourseError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_COURSE_ERROR,
  };
};

export const clearCourseFetched = () => {
  return {
    type: GET_COURSE_CLEAR,
  };
};

export const updateCourse = (data, history, courseCategoryId) => {
  return {
    payload: { data, history, courseCategoryId },
    type: UPDATE_COURSE,
  };
};

export const updateCourseSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_COURSE_SUCCESS,
  };
};

export const updateCourseError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_COURSE_ERROR,
  };
};

export const clearCourseUpdated = () => {
  return {
    type: UPDATE_COURSE_CLEAR,
  };
};

export const removeCourse = (data, history) => {
  return {
    payload: { data, history },
    type: REMOVE_COURSE,
  };
};

export const removeCourseSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_COURSE_SUCCESS,
  };
};

export const removeCourseError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_COURSE_ERROR,
  };
};

export const clearCourseRemoved = () => {
  return {
    type: REMOVE_COURSE_CLEAR,
  };
};

export const changeCourseStatus = (id) => {
  return {
    payload: { id },
    type: CHANGE_COURSE_STATUS,
  };
};

export const changeCourseStatusSuccess = ({ data }) => {
  return {
    payload: data,
    type: CHANGE_COURSE_STATUS_SUCCESS,
  };
};

export const changeCourseStatusError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CHANGE_COURSE_STATUS_ERROR,
  };
};

export const changeCourseStatusClear = () => {
  return {
    type: CHANGE_COURSE_STATUS_CLEAR,
  };
};

export const setCourseRecommendations = (courseId, data) => {
  return {
    payload: { courseId, data },
    type: SET_COURSE_RECOMMENDATIONS,
  };
};

export const setCourseRecommendationsSuccess = ({ data }) => {
  return {
    payload: data,
    type: SET_COURSE_RECOMMENDATIONS_SUCCESS,
  };
};

export const setCourseRecommendationsError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: SET_COURSE_RECOMMENDATIONS_ERROR,
  };
};

export const setCourseRecommendationsClear = () => {
  return {
    type: SET_COURSE_RECOMMENDATIONS_CLEAR,
  };
};

export const getCoursesForRecommendations = (pagination) => {
  return {
    payload: pagination,
    type: GET_COURSES_FOR_RECOMMENDATIONS,
  };
};

export const getCoursesForRecommendationsSuccess = (data) => {
  return {
    payload: data,
    type: GET_COURSES_FOR_RECOMMENDATIONS_SUCCESS,
  };
};

export const getCoursesForRecommendationsError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_COURSES_FOR_RECOMMENDATIONS_ERROR,
  };
};

export const getCoursesForRecommendationsClear = () => {
  return {
    type: GET_COURSES_FOR_RECOMMENDATIONS_CLEAR,
  };
};