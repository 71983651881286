import React, { useCallback, useEffect, useRef, Fragment } from "react";
import { Alert, Card, Col, FormGroup, Label, Row } from "reactstrap";
import Dropzone from "react-dropzone";

import formsValidators from "../../../validators/form.validators";
import useFilesToRemove from "../../../hooks/useFilesToRemove";
import noop from "../../../helpers/noop";
import {
  ERROR_MAX_VIDEOS,
  MAX_FILES_COUNT_VIDEOS,
} from "../../../constants/dropzone";
import PlaceholderVideo from "../../Common/PlaceholderVideo";
import FormGroupFileImage from "./FormGroupFileImage/FormGroupFileImage";

const FormGroupVideo = ({
  error,
  selectedVideos,
  handleAcceptedVideos,
  setSelectedVideos,
  thumbnailError,
  selectedThumbnailImages,
  handleTumbunalAcceptedFiles,
  setSelectedThumbnailImages,
  loadedThumbnailError,
  selectedLoadedThumbnailImages,
  handleLoadedTumbunalAcceptedFiles,
  setSelectedLoadedThumbnailImages,
  initialFile = null,
  initialVideoThumbnail = null,
  updateInitialVideoThumbnail = noop,
  videosUploaded = null,
  label = "",
  multiple = false,
  updateDeleteVideosIds = noop,
  setError = noop,
  withThumbnail = false,
  errorIndex,
  errorLoadedIndex,
}) => {
  const initialVideoThumbnailCash = useRef(initialVideoThumbnail || null);
  const needResetThumbnail = useRef(true);

  const [filesLoaded, remove, deleteVideosIds] =
    useFilesToRemove(videosUploaded);
  const handleRemoveClicked = (id, index) => {
    remove(id);
    setSelectedLoadedThumbnailImages(
      selectedLoadedThumbnailImages.filter(
        (image, imageIndex) => imageIndex !== index
      )
    );
  };
  const sliceVideosToLimit = (videos) => {
    setSelectedVideos(
      videos.slice(0, MAX_FILES_COUNT_VIDEOS - filesLoaded.length)
    );
  };

  useEffect(() => {
    if (
      multiple &&
      selectedVideos.length > MAX_FILES_COUNT_VIDEOS - filesLoaded.length
    ) {
      sliceVideosToLimit(selectedVideos);
      setError(ERROR_MAX_VIDEOS);
    }
  }, [selectedVideos, multiple]);

  useEffect(() => {
    if (deleteVideosIds.length) {
      updateDeleteVideosIds(deleteVideosIds);
    }
  }, [deleteVideosIds]);

  const getTumbunalSrc = useCallback(
    (file, index) => {
      if (initialVideoThumbnail && !selectedThumbnailImages.length) {
        return initialVideoThumbnail.preview;
      } else if (selectedThumbnailImages[index]) {
        return selectedThumbnailImages[index].preview;
      }
      return file.preview || file.thumbnailImage?.link;
    },
    [initialVideoThumbnail, selectedThumbnailImages]
  );

  const getLoadedThumbnaiSrc = useCallback(
    (file, index) => {
      if (selectedLoadedThumbnailImages[index]) {
        return selectedLoadedThumbnailImages[index].preview;
      }
      return file.preview || file.thumbnailImage?.link;
    },
    [selectedLoadedThumbnailImages]
  );

  const deleteHandler = useCallback(
    (file, index) => {
      setSelectedVideos(selectedVideos.filter((video) => video !== file));
      if (multiple) {
        setSelectedThumbnailImages(
          selectedThumbnailImages.filter(
            (image, imageIndex) => imageIndex !== index
          )
        );
      } else {
        if (initialVideoThumbnailCash.current) {
          updateInitialVideoThumbnail(initialVideoThumbnailCash.current);
          handleTumbunalAcceptedFiles([initialVideoThumbnailCash.current], []);
          needResetThumbnail.current = false;
        }
      }
    },
    [multiple, selectedThumbnailImages, selectedVideos]
  );

  useEffect(() => {
    if (!!initialFile && selectedVideos[0] !== initialFile && !multiple) {
      if (needResetThumbnail.current) {
        updateInitialVideoThumbnail(null);
        setSelectedThumbnailImages([]);
      }
    } else {
      needResetThumbnail.current = true;
    }
  }, [selectedVideos, initialFile, multiple]);

  return (
    <>
      <FormGroup className={withThumbnail ? "mb-0" : "mb-4"} row>
        <Label className="col-form-label col-lg-2">{label}</Label>
        <Col lg="10">
          {filesLoaded && (
            <>
              {filesLoaded.map((f, i) => (
                <Fragment key={`${i}-file`}>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {!f.preview && !f?.thumbnailImage?.link ? (
                            <div className="avatar-sm rounded">
                              <PlaceholderVideo />
                            </div>
                          ) : (
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={getLoadedThumbnaiSrc(f, i)}
                            />
                          )}
                        </Col>
                        <Col>
                          <p className="text-muted font-weight-bold mb-0">
                            {f.name}
                          </p>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        {initialFile !== selectedVideos[0] && (
                          <Col className="col-auto">
                            <i
                              className="bx bx-trash text-grey font-size-20 mr-3 cursor-pointer"
                              onClick={() => handleRemoveClicked(f.id, i)}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Card>
                  {withThumbnail && (
                    <FormGroupFileImage
                      multiple={multiple}
                      label="Video thumbnail"
                      error={loadedThumbnailError}
                      selectedImages={selectedLoadedThumbnailImages}
                      handleAcceptedImages={handleLoadedTumbunalAcceptedFiles}
                      setSelectedImages={setSelectedLoadedThumbnailImages}
                      initialFile={f?.thumbnailImage}
                      index={i}
                      errorIndex={errorLoadedIndex}
                    />
                  )}
                </Fragment>
              ))}
            </>
          )}
          <Dropzone
            multiple={multiple}
            maxFiles={MAX_FILES_COUNT_VIDEOS - filesLoaded.length}
            dictMaxFilesExceeded="sdasd"
            maxSize={formsValidators.file.video.maxSize.value}
            accept={formsValidators.file.video.formats.value
              .map((format) => "video/" + format)
              .join(",")}
            onDrop={(acceptedFiles, fileRejections) => {
              handleAcceptedVideos(acceptedFiles, fileRejections, multiple);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="dz-message needsclick">
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload.</h4>
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedVideos.map((f, i) => {
              return (
                <Fragment key={`${i}-file`}>
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {initialFile === selectedVideos[0] ? (
                            f.preview === null &&
                            !initialVideoThumbnail?.preview ? (
                              <div className="avatar-sm rounded">
                                <PlaceholderVideo />
                              </div>
                            ) : (
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={f.name}
                                src={getTumbunalSrc(f, i)}
                              />
                            )
                          ) : (
                            <video
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              src={f.preview}
                            />
                          )}
                        </Col>
                        <Col>
                          <p className="text-muted font-weight-bold mb-0">
                            {f.name}
                          </p>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        {initialFile !== selectedVideos[0] && (
                          <Col className="col-auto">
                            <i
                              className="bx bx-trash text-grey font-size-20 mr-3 cursor-pointer"
                              onClick={() => deleteHandler(f, i)}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </Card>
                  {withThumbnail && (
                    <FormGroupFileImage
                      multiple={multiple}
                      label="Video thumbnail"
                      error={thumbnailError}
                      selectedImages={selectedThumbnailImages}
                      handleAcceptedImages={handleTumbunalAcceptedFiles}
                      setSelectedImages={setSelectedThumbnailImages}
                      initialFile={initialVideoThumbnail}
                      index={i}
                      errorIndex={errorIndex}
                    />
                  )}
                </Fragment>
              );
            })}
          </div>
          {error && (
            <Alert className="mt-3" color="danger">
              {error}
            </Alert>
          )}
        </Col>
      </FormGroup>
    </>
  );
};

export default FormGroupVideo;
