import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyEmailChange from "../pages/Authentication/VerifyEmailChange";
import RestorePassword from "../pages/Authentication/RestorePassword";

// Dashboard
import Users from "../pages/Dashboard/Users/index";
import Payments from "../pages/Dashboard/Payments/index";
import Comments from "../pages/Dashboard/Comments/index";
import CouponsCourse from "../pages/Dashboard/Coupons/Course";
import CouponsSubscription from "../pages/Dashboard/Coupons/Subscription";
import CouponDetails from "../pages/Dashboard/Coupons/CouponDetails";
import Sale from "../pages/Dashboard/Sale/index";
import Notifications from "../pages/Dashboard/Notifications/index";
import Training from "../pages/Dashboard/Training/index";
import CreateCourseCategory from "../pages/Dashboard/Training/create";
import UpdateCourseCategory from "../pages/Dashboard/Training/update";
import CreatePremiumArticle from "../pages/Dashboard/DJHub/Articles/create";
import UpdatePremiumArticle from "../pages/Dashboard/DJHub/Articles/update";
import Admins from "../pages/Dashboard/Admins/index";
import Settings from "../pages/Dashboard/Settings/index";
import Courses from "../pages/Dashboard/Training/Courses";
import CreateCourse from "../pages/Dashboard/Training/Courses/create";
import UpdateCourse from "../pages/Dashboard/Training/Courses/update";

import Bundles from "../pages/Dashboard/Bundles";
import CreateBundle from "../pages/Dashboard/Bundles/create";
import UpdateBundle from "../pages/Dashboard/Bundles/update";

import Rewards from "../pages/Dashboard/Rewards";
import CreateReward from "../pages/Dashboard/Rewards/create";
import UpdateReward from "../pages/Dashboard/Rewards/update";

import ProductActivationCodes from "../pages/Dashboard/ProductActivationCodes";
import CreateBulkProductActivationCodes from "../pages/Dashboard/ProductActivationCodes/createBulk";

import Lessons from "../pages/Dashboard/Training/Lessons";
import CreateLessons from "../pages/Dashboard/Training/Lessons/create";
import UpdateLesson from "../pages/Dashboard/Training/Lessons/update";
import UsersProfile from "../pages/Dashboard/Users/usersProfile";
import IndividualContent from "../pages/Dashboard/IndividualContent";
import ExpiredLink from "../pages/ExpiredLink";
import DJHubArticles from "../pages/Dashboard/DJHub/Articles/index";
import DJHubCategories from "../pages/Dashboard/DJHub/Categories/index";
import DJHubTags from "../pages/Dashboard/DJHub/Tags/index";
import DJHubDrafts from "../pages/Dashboard/DJHub/Drafts/index";
import DJHubAnalytics from '../pages/Dashboard/DJHubAnalytics';

const userRoutes = [
  { path: "/users", component: Users, exact: true, isSuperAdminRoute: true },
  {
    path: "/users/:id",
    component: UsersProfile,
    exact: true,
    isSuperAdminRoute: true,
  },
  {
    path: "/payments",
    component: Payments,
    exact: true,
    isSuperAdminRoute: true,
  },
  {
    path: "/coupons/course",
    component: CouponsCourse,
    exact: true,
    isSuperAdminRoute: true,
  },
  {
    path: "/coupons/subscription",
    component: CouponsSubscription,
    exact: true,
    isSuperAdminRoute: true,
  },
  {
    path: "/coupons/course/:id",
    component: CouponDetails,
    exact: true,
    isSuperAdminRoute: true,
  },
  {
    path: "/coupons",
    exact: true,
    component: () => <Redirect to="/coupons/course" />,
  },
  { path: "/comments", component: Comments, exact: true },
  {
    path: "/individual-content/:contentName/:id",
    component: IndividualContent,
  },
  { path: "/sale", component: Sale, isSuperAdminRoute: true },
  { path: "/notifications", component: Notifications, isSuperAdminRoute: true },
  { path: "/training/create", component: CreateCourseCategory },
  { path: "/training/:categoryId/update", component: UpdateCourseCategory },
  { path: "/training/:categoryId/create", component: CreateCourse },
  { path: "/training/:categoryId", component: Courses },
  { path: "/training", component: Training },
  { path: "/courses/:courseId/lessons", component: Lessons },
  { path: "/courses/:courseId/create", component: CreateLessons },
  { path: "/courses/:courseId", component: UpdateCourse },
  { path: "/bundles/create", component: CreateBundle },
  { path: "/bundles/:bundleId", component: UpdateBundle },
  { path: "/bundles", component: Bundles },
  { path: "/rewards/create", component: CreateReward },
  { path: "/rewards/:rewardId", component: UpdateReward },
  { path: "/rewards", component: Rewards },
  { path: "/product-activation-codes/create-bulk", component: CreateBulkProductActivationCodes },
  // { path: "/product-activation-codes/:fileId", component: UpdateVideo },
  { path: "/product-activation-codes", component: ProductActivationCodes },

  { path: "/lessons/:lessonId", component: UpdateLesson },
  { path: "/dj-hub/tags", component: DJHubTags },
  { path: "/dj-hub/categories", component: DJHubCategories },
  { path: "/dj-hub/drafts", component: DJHubDrafts, exact: true },
  { path: "/dj-hub/articles", component: DJHubArticles, exact: true },
  { path: "/dj-hub/articles/create", component: CreatePremiumArticle },
  {
    path: "/dj-hub/articles/:articleId/update",
    component: UpdatePremiumArticle,
  },
  {
    path: "/dj-hub",
    exact: true,
    component: () => <Redirect to="/dj-hub/articles" />,
  },
  { path: "/admins", component: Admins, exact: true, isSuperAdminRoute: true },
  { path: "/settings", component: Settings },
  { path: "/djhub-analytics", component: DJHubAnalytics, isSuperAdminRoute: true },

  // this route should be at the end of all other routes
  { path: "*", exact: true, component: () => <Redirect to="/comments" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/restore-password", component: RestorePassword },
  { path: "/change-email", component: VerifyEmailChange },
  { path: "/expired-link", component: ExpiredLink },
];

export { userRoutes, authRoutes };
