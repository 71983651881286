import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Label, Col } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import { connect } from "react-redux";

import { DEFAULT_ARTICLES_LIMIT, DEFAULT_OFFSET } from "../../../constants/pagination";
import { customStyles, DropdownIndicator, MenuList } from "../../../helpers/reactSelectHelper";
import { getListPremiumArticles } from "../../../store/dashboard/PremiumArticles/actions";
import useDebounce from "../../../hooks/useDebounce";
import { ARTICLE_STATUSES } from "../../../constants/articleStatuses";
import { ARTICLE_FILTER_STATUSES } from "../../../constants/articleFilterStatuses";
import useSelect from "../../../hooks/useSelect";
import Option from './Option';

const LinkArticleField = ({
    PremiumArticles,
    getListPremiumArticles,
    validator,
    value,
    handleFormChange,
    name,
}) => {
    const [resultOptions, setResultOptions, selectRef, inputRef, handlerBlur] =
        useSelect(value, name, handleFormChange, 'id', false);
    const [searchQuery, setSearchQuery] = useState("");
    const [options, setOptions] = useState([]);
    const debouncedSearchTerm = useDebounce(searchQuery);
    const selRef = useRef(null);

    useEffect(() => {
        if (selRef.current) {
            const option = options.find(article => article.value === value);
            selRef.current.select.setValue(option);
            handleFormChange();
        }

        return () => {
            if (!value && selRef.current) {
                selRef.current.select.clearValue();
                handleFormChange();
            }
        }
    }, [value]);

    const fetchList = useCallback((
        nextPagination = {}
    ) => {
        const { limit = DEFAULT_ARTICLES_LIMIT, offset = DEFAULT_OFFSET } =
            nextPagination;
        getListPremiumArticles({
            limit,
            offset,
            filterType: ARTICLE_FILTER_STATUSES.PUBLISHED,
            q: debouncedSearchTerm || null,
            premiumArticleCategoryId: null,
        });
    }, [debouncedSearchTerm]);

    useEffect(() => {
        fetchList();
    }, [debouncedSearchTerm])

    const handleInputChange = (value) => {
        setSearchQuery(value);
    };

    const formatOptionLabel = (article) => {
        return (
            <Col lg={12} className="d-flex align-items-center my-0 px-0">{article.title}</Col>
        )
    }

    useEffect(() => {
        const { data } = PremiumArticles;
        const mappedOptions = filterArticles(data);

        setOptions(mappedOptions);
    }, [PremiumArticles]);

    const filterArticles = (articles) => articles
        .filter(article => article.status === ARTICLE_STATUSES.LIVE)
        .map(article => ({
            ...article,
            label: article?.title,
            value: article?.id,
        }));

    const generateResultOption = (changeValue) => {
        const { value } = changeValue || {};

        setResultOptions(value || null);
        handleFormChange();
    };

    return (
        <>
            <Label htmlFor={name}>Link article</Label>
            <Select
                id={name}
                isMulti={false}
                ref={selRef}
                placeholder="Select article"
                noOptionsMessage={() => <p className="d-flex my-0">No article is found</p>}
                options={options}
                formatOptionLabel={formatOptionLabel}
                onInputChange={handleInputChange}
                onChange={generateResultOption}
                onBlur={handlerBlur}
                styles={customStyles}
                isClearable
                components={{ DropdownIndicator, MenuList, Option }}
                filterOption={false}
            />
            <AvField
                hidden
                ref={inputRef}
                name={name}
                value={resultOptions}
                className="form-control"
                type="select"
                onChange={handleFormChange}
                onBlur={handleFormChange}
                validate={validator}
            />
        </>
    )
}

const mapStateToProps = ({ PremiumArticles }) => ({
    PremiumArticles,
})

export default connect(mapStateToProps, {
    getListPremiumArticles,
})(LinkArticleField);
