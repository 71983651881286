import React, { useMemo, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import styles from "./forms.module.scss";
import noop from '../../helpers/noop';
import { connect } from 'react-redux';
import Papa from 'papaparse';
import classNames from 'classnames';


const SaveBulkProductActivationCodes = ({
  model,
  saveBulkProductActivationCodesFunc,
  productActivationCodes,
  updateDirtyState = noop,
}) => {
  const pageTitle = 'Upload Product Activation Codes';
  const { loading } = productActivationCodes;
  const btnSaveLabel = 'Create';
  const [csvData, setCsvData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          setCsvData(results.data);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        },
      });
    }
  };


  const disableState = useMemo(
    () =>
      loading ||
      !csvData.length,
    [
      loading,
      csvData,
    ]
  );

  const handleFormSubmit = (event) => {
    // const model = {
    //   productActivationCodes: csvData,
    // };

    const formattedData = csvData.map((item) => {
      return {
        activationCode: item.activationCode,
        type: item.type,
        courseId: item.courseId ? parseInt(item.courseId) : null,
      };
    });

    saveBulkProductActivationCodesFunc(formattedData);
  };


  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">{pageTitle}</CardTitle>
          <FormGroup className={"mb-4"} row>
            <Col lg={2}>
              <Label
                htmlFor={'productActivationCodes'}
                className={classNames("col-form-label")}
              >
                Product Activation Codes CSV
              </Label>
          </Col>
          <Col lg={10}>
            <Input name='productActivationCodes' type="file" accept=".csv" onChange={handleFileUpload} />
          </Col>
        </FormGroup>
        </CardBody>
      </Card>
      <Row className="justify-content-end">
        <Col xs="12">
          <Link
            to={`/product-activation-codes`}
          >
            <Button color="secondary" className="mr-2">
              Cancel
            </Button>
          </Link>
          <Button
            type="button"
            color="primary"
            disabled={disableState}
            className={styles.btnSave}
            onClick={handleFormSubmit}
          >
            {btnSaveLabel}
          </Button>
        </Col>
      </Row>
    </>
    
  );
}

const mapStateToProps = ({ ProductActivationCodes }) => {
  return {
    productActivationCodes: ProductActivationCodes,
  };
};

export default connect(mapStateToProps, { })(SaveBulkProductActivationCodes);