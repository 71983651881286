import {
  CREATE_COUPON,
  CREATE_COUPON_CLEAR,
  CREATE_COUPON_ERROR,
  CREATE_COUPON_SUBSCRIPTION,
  CREATE_COUPON_SUBSCRIPTION_CLEAR,
  CREATE_COUPON_SUBSCRIPTION_ERROR,
  CREATE_COUPON_SUBSCRIPTION_SUCCESS,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUBSCRIPTION,
  DELETE_COUPON_SUBSCRIPTION_ERROR,
  DELETE_COUPON_SUBSCRIPTION_SUCCESS,
  EDIT_COUPON_SUBSCRIPTION,
  EDIT_COUPON_SUBSCRIPTION_ERROR,
  EDIT_COUPON_SUBSCRIPTION_SUCCESS,
  GET_COUPON,
  GET_COUPON_CLEAR,
  GET_COUPON_ERROR,
  GET_COUPON_SUCCESS,
  GET_LIST_COUPON_USAGES,
  GET_LIST_COUPON_USAGES_ERROR,
  GET_LIST_COUPON_USAGES_SUCCESS,
  GET_LIST_COUPONS,
  GET_LIST_COUPONS_ERROR,
  GET_LIST_COUPONS_SUBSCRIPTION,
  GET_LIST_COUPONS_SUBSCRIPTION_ERROR,
  GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS,
  GET_LIST_COUPONS_SUCCESS,
  GET_SUBSCRIPTION_COUPON,
  GET_SUBSCRIPTION_COUPON_CLEAR,
  GET_SUBSCRIPTION_COUPON_ERROR,
  GET_SUBSCRIPTION_COUPON_SUCCESS,
  REMOVE_COUPON,
  REMOVE_COUPON_CLEAR,
  REMOVE_COUPON_ERROR,
  REMOVE_COUPON_SUCCESS,
  UPDATE_SUBSCRIPTION_COUPON,
  UPDATE_SUBSCRIPTION_COUPON_ERROR,
  UPDATE_SUBSCRIPTION_COUPON_SUCCESS,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  item: null,
  created: null,
  subscriptionCoupon: null,
  removed: null,
  data: [],
  dataSubscription: [],
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  paginationSubscription: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  usages: [],
  usagesPagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  usagesLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_COUPONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_COUPONS_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_COUPONS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_LIST_COUPONS_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        dataSubscription: action.payload.data,
        paginationSubscription: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_COUPONS_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_COUPON_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_COUPON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COUPON_CLEAR:
      state = {
        ...state,
        created: null,
        loading: false,
      };
      break;
    case CREATE_COUPON_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_COUPON_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_COUPON_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_COUPON_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        created: null,
        loading: false,
      };
      break;
    case GET_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COUPON_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_COUPON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_COUPON_CLEAR:
      state = {
        ...state,
        item: null,
        loading: false,
      };
      break;
    case REMOVE_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_COUPON_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_COUPON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_COUPON_CLEAR:
      state = {
        ...state,
        removed: null,
        loading: false,
      };
      break;
    case GET_LIST_COUPON_USAGES:
      state = {
        ...state,
        usagesLoading: true,
      };
      break;
    case GET_LIST_COUPON_USAGES_SUCCESS:
      state = {
        ...state,
        usages: action.payload.data,
        usagesPagination: action.payload.pagination,
        usagesLoading: false,
      };
      break;
    case GET_LIST_COUPON_USAGES_ERROR:
      state = {
        ...state,
        usagesLoading: false,
      };
      break;
    case GET_SUBSCRIPTION_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SUBSCRIPTION_COUPON_SUCCESS:
      state = {
        ...state,
        subscriptionCoupon: action.payload,
        loading: false,
      };
      break;
    case GET_SUBSCRIPTION_COUPON_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.message,
      };
      break;
    case GET_SUBSCRIPTION_COUPON_CLEAR:
      state = {
        ...state,
        subscriptionCoupon: null,
        loading: false,
      };
      break;
    case UPDATE_SUBSCRIPTION_COUPON:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_SUBSCRIPTION_COUPON_SUCCESS:
      state = {
        ...state,
        subscriptionCoupon: action.payload,
        loading: false,
      };
      break;
    case UPDATE_SUBSCRIPTION_COUPON_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case EDIT_COUPON_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case EDIT_COUPON_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case EDIT_COUPON_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_COUPON_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_COUPON_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case DELETE_COUPON_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
      
    default:
      state = { ...state };
      break;
  }
  return state;
};
