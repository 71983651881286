export const GET_LIST_COMMENTS = "GET_LIST_COMMENTS";
export const GET_LIST_COMMENTS_SUCCESS = "GET_LIST_COMMENTS_SUCCESS";
export const GET_LIST_COMMENTS_CLEAR = "GET_LIST_COMMENTS_CLEAR";
export const GET_LIST_COMMENTS_ERROR = "GET_LIST_COMMENTS_ERROR";

export const GET_UNREAD_COUNT = "GET_UNREAD_COUNT";
export const GET_UNREAD_COUNT_SUCCESS = "GET_UNREAD_COUNT_SUCCESS";
export const GET_UNREAD_COUNT_ERROR = "GET_UNREAD_COUNT_ERROR";

export const READ_COMMENT = "READ_COMMENT";
export const READ_COMMENT_SUCCESS = "READ_COMMENT_SUCCESS";
export const READ_COMMENT_ERROR = "READ_COMMENT_ERROR";
export const READ_COMMENT_CLEAR = "READ_COMMENT_CLEAR";

export const APPROVE_COMMENT = "APPROVE_COMMENT";
export const APPROVE_COMMENT_SUCCESS = "APPROVE_COMMENT_SUCCESS";
export const APPROVE_COMMENT_ERROR = "APPROVE_COMMENT_ERROR";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
export const DELETE_COMMENT_CLEAR = "DELETE_COMMENT_CLEAR";

export const REPLY_TO_COMMENT = "REPLY_TO_COMMENT";
export const REPLY_TO_COMMENT_SUCCESS = "REPLY_TO_COMMENT_SUCCESS";
export const REPLY_TO_COMMENT_ERROR = "REPLY_TO_COMMENT_ERROR";
export const REPLY_TO_COMMENT_CLEAR = "REPLY_TO_COMMENT_CLEAR";

export const READ_ALL_COMMENTS = "READ_ALL_COMMENTS";
export const READ_ALL_COMMENTS_SUCCESS = "READ_ALL_COMMENTS_SUCCESS";
export const READ_ALL_COMMENTS_ERROR = "READ_ALL_COMMENTS_ERROR";
export const READ_ALL_COMMENTS_CLEAR = "READ_ALL_COMMENTS_CLEAR";
