import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";

import WYSISYGComponent from "../inputs/WYSISYGComponent";
import useDropzoneFacade from "../../../hooks/useDropzoneFacade";
import FormGroupImages from "../inputs/FormGroupImages";
import FormGroupVideo from "../inputs/FormGroupVideo";
import {
  ERROR_MAX_IMAGES,
  ERROR_MAX_VIDEOS,
} from "../../../constants/dropzone";

import styles from "../forms.module.scss";
import FormGroupText from '../inputs/FormGroupText';
import { AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation';
import { BLOCK_FOR } from '../../../constants/lessonsDefaults';

const previewLessonBlockValue = 'PreviewLessonBlock';

const LessonBlock = ({
  handleFormChange,
  index,
  data,
  addBlockHandler,
  updateFiles,
  setThumbnailAndVideoSuccess,
  setUpdatedThumbnails,
  isPreviewLesson,
  blockFor,
}) => {
  const [editorValue, updateValue] = useState("");
  const [deleteImagesIds, updateDeleteImagesIds] = useState([]);
  const [deleteVideosIds, updateDeleteVideosIds] = useState([]);
  const [
    error,
    selectedImages,
    handleAcceptedImages,
    setSelectedImages,
    setErrorImages,
  ] = useDropzoneFacade({
    validationErrors: { maxFiles: ERROR_MAX_IMAGES },
  });
  const [
    errorVideos,
    selectedVideos,
    handleAcceptedVideos,
    setSelectedVideos,
    setErrorVideo,
  ] = useDropzoneFacade({
    type: "video",
    validationErrors: { maxFiles: ERROR_MAX_VIDEOS },
  });

  const [
    thumbnailError,
    selectedThumbnailImages,
    handleTumbunalAcceptedFiles,
    setSelectedThumbnailImages,
    setError,
    errorFileId,
  ] = useDropzoneFacade();
  const [
    loadedThumbnailError,
    selectedLoadedThumbnailImages,
    handleLoadedTumbunalAcceptedFiles,
    setSelectedLoadedThumbnailImages,
    setLoadedError,
    errorLoadedIndex,
  ] = useDropzoneFacade();

  const blockName = useMemo(() => `block${index + 1}`, [index]);

  const addBlockClicked = (e) => {
    e.preventDefault();
    addBlockHandler();
  };

  useEffect(() => {
    const videosWithThumbnail = selectedVideos.map((video, index) => ({
      video,
      thumbnail: selectedThumbnailImages[index],
    }));

    let updatedVideoThumbnails = [];

    if (
      !!selectedLoadedThumbnailImages?.length &&
      !!data?.videosUploaded?.length
    ) {
      updatedVideoThumbnails = selectedLoadedThumbnailImages
        .filter((thumbnail) => !!thumbnail)
        .map((thumbnail, index) => ({
          videoId: data.videosUploaded[index].id,
          thumbnail,
        }));
    }

    updateFiles({
      blockName,
      deleteImagesIds,
      deleteVideosIds,
      images: selectedImages,
      videos: videosWithThumbnail,
      updatedVideoThumbnails,
    });
  }, [
    selectedImages,
    selectedVideos,
    deleteImagesIds,
    deleteVideosIds,
    selectedThumbnailImages,
    selectedLoadedThumbnailImages,
    data.videosUploaded,
  ]);

  useEffect(() => {
    if (!!data?.videosUploaded?.length) {
      const thumbnails = [];
      data.videosUploaded.forEach((video) => {
        if (video.thumbnailImage) {
          video.thumbnailImage.preview = video.thumbnailImage.link;
          video.thumbnailImage.size = null;
          thumbnails.push(video.thumbnailImage);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedVideos.length) {
      const thumbnailImages = selectedThumbnailImages.filter(
        (thumbnail) => !!thumbnail
      );

      setThumbnailAndVideoSuccess(
        thumbnailImages?.length === selectedVideos?.length
      );
    }
  }, [selectedThumbnailImages, selectedVideos]);

  useEffect(() => {
    setUpdatedThumbnails(!!selectedLoadedThumbnailImages?.length);
  }, [selectedLoadedThumbnailImages]);

  return (
    <Card>
      <CardBody>
        <CardTitle>Block #{index + 1}</CardTitle>
        {blockFor === BLOCK_FOR.lesson && <FormGroup className='mb-4' row>
          <Col lg={2}>
            <Label>Is Preview Lesson Block</Label>
          </Col>
          <Col lg={10}>
            <AvCheckboxGroup className='' name={`${blockName}.previewLessonBlock`}>
              <AvCheckbox
                disabled={!isPreviewLesson}
                onChange={handleFormChange}
                className="cursor-pointer"
                customInput
                label="Is Preview Lesson Block"
                value={previewLessonBlockValue}
              />
            </AvCheckboxGroup>
          </Col>
        </FormGroup>}
        <FormGroupText
          name={`${blockName}.ordinalNumber`}
          label="Ordinal Number"
          placeholder="Enter Ordinal Number"
          handleFormChange={handleFormChange}
        />
        <WYSISYGComponent
          name={`${blockName}.text`}
          label="Text"
          handleFormChange={handleFormChange}
          valueReflectFunction={(value) => updateValue(value)}
        />
        <FormGroupText
          name={`${blockName}.typeFormId`}
          label="TypeForm ID"
          placeholder="Enter TypeForm ID"
          handleFormChange={handleFormChange}
        />
        <FormGroupImages
          multiple={true}
          label="Images"
          error={error}
          selectedImages={selectedImages}
          imagesUploaded={data.imagesUploaded}
          handleAcceptedImages={handleAcceptedImages}
          setSelectedImages={setSelectedImages}
          updateDeleteImagesIds={(ids) => updateDeleteImagesIds(ids)}
          setError={setErrorImages}
        />
        <FormGroupVideo
          withThumbnail
          multiple={true}
          label="Videos"
          error={errorVideos}
          selectedVideos={selectedVideos}
          videosUploaded={data.videosUploaded}
          handleAcceptedVideos={handleAcceptedVideos}
          setSelectedVideos={setSelectedVideos}
          updateDeleteVideosIds={(ids) => updateDeleteVideosIds(ids)}
          setError={setErrorVideo}
          thumbnailError={thumbnailError}
          selectedThumbnailImages={selectedThumbnailImages}
          handleTumbunalAcceptedFiles={handleTumbunalAcceptedFiles}
          setSelectedThumbnailImages={setSelectedThumbnailImages}
          loadedThumbnailError={loadedThumbnailError}
          errorIndex={errorFileId}
          selectedLoadedThumbnailImages={selectedLoadedThumbnailImages}
          handleLoadedTumbunalAcceptedFiles={handleLoadedTumbunalAcceptedFiles}
          setSelectedLoadedThumbnailImages={setSelectedLoadedThumbnailImages}
          errorLoadedIndex={errorLoadedIndex}
        />

        {data.ableToAdd && (
          <Row>
            <Col className="offset-2">
              <Button
                color="success"
                className={styles.btnSave}
                onClick={addBlockClicked}
              >
                Add block
              </Button>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default LessonBlock;
