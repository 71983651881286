import React from "react";
import { Button } from "reactstrap";

import noop from "../../helpers/noop";

const Placeholder = ({ text = "", buttonLabel, onButtonPressed = noop() }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100 flex-grow-1">
      <p className="font-size-18 text-uppercase mb-4">{text}</p>
      {buttonLabel && (
        <Button color="primary" className="mt-3" onClick={onButtonPressed}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default Placeholder;
