import Http from "./HttpService";

class PremiumArticlesCategoriesService extends Http {
  BASE_URL = "/admins/premium-article-categories";

  getList(params) {
    return this.get(this.BASE_URL, { params });
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }
}

export default new PremiumArticlesCategoriesService();
