import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import classNames from "classnames";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

import useAvForm from "../../hooks/useAvForm";
import WYSISYGComponent from "../Forms/inputs/WYSISYGComponent";
import { EDITOR_ONLY_LINK_CONFIGURATION } from "../../constants/editorConstants";

import styles from "./modal.module.scss";

const TextEditorModal = ({
  isOpen,
  titleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
  bodyClassName,
  confirmBtnClassName,
  cancelBtnClassName,
  containerClassname,
  textEditorName,
  textEditorValidator,
  textEditorLabel,
  altBtnStyle = false,
}) => {
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();

  const handleFormSubmit = (event, formValues) => {
    onConfirm(formValues);
  };

  return (
    <Modal
      isOpen={isOpen}
      centered
      className={"confirmation-modal " + containerClassname}
      toggle={() => onCancel()}
    >
      <ModalHeader toggle={() => onCancel()}>{titleText}</ModalHeader>
      <ModalBody className={bodyClassName}>
        <div
          dangerouslySetInnerHTML={{ __html: contentText }}
          className={styles.textEditorContent}
        />
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <WYSISYGComponent
            vertical
            name={textEditorName}
            label={textEditorLabel}
            validator={textEditorValidator}
            handleFormChange={handleFormChange}
            editorSettings={EDITOR_ONLY_LINK_CONFIGURATION}
            editorClassName={styles.textEditor}
          />
          <div className="d-flex flex-row justify-content-end">
            <Button
              color="secondary"
              size="lg"
              onClick={() => onCancel()}
              className={classNames(
                cancelBtnClassName,
                altBtnStyle && styles.btnStyle,
                "mr-2"
              )}
            >
              {cancelButtonText}
            </Button>
            <Button
              className={classNames(
                confirmBtnClassName,
                altBtnStyle && styles.btnStyle
              )}
              color="primary"
              size="lg"
              type="submit"
              disabled={hasFormError || !isDirty}
            >
              {confirmButtonText}
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default TextEditorModal;
