import React, { useRef } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import classNames from "classnames";

import styles from "./index.module.scss";

const InputWithReadOnlyTextPart = ({
  id,
  name,
  label,
  validator,
  handleFormChange,
  className,
  labelClassName,
  value,
  readOnlyPart,
  vertical = false,
  readOnly = false,
}) => {
  const readOnlyPartRef = useRef(null);

  return (
    <FormGroup className={className || "mb-4"} row>
      <Col lg={vertical ? 12 : 2}>
        <Label
          htmlFor={id || name}
          className={classNames(labelClassName, "col-form-label")}
        >
          {label}
        </Label>
      </Col>
      <Col lg={vertical ? 12 : 10}>
        <div className={styles.inputWr}>
          <AvField
            id={id || name}
            name={name}
            type="text"
            className="form-control"
            onBlur={handleFormChange}
            onChange={handleFormChange}
            validate={validator}
            value={value}
            disabled={readOnly}
            readOnly={readOnly}
            style={{ paddingLeft: `${readOnlyPartRef.current?.clientWidth}px` }}
          />
          <span className={styles.readOnlyPart} ref={readOnlyPartRef}>
            {readOnlyPart}
          </span>
        </div>
      </Col>
    </FormGroup>
  );
};

export default InputWithReadOnlyTextPart;
