import {
  GET_ANALYTICS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_RESET,
} from "./actionTypes";

export const getAnalytics = (payload) => ({
  payload,
  type: GET_ANALYTICS,
});

export const getAnalyticsSuccess = (data) => ({
  payload: { data },
  type: GET_ANALYTICS_SUCCESS,
})

export const getAnalyticsFailed = () => ({
  type: GET_ANALYTICS_FAILED,
});

export const getAnalyticsReset = () => ({
  type: GET_ANALYTICS_RESET,
})
