import React from "react";

export default ({
  text = "NO DATA",
  placeholderClassName = "table-placeholder",
  PlaceholderButtonComponent = null,
}) => (
  <section className={placeholderClassName}>
    <span className="table-placeholder__title">{text}</span>
    {!!PlaceholderButtonComponent && (
      <div className="mt-2">
        <PlaceholderButtonComponent />
      </div>
    )}
  </section>
);
