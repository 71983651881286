import {
  FORGET_PASSWORD_SEND_LINK,
  FORGET_PASSWORD_SEND_LINK_SUCCESS,
  FORGET_PASSWORD_SEND_LINK_ERROR,
  FORGET_PASSWORD_SEND_LINK_CLEAR,
  VALIDATE_RESTORE_PASSWORD_TOKEN,
  VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS,
  VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR,
  VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR,
  RESTORE_PASSWORD,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_ERROR,
  RESTORE_PASSWORD_CLEAR,
  FORGET_PASSWORD_CLEAR_ERROR,
} from "./actionTypes";

const initialState = {
  linkSend: false,
  loading: false,
  isValidTokent: false,
  newPasswordSuccess: false,
  error: "",
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_SEND_LINK:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FORGET_PASSWORD_SEND_LINK_SUCCESS:
      state = {
        ...state,
        linkSend: true,
        loading: false,
      };
      break;
    case FORGET_PASSWORD_SEND_LINK_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case FORGET_PASSWORD_SEND_LINK_CLEAR:
      state = {
        ...state,
        linkSend: false,
        loading: false,
      };
      break;
    case VALIDATE_RESTORE_PASSWORD_TOKEN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VALIDATE_RESTORE_PASSWORD_TOKEN_SUCCESS:
      state = {
        ...state,
        isValidTokent: true,
        loading: false,
      };
      break;
    case VALIDATE_RESTORE_PASSWORD_TOKEN_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case VALIDATE_RESTORE_PASSWORD_TOKEN_CLEAR:
      state = {
        ...state,
        isValidTokent: false,
        loading: false,
      };
      break;
    case RESTORE_PASSWORD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RESTORE_PASSWORD_SUCCESS:
      state = {
        ...state,
        newPasswordSuccess: true,
        loading: false,
      };
      break;
    case RESTORE_PASSWORD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case RESTORE_PASSWORD_CLEAR:
      state = {
        ...state,
        newPasswordSuccess: false,
        loading: false,
      };
      break;
    case FORGET_PASSWORD_CLEAR_ERROR:
      state = {
        ...state,
        error: "",
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
