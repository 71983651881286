import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { AVATAR_MAX_SIZE } from "../../constants/avatar";
import { AVATAR_MAX_SIZE_ERROR, AVATAR_TYPE_ERROR } from "../../constants/errorMessages";
import noop from "../../helpers/noop";
import ToastrService from "../../services/ToastrService";
import styles from "./forms.module.scss";
import Cropper from "react-cropper";
import useModal from "../../hooks/useModal";
import EditAvatarIcon from "../../assets/images/icons/ic_editAvatar.svg";

const ALLOWED_AVATAR_FORMAT = "image/png,image/jpeg";
const DEFAULT_AVATAR_DIMENSION = { width: 300, height: 300 };

const UpdateAdminAvatarForm = ({ avatarUpdated = noop, initialAvatar = null, removeImage = noop }) => {
  const changeImageRef = useRef(null);
  const cropperRef = useRef(null);

  const [file, updateFile] = useState(null);
  const [fileCropped, updateFileCropped] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [enableCrop, toggleCrop] = useModal();

  const loadInitialAvatar = () => {
    const { isResized, resizedImages, link } = initialAvatar;
    const image = isResized ? resizedImages[Object.keys(resizedImages)[0]] : link;
    updateFileCropped(image);
  };

  useEffect(() => {
    if (initialAvatar) {
      loadInitialAvatar();
    }
  }, [initialAvatar]);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setImageFile(reader.result);
        toggleCrop();
      };

      reader.readAsDataURL(file);
    }
  }, [file]);

  const onChangeImage = (files) => {
    if (files && files.length) {
      const fileLoaded = files[0];

      if (!ALLOWED_AVATAR_FORMAT.includes(fileLoaded.type)) {
        ToastrService.error(AVATAR_TYPE_ERROR);
      } else if (fileLoaded.size <= AVATAR_MAX_SIZE) {
        updateFile(fileLoaded);
      } else {
        ToastrService.error(AVATAR_MAX_SIZE_ERROR);
      }
    }
  };

  const onImageRemove = () => {
    removeImage();
    
    updateFile(null);
    setImageFile(null);
    updateFileCropped(null);
  };

  const onSave = (result, sizes) => {
    const avatarSizes = sizes.width >= 300 ? DEFAULT_AVATAR_DIMENSION : sizes;
    avatarUpdated(result, file.name, avatarSizes);

    toggleCrop();
    updateFile(null);
    setImageFile(null);
  };

  const onCrop = () => {
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;
    const type = cropper.crossOriginUrl.includes("data:image/jpeg") ? "image/jpeg" : "image.png";
    const cropperCanvas = cropper.getCroppedCanvas();
    const resultFile = cropperCanvas.toDataURL(type);

    onSave(resultFile, {
      width: cropperCanvas.width,
      height: cropperCanvas.height,
    });
  };

  return (
    <Card className={classNames(styles.card, styles.cardTop)}>
      <CardBody>
        <div className={classNames(styles.cardTitle, "mb-3")}>Avatar</div>

        <div className="d-flex align-items-center">
          <div className={styles.avatarWr} onClick={() => changeImageRef.current.click()}>
            {fileCropped && <>
              <img src={fileCropped} alt="" className={styles.avatarImg} />
              <div className={styles.avatarBg}></div>
              <img src={EditAvatarIcon} alt="" className={styles.avatarIcon} />
            </>}


            {!fileCropped && <div className={styles.avatarDefault}>+</div>}
          </div>
          <div
            className={classNames(
              "d-flex align-items-center text-secondary",
              fileCropped && "text-danger",
              fileCropped && styles.pointer
            )}
            onClick={onImageRemove}
          >
            <i className={classNames("ri-delete-bin-line", styles.avatarDeleteIcon)}></i>
            <span className={styles.avatarDeleteText}>Delete avatar</span>
          </div>
        </div>

        <input
          multiple={false}
          hidden={true}
          ref={changeImageRef}
          type="file"
          accept={ALLOWED_AVATAR_FORMAT}
          onChange={(e) => onChangeImage(e.target.files)}
        />

        {enableCrop && (
          <Cropper
            src={imageFile}
            initialAspectRatio={1}
            guides={false}
            crop={onCrop}
            ref={cropperRef}
            center
            className="d-none"
          />
        )}
      </CardBody>
    </Card>
  );
};

export default UpdateAdminAvatarForm;
