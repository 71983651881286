import React, { useCallback } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import SaveCourseCategory from "../../../components/Forms/SaveCourseCategory";
import { createCourseCategory } from "../../../store/dashboard/Training/actions";
import noop from "../../../helpers/noop";
import ProgressWatcher from "../../../components/Common/ProgressWatcher";
import SEO from "../../../components/Common/SEO";

const PROGRESS_TEXT = "Image is uploading now. Please, don't quit this page";

const CreateCourseCategory = ({
  history,
  createCourseCategory = noop,
  loading,
}) => {
  const saveCourse = useCallback((values) => {
    if (!loading) {
      createCourseCategory(values, history);
    }
  }, []);
  return (
    <>
      <SEO title="Create course category" />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: false }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Create course category
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <SaveCourseCategory saveCategoryFunc={saveCourse} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    Training: { loading, error },
  } = state;
  return { loading, error };
};

export default withRouter(
  connect(mapStateToProps, { createCourseCategory })(CreateCourseCategory)
);
