import {
  CREATE_REWARD,
  CREATE_REWARD_CLEAR,
  CREATE_REWARD_ERROR,
  CREATE_REWARD_SUCCESS,
  GET_LIST_REWARD,
  GET_LIST_REWARD_ERROR,
  GET_LIST_REWARD_SUCCESS,
  GET_REWARD,
  GET_REWARD_SUCCESS,
  GET_REWARD_CLEAR,
  GET_REWARD_ERROR,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_CLEAR,
  UPDATE_REWARD_ERROR,
  REMOVE_REWARD,
  REMOVE_REWARD_SUCCESS,
  REMOVE_REWARD_CLEAR,
  REMOVE_REWARD_ERROR,
  CLEAR_LIST_REWARD,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  data: [],
  item: null,
  created: null,
  updated: null,
  removed: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_REWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_REWARD_SUCCESS:
      state = {
        ...state,
        data: [...state.data, ...action.payload.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_REWARD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_REWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_REWARD_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_REWARD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_REWARD_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case UPDATE_REWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_REWARD_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_REWARD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_REWARD_CLEAR:
      state = {
        ...state,
        updated: null,
      };
      break;
    case GET_REWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_REWARD_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_REWARD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_REWARD_CLEAR:
      state = {
        ...state,
        data: [],
        item: null,
        loading: false,
      };
      break;
    case REMOVE_REWARD:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_REWARD_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_REWARD_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_REWARD_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case CLEAR_LIST_REWARD:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
