import Http from "./HttpService";

class UsersService extends Http {
  BASE_URL = "/admins/users";

  getList(params) {
    return this.get(this.BASE_URL, { params });
  }

  getProfile(id) {
    return this.get(`${this.BASE_URL}/${id}`);
  }

  changeStatus(id, status) {
    return this.patch(`${this.BASE_URL}/${id}/status`, { status });
  }

  sendRecoveryLink(id) {
    return this.put(`${this.BASE_URL}/${id}/password`);
  }

  changeEmail(id, email) {
    return this.put(`${this.BASE_URL}/${id}/email`, email);
  }

  setPassword(id, data) {
    return this.put(`${this.BASE_URL}/${id}/set-password`, data);
  }

  provideSubscription(id, model) {
    return this.put(`${this.BASE_URL}/${id}/provide-subscription`, model);
  }

  getCourses(id, params) {
    return this.get(`${this.BASE_URL}/${id}/courses`, { params });
  }

  addCourses(id, model) {
    return this.post(`${this.BASE_URL}/${id}/courses`, model);
  }

  removeCourses(id, model) {
    return this.delete(`${this.BASE_URL}/${id}/courses`, {
      data: { ...model },
    });
  }

  deleteUser(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }
  
  revokeSubscription(id) {
    return this.delete(`${this.BASE_URL}/${id}/subscription`);
  }
}

export default new UsersService();
