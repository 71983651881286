import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";

import { getReminderNotification } from "../../store/dashboard/Notifications/actions";
import useAvForm from "../../hooks/useAvForm";
import formsValidators from "../../validators/form.validators";
import FormGroupText from "./inputs/FormGroupText";

import styles from "./forms.module.scss";
import { LinkArticleField } from "./LinkArticleField";
import {NOTIFICATIONS_TYPES} from "../../constants/notifications";

const initialState = {
  title: "",
  body: "",
  premiumArticleId: "",
};

const CreateNotificationForm = ({
  notifications,
  getReminderNotification,
  cardTitle,
  saveFunc,
  defaultNotificationType,
  notificationRadioBtns,
  isReminderNotification = false,
}) => {
  const [notificationType, setNotificationType] = useState(defaultNotificationType);

  const { reminderNotification: model, reminderNotificationLoading: loading } =
    notifications;

  const [fromValues, setFormValues] = useState(initialState);

  const [isEditing, updateIsEditing] = useState(false);
  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();
  const disableState = useMemo(
    () => loading || hasFormError || !isDirty,
    [loading, isDirty, hasFormError]
  );

  useEffect(() => {
    if (model && isReminderNotification && !loading) {
      const { title, body } = model;
      setFormValues({ title, body });
    }

    if (!model && !loading && isReminderNotification) {
      setFormValues(initialState);
      formRef.current && formRef.current.reset();
    }
  }, [model, isReminderNotification, loading, setFormValues, formRef]);

  const handleFormSubmit = (event, values) => {
    let model;
    const { premiumArticleId, ...restValues} = values;
    if (!premiumArticleId) {
      model = {...restValues, type: notificationType};
    } else {
      model = { ...values, type: notificationType };
    }
    saveFunc(model);

    if (isReminderNotification) {
      updateIsEditing(false);
    } else {
      setFormValues(initialState);
      formRef.current.reset();
      handleFormChange();
    }
  };

  useEffect(() => {
    if (isReminderNotification) {
      getReminderNotification(notificationType);
    }
  }, [isReminderNotification, getReminderNotification, notificationType]);

  const changeTypeHandler = useCallback(
    (event) => {
      setNotificationType(event.target.value);
      if (isReminderNotification) {
        handleFormChange();
        updateIsEditing(false);
      }
    },
    [isReminderNotification, getReminderNotification, handleFormChange, notificationType]
  );

  const cancelHandler = useCallback(
    (event) => {
      event.preventDefault();
      updateIsEditing(false);
      if (model) {
        const { title, body, premiumArticleId } = model;
        setFormValues({ title, body, premiumArticleId });
      }
    },
    [model]
  );

  useEffect(() => {
    setFormValues(values);
  }, [values]);

  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="m-0">{cardTitle}</CardTitle>
          <AvForm
            onValidSubmit={handleFormSubmit}
            ref={formRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                return false;
              }
            }}
          >
            <FormGroup className="custom-control custom-radio mt-4 mb-3 d-flex flex-row">
              {notificationRadioBtns.map(({ label, value }, index) => (
                <FormGroup
                  className={classNames(
                    "mb-0",
                    index !== notificationRadioBtns.length - 1 && "mr-5"
                  )}
                  key={`notification-radio-${index}`}
                >
                  <Input
                    type="radio"
                    id={`notification-radio-${isReminderNotification}-${index}`}
                    name="notificationRadio"
                    className="custom-control-input"
                    onChange={changeTypeHandler}
                    value={value}
                    defaultChecked={!index}
                  />
                  <Label
                    className="custom-control-label"
                    htmlFor={`notification-radio-${isReminderNotification}-${index}`}
                  >
                    {label}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            <FormGroupText
              vertical
              className="mb-0"
              labelClassName="pt-0"
              name="title"
              label="Notification title"
              placeholder="Enter notification title"
              validator={formsValidators.notification.title}
              handleFormChange={handleFormChange}
              value={fromValues?.title}
              readOnly={!isEditing && isReminderNotification}
            />

            <FormGroupText
              vertical
              type="textarea"
              className="mb-0"
              labelClassName="pt-0"
              name="body"
              label="Notification body"
              placeholder="Enter notification body"
              validator={formsValidators.notification.body}
              handleFormChange={handleFormChange}
              value={fromValues?.body}
              readOnly={!isEditing && isReminderNotification}
            />
            {
              notificationType ===  NOTIFICATIONS_TYPES.SUBSCRIBERS_ACTIVE && (
                  <FormGroup className="d-flex flex-column">
                    <LinkArticleField
                        name="premiumArticleId"
                        value={fromValues?.premiumArticleId}
                        handleFormChange={handleFormChange}
                        validator={formsValidators.notification.premiumArticleId}
                    />
                  </FormGroup>
              )
            }
            <FormGroup className="d-flex justify-content-end mt-3 mb-0">
              {!isReminderNotification ? (
                <Button
                  type="submit"
                  color="primary"
                  disabled={disableState}
                  className={styles.btnCreateAndSave}
                >
                  Create and Send
                </Button>
              ) : (
                <>
                  {!isEditing ? (
                    <Button
                      type="submit"
                      color="primary"
                      className={styles.btnSave}
                      onClick={(e) => {
                        e.preventDefault();
                        updateIsEditing(true);
                      }}
                    >
                      Edit
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="secondary"
                        className="mr-2"
                        onClick={cancelHandler}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={disableState}
                        className={styles.btnSave}
                      >
                        Update
                      </Button>
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </AvForm>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = ({ Notifications: notifications }) => ({
  notifications,
});

export default connect(mapStateToProps, {
  getReminderNotification,
})(CreateNotificationForm);
