import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { merge } from "lodash";
import classNames from "classnames";

import formsValidators from "../../../validators/form.validators";
import useAvForm from "../../../hooks/useAvForm";
import FormGroupText from "../inputs/FormGroupText";
import { getPremiumArticlesSections } from "../../../store/dashboard/PremiumArticlesSections/actions";
import { getPremiumArticlesCategories } from "../../../store/dashboard/PremiumArticlesCategories/actions";
import { getPremiumArticlesTags } from "../../../store/dashboard/PremiumArticlesTags/actions";
import useMountedState from "../../../hooks/useMountedState";
import FormGroupMultiSelect from "../inputs/FormGroupMultiSelect";
import FormGroupReactSelect from "../inputs/FormGroupReactSelect";
import LessonBlock from "../SaveLesson/LessonBlock";
import { getInitialState } from "./getInitialState";
import LessonsHelper from "../../../helpers/LessonsHelper";
import FormGroupImages from "../inputs/FormGroupImages";
import {
  MAX_TAG_LENGTH,
  MAX_CATEGORY_LENGTH,
} from "../../../constants/leghtValidity";
import useDropzoneFacade from "../../../hooks/useDropzoneFacade";
import { ARTICLE_STATUSES } from "../../../constants/articleStatuses";
import capitilizeFirstLetter from "../../../helpers/capitilizeFirstLetter";
import articleStyles from "../../../pages/Dashboard/DJHub/components/PremiumArticleCard/PremiumArticleCard.module.scss";
import { EMPTY_PARAGRAPH } from "../../../constants/editorConstants";
import useInputWithReadOnlyTextPart from "../../../hooks/useInputWithReadOnlyTextPart";
import InputWithReadOnlyTextPart from "../inputs/InputWithReadOnlyTextPart";
import getReadOnlyUrlPart from "../../../helpers/getReadOnlyUrlPart";
import FormGroupScheduleDate from "../inputs/FormGroupScheduleDate";
import { SCHEDULE_DATE_PLACEHOLDER } from "../../../constants/datePickerConstants";

import styles from "../forms.module.scss";
import { BLOCK_FOR } from '../../../constants/lessonsDefaults';

const SavePremiumArticle = ({
  model,
  saveArticleFunc,
  updateDirtyState,
  loading,
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  getPremiumArticlesTags,
  premiumArticlesSections,
  premiumArticlesCategories,
  premiumArticlesTags,
}) => {
  const [initialFile, updateInitialFile] = useMountedState(null);
  const [formData, updateFormData] = useState(getInitialState());
  const [needClearSelect, setNeedClearSelect] = useState(false);
  const [ready, updateReadyState] = useMountedState(false);
  const [articleSectionId, setArticleSectionId] = useState(null);
  const [thumbnailAndVideoSuccess, setThumbnailAndVideoSuccess] =
    useState(true);
  const [updatedThumbnails, setUpdatedThumbnails] = useState(false);

  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();

  const [error, selectedFiles, handleAcceptedFiles, setSelectedFiles] =
    useDropzoneFacade();

  const urlPart = useInputWithReadOnlyTextPart(
    values?.title,
    formRef,
    model?.urlPart
  );

  const isArticleLive = useMemo(
    () => model?.status === ARTICLE_STATUSES.LIVE,
    [model]
  );

  const extractedBlocksAsArray = useMemo(() => {
    return Object.keys(formData)
      .filter((key) => key.startsWith("block"))
      .map((blockName, index) => ({
        blockName,
        ableToAdd:
          !!formData[`block${index + 2}`] &&
          formData[`block${index + 1}`].isVisible &&
          !formData[`block${index + 2}`].isVisible,
        ...formData[blockName],
      }));
  }, [formData]);

  const hasBlocksFilled = useMemo(() => {
    const hasTexts =
      !!values &&
      !!Object.keys(values).filter(
        (key) =>
          key.startsWith("block") &&
          !!values[key].text &&
          values[key].text !== EMPTY_PARAGRAPH
      ).length;
    const hasFiles = !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter((block) => {
        const { images, videos, imagesUploaded, videosUploaded } = block;
        return (
          images.length > 0 ||
          videos.length > 0 ||
          (!!imagesUploaded && imagesUploaded.length > 0) ||
          (!!videosUploaded && videosUploaded.length > 0)
        );
      }).length;
    return hasTexts || hasFiles;
  }, [values, extractedBlocksAsArray]);

  const hasFilesToRemove = useMemo(() => {
    if (!model) {
      return false;
    }
    return !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter(({ deleteImagesIds, deleteVideosIds }) => {
        return (
          (!!deleteImagesIds && !!deleteImagesIds.length) ||
          (!!deleteVideosIds && !!deleteVideosIds.length)
        );
      }).length;
  }, [extractedBlocksAsArray, model]);

  const hasFilesToAdd = useMemo(() => {
    if (!model) {
      return false;
    }
    return !!extractedBlocksAsArray
      .filter((block) => block.isVisible)
      .filter(({ images, videos }) => {
        return (!!images && !!images.length) || (!!videos && !!videos.length);
      }).length;
  }, [extractedBlocksAsArray, model]);

  const { data: premiumArticlesSectionsData } = premiumArticlesSections;
  const { data: premiumArticlesCategoriesData } = premiumArticlesCategories;
  const { data: premiumArticlesTagsData } = premiumArticlesTags;

  const handleFormSubmit = (event, formValues) => {
    formValues.category = formData.category;
    formValues.tags = formData.tags;
    if (!model) {
      const blocksWithFiles = extractedBlocksAsArray
        .filter((block) => block.isVisible)
        .filter((block) => block.images.length > 0 || block.videos.length > 0)
        .reduce((acc, { blockName, images, videos }) => {
          acc = { ...acc, [blockName]: { images, videos } };
          return acc;
        }, {});
      const dataMerged = merge(blocksWithFiles, formValues);
      const dataToCreate = {
        title: values.title,
        urlPart: values.urlPart,
        description: values.description,
        premiumArticleSectionId:
          values.premiumArticleSectionId || articleSectionId,
        category: formData.category[0],
        tags: formData.tags,
        blocks: LessonsHelper.getBlocksTransformed(dataMerged)
          .filter(
            ({ text, images, videos, typeFormId }) =>
              (text !== "" && text !== EMPTY_PARAGRAPH) ||
              (images && images.length) ||
              (videos && videos.length) ||
              (typeFormId !== "")
          )
          .map((block) => LessonsHelper.getBlockCreateDTO(block)),
      };
      if (initialFile !== selectedFiles[0]) {
        dataToCreate.files = selectedFiles;
      }
      if (values.scheduledDate) {
        dataToCreate.scheduledDate = values.scheduledDate;
      }
      saveArticleFunc(dataToCreate);
    } else {
      const dataToUpdate = {
        title: values.title,
        urlPart: values.urlPart,
        description: values.description,
        premiumArticleSectionId:
          values.premiumArticleSectionId || articleSectionId,
        category:
          formData.category !== "" ? formData.category[0] : values.category[0],
        tags: formData.tags !== "" ? formData.tags : values.tags,
        updateBlocks: [],
      };
      const blocksVisible = LessonsHelper.getBlocksVisible(
        extractedBlocksAsArray
      );
      const dataMerged = merge(blocksVisible, formValues);
      const blocksTransformed = LessonsHelper.getBlocksTransformed(dataMerged);
      const updateBlocks = blocksTransformed
        .filter(({ id }) => !!id)
        .filter(
          ({
            text,
            images,
            videos,
            deleteImagesIds,
            deleteVideosIds,
            imagesUploaded,
            videosUploaded,
            updatedVideoThumbnails,
            typeFormId,
            ordinalNumber,
          }) => {
            return (
              !!text ||
              !!typeFormId ||
              !!ordinalNumber ||
              (!!images && !!images.length) ||
              (!!videos && !!videos.length) ||
              (!!imagesUploaded && !!imagesUploaded.length) ||
              (!!videosUploaded && !!videosUploaded.length) ||
              (!!deleteImagesIds && !!deleteImagesIds.length) ||
              (!!deleteVideosIds && !!deleteVideosIds.length) ||
              (!!updatedVideoThumbnails && !!updatedVideoThumbnails.length)
            );
          }
        )
        .map((block) => LessonsHelper.getBlockUpdateDTO(block))
        .filter((item) => !!item);
      const addBlocks = blocksTransformed
        .filter(({ id }) => !id)
        .filter(
          ({ text, images, videos }) =>
            (text !== "" && text !== EMPTY_PARAGRAPH) ||
            (images && images.length) ||
            (videos && videos.length)
        )
        .map((block) => LessonsHelper.getBlockCreateDTO(block));
      const deleteBlockIds = blocksTransformed
        .filter(({ id }) => id)
        .filter((block) => LessonsHelper.checkEmptyBlock(block))
        .map(({ id }) => id);

      if (updateBlocks.length) {
        dataToUpdate.updateBlocks = updateBlocks;
      }
      if (addBlocks.length) {
        dataToUpdate.addBlocks = addBlocks;
      }
      if (deleteBlockIds.length) {
        dataToUpdate.deleteBlockIds = deleteBlockIds;
      }
      if (initialFile !== selectedFiles[0]) {
        dataToUpdate.files = selectedFiles;
      }

      if (isArticleLive) {
        dataToUpdate.isLive = true;
      }

      if (values.scheduledDate) {
        dataToUpdate.scheduledDate = values.scheduledDate;
      }
      saveArticleFunc(dataToUpdate);
    }
  };
  useEffect(() => {
    getPremiumArticlesSections();
    getPremiumArticlesTags();
  }, []);

  useEffect(() => {
    if (premiumArticlesCategoriesData.length > 0) {
      if (model && model.premiumArticleSectionId !== articleSectionId) {
        setNeedClearSelect(true);
      }
      if (!model) {
        setNeedClearSelect(true);
      }
    }
  }, [premiumArticlesCategoriesData]);

  const addBlockHandler = useCallback(() => {
    const yetInvisibleIndex = extractedBlocksAsArray.findIndex(
      ({ isVisible }) => !isVisible
    );
    if (yetInvisibleIndex >= 0) {
      const { blockName } = extractedBlocksAsArray[yetInvisibleIndex];
      const formDataUpdated = { ...formData };
      formDataUpdated[blockName].isVisible = true;
      updateFormData(formDataUpdated);
    }
  }, [formData]);

  const updateFilesHandler = useCallback(
    (files) => {
      const {
        blockName,
        images,
        videos,
        deleteImagesIds,
        deleteVideosIds,
        updatedVideoThumbnails,
      } = files;
      const formDataUpdated = { ...formData };
      formDataUpdated[blockName].images = images;
      formDataUpdated[blockName].videos = videos;
      formDataUpdated[blockName].deleteImagesIds = deleteImagesIds;
      formDataUpdated[blockName].deleteVideosIds = deleteVideosIds;
      formDataUpdated[blockName].updatedVideoThumbnails =
        updatedVideoThumbnails;
      updateFormData(formDataUpdated);
    },
    [formData]
  );
  const pageTitle = useMemo(
    () => (model ? "Edit premium article" : "Create premium article"),
    [model]
  );

  const btnSaveLabel = useMemo(() => (model ? "Save" : "Create"), [model]);
  const disableState = useMemo(
    () =>
      !(
        ((isDirty || initialFile !== selectedFiles[0]) && hasBlocksFilled) ||
        hasFilesToRemove ||
        hasFilesToAdd ||
        updatedThumbnails
      ) ||
      !formData.category.length ||
      !formData.tags.length ||
      loading ||
      hasFormError ||
      !thumbnailAndVideoSuccess,
    [
      hasFormError,
      isDirty,
      hasBlocksFilled,
      hasFilesToRemove,
      hasFilesToAdd,
      initialFile,
      loading,
      formData,
      selectedFiles,
      thumbnailAndVideoSuccess,
      updatedThumbnails,
    ]
  );
  useEffect(() => {
    if (!!model) {
      // Prepare model
      const {
        title,
        urlPart,
        description,
        premiumArticleSectionId,
        premiumArticleCategory,
        premiumArticleTags,
        blocks,
      } = model;
      const formData = {
        ...getInitialState(),
        title,
        urlPart,
        description,
        premiumArticleSectionId,
        premiumArticleTags,
        category: [premiumArticleCategory.name],
      };

      blocks.forEach((block, index) => {
        const { id, images, videos, text, typeFormId, ordinalNumber } = block;
        const blockName = `block${index + 1}`;
        formData[blockName].id = id;
        formData[blockName].text = text || "";
        formData[blockName].typeFormId = typeFormId || "";
        formData[blockName].ordinalNumber = ordinalNumber || "";
        formData[blockName].isVisible = true;
        formData[blockName].imagesUploaded = images;
        formData[blockName].videosUploaded = videos;
      });
      if (!!model.image) {
        const { initialFile } = model;
        if (initialFile) {
          updateInitialFile(initialFile);
          handleAcceptedFiles([initialFile], []);
        }
      }

      updateFormData(formData);
      updateReadyState(true);
    } else {
      updateReadyState(true);
    }
  }, [model]);

  useEffect(() => {
    updateDirtyState(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (ready && selectedFiles.length === 0 && initialFile) {
      setSelectedFiles([initialFile]);
    }
  }, [selectedFiles, ready]);

  const getCategoryValue = useMemo(() => {
    if (!!model) {
      return [model.premiumArticleCategory];
    }
  }, [model]);

  const getStatusClassName = useMemo(
    () =>
      model &&
      classNames(
        "badge",
        isArticleLive && "badge-soft-success",
        model.status === ARTICLE_STATUSES.SCHEDULED && "badge-soft-info",
        model.status === ARTICLE_STATUSES.DRAFT &&
          `badge-soft-dark ${articleStyles.darkBadge}`,
        articleStyles.articleStatus
      ),
    [model, isArticleLive]
  );

  return (
    ready && (
      <AvForm
        onValidSubmit={handleFormSubmit}
        ref={formRef}
        model={formData}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      >
        <Card>
          <CardBody>
            <CardTitle className="mb-4">
              <Row>
                <Col lg={2}>{pageTitle}</Col>
                <Col lg={10}>
                  <span className={getStatusClassName}>
                    {model && capitilizeFirstLetter(model.status)}
                  </span>
                </Col>
              </Row>
            </CardTitle>
            <FormGroupScheduleDate
              name="scheduledDate"
              placeholder={SCHEDULE_DATE_PLACEHOLDER}
              handleFormChange={handleFormChange}
              defaultValue={model?.scheduledDate}
              validator={formsValidators.premiumArticle.scheduledDate}
              isLiveStatus={isArticleLive}
            />
            <FormGroupText
              name="title"
              label="Title"
              placeholder="Enter title"
              validator={formsValidators.premiumArticle.title}
              handleFormChange={handleFormChange}
              className="mb-0"
            />
            <InputWithReadOnlyTextPart
              name="urlPart"
              label="Premium article URL"
              readOnlyPart={getReadOnlyUrlPart("dj-hub/premium-article")}
              validator={formsValidators.premiumArticle.urlPart}
              handleFormChange={handleFormChange}
              value={urlPart}
              className="mb-0"
            />
            <FormGroupText
              name="description"
              label="Description"
              placeholder="Enter description"
              validator={formsValidators.premiumArticle.description}
              handleFormChange={handleFormChange}
              className="mb-0"
            />
            <FormGroupReactSelect
              options={premiumArticlesSectionsData}
              name="premiumArticleSectionId"
              label="Section"
              value={!!model && model.premiumArticleSection}
              getValue={setArticleSectionId}
              placeholder="Select section"
              validator={formsValidators.premiumArticle.premiumArticleSectionId}
              handleFormChange={handleFormChange}
              getElemetsByValue={getPremiumArticlesCategories}
            />
            <Row>
              <Col lg="10" className={`offset-2 mb-3 ${styles.noteText}`}>
                If you create a new category, it will be added to the section
                selected above
              </Col>
            </Row>
            <FormGroupMultiSelect
              name="category"
              label="Category"
              placeholder="Select category"
              value={getCategoryValue}
              disabled={!premiumArticlesCategoriesData.length}
              options={premiumArticlesCategoriesData}
              validator={formsValidators.premiumArticle.categories}
              handleFormChange={handleFormChange}
              needClear={needClearSelect}
              needClearState={setNeedClearSelect}
              formState={formData}
              updateFormState={updateFormData}
              maxCreateLength={MAX_CATEGORY_LENGTH}
            />
            <FormGroupMultiSelect
              name="tags"
              label="Tags"
              placeholder="Select tags"
              value={!!model && model.premiumArticleTags}
              options={premiumArticlesTagsData}
              validator={formsValidators.premiumArticle.tags}
              handleFormChange={handleFormChange}
              formState={formData}
              updateFormState={updateFormData}
              maxCreateLength={MAX_TAG_LENGTH}
              isMulti
            />
            <FormGroupImages
              multiple={false}
              label="Overview image"
              error={error}
              selectedImages={selectedFiles}
              initialFile={initialFile}
              handleAcceptedImages={handleAcceptedFiles}
              setSelectedImages={setSelectedFiles}
            />
          </CardBody>
        </Card>
        {extractedBlocksAsArray.map((block, index) => {
          return (
            block.isVisible && (
              <Fragment key={`save-article-block_${index}`}>
                <LessonBlock
                  index={index}
                  data={block}
                  handleFormChange={handleFormChange}
                  addBlockHandler={addBlockHandler}
                  updateFiles={updateFilesHandler}
                  setThumbnailAndVideoSuccess={setThumbnailAndVideoSuccess}
                  setUpdatedThumbnails={setUpdatedThumbnails}
                  blockFor={BLOCK_FOR.premiumArticle}
                />
              </Fragment>
            )
          );
        })}
        <Row className="justify-content-end">
          <Col xs="12">
            <Link to="/dj-hub/">
              <Button color="secondary" className="mr-2">
                Cancel
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              disabled={disableState || !selectedFiles.length}
              className={styles.btnSave}
            >
              {btnSaveLabel}
            </Button>
          </Col>
        </Row>
      </AvForm>
    )
  );
};

const mapStateToProps = ({
  PremiumArticles,
  PremiumArticlesSections,
  PremiumArticlesTags,
  PremiumArticlesCategories,
}) => {
  return {
    loading: PremiumArticles.loading,
    premiumArticlesSections: PremiumArticlesSections,
    premiumArticlesTags: PremiumArticlesTags,
    premiumArticlesCategories: PremiumArticlesCategories,
  };
};

export default connect(mapStateToProps, {
  getPremiumArticlesSections,
  getPremiumArticlesCategories,
  getPremiumArticlesTags,
})(SavePremiumArticle);
