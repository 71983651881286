import React, { useMemo } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect, useDispatch } from "react-redux";

import Table from "../../../../components/Table";
import couponsListStructure from "./tableStructure";
import { COUPONS_LIST_LIMIT } from "../../../../constants/paginationConstants";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import {
  clearCouponCreated,
  deleteCouponSubscription,
  editCouponSubscription,
  getListCouponsSubscription,
} from "../../../../store/dashboard/Coupons/actions";
import useTable from "../../../../hooks/useTable";
import SubscriptionCouponForm from "../../../../components/Forms/SubscriptionCouponForm";
import SEO from "../../../../components/Common/SEO";
import HeaderComponent from "../components/HeaderComponent";
import noop from "../../../../helpers/noop";
import CreateCouponButtonSubscriptions from "../components/CreateCouponButtonSubscriptions/CreateCouponButtonSubscriptions";
import TableHeaderSubscriptions from "../components/TableHeader/TableHeaderSubscriptions";

const CouponsSubscription = ({ coupons, getListCouponsSubscription }) => {
  const dispatch = useDispatch()
  
  const computedData = useMemo(() => {
    const data = coupons;
    
    data.data = data.dataSubscription;
    data.pagination = data.paginationSubscription;
    
    return data;
  }, [coupons])
  
  const handleEditCoupon = ({ id, model }) => dispatch(editCouponSubscription({ id, model }))
  const handleDeleteCoupon = ({ id }) => dispatch(deleteCouponSubscription({ id }))
  
  const { data, pagination, loading, handleTableChange, query } = useTable(
    computedData,
    getListCouponsSubscription,
    COUPONS_LIST_LIMIT,
    clearCouponCreated
  );
  
  return (
    <ScrollbarVertical>
      <SEO title="Coupons management"/>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Coupons management</h4>
              </div>
            </Col>
          </Row>
          <HeaderComponent pageName='Subscription coupons'/>
          
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <SubscriptionCouponForm/>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table
                HeaderComponent={TableHeaderSubscriptions}
                dataStructure={() => couponsListStructure({
                  onEdit: handleEditCoupon,
                  onDelete: handleDeleteCoupon
                })}
                data={data}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={COUPONS_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                placeholderText="There are no subscription coupons yet"
                PlaceholderButtonComponent={() => <CreateCouponButtonSubscriptions buttonText='Create subscription coupon'/>}
                rowClickFunc={noop}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, { clearCouponCreated, getListCouponsSubscription })(
  CouponsSubscription
);
