import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";
import { compact } from "lodash";
import { END, eventChannel } from "redux-saga";

import {
  createLessonError,
  createLessonSuccess,
  getLessonError,
  getLessonsCountError,
  getLessonsCountSuccess,
  getLessonSuccess,
  getListLessonsError,
  getListLessonsSuccess,
  refetchLessonsSuccess,
  removeLessonError,
  removeLessonSuccess,
  updateLessonError,
  updateLessonSuccess,
} from "./actions";
import {
  CREATE_LESSON,
  GET_LESSON,
  GET_LESSONS_COUNT,
  GET_LIST_LESSONS,
  REFETCH_LESSONS,
  REMOVE_LESSON,
  UPDATE_LESSON,
} from "./actionTypes";
import LessonsService from "../../../services/LessonsService";
import ImageService from "../../../services/FilesService";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import throwCustomError from "../../../helpers/throwCustomError";
import errorCodes from "../../../constants/errorCodes";

const getLessonListAsync = async (data) => {
  const { courseId, model } = data;
  return await LessonsService.getList(courseId, model);
};

const getLessonAsync = async (id) => {
  return await LessonsService.getOne(id);
};

const getLessonsCountAsync = async (id) => {
  return await LessonsService.lessonsCount(id);
};

const removeLessonAsync = async (id) => {
  return await LessonsService.remove(id);
};

const videosWithThumbnailHandler = async (block, onProgress) => {
  const videoFiles = block.videos.map((videoBlock) => videoBlock.video);
  const thumbnailFiles = block.videos.map((videoBlock) => videoBlock.thumbnail);

  const videoResponse = await ImageService.uploadVideoFile(
    { files: videoFiles },
    onProgress
  ).catch((error) => {
    throwCustomError(error);
  });

  const thumbnailResponse = await ImageService.uploadImageFile(
    { files: thumbnailFiles },
    onProgress
  ).catch((error) => {
    throwCustomError(error);
  });

  const videos = videoResponse.reduce((acc, val, index) => {
    acc.push({
      videoId: val.file.id,
      videoThumbnailId: thumbnailResponse[index].file.id,
    });
    return acc;
  }, []);

  return {
    videos,
    blockId: block.id,
  };
};

const lessonComposite = (model) => {
  const files = model.blocks.map(({ images, videos }) => ({ images, videos }));
  let filesTotalSize = files.reduce((acc, { images = [], videos = [] }) => {
    const imagesSize = images.reduce((sum, image) => sum + image.size, 0) || 0;
    const videosSize =
      videos.reduce(
        (sum, video) => sum + video.video.size + video.thumbnail.size,
        0
      ) || 0;
    return acc + imagesSize + videosSize;
  }, 0);
  let filesArray = files.reduce((acc, { images = [], videos = [] }) => {
    return [...acc, ...images, ...videos];
  }, []);
  const hasFiles = files.reduce((acc, { images, videos }) => {
    return (
      acc || (!!images && !!images.length) || (!!videos && !!videos.length)
    );
  }, false);

  return { files, hasFiles, filesTotalSize, filesArray };
};

const createLessonChannel = (data) => {
  return eventChannel((emit) => {
    const { courseId, model } = data;
    const { files, hasFiles, filesTotalSize, filesArray } =
      lessonComposite(model);

    let lesson = null;
    LessonsService.create(courseId, model)
      .then(({ data }) => {
        lesson = data;
        if (!hasFiles) {
          return { data };
        } else {
          emit({ files: filesArray, filesTotalSize });
          const blocksWithMappedFiles = lesson.blocks.map(({ id }, index) => ({
            id,
            ...files[index],
          }));

          return Promise.all(
            blocksWithMappedFiles.map(async (block) => {
              let result = null;
              const onProgress = ({ loaded }, file, chunkIndex) => {
                emit({ file, loaded, chunkIndex });
              };
              if (!!block.images && !!block.images.length) {
                const files = block.images;
                result = await ImageService.uploadImageFile(
                  { files },
                  onProgress
                )
                  .then((response) => {
                    const imagesIds = response.reduce((acc, val) => {
                      acc.push(val.file.id);
                      return acc;
                    }, []);
                    return {
                      imagesIds,
                      blockId: block.id,
                    };
                  })
                  .catch((error) => {
                    throwCustomError(error);
                  });
              }
              if (!!block.videos && !!block.videos.length) {
                const res = await videosWithThumbnailHandler(block, onProgress);
                result = { ...result, ...res };
              }
              return result;
            })
          ).then((addFilesModel) =>
            LessonsService.addFiles(lesson.id, compact(addFilesModel)).catch(
              (error) => {
                throwCustomError(error);
              }
            )
          );
        }
      })
      .then((result) => {
        emit({ success: result });
        emit(END);
      })
      .catch((err) => {
        emit({ err });
        emit(END);
      });

    return () => { };
  });
};

const lessonUpdateComposite = (model) => {
  const {
    title,
    urlPart,
    ordinalNumber,
    isPreviewLesson,
    updateBlocks = [],
    addBlocks = [],
    deleteBlockIds = [],
  } = model;
  const files = [...updateBlocks, ...addBlocks].map(
    ({ images, videos, updatedVideoThumbnails }) => ({
      images,
      videos,
      updatedVideoThumbnails,
    })
  );

  const updateBlocksDTO = updateBlocks.map(
    ({ images, videos, updatedVideoThumbnails, ...rest }) => ({
      ...rest,
    })
  );
  const addBlocksDTO = addBlocks.map(
    ({ images, videos, updatedVideoThumbnails, ...rest }) => ({
      ...rest,
    })
  );
  let filesTotalSize = files.reduce(
    (acc, { images = [], videos = [], updatedVideoThumbnails = [] }) => {
      const imagesSize =
        images.reduce((sum, image) => sum + image.size, 0) || 0;
      const videosSize =
        videos.reduce(
          (sum, video) => sum + video.video.size + video.thumbnail.size,
          0
        ) || 0;
      const updatedThumbnailSize =
        updatedVideoThumbnails.reduce(
          (sum, updatedThumbnail) => sum + updatedThumbnail.thumbnail.size,
          0
        ) || 0;
      return acc + imagesSize + videosSize + updatedThumbnailSize;
    },
    0
  );
  let filesArray = files.reduce(
    (acc, { images = [], videos = [], updatedVideoThumbnails = [] }) => {
      return [...acc, ...images, ...videos, ...updatedVideoThumbnails];
    },
    []
  );
  const hasFiles = files.reduce(
    (acc, { images, videos, updatedVideoThumbnails }) => {
      return (
        acc ||
        (!!images && !!images.length) ||
        (!!videos && !!videos.length) ||
        (!!updatedVideoThumbnails && !!updatedVideoThumbnails)
      );
    },
    false
  );
  const formData = {
    title,
    urlPart,
    ordinalNumber,
    isPreviewLesson,
  };
  if (deleteBlockIds.length) {
    formData.deleteBlockIds = deleteBlockIds;
  }
  if (addBlocksDTO.length) {
    formData.addBlocks = addBlocksDTO;
  }
  if (updateBlocksDTO.length) {
    formData.updateBlocks = updateBlocksDTO;
  }

  return { formData, files, hasFiles, filesTotalSize, filesArray };
};

const updateLessonChannel = (data) => {
  return eventChannel((emit) => {
    const { lessonId, model } = data;
    const { formData, files, hasFiles, filesTotalSize, filesArray } =
      lessonUpdateComposite(model);
    let lesson = null;
    LessonsService.update(lessonId, formData)
      .then(({ data }) => {
        lesson = data;
        if (!hasFiles) {
          return { data };
        } else {
          emit({ files: filesArray, filesTotalSize });
          const blocksWithMappedFiles = lesson.blocks.map(({ id }, index) => ({
            id,
            ...files[index],
          }));

          return Promise.all(
            blocksWithMappedFiles.map(async (block) => {
              let result = null;
              let updateThumbnailResult = null;
              const onProgress = ({ loaded }, file, chunkIndex) => {
                emit({ file, loaded, chunkIndex });
              };
              if (!!block.images && !!block.images.length) {
                const files = block.images;
                result = await ImageService.uploadImageFile(
                  { files },
                  onProgress
                )
                  .then((response) => {
                    const imagesIds = response.reduce((acc, val) => {
                      acc.push(val.file.id);
                      return acc;
                    }, []);
                    return {
                      imagesIds,
                      blockId: block.id,
                    };
                  })
                  .catch((error) => {
                    throwCustomError(error);
                  });
              }

              if (
                !!block.updatedVideoThumbnails &&
                !!block.updatedVideoThumbnails.length
              ) {
                const files = block.updatedVideoThumbnails.map(
                  (thumbnailBlock) => thumbnailBlock.thumbnail
                );

                const videoIds = block.updatedVideoThumbnails.map(
                  (thumbnailBlock) => thumbnailBlock.videoId
                );
                const thumbnailResponse = await ImageService.uploadImageFile(
                  { files },
                  onProgress
                ).catch((error) => {
                  throwCustomError(error);
                });
                const videos = thumbnailResponse.reduce((acc, val, index) => {
                  acc.push({
                    videoId: videoIds[index],
                    videoThumbnailId: val.file.id,
                  });
                  return acc;
                }, []);
                const updateThumbnailResultBlock = {
                  videos,
                  blockId: block.id,
                };
                updateThumbnailResult = {
                  ...updateThumbnailResult,
                  ...updateThumbnailResultBlock,
                };
              }

              if (!!block.videos && !!block.videos.length) {
                const res = await videosWithThumbnailHandler(block, onProgress);
                result = { ...result, ...res };
              }

              return { result, updateThumbnailResult };
            })
          ).then(async (addFilesModel) => {
            const files = compact(
              addFilesModel.map((fileResult) => fileResult.result)
            );
            const thumbnails = compact(
              addFilesModel.map(
                (thumbnailsResult) => thumbnailsResult.updateThumbnailResult
              )
            );
            let result = null;
            if (files?.length) {
              const filesResult = await LessonsService.addFiles(
                lesson.id,
                files
              ).catch((error) => {
                throwCustomError(error);
              });

              result = { ...result, ...filesResult };
            }

            if (thumbnails?.length) {
              const ThumbnailResult =
                await LessonsService.changeVideoThumbnails(
                  lesson.id,
                  thumbnails
                ).catch((error) => {
                  throwCustomError(error);
                });

              result = { ...result, ...ThumbnailResult };
            }

            return result;
          });
        }
      })
      .then((result) => {
        emit({ success: result });
        emit(END);
      })
      .catch((err) => {
        emit({ err });
        emit(END);
      });

    return () => { };
  });
};

function* getLessonList({ payload }) {
  try {
    const response = yield call(getLessonListAsync, payload);
    yield put(getListLessonsSuccess(response));
  } catch (error) {
    yield put(getListLessonsError(error));
  }
}

function* refetchLessons({ payload }) {
  try {
    const response = yield call(getLessonListAsync, payload);
    yield put(refetchLessonsSuccess(response));
  } catch (error) {
    yield put(getListLessonsError(error));
  }
}

function* getLesson({ payload: { courseId } }) {
  try {
    const response = yield call(getLessonAsync, courseId);
    yield put(getLessonSuccess(response));
  } catch (error) {
    yield put(getLessonError(error));
  }
}

function* createLessonProgressListener(chan, courseId, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(createLessonError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(createLessonError(err));
        history.push(`/courses/${courseId}/lessons`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createLessonSuccess(success));
      history.push(`/courses/${courseId}/lessons`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* createLesson({ payload: { data, history } }) {
  try {
    const { courseId } = data;
    yield fork(
      createLessonProgressListener,
      createLessonChannel(data),
      courseId,
      history
    );
  } catch (error) {
    yield put(createLessonError(error));
  }
}

function* updateLessonProgressListener(chan, history, courseId) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(updateLessonError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(updateLessonError(err));
        history.push(`/courses/${courseId}/lessons`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updateLessonSuccess(success));
      history.push(`/courses/${success.data.courseId}/lessons`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* updateLesson({ payload: { data, history, courseId } }) {
  try {
    yield fork(
      updateLessonProgressListener,
      updateLessonChannel(data),
      history,
      courseId
    );
  } catch (error) {
    yield put(updateLessonError(error));
  }
}

function* removeLesson({ payload: { data } }) {
  try {
    const response = yield call(removeLessonAsync, data);
    yield put(removeLessonSuccess(response));
  } catch (error) {
    yield put(removeLessonError(error));
  }
}

function* getLessonsCount({ payload: { courseId } }) {
  try {
    const response = yield call(getLessonsCountAsync, courseId);
    yield put(getLessonsCountSuccess(response));
  } catch (error) {
    yield put(getLessonsCountError(error));
  }
}

export function* watchRefetchLessonsList() {
  yield takeEvery(REFETCH_LESSONS, refetchLessons);
};

export function* watchGetLessonList() {
  yield takeEvery(GET_LIST_LESSONS, getLessonList);
}

export function* watchCreateLesson() {
  yield takeEvery(CREATE_LESSON, createLesson);
}

export function* watchUpdateLesson() {
  yield takeEvery(UPDATE_LESSON, updateLesson);
}

export function* watchRemoveLesson() {
  yield takeEvery(REMOVE_LESSON, removeLesson);
}

export function* watchGetLesson() {
  yield takeEvery(GET_LESSON, getLesson);
}

export function* watchGetLessonsCount() {
  yield takeEvery(GET_LESSONS_COUNT, getLessonsCount);
}

function* coursesSaga() {
  yield all([
    fork(watchRefetchLessonsList),
    fork(watchGetLessonList),
    fork(watchCreateLesson),
    fork(watchUpdateLesson),
    fork(watchGetLesson),
    fork(watchGetLessonsCount),
    fork(watchRemoveLesson),
  ]);
}

export default coursesSaga;
