import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
    positionClass: "custom-toast-position",
    timeOut: 5000,
    preventDuplicates: true,
    newestOnTop: true,
    closeButton: true,
    toastClass: "custom-toast-class"
}

export default toastr;