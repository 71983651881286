import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import {
  changeEmail,
  setPassword,
  changeStatus,
  clearState,
  deleteUser,
  getUserProfile,
  revokeSubscription,
  sendRecoveryLink,
  provideSubscription
} from "../../../../store/actions";
import BlueSpinner from "../../../../components/Common/Spinner";
import Courses from "./courses";
import Preferenses from "./preferenses";
import BasicInfo from "./basicInfo";
import PersonalInfo from "./personalInfo";
import formatDate from "../../../../helpers/formatDate";
import SubscriptionStatusBadge from "../../../../components/Common/SubscriptionStatusBadge";
import capitalizeFirstLetter from "../../../../helpers/capitilizeFirstLetter";
import VerticalScrollbar from "../../../../components/Common/ScrollbarVertical";
import { useLocation } from "react-router";
import SEO from "../../../../components/Common/SEO";

function transformPersonalInfo(profile) {
  return [
    { label: "Email address:", value: profile.email },
    { label: "Date of registration:", value: formatDate(profile.createdAt) },
    { label: "Location:", value: profile.country || "-" },
    {
      label: "Subscription status:",
      value: SubscriptionStatusBadge(profile) || "-",
    },
    {
      label: "Subscription type:",
      value: capitalizeFirstLetter(profile.userSubscription?.billingType),
    },
    {
      label: "Subscription Plan Name:",
      value: profile.userSubscription?.subscriptionPlan?.braintreeId || "-",
    },
  ];
}

function UsersProfile({
  match,
  profileState,
  getUserProfile,
  changeStatus,
  sendRecoveryLink,
  changeEmail,
  setPassword,
  clearState,
  deleteUser,
  revokeSubscription,
  history,
  provideSubscription
}) {
  const { loading, profile, disabled } = profileState;
  const location = useLocation();

  function changeUsersStatus(status) {
    changeStatus({ id: profile.id, status });
  }

  function handleSendRecoveryLink() {
    sendRecoveryLink({ id: profile.id });
  }

  function handleChangeEmail(email, toForceUpdate) {
    changeEmail(profile.id, email, toForceUpdate);
  }

  function handleSetPassword(newPassword) {
    setPassword(profile.id, newPassword);
  }

  function handleProvideSubscription(model) {
    provideSubscription({ userId: profile.id, model });
  }

  function handleDeleteUser() {
    deleteUser({ id: match.params.id, history });
  }

  function handleRevokeSubscription() {
    revokeSubscription({ id: match.params.id });
  }

  useEffect(() => {
    getUserProfile(match.params.id);
  }, [match.params.id, getUserProfile]);

  useEffect(() => {
    return () => {
      clearState();
    };
  }, [location]);

  return (
    <VerticalScrollbar>
      <SEO title="User info" />
      <section className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <h4 className="mb-0 font-size-16">User info</h4>
          </div>
          {loading && <BlueSpinner />}
          {!loading && profile && (
            <Row>
              <Col lg={12}>
                <BasicInfo
                  avatar={profile.avatar}
                  username={profile.userName}
                  status={profile.status}
                  email={profile.email}
                  changeStatus={changeUsersStatus}
                  handleSendRecoveryLink={handleSendRecoveryLink}
                  handleChangeEmail={handleChangeEmail}
                  handleSetPassword={handleSetPassword}
                  handleProvideSubscription={handleProvideSubscription}
                  handleDeleteUser={handleDeleteUser}
                  handleRevokeSubscription={handleRevokeSubscription}
                  disabled={disabled}
                  isDisabledRevokeSubscription={!profile.isSubscriptionActive}
                />
              </Col>
              <Col lg={4}>
                <PersonalInfo info={transformPersonalInfo(profile)} />
              </Col>
              <Col lg={8}>
                <Preferenses
                  equipment={profile.equipment}
                  djSoftware={profile.djSoftware}
                  favouriteGenres={profile.favouriteGenres}
                />
              </Col>
              <Col lg={12}>
                <Courses id={match.params.id} />
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </VerticalScrollbar>
  );
}

const mapStateToProps = ({ Users }) => {
  return {
    profileState: Users,
  };
};

export default connect(mapStateToProps, {
  getUserProfile,
  changeStatus,
  sendRecoveryLink,
  changeEmail,
  setPassword,
  clearState,
  deleteUser,
  revokeSubscription,
  provideSubscription
})(UsersProfile);
