export const RECOVERY_SUCCESS = "The recovery link has been sent";
export const INVITATION_SUCCESS = "The invitation has been successfully sent";
export const USERNAME_CHANGED_SUCCESS =
  "New username has been successfully saved";
export const AVATAR_CHANGED_SUCCESS =
  "New avatar has been successfully saved";
export const AVATAR_DELETED_SUCCESS =
  "The avatar has been successfully deleted";
export const PASSWORD_CHANGED_SUCCESS =
  "New password has been successfully saved";
export const COURSES_ADDED = "Courses have been successfully added";
export const COURSES_DELETED = "The course has been successfully deleted";
export const COUPON_CREATED = "New course coupon has been successfully created";
export const COUPON_SUBSCRIPTION_CREATED = "New subscription coupon has been successfully created";
export const COUPON_DELETED = "The coupon has been deleted";
export const USER_DELETED = "The user has been successfully deleted";
export const REVOKE_SUBSCRIPTION_SUCCESS = "Revoke subscription success";
export const PROVIDE_SUBSCRIPTION_SUCCESS = "Provide subscription success";
