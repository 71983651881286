import Http from "./HttpService";

class PaymentsService extends Http {
  BASE_URL = "admins/payment-transactions";

  getList(params) {
    return this.get(this.BASE_URL, { params });
  }

  getOne(id) {
    return this.get(`${this.BASE_URL}/${id}`);
  }
}

export default new PaymentsService();
