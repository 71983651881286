import {
  UPLOAD_FAILURE,
  UPLOAD_INITIATE,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE_CLEAR,
} from "./actionTypes";

/**
 * @param {Array<{file: File, loaded: number}>} files file requested to be loaded
 * @param {number} filesTotalSize summary file size to be loaded, must be calculated on files loading request
 * @param {number} progress percentage of total files loading progress
 * */
const initialState = {
  files: [],
  filesTotalSize: null,
  progress: null,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_INITIATE:
      const { files, filesTotalSize } = action.payload;
      state = {
        ...state,
        filesTotalSize,
        files: files.map((file) => ({ file, loaded: { 0: 0 } })),
        progress: 0,
      };
      break;
    case UPLOAD_PROGRESS:
      const { file, loaded, chunkIndex = 0 } = action.payload;
      const filesInStore = state.files || [];
      const fileIndex = filesInStore?.findIndex(
        ({ file: fileExists }) => fileExists === file
      );
      if (fileIndex >= 0) {
        filesInStore[fileIndex].loaded[chunkIndex] = loaded;
      } else {
        filesInStore.push({ file, loaded: { [chunkIndex]: loaded } });
      }
      const loadedTotal = filesInStore.reduce(
        (acc, { loaded: fileLoaded }) =>
          acc + Object.values(fileLoaded).reduce((acc, val) => acc + val, 0),
        0
      );
      const progress = Math.floor((loadedTotal * 100) / state.filesTotalSize);
      state = {
        ...state,
        progress: state.progress > progress ? state.progress : progress,
        files: [...filesInStore],
      };
      break;
    case UPLOAD_SUCCESS:
      state = {
        ...state,
        files: null,
        filesTotalSize: null,
        progress: null,
      };
      break;
    case UPLOAD_FAILURE:
      state = {
        ...state,
        files: null,
        filesTotalSize: null,
        progress: null,
        error: action.payload,
      };
      break;
    case UPLOAD_FAILURE_CLEAR:
      state = {
        ...state,
        error: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
