import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import useModal from "../../../../hooks/useModal";
import ProgressDialog from "../../../../components/Modals/ProgressDialog";
import {
  getPremiumArticle,
  clearPremiumArticleFetched,
  updatePremiumArticle,
} from "../../../../store/dashboard/PremiumArticles/actions";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import SavePremiumArticle from "../../../../components/Forms/SavePremiumArticle";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TITLE = "Uploading";
const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const UpdatePremiumArticle = ({
  articles,
  getPremiumArticle,
  clearPremiumArticleFetched,
  updatePremiumArticle,
  filesError,
}) => {
  
  const { updated, loading, item } = articles;

  const {
    params: { articleId },
  } = useRouteMatch("/dj-hub/articles/:articleId/update");

  const [isDirty, updateDirty] = useState(false);
  const [ready, updateReadyState] = useState(false);

  const history = useHistory();

  const [modalVisible, toggleModal] = useModal();

  const savePremiumArticleCallback = useCallback((model) => {
    updatePremiumArticle({ id: articleId, model }, history);
  }, []);

  const isBlocked = updated ? false : isDirty;

  useEffect(() => {
    if(item){
      toggleModal(loading);
    }
  }, [loading, item]);

  useEffect(() => {
    if (!ready && articleId && !loading) {
      getPremiumArticle(articleId);
      updateReadyState(true);
    }
  }, [articleId, ready, loading]);

  useEffect(() => {
    return () => {
      clearPremiumArticleFetched();
    };
  }, []);

  return (
    <Fragment>
      <SEO title="Edit premium article" />
      <RouteLeavingGuard
        contentText="Changed data will not be saved. Are you sure you want to cancel?"
        when={isBlocked && !filesError}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => isBlocked}
      />
      <ProgressDialog
        isOpen={modalVisible}
        titleText={PROGRESS_TITLE}
        contentText={PROGRESS_TEXT}
      />
      <ScrollbarVertical>
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Edit premium article
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                {item && (
                  <SavePremiumArticle
                    model={item}
                    saveArticleFunc={savePremiumArticleCallback}
                    updateDirtyState={updateDirty}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </ScrollbarVertical>
    </Fragment>
  );
};

const mapStateToProps = ({ PremiumArticles, Progress }) => {
  return { articles: PremiumArticles, filesError: Progress.error };
};

export default connect(mapStateToProps, {
  getPremiumArticle,
  clearPremiumArticleFetched,
  updatePremiumArticle,
})(UpdatePremiumArticle);
