import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import {
  createCourseCategoryError,
  createCourseCategorySuccess,
  getCourseCategoryError,
  getCourseCategorySuccess,
  getListCoursesCategoriesError,
  getListCoursesCategoriesSuccess,
  removeCourseCategoryError,
  removeCourseCategorySuccess,
  updateCourseCategoryError,
  updateCourseCategorySuccess,
} from "./actions";
import {
  CREATE_COURSE_CATEGORY,
  GET_COURSE_CATEGORY,
  GET_LIST_COURSE_CATEGORIES,
  REMOVE_COURSE_CATEGORY,
  UPDATE_COURSE_CATEGORY,
} from "./actionTypes";
import CourseCategoriesService from "../../../services/CourseCategoriesService";
import FilesService from "../../../services/FilesService";
import { eventChannel } from "@redux-saga/core";
import { END } from "redux-saga";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import throwCustomError from "../../../helpers/throwCustomError";
import errorCodes from "../../../constants/errorCodes";
import ToastrService from "../../../services/ToastrService";

const getCourseCategoriesListAsync = async (pagination) => {
  return await CourseCategoriesService.getList(pagination);
};

const getCourseCategoryAsync = async (id) => {
  return await CourseCategoriesService.getOne(id);
};

const removeCourseCategoryAsync = async (id) => {
  return await CourseCategoriesService.remove(id);
};

const courseCategoryFilesChannel = (actionFunc, data, categoryId = null) => {
  return eventChannel((emit) => {
    const { name, files } = data;
    const request =
      categoryId === null
        ? actionFunc({ name })
        : actionFunc(categoryId, { name });

    if (!files || !files.length) {
      // here is only one file and it's mandatory
      request
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    } else {
      const onProgress = ({ loaded }, file, chunkIndex) => {
        emit({ file, loaded, chunkIndex });
      };
      let requestData;
      request
        .then(({ data }) => {
          requestData = data;
          emit({ files, filesTotalSize: files[0].size });
          return FilesService.uploadImageFile({ files }, onProgress).catch(
            (error) => {
              throwCustomError(error);
            }
          );
        })
        .then((filesResponse) => {
          return CourseCategoriesService.addImageToCategory(requestData.id, {
            fileId: filesResponse[0].file.id,
          }).catch((error) => {
            throwCustomError(error);
          });
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    }
  });
};

function* getCourseCategoriesList({ payload }) {
  try {
    const response = yield call(getCourseCategoriesListAsync, payload);
    yield put(getListCoursesCategoriesSuccess(response));
  } catch (error) {
    yield put(getListCoursesCategoriesError(error));
  }
}

function* getCourseCategories({ payload: { categoryId } }) {
  try {
    const response = yield call(getCourseCategoryAsync, categoryId);
    yield put(getCourseCategorySuccess(response));
  } catch (error) {
    yield put(getCourseCategoryError(error));
  }
}

function* createCourseCategoryProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, err, success } = yield take(
      chan
    );
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        ToastrService.error(err.message);
        yield put(createCourseCategoryError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(createCourseCategoryError(err));
        history.push("/training");
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createCourseCategorySuccess(success));
      history.push("/training");
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded }));
    }
  }
}

function* createCourseCategory({ payload: { category, history } }) {
  try {
    yield fork(
      createCourseCategoryProgressListener,
      courseCategoryFilesChannel(
        async (...rest) => await CourseCategoriesService.create(...rest),
        category
      ),
      history
    );
  } catch (error) {
    yield put(createCourseCategoryError(error));
  }
}

function* updateCourseCategoryProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, err, success } = yield take(
      chan
    );
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        ToastrService.error(err.message);
        yield put(updateCourseCategoryError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(updateCourseCategoryError(err));
        history.push("/training");
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updateCourseCategorySuccess(success));
      history.push("/training");
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded }));
    }
  }
}

function* updateCourseCategory({ payload: { categoryId, model, history } }) {
  try {
    yield fork(
      updateCourseCategoryProgressListener,
      courseCategoryFilesChannel(
        async (...rest) => await CourseCategoriesService.update(...rest),
        model,
        categoryId
      ),
      history
    );
  } catch (error) {
    yield put(updateCourseCategoryError(error));
  }
}

function* removeCourseCategory({ payload: { categoryId } }) {
  try {
    const response = yield call(removeCourseCategoryAsync, categoryId);
    yield put(removeCourseCategorySuccess(response));
  } catch (error) {
    yield put(removeCourseCategoryError(error));
  }
}

export function* watchGetCourseCategoriesList() {
  yield takeEvery(GET_LIST_COURSE_CATEGORIES, getCourseCategoriesList);
}

export function* watchCreateCourseCategory() {
  yield takeEvery(CREATE_COURSE_CATEGORY, createCourseCategory);
}

export function* watchUpdateCourseCategory() {
  yield takeEvery(UPDATE_COURSE_CATEGORY, updateCourseCategory);
}

export function* watchGetCourseCategory() {
  yield takeEvery(GET_COURSE_CATEGORY, getCourseCategories);
}

export function* watchRemoveCourseCategory() {
  yield takeEvery(REMOVE_COURSE_CATEGORY, removeCourseCategory);
}

function* trainingSaga() {
  yield all([
    fork(watchGetCourseCategoriesList),
    fork(watchCreateCourseCategory),
    fork(watchGetCourseCategory),
    fork(watchUpdateCourseCategory),
    fork(watchRemoveCourseCategory),
  ]);
}

export default trainingSaga;
