import {
  GET_ANALYTICS_RESET,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILED,
  GET_ANALYTICS
} from "./actionTypes";

const initialState = {
  allTotalSubscribersCount: 0,
  monthlyTotalSubscribersCount: 0,
  regularAnnualTotalSubscribersCount: 0,
  blackFridayAnnualTotalSubscribersCount: 0,
  allNewSubscribersCount: 0,
  monthlyNewSubscribersCount: 0,
  regularAnnualNewSubscribersCount: 0,
  blackFridayAnnualNewSubscribersCount: 0,
  allNewUnsubscribersCount: 0,
  monthlyNewUnsubscribersCount: 0,
  regularAnnualNewUnsubscribersCount: 0,
  blackFridayAnnualNewUnsubscribersCount: 0,
  isLoading: true,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ANALYTICS:
      return { ...state, isLoading: true };

    case GET_ANALYTICS_SUCCESS:
      return state = {
        ...state,
        ...payload.data,
        isLoading: false,
      };

    case GET_ANALYTICS_FAILED:
      return { ...initialState, isLoading: false };

    case GET_ANALYTICS_RESET:
      return initialState;

    default:
      return { ...state };
  }
};
