import {
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
  DELETE_SALE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_ERROR,
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_ERROR,
  GET_SALE_CLEAR,
} from "./actionTypes";

export const getSale = () => {
  return {
    type: GET_SALE,
  };
};

export const getSaleSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_SALE_SUCCESS,
  };
};

export const getSaleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_SALE_ERROR,
  };
};

export const getSaleClear = () => {
  return {
    type: GET_SALE_CLEAR,
  };
};

export const deleteSale = () => {
  return {
    type: DELETE_SALE,
  };
};

export const deleteSaleSuccess = () => {
  return {
    type: DELETE_SALE_SUCCESS,
  };
};

export const deleteSaleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: DELETE_SALE_ERROR,
  };
};

export const createSale = (model) => {
  return {
    payload: { model },
    type: CREATE_SALE,
  };
};

export const createSaleSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CREATE_SALE_SUCCESS,
  };
};

export const createSaleError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_SALE_ERROR,
  };
};
