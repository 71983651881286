export const GET_LIST_COURSE_CATEGORIES = "GET_LIST_COURSE_CATEGORIES";
export const GET_LIST_COURSE_CATEGORIES_SUCCESS =
  "GET_LIST_COURSE_CATEGORIES_SUCCESS";
export const GET_LIST_COURSE_CATEGORIES_ERROR =
  "GET_LIST_COURSE_CATEGORIES_ERROR";

export const CREATE_COURSE_CATEGORY = "CREATE_COURSE_CATEGORY";
export const CREATE_COURSE_CATEGORY_SUCCESS = "CREATE_COURSE_CATEGORY_SUCCESS";
export const CREATE_COURSE_CATEGORY_CLEAR = "CREATE_COURSE_CATEGORY_CLEAR";
export const CREATE_COURSE_CATEGORY_ERROR = "CREATE_COURSE_CATEGORY_ERROR";

export const GET_COURSE_CATEGORY = "GET_COURSE_CATEGORY";
export const GET_COURSE_CATEGORY_SUCCESS = "GET_COURSE_CATEGORY_SUCCESS";
export const GET_COURSE_CATEGORY_ERROR = "GET_COURSE_CATEGORY_ERROR";
export const GET_COURSE_CATEGORY_CLEAR = "GET_COURSE_CATEGORY_CLEAR";

export const UPDATE_COURSE_CATEGORY = "UPDATE_COURSE_CATEGORY";
export const UPDATE_COURSE_CATEGORY_SUCCESS = "UPDATE_COURSE_CATEGORY_SUCCESS";
export const UPDATE_COURSE_CATEGORY_ERROR = "UPDATE_COURSE_CATEGORY_ERROR";

export const REMOVE_COURSE_CATEGORY = "REMOVE_COURSE_CATEGORY";
export const REMOVE_COURSE_CATEGORY_SUCCESS = "REMOVE_COURSE_CATEGORY_SUCCESS";
export const REMOVE_COURSE_CATEGORY_ERROR = "REMOVE_COURSE_CATEGORY_ERROR";
export const REMOVE_COURSE_CATEGORY_CLEAR = "REMOVE_COURSE_CATEGORY_CLEAR";
