export const COURSES_STATUSES = {
  LIVE: "LIVE",
  DRAFT: "DRAFT",
};

export const ADDING_TYPES = {
  USER: "USER",
  ADMIN: "ADMIN",
};

export const getPublishModalDescription = (title) =>
  `The course <strong>${title}</strong> will become visible for everyone. Users will be able to purchase it. You can edit the course and add new lessons at any time. Changes will be published automatically after saving. Do you want to publish this course?`;

export const UNLIMITED = 'Unlimited'

export const RECOMMENDATIONS_COURSES_COUNT = 2;

export const MAX_COURSES_LIMIT = 100;