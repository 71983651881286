import React from "react";
import { Card, CardBody, Nav, NavItem, NavLink, } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import couponStates from "../../../../../constants/couponStates";

const HeaderComponent = ({ pageName }) => {
  return (
    <>
      <Card className='w-100'>
        <CardBody>
          <Nav className="nav nav-tabs nav-tabs-custom" role="tablist">
            {Object.keys(couponStates).map((keyState, index) => {
              const item = couponStates[keyState];
              
              return (
                <NavItem key={`dj-hub-state-${index}`} className="nav-item">
                  <NavLink
                    className={classNames(
                      "nav-link",
                      pageName === item.text && "active"
                    )}
                    tag={Link}
                    to={`/coupons/${item.url}`}
                  >
                    {item.text}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
        </CardBody>
      </Card>
    </>
  );
};

export default HeaderComponent;
