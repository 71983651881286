import React from "react";
import {
  Card,
  CardBody,
  Col,
  NavItem,
  Row,
  Alert,
  Nav,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import SEO from "../../../../components/Common/SEO";
import djHubStates from "../../../../constants/djHubStates";

const HeaderComponent = ({ alertText, pageName = djHubStates.articles }) => {
  return (
    <>
      <SEO title="DJ Hub management" />

      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">DJ HUB MANAGEMENT</h4>
          </div>
        </Col>
      </Row>
      {alertText && (
        <Row>
          <Col xs="12">
            <Alert color="success">{alertText}</Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Nav className="nav nav-tabs nav-tabs-custom" role="tablist">
                {Object.keys(djHubStates).map((state, index) => (
                  <NavItem key={`dj-hub-state-${index}`} className="nav-item">
                    <NavLink
                      className={classNames(
                        "nav-link",
                        pageName === djHubStates[state] && "active"
                      )}
                      tag={Link}
                      to={`/dj-hub/${djHubStates[state].toLowerCase()}`}
                    >
                      {djHubStates[state]}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default HeaderComponent;
