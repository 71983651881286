import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";
import calculateCurrentPage from "../../helpers/calculateCurrentPage";
import Placeholder from "./placeholder";
import overlayFactory from "react-bootstrap-table2-overlay";
import queryParser from "../../helpers/queryParser";
import TabelSearch from "./TabelSearch";
import styles from "./index.module.scss";
import classNames from "classnames";

const spinnerStyles = {
  overlay: (base) => ({ ...base, background: "rgba(255, 255, 255, 1)" }),
  spinner: (base) => ({
    ...base,
    width: "75px",
    "& svg circle": {
      stroke: "rgba(0, 0, 0, 1)",
    },
  }),
};

export default function Table({
  dataStructure,
  data,
  FiltersComponent,
  HeaderComponent,
  handleTableChange,
  totalCount,
  limit,
  offset,
  isRowClick = true,
  rowClickFunc,
  loading,
  rowClickPath,
  searchField = "",
  withLocation = true,
  placeholderText = "",
  placeholderClassName,
  containerClassName = "",
  PlaceholderButtonComponent = null,
  needSearch = false,
  hoverRows = true,
}) {
  const location = useLocation();
  const history = useHistory();

  const query = queryParser(location.search);

  const pagination = useMemo(
    () =>
      paginationFactory({
        sizePerPage: limit,
        totalSize: totalCount,
        custom: true,
        page: calculateCurrentPage(offset, limit),
      }),
    [limit, totalCount, offset]
  );

  const getOnClickEvent = useCallback((row) => {
    if (rowClickFunc) {
      rowClickFunc(row.id);
      return;
    }
    const path =
      (withLocation ? location.pathname : "") +
      (rowClickPath ? rowClickPath + `/${row.id}` : `/${row.id}`);
    history.push(path);
  }, []);

  const rowEvents = useMemo(
    () => ({ onClick: (event, row) => getOnClickEvent(row) }),
    [getOnClickEvent]
  );

  function getPlaceholderText({ limit, offset, ...otherQueryVariables }) {
    let text = placeholderText;

    if (!!Object.keys(otherQueryVariables).length) {
      text = "No results found";
    }

    return text.toLocaleUpperCase();
  }

  return (
    <Row className="m-0">
      <Col lg="12" className="p-0">
        <Card>
          <CardBody className={containerClassName}>
            {HeaderComponent && <HeaderComponent />}
            <PaginationProvider pagination={pagination}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={data || []}
                  columns={dataStructure(location)}
                  bootstrap4
                  search={{
                    defaultSearch: query[searchField] || "",
                  }}
                >
                  {(toolkitProps) => (
                    <>
                      {!!FiltersComponent && (
                        <FiltersComponent toolkitProps={toolkitProps} />
                      )}
                      {!FiltersComponent && needSearch && (
                        <TabelSearch toolkitProps={toolkitProps} />
                      )}
                      <Row>
                        <Col xl="12">
                          <div className={classNames("table-responsive", !hoverRows && styles.noHover)}>
                            <BootstrapTable
                              responsive
                              remote
                              rowEvents={isRowClick ? rowEvents : {}}
                              bordered={false}
                              striped={false}
                              loading={loading}
                              overlay={overlayFactory({
                                spinner: true,
                                styles: spinnerStyles,
                              })}
                              noDataIndication={() => (
                                <Placeholder
                                  text={getPlaceholderText(query)}
                                  placeholderClassName={placeholderClassName}
                                  PlaceholderButtonComponent={
                                    PlaceholderButtonComponent
                                  }
                                />
                              )}
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              headerWrapperClasses={"table-light"}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-4">
                        <Col className="pagination pagination-rounded justify-content-center mb-0 inner-custom-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  dataStructure: PropTypes.any,
  loading: PropTypes.bool,
  handleTableChange: PropTypes.func,
};
