import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Row, Col, Button, Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import {
  DEFAULT_ARTICLES_LIMIT,
  DEFAULT_OFFSET,
} from "../../../../constants/pagination";
import {
  getListPremiumArticles,
  removePremiumArticle,
  clearPremiumArticleRemove,
  getListPremiumArticlesInfiniteScroll,
} from "../../../../store/dashboard/PremiumArticles/actions";
import PremiumArticleCard from "../components/PremiumArticleCard";
import useSnackbarState from "../../../../hooks/useSnackbarState";
import InfiniteScroll from "../../../../components/Common/InfinitScroll";
import HeaderComponent from "../components/HeaderComponent";
import djHubStates from "../../../../constants/djHubStates";

import styles from "../Articles/Articles.module.scss";

const DELETE_ARTICLE = "The premium article has been deleted";

const DJHubDrafts = ({
  getListPremiumArticles,
  getListPremiumArticlesInfiniteScroll,
  premiumArticles,
  removePremiumArticle,
  clearPremiumArticleRemove,
}) => {
  const { removed, data, pagination, loading } = premiumArticles;
  const scrollRef = useRef(null);
  const [isRemoved] = useSnackbarState(
    removed,
    clearPremiumArticleRemove,
  );

  const fetchList = (nextPagination = {}) => {
    const { limit = DEFAULT_ARTICLES_LIMIT, offset = DEFAULT_OFFSET } = nextPagination;

    getListPremiumArticles({
      limit,
      offset,
      onlyDraft: true,
    });
  };

  const loadMore = useCallback(({ limit = DEFAULT_ARTICLES_LIMIT, offset = DEFAULT_OFFSET }) => {
    getListPremiumArticlesInfiniteScroll({
      offset,
      limit,
      onlyDraft: true,
    });
  }, [])

  useEffect(() => {
    fetchList();

    return () => {
      clearPremiumArticleRemove();
    };
  }, []);

  const removePremiumArticleCallback = useCallback(
    (id) => {
      if (!loading) {
        removePremiumArticle(id);
      }
    },
    [loading]
  );

  return (
    <InfiniteScroll
      className="w-100 h-100"
      loadMore={(offset) => loadMore({ offset })}
      ref={scrollRef}
      disabled={data?.length === 0 || loading}
      offset={pagination.nextOffset}
      nextOffset={pagination.nextOffset}
      totalCount={pagination.totalCount}
    >
      <div className="page-content">
        <Container fluid>
          <HeaderComponent
            alertText={isRemoved && DELETE_ARTICLE}
            pageName={djHubStates.drafts}
          />
          <Col xs="12" className="h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className={`font-size-16 ${styles.blockTitle}`}>
                Draft articles
              </div>
              <section className="d-flex flex-row">
                <Link to="/dj-hub/articles/create">
                  <Button
                    color="primary"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Create premium article
                  </Button>
                </Link>
              </section>
            </div>
            <Card className="mb-0">
              <CardBody className={styles.tabelHeadWr}>
                <Row className={classNames("align-items-center", styles.tabelHead)}>
                  <Col xs="6">Title</Col>
                  <Col xs="3">Section & Category</Col>
                  <Col xs="2">Date & Time</Col>
                  <Col xs="1">Action</Col>
                </Row>
              </CardBody>
            </Card>
            {!!data.length && (
              <Row className="mt-3">
                {data.map((premiumArticle, index) => (
                  <Col xs={12} key={`premium-article-${index}`}>
                    <PremiumArticleCard
                      isDraftCard
                      premiumArticle={premiumArticle}
                      removePremiumArticle={removePremiumArticleCallback}
                    />
                  </Col>
                ))}
              </Row>
            )}
            {loading && !!data.length && data.length < pagination.totalCount && (
              <Col xs={12}>
                <div className="text-center my-3">
                  <div className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                    Load more
                  </div>
                </div>
              </Col>
            )}
            {!data.length && !loading && (
              <div className="drafts-placeholder">
                There are no drafts created
              </div>
            )}
          </Col>
        </Container>
      </div>
    </InfiniteScroll>
  );
};
const mapStateToProps = ({ PremiumArticles: premiumArticles }) => ({
  premiumArticles,
});

export default withRouter(
  connect(mapStateToProps, {
    getListPremiumArticles,
    removePremiumArticle,
    getListPremiumArticlesInfiniteScroll,
    clearPremiumArticleRemove,
  })(DJHubDrafts)
);
