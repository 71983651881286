import { CLEAR_LIST_10_MONTH_SUBSCRIPTION_PLAN, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_ERROR, GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_SUCCESS, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_ERROR, TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_SUCCESS } from './actionTypes';

const initialState = {
  tenMonthSubscriptionPlans: [],
  error: "",
  tenMonthSubscriptionPlanListLoading: false,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_10_MONTH_SUBSCRIPTION_PLAN_LIST:
      state = {
        ...state,
        tenMonthSubscriptionPlanListLoading: true,
      };
      break;
    case GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_SUCCESS:
      state = {
        ...state,
        tenMonthSubscriptionPlans: action.payload.data,
        tenMonthSubscriptionPlanListLoading: false,
      };
      break;
    case GET_10_MONTH_SUBSCRIPTION_PLAN_LIST_ERROR:
      state = {
        ...state,
        tenMonthSubscriptionPlanListLoading: false,
      };
      break;
    case CLEAR_LIST_10_MONTH_SUBSCRIPTION_PLAN:
      state = {
        ...state,
        tenMonthSubscriptionPlans: [],
      };
      break;
    case TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN:
      state = {
        ...state,
        loading: true,
      };
      break;
    case TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case TOGGLE_SHOW_10_MONTH_SUBSCRIPTION_PLAN_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }

  return state;
};