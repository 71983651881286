import {
  GET_SALE,
  GET_SALE_SUCCESS,
  GET_SALE_ERROR,
  DELETE_SALE,
  DELETE_SALE_SUCCESS,
  DELETE_SALE_ERROR,
  CREATE_SALE,
  CREATE_SALE_SUCCESS,
  CREATE_SALE_ERROR,
  GET_SALE_CLEAR,
} from "./actionTypes";

const initialState = {
  item: null,
  error: "",
  loading: false,
  needGetSale: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SALE_SUCCESS:
      state = {
        ...state,
        item: action.payload.data,
        needGetSale: false,
        loading: false,
      };
      break;
    case GET_SALE_ERROR:
      state = {
        ...state,
        needGetSale: false,
        loading: false,
      };
      break;
    case GET_SALE_CLEAR:
      state = {
        ...state,
        needGetSale: false,
      };
      break;
    case DELETE_SALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_SALE_SUCCESS:
      state = {
        ...state,
        item: null,
        loading: false,
      };
      break;
    case DELETE_SALE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_SALE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_SALE_SUCCESS:
      state = {
        ...state,
        item: null,
        needGetSale: true,
        loading: false,
      };
      break;
    case CREATE_SALE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
