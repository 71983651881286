import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import {
  removeBundle,
  changeBundleStatus,
} from "../../../../store/dashboard/Bundles/actions";
import {
  COURSES_STATUSES,
} from "../../../../constants/courses";
import {
  getPublishModalDescription,
} from "../../../../constants/bundles";

import styles from "../../dashboard.module.scss";
import componentStyles from "./index.module.scss";
import Icon from "../../../../components/Common/Icon";

const DELETE_MODAL_TITLE = "Delete a bundle";
const DELETE_MODAL_DESCRIPTION =
  "Bundle info will be deleted. Are you sure you want to delete this bundle?";
const PUBLISH_MODAL_TITLE = "Publish bundle";

const BundleTableRow = ({ bundle, removeBundle, changeBundleStatus }) => {
  const [modalVisible, toggleModal] = useModal();
  const [publishModalVisible, togglePublishModal] = useModal();

  const handleConfirmRemove = () => {
    toggleModal();
    removeBundle(bundle.id);
  };

  const handleConfirmPublish = () => {
    togglePublishModal();
    changeBundleStatus(bundle.id);
  };
  return (
    <>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <ConfirmationDialog
        isOpen={publishModalVisible}
        titleText={PUBLISH_MODAL_TITLE}
        contentHtmlText={getPublishModalDescription(bundle.title)}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => togglePublishModal()}
        onConfirm={handleConfirmPublish}
        altBtnStyle
      />
      <tr>
        <td className={classNames(styles.tableTitle, "align-middle")}>
          <p
            className={styles.tableTitle}
          >
            {bundle.title}
          </p>
        </td>
        <td className="d-flex justify-content-end w-220px font-size-20 pr-5 ">
          {bundle.status === COURSES_STATUSES.LIVE ? (
            <span
              className={classNames(
                componentStyles.courseStatus,
                componentStyles.courseStatusLive
              )}
            >
              <span>Live</span>
              <Icon icon="checkGray" />
            </span>
          ) : (
            <Link
              to="#"
              onClick={() => togglePublishModal()}
              className={classNames(
                componentStyles.courseStatus,
                componentStyles.courseStatusDraft
              )}
            >
              <span>Publish</span>
              <Icon icon="arrowPrimary" />
            </Link>
          )}
          <Link to={`/bundles/${bundle.id}`}>
            <i className="bx bx-edit text-grey mr-3" />
          </Link>
          <Link to="#" onClick={() => toggleModal()}>
            <i className="bx bx-trash text-grey" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default connect(() => ({}), { removeBundle, changeBundleStatus })(
  BundleTableRow
);
