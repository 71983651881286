import React from "react";
import { Col, Row } from "reactstrap";

import SearchBar from "../../../../components/Common/searchBar";
import { DEFAULT_COMMENTS_LIMIT } from "../../../../constants/pagination";
import CustomReactPaginate from "../../../../components/Common/CustomReactPaginate";
import CommentFiltres from "./CommentFiltres";

const IndividualContentTopContent = ({
  handleEnterSearch,
  searchQuery,
  items,
  pagination,
  pageChange,
  showResetFilters,
  handleResetFilters,
  isFilterOpen,
  setIsFilterOpen,
  isPremiumArticlesComments,
  filterOption,
  handleChangeFilters,
  handleSubmitFilters,
}) => (
  <>
    <Row className="align-items-center mb-4">
      <Col lg="4">
        <SearchBar
          handleEnterSearch={handleEnterSearch}
          searchQuery={searchQuery}
        />
      </Col>
      <Col lg="4" className="d-flex justify-content-end">
        {!!items.length && pagination?.totalCount > DEFAULT_COMMENTS_LIMIT && (
          <CustomReactPaginate
            pagination={pagination}
            pageChange={pageChange}
            limit={DEFAULT_COMMENTS_LIMIT}
            className="mb-0"
            needForcePage
          />
        )}
      </Col>
      <Col lg="4" className="d-flex align-items-center justify-content-end">
        <CommentFiltres
          showResetFilters={showResetFilters}
          handleResetFilters={handleResetFilters}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
          isPremiumArticlesComments={isPremiumArticlesComments}
          filterOption={filterOption}
          handleChangeFilters={handleChangeFilters}
          handleSubmitFilters={handleSubmitFilters}
        />
      </Col>
    </Row>
  </>
);

export default IndividualContentTopContent;
