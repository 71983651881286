import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import CustomReactPaginate from "../../../../components/Common/CustomReactPaginate";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import djHubStates from "../../../../constants/djHubStates";
import {
  DEFAULT_OFFSET,
  DEFAULT_TAGS_LIMIT,
} from "../../../../constants/pagination";
import useSnackbarState from "../../../../hooks/useSnackbarState";
import {
  getPremiumArticlesTags,
  updatePremiumArticlesTag,
  clearPremiumArticlesTagUpdated,
} from "../../../../store/dashboard/PremiumArticlesTags/actions";
import HeaderComponent from "../components/HeaderComponent";
import PremiumArticleTags from "../components/PremiumArticleTags";

import styles from "./Tags.module.scss";

const DJHubTags = ({
  getPremiumArticlesTags,
  premiumArticlesTags,
  updatePremiumArticlesTag,
  clearPremiumArticlesTagUpdated,
}) => {
  const { data, updated, loading, pagination } = premiumArticlesTags;

  const [editId, setEditId] = useState(null);
  const [ready, setReady] = useState(false);
  const [items, updateItems] = useState([]);
  const [needUpdateTagsState, setNeedUpdateTagsState] = useState(false);

  const [isUpdatedTag] = useSnackbarState(
    updated,
    clearPremiumArticlesTagUpdated,
    () => setNeedUpdateTagsState(true)
  );

  const fetchList = (nextPagination = {}) => {
    const { limit = DEFAULT_TAGS_LIMIT, offset = DEFAULT_OFFSET } =
      nextPagination;
    getPremiumArticlesTags({ limit, offset });
  };

  useEffect(() => {
    fetchList();
    setReady(true);
  }, []);

  useEffect(() => {
    if (!loading && !!data && ready) {
      updateItems(data);
    }
  }, [data, loading]);

  useEffect(() => {
    if (needUpdateTagsState) {
      getPremiumArticlesTags({
        limit: DEFAULT_TAGS_LIMIT,
        offset: pagination.nextOffset - DEFAULT_TAGS_LIMIT,
      });
      setNeedUpdateTagsState(false);
    }
  }, [needUpdateTagsState]);

  const pageChange = ({ selected }) => {
    fetchList({
      limit: DEFAULT_TAGS_LIMIT,
      offset: selected * DEFAULT_TAGS_LIMIT,
    });
  };

  return (
    <ScrollbarVertical>
      <div className="page-content">
        <Container fluid>
          <HeaderComponent
            pageName={djHubStates.tags}
            alertText={
              isUpdatedTag && "Changes have been successfully saved"
            }
          />
          <Card>
            <CardBody className={styles.cardWr}>
              <CardTitle className={styles.cardTitle}>
                Premium article tags
              </CardTitle>
              {!!items && items.length > 0 && (
                <Row>
                  {items.map((premiumTag, index) => (
                    <Col
                      xs={12}
                      sm={4}
                      lg={4}
                      key={"premium-article-tag" + index}
                    >
                      <PremiumArticleTags
                        {...premiumTag}
                        updateFunc={updatePremiumArticlesTag}
                        editId={editId}
                        setEditId={setEditId}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </CardBody>
          </Card>
          <Row>
            <Col lg="12">
              <CustomReactPaginate
                pagination={pagination}
                pageChange={pageChange}
                pageCount={Math.ceil(
                  pagination.totalCount / DEFAULT_TAGS_LIMIT
                )}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ PremiumArticlesTags }) => {
  return {
    premiumArticlesTags: PremiumArticlesTags,
  };
};

export default connect(mapStateToProps, {
  getPremiumArticlesTags,
  updatePremiumArticlesTag,
  clearPremiumArticlesTagUpdated,
})(DJHubTags);
