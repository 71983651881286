import React from "react";
import { Fragment } from "react";
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import Checkbox from "../../../../components/Common/checkbox";
import { COMMENTS_FILTERS } from "../../../../constants/comments";

import styles from "../index.module.scss";

const CommentFiltres = ({
  showResetFilters,
  handleResetFilters,
  isFilterOpen,
  setIsFilterOpen,
  isPremiumArticlesComments,
  filterOption,
  handleChangeFilters,
  handleSubmitFilters,
}) => (
  <>
    {showResetFilters && (
      <Button
        className={styles.commentButton}
        color="primary"
        onClick={handleResetFilters}
      >
        Reset filters
      </Button>
    )}
    <Dropdown
      isOpen={isFilterOpen}
      toggle={() => setIsFilterOpen((prevState) => !prevState)}
      className="ml-3"
    >
      <DropdownToggle tag="button" className="btn user-list--dropdown__toggle">
        Filter <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-right user-list--dropdown">
        <span className="user-list--dropdown__title ms-2 mb-2">Status</span>
        {COMMENTS_FILTERS.map(({ label, value }, index) => (
          <Fragment key={`comment-filter-${index}`}>
            {isPremiumArticlesComments && index > 1 ? null : (
              <Checkbox
                value={value}
                onChange={() => handleChangeFilters(value)}
                checked={filterOption.includes(value)}
                label={label}
              />
            )}
          </Fragment>
        ))}
        <Button
          color="success"
          className="w-100 mt-4"
          onClick={handleSubmitFilters}
        >
          Apply
        </Button>
      </DropdownMenu>
    </Dropdown>
  </>
);

export default CommentFiltres;
