const customImageError = {
    code: "rezolution-too-large", message: "Image can not be bigger than 4000px*4000px",
}

const MAX_IMAGE_DEMENSION = 4000;

export default async function validateDropzoneImage(acceptedFiles, fileRejections) {
    const obj = acceptedFiles[0];
    await new Promise((resolve) => {
        const image = new Image()
        let url;
        image.onload = function () {
            obj.width = image.width
            obj.height = image.height
            if (obj.width < MAX_IMAGE_DEMENSION && obj.height < MAX_IMAGE_DEMENSION) {
                resolve(obj)
            } else {
                const errors = [customImageError]
                fileRejections = [{
                    errors,
                    file: obj
                }]
                acceptedFiles.pop();
                resolve(obj);
            }
        }
        url = URL.createObjectURL(obj)
        image.src = url
    }).catch(() => { });

    return { validatedAcceptedFiles: acceptedFiles, validatedFileRejections: fileRejections };
}