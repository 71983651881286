import {
  ADD_COURSE_TO_USER,
  ADD_COURSE_TO_USER_ERROR,
  ADD_COURSE_TO_USER_SUCCESS,
  CHANGE_EMAIL,
  CHANGE_EMAIL_ERROR,
  CHANGE_EMAIL_SUCCESS,
  PROVIDE_SUBSCRIPTION,
  PROVIDE_SUBSCRIPTION_ERROR,
  PROVIDE_SUBSCRIPTION_SUCCESS,
  CHANGE_USERS_STATUS,
  CHANGE_USERS_STATUS_ERROR,
  CHANGE_USERS_STATUS_SUCCESS,
  CLEAR_STATE,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_NOT_PURCHASED_USER_COURSES,
  GET_NOT_PURCHASED_USER_COURSES_ERROR,
  GET_NOT_PURCHASED_USER_COURSES_SUCCESS,
  GET_USER_COURSES,
  GET_USER_COURSES_ERROR,
  GET_USER_COURSES_SUCCESS,
  GET_USERS_LIST,
  GET_USERS_LIST_ERROR,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_PROFILE,
  GET_USERS_PROFILE_ERROR,
  GET_USERS_PROFILE_SUCCESS,
  REMOVE_COURSE_FROM_SUCCESS,
  REMOVE_COURSE_FROM_USER,
  REMOVE_COURSE_FROM_USER_ERROR,
  REVOKE_SUBSCRIPTION,
  REVOKE_SUBSCRIPTION_ERROR,
  REVOKE_SUBSCRIPTION_SUCCESS,
  SEND_RECOVERY_LINK,
  SEND_RECOVERY_LINK_ERROR,
  SEND_RECOVERY_LINK_SUCCESS,
  UPDATE_COURSE_LIST_CLEAR,
  SET_PASSWORD,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
} from "./actionTypes";

export const getUserList = (payload) => {
  return {
    payload,
    type: GET_USERS_LIST,
  };
};

export const clearState = () => {
  return {
    type: CLEAR_STATE,
  };
};
export const getUserListSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_USERS_LIST_SUCCESS,
  };
};

export const getUserListError = ({ message }) => {
  return {
    payload: { message },
    type: GET_USERS_LIST_ERROR,
  };
};

export const getUserProfile = (id) => {
  return {
    payload: id,
    type: GET_USERS_PROFILE,
  };
};

export const getUserProfileSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_USERS_PROFILE_SUCCESS,
  };
};

export const getUserProfileError = ({ message }) => {
  return {
    payload: { message },
    type: GET_USERS_PROFILE_ERROR,
  };
};

export const changeStatus = ({ id, status }) => {
  return {
    payload: { id, status },
    type: CHANGE_USERS_STATUS,
  };
};

export const changeStatusSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CHANGE_USERS_STATUS_SUCCESS,
  };
};

export const changeStatusError = ({ message }) => {
  return {
    payload: { message },
    type: CHANGE_USERS_STATUS_ERROR,
  };
};

export const sendRecoveryLink = ({ id }) => {
  return {
    payload: { id },
    type: SEND_RECOVERY_LINK,
  };
};

export const sendRecoveryLinkSuccess = () => {
  return {
    type: SEND_RECOVERY_LINK_SUCCESS,
  };
};

export const sendRecoveryLinkError = () => {
  return {
    type: SEND_RECOVERY_LINK_ERROR,
  };
};

export const changeEmail = (id, email, toForceUpdate) => {
  return {
    payload: { id, email, toForceUpdate },
    type: CHANGE_EMAIL,
  };
};

export const changeEmailSuccess = () => {
  return {
    type: CHANGE_EMAIL_SUCCESS,
  };
};

export const changeEmailError = () => {
  return {
    type: CHANGE_EMAIL_ERROR,
  };
};

export const setPassword = (id, newPassword) => {
  return {
    payload: { id, newPassword },
    type: SET_PASSWORD,
  };
};

export const setPasswordSuccess = () => {
  return {
    type: SET_PASSWORD_SUCCESS,
  };
};

export const setPasswordError = () => {
  return {
    type: SET_PASSWORD_ERROR,
  };
};

export const provideSubscription = (data) => {
  return {
    payload: { data },
    type: PROVIDE_SUBSCRIPTION,
  };
};

export const provideSubscriptionSuccess = () => {
  return {
    type: PROVIDE_SUBSCRIPTION_SUCCESS,
  };
};

export const provideSubscriptionError = () => {
  return {
    type: PROVIDE_SUBSCRIPTION_ERROR,
  };
};

export const getUsersCourses = (id, pagination) => {
  return {
    payload: { id, pagination },
    type: GET_USER_COURSES,
  };
};

export const getUsersCoursesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_USER_COURSES_SUCCESS,
  };
};

export const getUsersCoursesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_USER_COURSES_ERROR,
  };
};

export const getNotPurchasedUsersCourses = (id, pagination) => {
  return {
    payload: { id, pagination },
    type: GET_NOT_PURCHASED_USER_COURSES,
  };
};

export const getNotPurchasedUsersCoursesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_NOT_PURCHASED_USER_COURSES_SUCCESS,
  };
};

export const getNotPurchasedUsersCoursesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_NOT_PURCHASED_USER_COURSES_ERROR,
  };
};

export const addCourseToUser = (id, coursesIds) => {
  return {
    payload: { id, coursesIds },
    type: ADD_COURSE_TO_USER,
  };
};

export const addCourseToUserSuccess = () => {
  return {
    type: ADD_COURSE_TO_USER_SUCCESS,
  };
};

export const addCourseToUserError = ({ message }) => {
  return {
    payload: { message },
    type: ADD_COURSE_TO_USER_ERROR,
  };
};

export const removeCourseFromUser = (id, coursesIds) => {
  return {
    payload: { id, coursesIds },
    type: REMOVE_COURSE_FROM_USER,
  };
};

export const removeCourseFromUserSuccess = () => {
  return {
    type: REMOVE_COURSE_FROM_SUCCESS,
  };
};

export const removeCourseFromUserError = ({ message }) => {
  return {
    payload: { message },
    type: REMOVE_COURSE_FROM_USER_ERROR,
  };
};

export const updateCourseListClear = () => {
  return {
    type: UPDATE_COURSE_LIST_CLEAR,
  };
};

export const deleteUser = (payload) => {
  return {
    payload,
    type: DELETE_USER,
  };
};

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS,
  };
};

export const deleteUserError = ({ message }) => {
  return {
    payload: { message },
    type: DELETE_USER_ERROR,
  };
};

export const revokeSubscription = (payload) => {
  return {
    payload,
    type: REVOKE_SUBSCRIPTION,
  };
};

export const revokeSubscriptionSuccess = () => {
  return {
    type: REVOKE_SUBSCRIPTION_ERROR,
  };
};

export const revokeSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: REVOKE_SUBSCRIPTION_SUCCESS,
  };
};
