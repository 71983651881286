import React, { useState } from "react";

const ImgWithBubbleDefault = ({ imageLink, nameIcon }) => {
  const [isImageValid, setIsImageValid] = useState(true);

  return (
    <>
      {imageLink && isImageValid ? (
        <img
          src={imageLink}
          className="avatar-sm rounded-circle mb-4 float-left float-lg-none"
          alt="img"
          onError={() => {
            setIsImageValid(false);
          }}
        />
      ) : (
        <div className="avatar-sm mx-lg-auto mb-4 float-left float-lg-none">
          <span
            className={
              "avatar-title rounded-circle text-primary font-size-16 bg-soft-primary"
            }
          >
            {nameIcon}
          </span>
        </div>
      )}
    </>
  );
};

export default ImgWithBubbleDefault;
