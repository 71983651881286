import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { DEFAULT_ARTICLES_LIMIT, DEFAULT_OFFSET, } from "../../../../constants/pagination";
import {
  changeIsNewMark,
  clearPremiumArticleCreated,
  clearPremiumArticleUpdated,
  getListPremiumArticles,
  getListPremiumArticlesInfiniteScroll,
  removePremiumArticle,
  clearPremiumArticleRemove,
  reorderArticle,
} from "../../../../store/dashboard/PremiumArticles/actions";
import { uploadFailureClear } from "../../../../store/progress/actions";
import PremiumArticleCard from "../components/PremiumArticleCard";
import useSnackbarState from "../../../../hooks/useSnackbarState";
import useDebounce from "../../../../hooks/useDebounce";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import { MAX_SEARCH_LENGTH } from "../../../../constants/leghtValidity";
import SearchBar from "../../../../components/Common/searchBar";
import dashboardStyles from "../../dashboard.module.scss";
import HeaderComponent from "../components/HeaderComponent";
import ArticlesFilters from "./ArticlesFilters";

import styles from "./Articles.module.scss";

const MODAL_TITLE = "Error";
const MODAL_DESCRIPTION = "Files uploading has been failed";

const NEW_ARTICLE = "New premium article has been successfully created";
const EDIT_ARTICLE =
  "Changes to the premium article have been successfully saved";
const DELETE_ARTICLE = "The premium article has been deleted";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return { result, removed };
};

const DJHubArticles = ({
  getListPremiumArticlesInfiniteScroll,
  getListPremiumArticles,
  PremiumArticles,
  changeIsNewMark,
  removePremiumArticle,
  clearPremiumArticleRemove,
  clearPremiumArticleCreated,
  clearPremiumArticleUpdated,
  filesError,
  uploadFailureClear,
  reorderArticle
}) => {
  const {
    data,
    loading,
    pagination,
    created,
    updated,
    removed,
    categoryFilter,
    reorderLoading
  } = PremiumArticles;

  const [searchQuery, setSearchQuery] = useState("");
  const [isRequestedBefore, setIsRequestedBefore] = useState(false)

  const debouncedSearchTerm = useDebounce(searchQuery);
  const [alertValues, setAlertValues] = useState(null);
  const [filters, setFilters] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const [shouldLoadMore, setShouldLoadMore] = useState(false);

  const [isCreated] = useSnackbarState(created, clearPremiumArticleCreated);
  const [isUpdated] = useSnackbarState(updated, clearPremiumArticleUpdated);
  const [isRemoved] = useSnackbarState(
    removed,
    clearPremiumArticleRemove,
  );

  function getAlertText() {
    return (
      (isCreated && NEW_ARTICLE) ||
      (isUpdated && EDIT_ARTICLE) ||
      (isRemoved && DELETE_ARTICLE)
    );
  }

  const showAlert = useCallback(() => {
    if (isCreated || isUpdated || isRemoved) {
      setAlertValues({
        text: getAlertText(),
      });
      return;
    }

    setAlertValues(null);
  }, [isCreated, isUpdated, isRemoved, setAlertValues]);

  const fetchList = (
    nextPagination = {},
    query = debouncedSearchTerm || null,
    premiumArticleCategoryId = filters || null
  ) => {
    const { limit = DEFAULT_ARTICLES_LIMIT, offset = DEFAULT_OFFSET } =
      nextPagination;

    getListPremiumArticles({
      limit,
      offset,
      q: query?.length ? query : null,
      premiumArticleCategoryId: premiumArticleCategoryId || null,
    });
  };

  const loadMore = ({
    limit = DEFAULT_ARTICLES_LIMIT,
    offset = DEFAULT_OFFSET,
    query = debouncedSearchTerm || null,
    premiumArticleCategoryId = filters || null
  }) => {
    getListPremiumArticlesInfiniteScroll({
      limit,
      offset,
      q: query,
      premiumArticleCategoryId,
    });
  }

  useEffect(() => {
    fetchList(
      {},
      searchQuery,
      categoryFilter
    );

    setIsRequestedBefore(true);

    return () => {
      clearPremiumArticleCreated();
      clearPremiumArticleRemove();
      clearPremiumArticleUpdated();
      uploadFailureClear();
    };
  }, []);

  useEffect(() => {
    if (isRequestedBefore) {
      fetchList(
        {},
        debouncedSearchTerm,
        filters
      );
    }
  }, [debouncedSearchTerm, filters]);

  useEffect(() => {
    setFilters(categoryFilter?.value || "");
  }, [categoryFilter]);

  const whatsNewChange = useCallback(
    (id, isNew) => {
      if (!loading) {
        changeIsNewMark({ id, isNew });
      }
    },
    [loading]
  );

  const removePremiumArticleCallback = useCallback(
    (id) => {
      if (!loading) {
        removePremiumArticle(id);
      }
    },
    [loading]
  );

  const handleEnterSearch = ({ target: { value } }) => {
    const trimmedSearch = value.trimLeft();

    if (trimmedSearch.length <= MAX_SEARCH_LENGTH) {
      setSearchQuery(trimmedSearch);
    }
  };

  useEffect(() => {
    showAlert();
  }, [showAlert]);

  const onDragEnd = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    const { removed: movedElement, result: reorderResult } = reorder(
      data,
      result.source.index,
      result.destination.index
    );

    reorderArticle(movedElement.id, result.destination.index + 1, filters, reorderResult)
    setIsDragging(false);
  }

  useEffect(() => {
    if (shouldLoadMore && !isDragging) {
      loadMore({ offset: pagination.nextOffset });
      setShouldLoadMore(false);
    }
  }, [shouldLoadMore, isDragging])

  return (
    <div className="page-content scrollable-page" id="scrollable-page">
      <Container fluid>
        <HeaderComponent alertText={alertValues && alertValues.text} />
        <Col xs="12" className="h-100">
          <div className="d-flex justify-content-between">
            <div className={`font-size-16 ${styles.blockTitle}`}>
              Premium articles
            </div>
            <section className="d-flex flex-row">
              <Link to="/dj-hub/articles/create">
                <Button
                  color="primary"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Create premium article
                </Button>
              </Link>
            </section>
          </div>
          <div className="d-flex flex-row flex-wrap align-items-end justify-content-between mt-2 mb-3">
            <ArticlesFilters needDisable={!!searchQuery} />
            <SearchBar
              className={styles.search}
              handleEnterSearch={handleEnterSearch}
              searchQuery={searchQuery}
              disabled={!!filters}
            />
          </div>
          <ConfirmationDialog
            isOpen={filesError?.isImageError}
            titleText={MODAL_TITLE}
            contentText={MODAL_DESCRIPTION}
            confirmButtonText="OK"
            bodyClassName={dashboardStyles.modalBody}
            confirmBtnClassName={dashboardStyles.modalConfirm}
            onCancel={() => uploadFailureClear()}
            onConfirm={() => uploadFailureClear()}
          />
          <Card className="mb-0">
            <CardBody className={styles.tabelHeadWr}>
              <Row
                className={classNames("align-items-center", styles.tabelHead)}
              >
                <Col xs="1">What’s new</Col>
                <Col xs="4">Title</Col>
                <Col xs="2">Section & Category</Col>
                <Col xs="1">Status</Col>
                <Col xs="1">Likes Count</Col>
                <Col xs="2">Date & Time</Col>
                <Col xs="1">Action</Col>
              </Row>
            </CardBody>
          </Card>
          {!!data.length && (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setIsDragging(true)} className="mb-0">
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <InfiniteScroll
                      dataLength={data.length}
                      next={() => {
                        if (isDragging) {
                          setShouldLoadMore(true);
                          return;
                        }

                        loadMore({ offset: pagination.nextOffset })
                      }}
                      hasMore={pagination.totalCount > data.length}
                      scrollableTarget="scrollable-page"
                      className="scrollable-page__container"
                    >
                      <Row className="mt-3 pb-5 mb-3">
                        {data.map((premiumArticle, index) => (
                          <Draggable
                            key={premiumArticle.id}
                            draggableId={`${premiumArticle.id}`}
                            index={index}
                            isDragDisabled={!filters || reorderLoading}
                          >
                            {(provided, snapshot) => (
                              <Col xl={12}>
                                <div
                                  key={"premium-article-" + index}
                                  ref={provided.innerRef}
                                  className="mb-3"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <PremiumArticleCard
                                    premiumArticle={premiumArticle}
                                    whatsNewChange={whatsNewChange}
                                    removePremiumArticle={removePremiumArticleCallback}
                                    index={index}
                                    isDragging={snapshot.isDragging}
                                    filters={filters}
                                  />
                                </div>
                              </Col>
                            )}
                          </Draggable>
                        ))}
                      </Row>
                      {provided.placeholder}
                    </InfiniteScroll>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {loading && !!data.length && data.length < pagination.totalCount && (
            <Col xs={12}>
              <div className="text-center my-3">
                <div className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                  Load more
                </div>
              </div>
            </Col>
          )}
          {!data.length && !loading && !searchQuery && !filters && (
            <div className="d-flex">
              <div className={styles.noArticles}>
                <p>THERE ARE NO PREMIUM ARTICLE CREATED</p>
                <Link to="/dj-hub/articles/create">
                  <Button color="primary">Create premium article</Button>
                </Link>
              </div>
            </div>
          )}
          {!data.length && !loading && (searchQuery || filters) && (
            <div className="d-flex">
              <div className={styles.noArticles}>
                <p className="list-search-bar__placeholder">
                  No results found
                </p>
              </div>
            </div>
          )}
        </Col>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { PremiumArticles, Progress } = state;

  return {
    PremiumArticles,
    filesError: Progress.error,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getListPremiumArticles,
    getListPremiumArticlesInfiniteScroll,
    clearPremiumArticleCreated,
    changeIsNewMark,
    removePremiumArticle,
    clearPremiumArticleRemove,
    clearPremiumArticleUpdated,
    uploadFailureClear,
    reorderArticle,
  })(DJHubArticles)
);
