import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";

import {
  changeIsNewMarkError,
  changeIsNewMarkSuccess,
  createPremiumArticleError,
  createPremiumArticleSuccess,
  getListPremiumArticlesError,
  getListPremiumArticlesSuccess,
  getPremiumArticleError,
  getPremiumArticleSuccess,
  removePremiumArticleError,
  removePremiumArticleSuccess,
  updatePremiumArticleError,
  updatePremiumArticleSuccess,
  reorderSuccess,
  reorderError,
  getListPremiumArticlesInfiniteScrollSuccess,
} from "./actions";

import {
  CHANGE_IS_NEW_MARK,
  CREATE_PREMIUM_ARTICLE,
  GET_LIST_PREMIUM_ARTICLES,
  GET_PREMIUM_ARTICLE,
  REMOVE_PREMIUM_ARTICLE,
  UPDATE_PREMIUM_ARTICLE,
  REORDER_ARTICLE,
  GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL
} from "./actionTypes";

import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";

import PremiumArticlesService from "../../../services/PremiumArticlesService";
import ObjectWithBlocksSagaHepler from "../../../helpers/ObjectWithBlocksSagaHepler";

const getPremiumArticlesListAsync = async (pagination) => {
  return await PremiumArticlesService.getList(pagination);
};

const getPremiumArticleAsync = async (id, noFilePreload) => {
  return await PremiumArticlesService.getOne(id, noFilePreload);
};

const changeIsNewMarkAsync = async ({ id, isNew }) => {
  return await PremiumArticlesService.changeIsNewMark(id, isNew);
};

const removePremiumArticleAsync = async (id) => {
  return await PremiumArticlesService.remove(id);
};

const reorderArticleAsync = async (articleId, ordinalNumber, premiumArticleCategoryId) => {
  return await PremiumArticlesService.changeOrdinalNumber(articleId, ordinalNumber, premiumArticleCategoryId);
};

function* getPremiumArticlesList({ payload }) {
  try {
    const response = yield call(getPremiumArticlesListAsync, payload);
    yield put(getListPremiumArticlesSuccess(response));
  } catch (error) {
    yield put(getListPremiumArticlesError(error));
  }
}

function* getPremiumArticlesListInfiniteScroll({ payload }) {
  try {
    const response = yield call(getPremiumArticlesListAsync, payload);
    yield put(getListPremiumArticlesInfiniteScrollSuccess(response));
  } catch (error) {
    yield put(getListPremiumArticlesError(error));
  }
}

function* getPremiumArticle({ payload: { id, noFilePreload } }) {
  try {
    const response = yield call(getPremiumArticleAsync, id, noFilePreload);
    yield put(getPremiumArticleSuccess(response));
  } catch (error) {
    yield put(getPremiumArticleError(error));
  }
}

function* changeIsNewMark({ payload }) {
  try {
    const response = yield call(changeIsNewMarkAsync, payload);
    yield put(changeIsNewMarkSuccess(response));
  } catch (error) {
    yield put(changeIsNewMarkError(error));
  }
}

function* removePremiumArticle({ payload: { id } }) {
  try {
    yield call(removePremiumArticleAsync, id);
    yield put(removePremiumArticleSuccess(id));
  } catch (error) {
    yield put(removePremiumArticleError(error));
  }
}

function* createPremiumArticleProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (!err.isImageError) {
        yield put(createPremiumArticleError(err));
        return;
      }
      yield put(uploadFailure(err));
      yield put(createPremiumArticleError(err));
      history.push("/dj-hub");

      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createPremiumArticleSuccess(success));
      history.push("/dj-hub");
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* createPremiumArticle({ payload: { data, history } }) {
  try {
    yield fork(
      createPremiumArticleProgressListener,
      ObjectWithBlocksSagaHepler.create(
        data,
        PremiumArticlesService.create.bind(PremiumArticlesService),
        PremiumArticlesService.addFiles.bind(PremiumArticlesService),
        PremiumArticlesService.addOverview.bind(PremiumArticlesService)
      ),
      history
    );
  } catch (error) {
    yield put(createPremiumArticleError(error));
  }
}

function* updatePremiumArticleProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (!err.isImageError) {
        yield put(updatePremiumArticleError(err));
        return;
      }
      yield put(uploadFailure(err));
      yield put(updatePremiumArticleError(err));
      history.push("/dj-hub");
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updatePremiumArticleSuccess(success));
      history.push("/dj-hub");
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* updatePremiumArticle({ payload: { data, history } }) {
  try {
    if (!data.model.isLive) {
      yield call(PremiumArticlesService.changeScheduledDate.bind(PremiumArticlesService), data.id, data.model.scheduledDate);
    }
    yield fork(
      updatePremiumArticleProgressListener,
      ObjectWithBlocksSagaHepler.update(
        data,
        PremiumArticlesService.update.bind(PremiumArticlesService),
        PremiumArticlesService.addFiles.bind(PremiumArticlesService),
        PremiumArticlesService.addOverview.bind(PremiumArticlesService),
        PremiumArticlesService.changeVideoThumbnails.bind(PremiumArticlesService),
      ),
      history
    );
  } catch (error) {
    yield put(createPremiumArticleError(error));
  }
}

function* reorderArticle({ payload: { articleId, ordinalNumber, premiumArticleCategoryId, data } }) {
  try {
    yield call(reorderArticleAsync, articleId, ordinalNumber, premiumArticleCategoryId)
    yield put(reorderSuccess(data));
  } catch (error) {
    yield put(reorderError())
  }
}

export function* watchGetPremiumArticlesList() {
  yield takeEvery(GET_LIST_PREMIUM_ARTICLES, getPremiumArticlesList);
}

export function* watchGetPremiumArticlesListInfiniteScroll() {
  yield takeEvery(GET_LIST_PREMIUM_ARTICLES_INFINITE_SCROLL, getPremiumArticlesListInfiniteScroll);
}

export function* watchGetPremiumArticle() {
  yield takeEvery(GET_PREMIUM_ARTICLE, getPremiumArticle);
}

export function* watchCreatePremiumArticle() {
  yield takeEvery(CREATE_PREMIUM_ARTICLE, createPremiumArticle);
}

export function* watchUpdatePremiumArticle() {
  yield takeEvery(UPDATE_PREMIUM_ARTICLE, updatePremiumArticle);
}

export function* watchChangeIsNewMark() {
  yield takeEvery(CHANGE_IS_NEW_MARK, changeIsNewMark);
}

export function* watchRemovePremiumArticle() {
  yield takeEvery(REMOVE_PREMIUM_ARTICLE, removePremiumArticle);
}

export function* watchReorderArticle() {
  yield takeEvery(REORDER_ARTICLE, reorderArticle);
}

function* premiumArticlesSaga() {
  yield all([
    fork(watchGetPremiumArticlesList),
    fork(watchGetPremiumArticle),
    fork(watchUpdatePremiumArticle),
    fork(watchCreatePremiumArticle),
    fork(watchChangeIsNewMark),
    fork(watchRemovePremiumArticle),
    fork(watchReorderArticle),
    fork(watchGetPremiumArticlesListInfiniteScroll),
  ]);
}

export default premiumArticlesSaga;
