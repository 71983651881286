import Http from "./HttpService";
import pause from "../helpers/pause";
import noop from "../helpers/noop";
import FileHelper from "../helpers/FileHelper";
import Axios from "axios";

const FILE_CHUNK_SIZE = 4 * 1000 * 1000 * 1000; // 4 Gb

class FileService extends Http {
  /**
   * Sending request on adding file to S3
   * fileType is a constants variable
   * */
  requestLoading(model) {
    return this.post("/files", model, { disableToast: true });
  }

  requestLoadingMultipart(model) {
    return this.post("/files/multipart", model, { disableToast: true });
  }

  completeLoadingMultipart(model) {
    return this.post("/files/multipart/complete", model, {
      disableToast: true,
    });
  }

  /**
   * Direct S3 file loading
   * */
  uploadToS3(model, onUploadProgress = noop()) {
    const { data, files } = model;
    const promises = data.map((data, index) => {
      const formData = new FormData();
      Object.keys(data.meta.formData).forEach((key) => {
        formData.append(key, data.meta.formData[key].toString());
      });
      formData.append("file", files[index]);
      return this.post(data.meta.url, formData, {
        onUploadProgress: (e) => onUploadProgress(e, files[index]),
        disableToast: true,
      }).then(() => data.data);
    });

    return Promise.all(promises);
  }

  uploadToS3Multipart(model, onUploadProgress = noop()) {
    const axios = Axios.create();
    delete axios.defaults.headers.put["Content-Type"];

    const { data, files } = model;
    const promises = data.map((fileData, index) => {
      const { file, uploadId, uploadLinks } = fileData;
      const chunks = FileHelper.createChunks(files[index], FILE_CHUNK_SIZE);

      return uploadLinks.map((link, chunkIndex) =>
        axios
          .put(link, chunks[chunkIndex], {
            disableToast: true,
            onUploadProgress: (e) =>
              onUploadProgress(e, files[index], chunkIndex + 1),
          })
          .then((res) => {
            const etag = res.headers.etag.replace(/\"/g, "");

            return {
              uploadId,
              fileId: file.id,
              key: file.fileKey,
              parts: [
                {
                  etag,
                  partNumber: chunkIndex + 1,
                },
              ],
            };
          })
      );
    });

    return Promise.all(promises.flat()).then((result) =>
      result.reduce((acc, val) => {
        const index = acc.findIndex((r) => r.fileId === val.fileId);
        if (index >= 0) {
          acc[index].parts.push(...val.parts);
        } else {
          acc.push(val);
        }
        return acc;
      }, [])
    );
  }

  /**
   * Update files state method
   * @param {{filesIds: number[]}} model is an object with array of files ids;
   * */
  updateFilesState(model) {
    return this.patch("/files", model, { disableToast: true });
  }

  /**
   * Resize images by IDs
   * @param {{filesIds: number[]}} model is an object with array of files ids;
   * */
  resizeImageFile(model) {
    return this.patch("/files/images/resize", model, { disableToast: true });
  }

  /**
   * Convert videos by IDs
   * @param {{filesIds: number[]}} model is an object with array of files ids;
   * */
  convertVideos(model) {
    return this.patch("/files/videos/convert", model, { disableToast: true });
  }

  /**
   * Upload files
   * @param {File[]} model array of files to upload
   * @param {function} onProgress on upload progress effect function
   * */
  async uploadFiles(model, onProgress = noop) {
    const { files } = model;
    const contentTypes = files.map((file) => ({ contentType: file.type }));
    const { data } = await this.requestLoading(contentTypes);
    await this.uploadToS3({ data, files }, onProgress);
    const filesIds = data.map(({ file: { id } }) => id);
    await this.updateFilesState({ filesIds });

    return { data, filesIds };
  }

  /**
   * Transforms file to required format before sending multipart upload request
   * @param {File} file 
   * @returns {{height: number, width:number, contentType: string, partsCount: number}} Multipart upload
   */
  async processVideo(file) {
    const { height, width } = await FileHelper.getDimesnsions(file);

    return {
      height,
      width,
      contentType: file.type,
      partsCount: Math.ceil(file.size / FILE_CHUNK_SIZE),
    }
  }

  async uploadFilesMultipart(model, onProgress = noop) {
    const { files } = model;
    const requestParameters = await Promise.all(files.map(this.processVideo));
    const { data } = await this.requestLoadingMultipart(requestParameters);
    const response = await this.uploadToS3Multipart(
      { data, files },
      onProgress
    );
    await this.completeLoadingMultipart(response);
    const filesIds = response.map(({ fileId }) => fileId);

    return { data, filesIds };
  }

  /**
   * Simplified images uploading process, with resize request, response is postponed by 3000 ms
   * @param {File[]} model array of images to upload
   * @param {function} onProgress on upload progress effect function
   * */
  async uploadImageFile(model, onProgress = noop) {
    const { files } = model;
    const { data, filesIds } = await this.uploadFiles(model, onProgress);
    // there is no way to define in which order files came from backend, so I simply map file sizes to responding file ids
    const resizeModel = {
      files: files.map((file, index) => ({
        id: filesIds[index],
        sizes: file.sizes,
      })),
    };
    await this.resizeImageFile(resizeModel);

    return pause(3000)(data);
  }

  /**
   * Simplified videos uploading process, with convert request, response is postponed by 3000 ms
   * @param {File[]} model array of videos to upload
   * @param {function} onProgress on upload progress effect function
   * */
  async uploadVideoFile(model, onProgress = noop) {
    const { data, filesIds } = await this.uploadFilesMultipart(
      model,
      onProgress
    );
    await this.convertVideos({ filesIds });

    return pause(3000)(data);
  }
}

export default new FileService();
