import { MAX_SERVER_LIMIT } from '../constants/leghtValidity';

export default async function downloadAllData(getData, query) {
    let data = [];
    try {
        const serverResponse = await getData(query);
        data = [...serverResponse.data];
        let offset = 0;
        while (serverResponse.pagination.totalCount > offset + MAX_SERVER_LIMIT) {
            offset += MAX_SERVER_LIMIT;
            const nextServerResponse = await getData({ ...query, offset });
            data = [...data, ...nextServerResponse.data];
        }
    } catch {
        //empty 
    }
    return data;
}