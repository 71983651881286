import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import Select, { createFilter } from "react-select";
import {
  createDefaultSelected,
  createOptionsList,
  SelectWithCheckboxCustomStyles,
  TextWithCheckBoxControlComponent,
  TextWithCheckBoxInput,
  TextWithCheckBoxMenuList,
  TextWithCheckboxOption
} from "../../../../../helpers/multiSelectInputWithCheckboxes";
import { setCourseRecommendations } from "../../../../../store/dashboard/Courses/actions";
import useModal from "../../../../../hooks/useModal";
import ConfirmationDialog from "../../../../../components/Modals/ConfirmationDialog";
import { RECOMMENDATIONS_COURSES_COUNT } from "../../../../../constants/courses";

import styles from './RecommendedCoursesSelect.module.scss';

const WARNING_MODAL_TITLE = "Too many recommended courses";
const WARNING_MODAL_MODAL_DESCRIPTION = (value) =>
  `You have selected ${value} courses.
  </br>
  Please select from 0 to 2 courses.`;

const RecommendedCoursesSelect = ({
  courseId,
  setCourseRecommendations,
  loading,
  coursesList,
  dataRecommendations,
  loadingTriggerCourse,
  loadingRecommendations,
}) => {
  const [modalVisible, toggleModal] = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef();
  const helperRef = useRef({});

  const selectedRecommendationIds = coursesList.find(course => course.id === courseId)?.recommendations?.map((item) => item.id) || [];
  const optionsList = createOptionsList(dataRecommendations || [], "id", "title").filter((item) => item.value !== courseId);
  const defaultValues = createDefaultSelected(optionsList, selectedRecommendationIds);
  const getNewRecommendationIds = (values) => ({
    recommendationIds: values?.map(item => item.value) || []
  });
  const [selected, setSelected] = useState(null);

  const onMenuOpen = () => {
    setIsMenuOpen(true);
    if (selectRef.current) {
      selectRef.current.focus()
    }
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
    if (selectRef.current) {
      selectRef.current.blur()
    }
  }

  const onSubmit = (values) => {
    if (values?.length > RECOMMENDATIONS_COURSES_COUNT) {
      toggleModal();
      return;
    }
    const recommendationIds = getNewRecommendationIds(values);
    setCourseRecommendations(courseId, recommendationIds);
    helperRef.current.isSubmitting = true
    onMenuClose()
    helperRef.current.isSubmitting = false
  }

  const handleChange = (value) => {
    setSelected(value);
  };

  const onClose = () => {
    !helperRef.current?.isSubmitting && setSelected(defaultValues)
    onMenuClose();
  }

  return(
    <>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={WARNING_MODAL_TITLE}
        contentHtmlText={WARNING_MODAL_MODAL_DESCRIPTION(selected?.length)}
        containerClassname={styles.modal}
        confirmButtonText="OK"
        onConfirm={() => toggleModal()}
        altBtnStyle
      />
      <div className={styles.inputControl}>
        <Select
          isLoading={(loading && loadingTriggerCourse === courseId) || loadingRecommendations}
          ref={selectRef}
          options={optionsList}
          filterOption={createFilter({ ignoreAccents: false })}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          placeholder="Recommended Course"
          components={{
            Option: TextWithCheckboxOption,
            Control: TextWithCheckBoxControlComponent(),
            Input: TextWithCheckBoxInput,
            MenuList: TextWithCheckBoxMenuList(
              'Close',
              styles.selectDropdownButton,
              onClose,
              "Ok",
              styles.selectDropdownButton,
              () => onSubmit(selected || defaultValues),
              styles.selectDropdownButtonsContainer,
            ),
          }}
          styles={{
            ...SelectWithCheckboxCustomStyles
          }}
          onChange={handleChange}
          allowSelectAll
          value={selected || defaultValues}
          cancelButtonText="Cancel"
          menuPortalTarget={document.querySelector('body')}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          menuIsOpen={isMenuOpen}
          menuShouldBlockScroll={true}
          isClearable={false}
          onBlur={onClose}
        />
      </div>
    </>

  )
}

const mapStateToProps = ({Courses: {loading, data, loadingTriggerCourse, dataRecommendations, loadingRecommendations}}) => {
  return {
    loading,
    coursesList: data,
    loadingTriggerCourse,
    dataRecommendations,
    loadingRecommendations,
  }
}

export default connect(mapStateToProps, { setCourseRecommendations })(RecommendedCoursesSelect);