import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { SUPER_ADMIN } from "../../constants/userRoles";

import StorageService from "../../services/StorageService";

const AuthMiddleware = ({
  component: Component,
  layout: Layout,
  isSuperAdminRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const user = StorageService.user.value;
      const session = StorageService.session.value;
      if (!!user && !!session.accessToken) {
        if (isSuperAdminRoute) {
          return user.role !== SUPER_ADMIN ? (
            <Layout />
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        } else {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }
      }

      return (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      );
    }}
  />
);

export default withRouter(AuthMiddleware);
