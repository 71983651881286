import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class LessonsService extends Http {
  BASE_URL = "/admins/courses";
  BASE_URL_LESSONS = "/admins/lessons";

  getList(id, pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}/${id}/lessons`, {
      params: {
        limit,
        offset,
      },
    });
  }

  create(id, model) {
    return this.post(
      `${this.BASE_URL}/${id}/lessons`,
      LessonsService.getCreateDto(model)
    );
  }

  getOne(id) {
    return this.get(`${this.BASE_URL_LESSONS}/${id}`);
  }

  update(id, model) {
    return this.put(`${this.BASE_URL_LESSONS}/${id}`, model);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL_LESSONS}/${id}`);
  }

  addFiles(id, model) {
    return this.post(`${this.BASE_URL_LESSONS}/${id}/files`, model);
  }

  changeVideoThumbnails(id, model) {
    return this.put(`${this.BASE_URL_LESSONS}/${id}/videos/thumbnails`, model, {
      disableToast: true,
    });
  }

  lessonsCount(id) {
    return this.get(`${this.BASE_URL}/${id}/lessons/count`);
  }

  static getLessonBlockDto(block)  {
    const obj = {
      isPreviewLessonBlock: block.isPreviewLessonBlock,
    };

    if (block.text) {
      obj.text = block.text;
    }

    if (block.typeFormId) {
      obj.typeFormId = block.typeFormId;
    } else if (block.typeFormId === null) {
      obj.typeFormId = null; // To allow removal of typeFormId on update
    }

    if (block.ordinalNumber) {
      obj.ordinalNumber = block.ordinalNumber;
    } else if (block.ordinalNumber === null) {
      obj.ordinalNumber = null; // To allow removal of ordinalNumber on update
    }

    return obj;
  }

  static getCreateDto(model) {
    const { title, urlPart, ordinalNumber, isPreviewLesson, blocks } = model;
    return {
      title,
      urlPart,
      ordinalNumber,
      isPreviewLesson,
      blocks: blocks.map((block) => this.getLessonBlockDto(block)),
    };
  }
}

export default new LessonsService();
