import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { connect } from "react-redux";

import {
  DEFAULT_LIMIT,
  DEFAULT_OFFSET,
} from "../../../../constants/pagination";
import Placeholder from "../../../../components/Common/Placeholder";
import InfiniteScroll from "../../../../components/Common/InfinitScroll";
import {
  clearLessonCreated,
  clearLessonRemoved,
  clearLessonUpdated,
  getListLessons,
  clearLessons,
  refetchLessons,
} from "../../../../store/dashboard/Lessons/actions";
import {
  clearCourseFetched,
  getCourse,
} from "../../../../store/dashboard/Courses/actions";
import SEO from "../../../../components/Common/SEO";
import useSnackbarState from "../../../../hooks/useSnackbarState";
import LessonTableRow from "../components/LessonTableRow";
import withUploadingError from "../../../../HOCs/withUploadingError";

import styles from "../../dashboard.module.scss";

const Lessons = ({
  courses,
  lessons,
  getCourse,
  clearCourseFetched,
  clearLessonCreated,
  clearLessonUpdated,
  clearLessons,
  getListLessons,
  refetchLessons,
  clearLessonRemoved,
}) => {
  const { item } = courses;
  const { data, pagination, loading, created, removed, updated } = lessons;
  const {
    params: { courseId },
  } = useRouteMatch("/courses/:courseId/lessons");
  const scrollRef = useRef(null);

  const fetchList = useCallback(
    (nextPagination = {}) => {
      const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = nextPagination;

      getListLessons({ courseId, model: { limit, offset } });
    },
    [courseId, getListLessons]
  );

  const fetchCourse = () => {
    getCourse(courseId, true);
  };

  const resetLessons = () => {
    refetchLessons({ courseId, model: { limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET } });
  };

  const [isCreated] = useSnackbarState(created, clearLessonCreated);
  const [isUpdated] = useSnackbarState(updated, clearLessonUpdated);

  const loadMore = useCallback((offset) => fetchList({ offset }), []);

  useEffect(() => {
    // perform effect if entity appears
    if (removed) {
      resetLessons();
      clearLessonRemoved();
    }
  }, [removed]);

  useEffect(() => {
    fetchCourse();
    fetchList();

    return () => {
      clearCourseFetched();
      clearLessonRemoved();
      clearLessonCreated();
      clearLessons();
    };
  }, []);

  return (
    <>
      <SEO title="Lessons" />
      <Row className="page-content h-100">
        <Container fluid className="h-100">
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">LESSONS</h4>
              </div>
            </Col>
          </Row>
          {isCreated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  New lesson has been successfully created
                </Alert>
              </Col>
            </Row>
          )}
          {isUpdated && (
            <Row>
              <Col xs="12">
                <Alert color="success">
                  Changes to the lesson have been successfully saved
                </Alert>
              </Col>
            </Row>
          )}
          <Card className={styles.cardFullsize}>
            <CardBody>
              <CardTitle className="d-flex flex-row justify-content-between pb-3">
                <div className={styles.header}>
                  Course: {item && item.title}
                </div>
                <Link to={`/courses/${courseId}/create`}>
                  <Button color="primary">Create lesson</Button>
                </Link>
              </CardTitle>
              <CardText tag={"div"}>
                <Row className="h-100 scrollbar-wrapper">
                  {!loading && !data.length && (
                    <Placeholder text="No lesson created" />
                  )}
                  <InfiniteScroll
                    className="w-100 h-100"
                    loadMore={loadMore}
                    ref={scrollRef}
                    disabled={data?.length === 0 || loading}
                    offset={pagination.nextOffset}
                    nextOffset={pagination.nextOffset}
                    totalCount={pagination.totalCount}
                  >
                    {!!data && !!data.length > 0 && (
                      <div className="table-responsive col-12">
                        <Table className="table mt-0 mb-0 border-bottom">
                          <thead className="thead-light">
                            <tr>
                              <th className="w-50px">#</th>
                              <th>Lesson title</th>
                              <th className="w-100px">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((lesson, index) => (
                              <Fragment key={"table-item-" + index}>
                                <LessonTableRow lesson={lesson} index={index} />
                              </Fragment>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {loading && data?.length < pagination?.totalCount && (
                      <Col xs={12}>
                        <div className="text-center my-3">
                          <div className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                            Load more
                          </div>
                        </div>
                      </Col>
                    )}
                  </InfiniteScroll>
                </Row>
              </CardText>
            </CardBody>
          </Card>
        </Container>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  const { Courses, Lessons } = state;

  return { courses: Courses, lessons: Lessons };
};

export default withUploadingError(
  connect(mapStateToProps, {
    getCourse,
    clearCourseFetched,
    clearLessonCreated,
    clearLessonUpdated,
    getListLessons,
    clearLessons,
    clearLessonRemoved,
    refetchLessons,
  })(Lessons)
);
