import {
  GET_LIST_CONVERTER_KIT_TAGS,
  GET_LIST_CONVERTER_KIT_TAGS_SUCCESS,
  GET_LIST_CONVERTER_KIT_TAGS_ERROR,
} from "./actionTypes";

export const getConverterKitTags = (payload) => {
  return {
    payload,
    type: GET_LIST_CONVERTER_KIT_TAGS,
  };
};
export const getConverterKitTagsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_CONVERTER_KIT_TAGS_SUCCESS,
  };
};
export const getConverterKitTagsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_CONVERTER_KIT_TAGS_ERROR,
  };
};
