import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import classNames from "classnames";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import useDatePicker from "../../../hooks/useDatePicker";
import {
  DEFAULT_DATE_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
} from "../../../constants/datePickerConstants";
import datePickerDateFormater from "../../../helpers/datePickerDateFormater";
import stringToDate from "../../../helpers/stringToDate";

const FormGroupReactDatePicker = ({
  name,
  label,
  validator,
  handleFormChange,
  timeFormat,
  onChange,
  dateValue,
  className,
  labelClassName,
  dateFormat = DEFAULT_DATE_FORMAT,
  placeholder = DEFAULT_DATE_PLACEHOLDER,
  vertical = false,
  disabled = false,
  showTimeSelect = false,
}) => {
  const [date, setDate, pickerRef] = useDatePicker(
    dateValue,
    onChange,
    name,
    handleFormChange
  );

  return (
    <FormGroup className={className || "mb-4"} row>
      <Col lg={2 + vertical * 10}>
        <Label
          htmlFor={name}
          className={classNames(labelClassName, "col-form-label")}
        >
          {label}
        </Label>
      </Col>
      <Col lg={10 + vertical * 2}>
        <ReactDatePicker
          selected={date ? stringToDate(date) : ""}
          onChange={setDate}
          showMonthYearDropdown={showTimeSelect}
          placeholderText={placeholder}
          todayButton="Today"
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          timeIntervals={showTimeSelect && 1}
          showTimeSelect={showTimeSelect}
          id={name}
          className="form-control"
          disabled={disabled}
          readOnly={disabled}
          minDate={showTimeSelect && moment().toDate()}
          maxDate={showTimeSelect && moment().add(1, "year").toDate()}
          autoComplete="off"
        />
        <AvField
          hidden
          ref={pickerRef}
          name={name}
          type="text"
          value={datePickerDateFormater(date, showTimeSelect)}
          className="form-control"
          validate={validator}
        />
      </Col>
    </FormGroup>
  );
};

export default FormGroupReactDatePicker;
