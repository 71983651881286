import { useEffect, useState } from "react";

const useFilesToRemove = (files) => {
  const [filesLoaded, updateFilesLoaded] = useState([]);
  const [ids, updateIds] = useState([]);
  const updateFiles = (files) => {
    updateFilesLoaded(files);
  };
  const filterValues = () => {
    if (filesLoaded.length) {
      const filesFiltered = filesLoaded.filter(
        (file) => !ids.includes(file.id)
      );
      updateFilesLoaded(filesFiltered);
    }
  };
  const remove = (id) => {
    updateIds([...ids, id]);
  };

  useEffect(() => {
    if (files) {
      updateFiles(files);
    }
  }, [files]);

  useEffect(() => {
    filterValues();
  }, [ids]);

  return [filesLoaded, remove, ids];
};

export default useFilesToRemove;
