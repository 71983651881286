import React from "react";
import queryParser from "../../../helpers/queryParser";
import formatDate from "../../../helpers/formatDate";
import getBraintreePaymentUrl from "../../../helpers/getBraintreePaymentUrl";

import styles from "./index.module.scss";
import SortElement from "../../../components/Table/SortElement";
import getPaidCurrency from "../../../helpers/getPaidCurrency";
import formatTabelCell from "../../../helpers/formatTabelCell";
import getChargebeeInvoiceUrl from '../../../helpers/getChargebeeInvoiceUrl';

export default function paymentsListStructure(location) {
  const { orderType } = queryParser(location.search);

  return [
    {
      dataField: "transactionId",
      text: "Transaction ID",
      formatter: (cellContent) => (
        <>{formatTabelCell(cellContent)}</>
      ),
    },
    {
      dataField: "user.email",
      text: "Email address",
      formatter: (cellContent) => (
        <>{formatTabelCell(cellContent)}</>
      ),
    },
    {
      text: "",
      headerFormatter: () => (
        <SortElement title="Date of payment" orderType={orderType} />
      ),
      dataField: "createdAt",
      sort: true,
      formatter: (cellContent) => (
        <>{formatDate(cellContent)}</>
      ),
    },
    {
      dataField: "paidAmount",
      text: "Amount",
      formatter: (cellContent, rowContent) => (
        <>{getPaidCurrency(rowContent.currency, cellContent)}</>
      ),
    },
    {
      text: "Action",
      formatter: (cellContent, rowContent) => (
        <a
          href={getChargebeeInvoiceUrl(rowContent.transactionId)}
          onClick={(event) => event.stopPropagation()}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.linkBtn}
        >
          <span>Go to Chargebee</span>
          <i class="ri-external-link-line" />
        </a>
      ),
    },
  ];
}
