import React, { useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";

import FormGroupText from "../inputs/FormGroupText";
import FormGroupReactDatePicker from "../inputs/FormGroupReactDatePicker";
import formsValidators from "../../../validators/form.validators";
import useAvForm from "../../../hooks/useAvForm";
import { createSale } from "../../../store/dashboard/Sale/actions";

import styles from "./index.module.scss";

const initialState = {
  discount: "",
  startDate: "",
  endDate: "",
};

const CreateSaleForm = ({ sale, createSale }) => {
  const { loading } = sale;
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleFormSubmit = (event, values) => {
    createSale(values);
  };

  return (
    <>
      {!loading && (
        <Card>
          <CardBody>
            <h4 className="font-size-16 mb-4">Create sale</h4>
            <AvForm
              className="d-flex flex-row justify-content-between"
              onValidSubmit={handleFormSubmit}
              ref={formRef}
              model={initialState}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  return false;
                }
              }}
            >
              <div className="d-flex flex-row align-items-start">
                <FormGroupText
                  vertical
                  className={styles.discountInput}
                  labelClassName="pt-0"
                  name="discount"
                  label="Discount, %"
                  placeholder="Enter discount, %"
                  validator={formsValidators.courseCoupon.discount}
                  handleFormChange={handleFormChange}
                />
                <FormGroupReactDatePicker
                  vertical
                  className={styles.datepicker}
                  labelClassName="pt-0"
                  name="startDate"
                  label="Start date"
                  validator={formsValidators.courseCoupon.startDate}
                  handleFormChange={handleFormChange}
                />
                <FormGroupReactDatePicker
                  vertical
                  className={styles.datepicker}
                  labelClassName="pt-0"
                  name="endDate"
                  label="End date"
                  validator={formsValidators.courseCoupon.endDate}
                  handleFormChange={handleFormChange}
                />
                <FormGroupText
                  vertical
                  className={styles.discountInput}
                  labelClassName="pt-0"
                  name="couponId"
                  label="Coupon Id"
                  placeholder="Enter Coupon Id"
                  validator={formsValidators.courseCoupon.couponId}
                  handleFormChange={handleFormChange}
                />
              </div>
              <Button
                type="submit"
                color="primary"
                disabled={disableState}
                className={styles.createSaleBtn}
              >
                Create sale
              </Button>
            </AvForm>
          </CardBody>
        </Card>
      )}
    </>
  );
};
const mapStateToProps = ({ Sale: sale }) => ({ sale });

export default connect(mapStateToProps, { createSale })(CreateSaleForm);
