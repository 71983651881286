import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";
import Table from "../../../../components/Table";
import {
  USAGES_LIST_LIMIT,
  USER_LIST_LIMIT,
} from "../../../../constants/paginationConstants";
import { DEFAULT_DATE_FORMAT_WITH_COMMA } from "../../../../constants/dateFormats";
import usagesTableStructure from "./usagesTableStructure";
import {
  getListCouponUsages,
  removeCoupon,
  removeCouponClear,
} from "../../../../store/dashboard/Coupons/actions";
import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import formatDate from "../../../../helpers/formatDate";

const CouponInfoBlock = ({ title = "", text = "" }) => (
  <div className={styles.couponInfo}>
    <div className={styles.couponInfoTitle}>{title}</div>
    <div className={styles.couponInfoText}>{text}</div>
  </div>
);

const DELETE_MODAL_TITLE = "Delete";
const DELETE_MODAL_DESCRIPTION = "This coupon will be deleted";

const CouponInfo = ({
  coupons,
  model,
  couponId,
  getListCouponUsages,
  removeCoupon,
  removeCouponClear,
}) => {
  const { usages, usagesPagination, usagesLoading, removed } = coupons;
  const { body, discount, startDate, endDate } = model;
  const [offset, setOffset] = useState(0);
  const [modalVisible, toggleModal] = useModal();
  const history = useHistory();

  const handleTableChange = (type, { page }) => {
    setOffset(USER_LIST_LIMIT * (page - 1));
  };
  const handleConfirmRemove = () => {
    toggleModal();
    removeCoupon(couponId);
  };

  useEffect(() => {
    if (!removed) {
      getListCouponUsages({
        couponId: couponId,
        pagination: { offset, limit: USAGES_LIST_LIMIT },
      });
    }
  }, [offset, couponId]);

  useEffect(() => {
    if (removed) {
      history.push("/coupons");
    }

    return () => {
      removeCouponClear();
    };
  }, [removed]);

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <div className="d-flex flex-row justify-content-between mb-4">
            <div className={styles.title}>Coupon info</div>
            <Button
              className="user-details--basic-info__button"
              color="danger"
              onClick={() => toggleModal()}
            >
              Delete
            </Button>
          </div>
        </CardTitle>
        <div className="d-flex flex-row justify-content-start mb-4">
          <CouponInfoBlock title={"Coupon body"} text={body} />
          <CouponInfoBlock
            title={"Start date"}
            text={formatDate(startDate, DEFAULT_DATE_FORMAT_WITH_COMMA)}
          />
          <CouponInfoBlock
            title={"End date"}
            text={formatDate(endDate, DEFAULT_DATE_FORMAT_WITH_COMMA)}
          />
          <CouponInfoBlock title={"Discount, %"} text={discount} />
          <CouponInfoBlock
            title={"Number of uses"}
            text={usagesPagination.totalCount}
          />
        </div>

        <Table
          dataStructure={usagesTableStructure}
          data={usages}
          handleTableChange={handleTableChange}
          totalCount={usagesPagination.totalCount}
          limit={USAGES_LIST_LIMIT}
          offset={offset}
          loading={usagesLoading}
          isRowClick={false}
          placeholderText="Coupon has never been used"
          placeholderClassName="user-details--courses--placeholder"
          containerClassName="m-0 p-0"
        />
      </CardBody>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
    </Card>
  );
};
const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, {
  getListCouponUsages,
  removeCoupon,
  removeCouponClear,
})(CouponInfo);
