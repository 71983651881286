import React, { useCallback, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import { createPremiumArticle } from "../../../../store/dashboard/PremiumArticles/actions";
import ProgressDialog from "../../../../components/Modals/ProgressDialog";
import useModal from "../../../../hooks/useModal";
import SavePremiumArticle from "../../../../components/Forms/SavePremiumArticle";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TITLE = "Uploading";
const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const CreatePremiumArticle = ({
  createPremiumArticle,
  articles,
  filesError,
}) => {
  const { created, loading } = articles;
  const [isDirty, updateDirty] = useState(false);
  const history = useHistory();
  const [modalVisible, toggleModal] = useModal();

  const saveArticleCallback = useCallback((model) => {
    const creatArticleFunc = () => {
      createPremiumArticle(model, history);
    };
    creatArticleFunc();
  }, []);
  const isBlocked = created ? false : isDirty;

  useEffect(() => {
    toggleModal(loading);
  }, [loading]);

  return (
    <>
      <SEO title="Create premium article" />
      <RouteLeavingGuard
        contentText="Added data will not be saved. Are you sure you want to cancel?"
        when={isBlocked && !filesError}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressDialog
        isOpen={modalVisible}
        titleText={PROGRESS_TITLE}
        contentText={PROGRESS_TEXT}
      />
      <PerfectScrollbar
        options={{ suppressScrollX: true, wheelPropagation: true }}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12" className="pb-4">
                <SavePremiumArticle
                  saveArticleFunc={saveArticleCallback}
                  updateDirtyState={updateDirty}
                />
              </Col>
            </Row>
          </Container>
        </Row>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = ({ PremiumArticles, Progress }) => {
  return { articles: PremiumArticles, filesError: Progress.error };
};


export default connect(mapStateToProps, { createPremiumArticle })(
  CreatePremiumArticle
);
