import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";
import StorageService from "./StorageService";

class AdminsSerivce extends Http {
  BASE_URL = "/admins";

  getProfile() {
    return this.get(`${this.BASE_URL}/me`).then(({ data }) => {
      StorageService.user.value = data;
      return { data };
    });
  }

  getList(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(this.BASE_URL, {
      params: {
        limit,
        offset,
      },
    });
  }

  changeAdminStatus({ id, status }) {
    return this.patch(`${this.BASE_URL}/${id}/status`, { status });
  }

  create(model) {
    return this.post(this.BASE_URL, model);
  }

  updateAdminProfile(model) {
    return this.patch(`${this.BASE_URL}/me`, model).then(({ data }) => {
      StorageService.user.value = data;
      return { data };
    });
  }

  changeAdminPassword(model) {
    return this.put(`${this.BASE_URL}/password`, model);
  }

  changeAdminEmail(model) {
    return this.put(`${this.BASE_URL}/email`, model);
  }

  verifyChangeEmail(token) {
    return this.put("/verifications/email", { token }, { disableToast: true });
  }

  addAvatar(model) {
    return this.post(`${this.BASE_URL}/avatar`, model);
  }

  removeAvatar() {
    return this.delete(`${this.BASE_URL}/avatar`);
  }
}

export default new AdminsSerivce();
