import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import CreatableSelect from "react-select/creatable";
import Select from 'react-select';
import useSelect from "../../../hooks/useSelect";
import { generateOptions, MenuList } from "../../../helpers/reactSelectHelper";
import { VALUE_FIELD_NAME } from "../../../constants/selectConstants";

const FormGroupMultiSelect = ({
  name,
  label,
  placeholder,
  validator,
  handleFormChange,
  value,
  updateFormState,
  options = [],
  disabled = false,
  needClear = false,
  needClearState,
  maxCreateLength = 100,
  isMulti = false,
  allowCreate = true,
  optionValueName = VALUE_FIELD_NAME,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [values, setValues] = useState([]);

  const [resultOptions, setResultOptions, selectRef, inputRef, handlerBlur] =
    useSelect(value, name, handleFormChange, VALUE_FIELD_NAME, isMulti);

  useEffect(() => {
    if (needClear) {
      selectRef.current.select.select.clearValue();
      setResultOptions([]);
      needClearState(false);
    }
  }, [needClear]);

  const generateResultOptions = (values) => {
    if (isMulti) {
      setResultOptions(values?.map(({ value }) => value) || []);
      setValues(values?.map(({ value, label }) => ({ label, value })) || []);
      return;
    }

    if (values?.length) {
      const selectValue = [values[0].value];
      setResultOptions(selectValue);
      setValues([{ label: selectValue, value: selectValue }]);
      return;
    }
    const selectValue = values?.value;
    setResultOptions(values ? [selectValue] : []);
    setValues(values ? [{ label: selectValue, value: selectValue }] : []);
  };

  const handleCreate = (inputValue) => {
    if (inputValue.length > maxCreateLength) {
      return;
    }
    setInputValue(inputValue);
  };

  useEffect(() => {
    updateFormState((prevState) => ({
      ...prevState,
      [name]: [...resultOptions],
    }));
    handleFormChange();
  }, [resultOptions]);

  const SelectComponent = allowCreate ? CreatableSelect : Select;

  return (
    <FormGroup row>
      <Label htmlFor={name} className="col-form-label col-lg-2">
        {label}
      </Label>
      <Col lg="10">
        <SelectComponent
          ref={selectRef}
          isMulti={isMulti}
          isDisabled={disabled}
          id={name}
          placeholder={placeholder}
          options={generateOptions(options, optionValueName)}
          onChange={generateResultOptions}
          onInputChange={handleCreate}
          components={{ MenuList }}
          inputValue={inputValue}
          value={values}
          isClearable={!isMulti}
          onBlur={handlerBlur}
        />
        <AvField
          hidden
          ref={inputRef}
          id={name}
          name={name}
          type="select"
          className="form-control"
          placeholder={placeholder}
          value={resultOptions}
          validate={validator}
        />
      </Col>
    </FormGroup>
  );
};

export default FormGroupMultiSelect;
