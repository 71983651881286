import {
  CREATE_COUPON,
  CREATE_COUPON_CLEAR,
  CREATE_COUPON_ERROR,
  CREATE_COUPON_SUBSCRIPTION,
  CREATE_COUPON_SUBSCRIPTION_CLEAR,
  CREATE_COUPON_SUBSCRIPTION_SUCCESS,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUBSCRIPTION,
  DELETE_COUPON_SUBSCRIPTION_ERROR,
  DELETE_COUPON_SUBSCRIPTION_SUCCESS,
  EDIT_COUPON_SUBSCRIPTION,
  EDIT_COUPON_SUBSCRIPTION_ERROR,
  EDIT_COUPON_SUBSCRIPTION_SUCCESS,
  GET_COUPON,
  GET_COUPON_CLEAR,
  GET_COUPON_ERROR,
  GET_COUPON_SUCCESS,
  GET_LIST_COUPON_USAGES,
  GET_LIST_COUPON_USAGES_ERROR,
  GET_LIST_COUPON_USAGES_SUCCESS,
  GET_LIST_COUPONS,
  GET_LIST_COUPONS_ERROR,
  GET_LIST_COUPONS_SUBSCRIPTION,
  GET_LIST_COUPONS_SUBSCRIPTION_ERROR,
  GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS,
  GET_LIST_COUPONS_SUCCESS,
  GET_SUBSCRIPTION_COUPON,
  GET_SUBSCRIPTION_COUPON_CLEAR,
  GET_SUBSCRIPTION_COUPON_ERROR,
  GET_SUBSCRIPTION_COUPON_SUCCESS,
  REMOVE_COUPON,
  REMOVE_COUPON_CLEAR,
  REMOVE_COUPON_ERROR,
  REMOVE_COUPON_SUCCESS,
  UPDATE_SUBSCRIPTION_COUPON,
  UPDATE_SUBSCRIPTION_COUPON_ERROR,
  UPDATE_SUBSCRIPTION_COUPON_SUCCESS,
} from "./actionTypes";

export const getListCoupons = (payload) => {
  return {
    payload,
    type: GET_LIST_COUPONS,
  };
};
export const getListCouponsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COUPONS_SUCCESS,
  };
};
export const getListCouponsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_COUPONS_ERROR,
  };
};

export const getListCouponsSubscription = (payload) => {
  return {
    payload,
    type: GET_LIST_COUPONS_SUBSCRIPTION,
  };
};

export const getListCouponsSubscriptionSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COUPONS_SUBSCRIPTION_SUCCESS,
  };
};

export const getListCouponsSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_COUPONS_SUBSCRIPTION_ERROR,
  };
};

export const createCoupon = ({ model }) => {
  return {
    payload: { model },
    type: CREATE_COUPON,
  };
};

export const createCouponSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_COUPON_SUCCESS,
  };
};

export const createCouponError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_COUPON_ERROR,
  };
};

export const clearCouponCreated = () => {
  return {
    type: CREATE_COUPON_CLEAR,
  };
};

export const createCouponSubscription = ({ model }) => {
  return {
    payload: { model },
    type: CREATE_COUPON_SUBSCRIPTION,
  };
};

export const createCouponSubscriptionSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_COUPON_SUBSCRIPTION_SUCCESS,
  };
};

export const createCouponSubscriptionError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_COUPON_SUBSCRIPTION_CLEAR,
  };
};

export const getCoupon = (categoryId) => {
  return {
    payload: { categoryId },
    type: GET_COUPON,
  };
};

export const getCouponSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_COUPON_SUCCESS,
  };
};

export const getCouponError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_COUPON_ERROR,
  };
};

export const clearFetchedCoupon = () => {
  return {
    type: GET_COUPON_CLEAR,
  };
};

export const removeCoupon = (categoryId) => {
  return {
    payload: { categoryId },
    type: REMOVE_COUPON,
  };
};

export const removeCouponSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_COUPON_SUCCESS,
  };
};

export const removeCouponError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_COUPON_ERROR,
  };
};

export const removeCouponClear = () => {
  return {
    type: REMOVE_COUPON_CLEAR,
  };
};

export const getListCouponUsages = (payload) => {
  return {
    payload,
    type: GET_LIST_COUPON_USAGES,
  };
};
export const getListCouponUsagesSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COUPON_USAGES_SUCCESS,
  };
};
export const getListCouponUsagesError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_COUPON_USAGES_ERROR,
  };
};

export const getSubscriptionCoupon = (categoryId) => {
  return {
    payload: { categoryId },
    type: GET_SUBSCRIPTION_COUPON,
  };
};

export const getSubscriptionCouponSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_SUBSCRIPTION_COUPON_SUCCESS,
  };
};

export const getSubscriptionCouponError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_SUBSCRIPTION_COUPON_ERROR,
  };
};

export const clearFetchedSubscriptionCoupon = () => {
  return {
    type: GET_SUBSCRIPTION_COUPON_CLEAR,
  };
};

export const updateSubscriptionCoupon = (payload) => {
  return {
    payload,
    type: UPDATE_SUBSCRIPTION_COUPON,
  };
};

export const updateSubscriptionCouponSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_SUBSCRIPTION_COUPON_SUCCESS,
  };
};

export const updateSubscriptionCouponError = ({
  message = "Unknown error",
}) => {
  return {
    payload: { message },
    type: UPDATE_SUBSCRIPTION_COUPON_ERROR,
  };
};

export const editCouponSubscription = ({ id, model }) => {
  return {
    payload: { id, model },
    type: EDIT_COUPON_SUBSCRIPTION,
  };
};

export const editCouponSubscriptionSuccess = ({ data }) => {
  return {
    payload: data,
    type: EDIT_COUPON_SUBSCRIPTION_SUCCESS,
  };
};

export const editCouponSubscriptionError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: EDIT_COUPON_SUBSCRIPTION_ERROR,
  };
};

export const deleteCouponSubscription = ({ id }) => {
  return {
    payload: { id },
    type: DELETE_COUPON_SUBSCRIPTION,
  };
};

export const deleteCouponSubscriptionSuccess = ({ data }) => {
  return {
    payload: data,
    type: DELETE_COUPON_SUBSCRIPTION_SUCCESS,
  };
};

export const deleteCouponSubscriptionError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: DELETE_COUPON_SUBSCRIPTION_ERROR,
  };
};
