import React from 'react'
import { Card } from 'reactstrap';

export default function Personalinfo({ info }) {
    return (
        <Card className="user-details--personal-info">
            <span className="user-details--personal-info__title">Personal information</span>

            {info.map((item, index) => (
                <section className="d-flex flex-column user-details--personal-info__section" key={index}>
                    <section className="d-flex flex-row">
                        <span className="user-details--personal-info__label">{item.label}</span>
                        <span className="user-details--personal-info__value">{item.value}</span>
                    </section>
                    {index !== info.length - 1 &&
                        <span className="user-details--personal-info__delimiter" />
                    }
                </section>
            ))}
        </Card>
    )
}