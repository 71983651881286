import {
  UPLOAD_FAILURE,
  UPLOAD_INITIATE,
  UPLOAD_PROGRESS,
  UPLOAD_SUCCESS,
  UPLOAD_FAILURE_CLEAR,
} from "./actionTypes";

export const uploadInitiate = (data) => ({
  type: UPLOAD_INITIATE,
  payload: data,
});

export const uploadProgress = (data) => ({
  type: UPLOAD_PROGRESS,
  payload: data,
});

export const uploadSuccess = (data) => ({
  type: UPLOAD_SUCCESS,
  payload: data,
});

export const uploadFailure = (data) => ({
  type: UPLOAD_FAILURE,
  payload: data,
});

export const uploadFailureClear = () => ({
  type: UPLOAD_FAILURE_CLEAR,
});
