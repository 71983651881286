import React, { useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryParser from "../../helpers/queryParser";
import { verifyChangeEmail } from "../../store/dashboard/Settings/actions";

const VerifyEmailChange = ({ history, verifyChangeEmail }) => {
  const location = useLocation();
  const { token } = queryParser(location.search);

  useEffect(() => {
    if (token) {
      verifyChangeEmail({ token, history });
    } else {
      history.push("/login");
    }
  }, []);
  return <></>;
};

export default withRouter(
  connect(null, { verifyChangeEmail })(VerifyEmailChange)
);
