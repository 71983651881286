import { useEffect, useRef, useState } from "react";
import { END_DATE_INPUT_NAME } from "../constants/datePickerConstants";

const useDatePicker = (
  dateValue,
  onChange,
  name,
  handleFormChange,
  otherPickerName = END_DATE_INPUT_NAME
) => {
  const pickerRef = useRef(null);
  const defaultValue = useRef(dateValue || "");

  const [date, setDate] = useState(defaultValue.current);

  useEffect(() => {
    onChange && onChange(date);
  }, [date, onChange]);

  useEffect(() => {
    (dateValue || dateValue === "") && setDate(dateValue);
  }, [dateValue]);

  useEffect(() => {
    if (date !== defaultValue.current) {
      handleFormChange();
      const picker = pickerRef.current?.context.FormCtrl;
      if (picker) {
        picker.getInput(otherPickerName) &&
          picker.getInput(otherPickerName).validate();

        picker.setDirty(name);
        picker.setTouched(name);
      }
    }
  }, [date, name, defaultValue.current]);

  return [date, setDate, pickerRef];
};

export default useDatePicker;
