import React from "react";
import { ORDER_ASC } from "../../constants/paginationConstants";
import Icon from "../Common/Icon";

const SortElement = ({ title, orderType }) => (
  <>
    <span>{title}</span>&nbsp;
    <Icon icon={orderType === ORDER_ASC ? "sortASC" : "sortDESC"} />
  </>
);

export default SortElement;
