import Http from "./HttpService";

/**
 * @typedef {Object} AnalyticsSchema
 * @property {number} allTotalSubscribersCount
 * @property {number} monthlyTotalSubscribersCount
 * @property {number} regularAnnualTotalSubscribersCount
 * @property {number} blackFridayAnnualTotalSubscribersCount
 * @property {number} allNewSubscribersCount
 * @property {number} monthlyNewSubscribersCount
 * @property {number} regularAnnualNewSubscribersCount
 * @property {number} blackFridayAnnualNewSubscribersCount
 * @property {number} allNewUnsubscribersCount
 * @property {number} monthlyNewUnsubscribersCount
 * @property {number} regularAnnualNewUnsubscribersCount
 * @property {number} blackFridayAnnualNewUnsubscribersCount
 */

class DjHubAnalyticsService extends Http {
  BASE_URL = "/admins/analytics";

  /**
   * Get DJ Hub analytics
   * @param {string} startDate e.g `2017-07-21T17:32:28Z`
   * @param {string} endDate e.g `2017-08-21T17:32:28Z `
   * @returns {Promise<AnalyticsSchema>} Analytics for selected period
   */
  getAnalytics(startDate, endDate) {
    return this.get(this.BASE_URL, {
      params: {
        startDate,
        endDate,
      },
    });
  }
}

export default new DjHubAnalyticsService();
