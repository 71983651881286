import Http from "./HttpService";

class SubscriptionPlanService extends Http {
  BASE_URL = "/admins/subscriptions-plans";

  get10MonthSubscriptionPlanList() {
    return this.get(`${this.BASE_URL}/get-10-month-subscription-plans`);
  }

  toggle10MonthSubscriptionPlan(model) {
    return this.post(`${this.BASE_URL}/toggle-10-month-subscription-plan`, model);
  }
}

export default new SubscriptionPlanService();
