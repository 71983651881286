import React from "react";
import { components } from "react-select";
import {Button, Input, Label, Spinner} from "reactstrap";
import classNames from "classnames";
import noop from "./noop";
import PerfectScrollbar from "react-perfect-scrollbar";
import { customStyles } from "./reactSelectHelper";
import { MAX_RECOMMENDED_COURSE_LABEL_LENGTH } from "../constants/leghtValidity";
import { textMaxLength } from "./textMaxLength";

export const createOptionsList = (values, valueField, labelField, ) =>
  values.map((option) => ({
    value: option[valueField],
    label: option[labelField],
  }));

export const createDefaultSelected = (options, values) =>
  options?.filter((option) => values.includes(option.value)) || [];

export const SelectWithCheckboxCustomStyles = {
  ...customStyles,
  multiValue: (base) => ({
    ...base,
    backgroundColor: "transparent",
    fontWeight: 400,
    alignSelf: "center",
    color: "#495057",
    textOverflow: "ellipsis",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "white",
    borderBottom: "1px solid #F2F2F2",
    padding: "12px 8px 12px 0",
    minWidth: "220px",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    height: "100%",
    maxHeight: "64px",
}),
  multiValueRemove: (base) => ({
    ...base,
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "stretch",
  }),
  placeholder: (base) => ({
    ...base,
    whiteSpace: "nowrap"
  }),
  menuPortal: (base) => ({
    ...base,
    minWidth: "220px",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "initial",
    height: "100%",
    overflowY: "visible"
  }),
}
export const ControlCustomStyles = {
  caretColor: "transparent",
}
export const TextWithCheckboxOption = (props) => (
  <components.Option {...props}>
    <div className="custom-control custom-checkbox m-0">
      <Input
        type="checkbox"
        className="custom-control-input"
        checked={props.isSelected}
        onChange={() => null}
      />{" "}
      <Label
        className="custom-control-label ml-2 text-grey "
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "break-spaces",
          wordWrap: "break-any",
          overflowWrap: "anywhere",
          //font
          fontSize: "13px",
          fontWeight: "400",
          lineHeight: "20px",
          textAlign: "left",
        }}
      >
        {textMaxLength(props.label, MAX_RECOMMENDED_COURSE_LABEL_LENGTH, true)}
      </Label>
    </div>

  </components.Option>
);
export const TextWithCheckBoxInput = (props) => (
  <components.Input
    {...props}
    isHidden
    onChange={noop}
  />
)
export const TextWithCheckBoxControlComponent = (controlClassNames = "") => (
  (props) => (
    <div style={ControlCustomStyles} className={controlClassNames}>
      <components.Control  {...props} classNames="d-flex flex-row"/>
    </div>
  )
);

export const TextWithCheckBoxMenuList = (
  cancelButtonText = "",
  cancelBtnClassName = "",
  onCancel = noop,
  confirmButtonText = "",
  confirmBtnClassName = "",
  onConfirm = noop,
  buttonSectionClassNames = "",
  dropDownClassnames = "",
  ) => (props) => {
  props.children.length && props.children.sort((a) => a.props.isSelected ? -1 : 1)

  return  (
    <components.MenuList {...props} className={dropDownClassnames}>
      {!props.children.length &&
        <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
          <Spinner color="primary" >
          Loading...
          </Spinner>
        </div>
      }
      {props.children.length &&
        <>
          <div style={{height: props.children.length >= 5 ? 320 : "unset"}}>
            <PerfectScrollbar>
              {props.children}
            </PerfectScrollbar>
          </div>
          <div className={buttonSectionClassNames}>
            <Button
              color="secondary"
              size="lg"
              onClick={onCancel}
              className={classNames(
                cancelBtnClassName
              )}
            >
              {cancelButtonText}
            </Button>
            <Button
              className={classNames(
                confirmBtnClassName,
              )}
              color="primary"
              size="lg"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          </div>
        </>
      }

    </components.MenuList>
  )
}

