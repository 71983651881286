import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Alert, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

import {
  clearCourseCategoryCreated,
  getListCoursesCategories,
  removeCourseCategory,
  removeCourseCategoryClear,
} from "../../../store/dashboard/Training/actions";
import CourseCategoryCard from "./components/CourseCategoryCard";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../../constants/pagination";
import Placeholder from "../../../components/Common/Placeholder";
import InfiniteScroll from "../../../components/Common/InfinitScroll";
import useSnackbarState from "../../../hooks/useSnackbarState";
import withUploadingError from "../../../HOCs/withUploadingError";

import dashboardStyles from "../dashboard.module.scss";
import SEO from "../../../components/Common/SEO";

const Training = ({
  getListCoursesCategories,
  courseCategories,
  history,
  clearCourseCategoryCreated,
  removeCourseCategory,
  removeCourseCategoryClear,
}) => {
  const { data, created, removed, loading, pagination } = courseCategories;
  const [items, updateItems] = useState([]);
  const [ready, setReady] = useState(false);
  const [isCreated] = useSnackbarState(created, clearCourseCategoryCreated);
  const [isRemoved] = useSnackbarState(removed, removeCourseCategoryClear, () =>
    fetchList({ limit: DEFAULT_LIMIT, offset: DEFAULT_OFFSET })
  );
  const scrollRef = useRef(null);

  const fetchList = (nextPagination = {}) => {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = nextPagination;
    getListCoursesCategories({ limit, offset });
  };

  const disabledState = useMemo(
    () => loading || pagination.nextOffset > pagination.totalCount,
    [loading, pagination]
  );

  const removeCourseCategoryCallback = useCallback(
    (categoryId) => {
      if (!loading) {
        removeCourseCategory(categoryId);
      }
    },
    [loading]
  );

  useEffect(() => {
    fetchList();
    setReady(true);
  }, []);

  useEffect(() => {
    if (!loading && !!data && ready) {
      updateItems(removed ? data : [...items, ...data]);
    }
  }, [data, loading]);

  return (
    <>
    <SEO title="Training management" />
      <InfiniteScroll
        loadMore={() => fetchList({ offset: pagination.nextOffset })}
        ref={scrollRef}
        disabled={disabledState}
      >
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18">TRAINING MANAGEMENT</h4>
                </div>
              </Col>
            </Row>
            {isCreated && (
              <Row>
                <Col xs="12">
                  <Alert color="success">
                    New course category has been successfully created
                  </Alert>
                </Col>
              </Row>
            )}
            {isRemoved && (
              <Row>
                <Col xs="12">
                  <Alert color="success">
                    The course category has been deleted
                  </Alert>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex flex-row justify-content-between">
                      <p className={dashboardStyles.header}>
                        Course categories
                      </p>
                      <Link to="/training/create">
                        <Button color="link" className="p-0">
                          + Create course category
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="h-100">
              {!loading && !items.length && (
                <Placeholder
                  buttonLabel="Create course category"
                  text="no course category created"
                  onButtonPressed={() => history.push("/training/create")}
                />
              )}
              <Col xs={12}>
                <Row>
                  {items.map((courseCategory, index) => (
                    <Col xs={12} sm={4} lg={3} key={"card-item-" + index}>
                      <CourseCategoryCard
                        courseCategory={courseCategory}
                        removeCourseCategory={removeCourseCategoryCallback}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
              {loading &&
                !!items.length &&
                items.length < pagination.totalCount && (
                  <Col xs={12}>
                    <div className="text-center my-3">
                      <div className="text-success">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2" />{" "}
                        Load more
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </Container>
        </Row>
      </InfiniteScroll>
    </>
  );
};
const mapStateToProps = (state) => {
  const { Training } = state;

  return {
    courseCategories: Training,
  };
};
export default withUploadingError(
  withRouter(
    connect(mapStateToProps, {
      getListCoursesCategories,
      clearCourseCategoryCreated,
      removeCourseCategory,
      removeCourseCategoryClear,
    })(Training)
  )
);
