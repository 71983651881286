import React, { useEffect, useMemo } from "react";
import { Button, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AvField, AvForm } from "availity-reactstrap-validation";

import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";
import useDropzoneFacade from "../../hooks/useDropzoneFacade";
import useMountedState from "../../hooks/useMountedState";
import FormGroupImages from "./inputs/FormGroupImages";

import styles from "./forms.module.scss";

const SaveCourseCategory = ({ model, saveCategoryFunc, loading }) => {
  const [initialFile, updateInitialFile] = useMountedState(null);
  const [ready, updateReadyState] = useMountedState(false);
  const [
    error,
    selectedFiles,
    handleAcceptedFiles,
    setSelectedFiles,
  ] = useDropzoneFacade();
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();

  const handleFormSubmit = (event, values) => {
    const model = {
      name: values.name,
    };
    if (initialFile !== selectedFiles[0]) {
      model.files = selectedFiles;
    }
    saveCategoryFunc(model);
  };

  const pageTitle = useMemo(
    () => (model ? "Edit course category" : "Create course category"),
    [model]
  );
  const btnSaveLabel = useMemo(() => (model ? "Save" : "Create"), [model]);
  const disableState = useMemo(
    () =>
      !(isDirty || initialFile !== selectedFiles[0]) ||
      loading ||
      !selectedFiles.length ||
      hasFormError,
    [loading, selectedFiles, hasFormError, isDirty, initialFile]
  );

  useEffect(() => {
    if (!!model && !!model.image) {
      const { initialFile } = model;
      if (initialFile) {
        updateInitialFile(initialFile);
        handleAcceptedFiles([initialFile], []);
      }
      updateReadyState(true);
    }
  }, [model]);

  useEffect(() => {
    if (ready && selectedFiles.length === 0 && initialFile) {
      setSelectedFiles([initialFile]);
    }
  }, [selectedFiles, ready]);

  return (
    <>
      <CardTitle className="mb-4">{pageTitle}</CardTitle>
      <AvForm
        onValidSubmit={handleFormSubmit}
        ref={formRef}
        model={model}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      >
        <FormGroup className="mb-4" row>
          <Label htmlFor="name" className="col-form-label col-lg-2">
            Category name
          </Label>
          <Col lg="10">
            <AvField
              id="name"
              name="name"
              type="text"
              className="form-control"
              placeholder="Enter category name"
              onChange={() => handleFormChange()}
              onBlur={() => handleFormChange()}
              validate={formsValidators.courseCategory.name}
            />
          </Col>
        </FormGroup>
        <FormGroupImages
          multiple={false}
          label="Category icon"
          error={error}
          selectedImages={selectedFiles}
          initialFile={initialFile}
          handleAcceptedImages={handleAcceptedFiles}
          setSelectedImages={setSelectedFiles}
        />
        <Row className="justify-content-end">
          <Col lg="10">
            <Link to="/training">
              <Button color="secondary" className="mr-2">
                Cancel
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              disabled={disableState}
              className={styles.btnSave}
            >
              {btnSaveLabel}
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};

const mapStateToProps = ({ Training }) => ({ loading: Training.loading });

export default connect(mapStateToProps)(SaveCourseCategory);
