import React, { useEffect, useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";
import {
  changeAdminEmail,
  changeAdminEmailClear,
} from "../../store/dashboard/Settings/actions";

import styles from "./forms.module.scss";
import ConfirmationDialog from "../Modals/ConfirmationDialog";
import useModal from "../../hooks/useModal";
import getChangeEmailModalDescription from "../../helpers/getChangeEmailModalDescription";

const MODAL_TITLE = "Change email";

const initialState = {
  email: "",
  password: "",
  newEmail: "",
};

const ChangeAdminEmail = ({
  settings,
  changeAdminEmail,
  changeAdminEmailClear,
}) => {
  const { loading, email } = settings;
  const [modalVisible, toggleModal] = useModal();
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  useEffect(() => {
    if (!loading && !!email) toggleModal(true);
  }, [email, loading]);

  const handleCancelModal = () => {
    changeAdminEmailClear();
    toggleModal(false);
    formRef.current.reset();
    handleFormChange();
  };

  const handleFormSubmit = (event, values) => {
    changeAdminEmail(values);
  };

  return (
    <>
      <Card className={styles.card}>
        <CardBody>
          <div className={classNames(styles.cardTitle, "mb-3")}>
            Change your email address
          </div>
          <AvForm
            onValidSubmit={handleFormSubmit}
            ref={formRef}
            model={initialState}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                return false;
              }
            }}
          >
            <FormGroupText
              vertical
              className="mb-0"
              labelClassName="pt-0"
              name="email"
              label="Current email address"
              placeholder="Enter current email address"
              validator={formsValidators.email}
              handleFormChange={handleFormChange}
            />

            <FormGroupText
              vertical
              className="mb-0"
              labelClassName="pt-0"
              name="password"
              label="Current password"
              placeholder="Enter current password"
              type="password"
              id="currentPassword"
              validator={formsValidators.currentPassword}
              handleFormChange={handleFormChange}
              autoComplete="on"
            />

            <FormGroupText
              vertical
              className="mb-0"
              labelClassName="pt-0"
              name="newEmail"
              label="New email address"
              placeholder="Enter new email address"
              validator={formsValidators.confirmEmail}
              handleFormChange={handleFormChange}
            />
            <Button
              type="submit"
              color="primary"
              disabled={disableState}
              className={classNames(styles.cardBtn, "ml-auto d-block mt-2")}
            >
              Change email
            </Button>
          </AvForm>
        </CardBody>
      </Card>

      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={MODAL_TITLE}
        contentText={getChangeEmailModalDescription(email)}
        confirmButtonText="OK"
        onCancel={handleCancelModal}
        onConfirm={handleCancelModal}
        altBtnStyle
      />
    </>
  );
};
const mapStateToProps = ({ Settings: settings }) => ({ settings });

export default connect(mapStateToProps, {
  changeAdminEmail,
  changeAdminEmailClear,
})(ChangeAdminEmail);
