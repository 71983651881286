import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import { INDIVIDUAL_CONTENT_NAMES } from "../../../constants/comments";
import CommentsList from "../Comments/components/commentsList";
import {
  getLesson,
  clearLessonFetched,
} from "../../../store/dashboard/Lessons/actions";
import {
  getPremiumArticle,
  clearPremiumArticleFetched,
} from "../../../store/dashboard/PremiumArticles/actions";

import styles from "./index.module.scss";
import getLessonCommentLinkTitle from "../../../helpers/getLessonCommentLinkTitle";

const IndividualContent = ({
  getLesson,
  getPremiumArticle,
  lessons,
  premiumArticles,
  clearLessonFetched,
  clearPremiumArticleFetched,
}) => {
  const {
    params: { contentName, id },
  } = useRouteMatch("/individual-content/:contentName/:id");

  const { item: lesson, loading: lessonLoading } = lessons;
  const { item: article, loading: articleLoading } = premiumArticles;

  useEffect(() => {
    if (contentName === INDIVIDUAL_CONTENT_NAMES.article && id) {
      getPremiumArticle(id);
    }

    if (contentName === INDIVIDUAL_CONTENT_NAMES.lesson && id) {
      getLesson(id);
    }
  }, [contentName, id]);

  useEffect(() => {
    return () => {
      clearLessonFetched();
      clearPremiumArticleFetched();
    };
  }, []);

  return (
    <ScrollbarVertical>
      {(!!lesson || !!article) && !lessonLoading && !articleLoading && (
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-16">Individual content</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <Card className="d-flex flex-row">
                  <CardBody>
                    <div className={styles.cardTitle}>
                      {contentName === INDIVIDUAL_CONTENT_NAMES.lesson
                        ? "Lesson"
                        : "Premium article"}
                    </div>
                    <div className={styles.cardContent}>
                      {contentName === INDIVIDUAL_CONTENT_NAMES.lesson
                        ? `${lesson.course.title}: ${getLessonCommentLinkTitle(
                            lesson.ordinalNumber,
                            lesson.title
                          )}`
                        : article &&
                          article.premiumArticleSection &&
                          article.premiumArticleCategory &&
                          `${article.premiumArticleSection.name} > ${article.premiumArticleCategory.name}: ${article.title}`}
                    </div>
                    <a
                      href={
                        contentName === INDIVIDUAL_CONTENT_NAMES.lesson
                          ? `${process.env.REACT_APP_MAIN_SITE_URL}/lessons/${lesson.urlPart}`
                          : `${process.env.REACT_APP_MAIN_SITE_URL}/dj-hub/premium-article/${article.urlPart}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className={styles.cardButton}>
                        {contentName === INDIVIDUAL_CONTENT_NAMES.lesson
                          ? "View lesson"
                          : "View article"}
                      </Button>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className={styles.commentsTitle}>Comments</div>
                <CommentsList
                  id={id}
                  isPremiumArticlesComments={
                    contentName === INDIVIDUAL_CONTENT_NAMES.article
                  }
                />
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Lessons, PremiumArticles }) => {
  return {
    lessons: Lessons,
    premiumArticles: PremiumArticles,
  };
};

export default connect(mapStateToProps, {
  getLesson,
  getPremiumArticle,
  clearLessonFetched,
  clearPremiumArticleFetched,
})(IndividualContent);
