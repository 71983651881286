import { useEffect, useRef, useState } from "react";

const useInputWithReadOnlyTextPart = (
  title,
  formRef,
  modelUrlPart,
  inputName = "urlPart"
) => {
  const [urlPart, setUrlPart] = useState(modelUrlPart || "");
  const needSetTouched = useRef(true);
  const needUpdateUrlPart = useRef(false);

  useEffect(() => {
    if (title || title === "") {
      if (modelUrlPart && !needUpdateUrlPart.current) {
        needUpdateUrlPart.current = true;
        return;
      } else if (formRef.current) {
        setUrlPart(title.replaceAll(" ", "-").replace(/[^a-zA-Z0-9~_.-]/g, ""));
        if (title !== "" && needSetTouched.current) {
          formRef.current.setTouched(inputName);
          needSetTouched.current = false;
        }
      }
    }
  }, [title, formRef, modelUrlPart, inputName]);

  return urlPart;
};

export default useInputWithReadOnlyTextPart;
