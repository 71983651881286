import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import { useLocation, useRouteMatch } from "react-router-dom";

import VerticalScrollbar from "../../../../components/Common/ScrollbarVertical";
import BlueSpinner from "../../../../components/Common/Spinner";
import {
  clearFetchedCoupon,
  getCoupon,
} from "../../../../store/dashboard/Coupons/actions";
import CouponInfo from "./CouponInfo";
import SEO from "../../../../components/Common/SEO";

const CouponDetails = ({ coupons, getCoupon, clearFetchedCoupon }) => {
  const { loading, item } = coupons;
  const location = useLocation();
  const {
    params: { couponId },
  } = useRouteMatch("/coupons/course/:couponId");
  
  useEffect(() => {
    if (!item && !loading) {
      getCoupon(couponId);
    }
  }, [item, loading]);

  useEffect(() => {
    return () => {
      clearFetchedCoupon();
    };
  }, [location]);

  return (
    <VerticalScrollbar>
      <SEO title="Coupon info" />
      <section className="page-content">
        <Container fluid>
          {loading && <BlueSpinner />}
          {!loading && !!item && (
            <CouponInfo model={item} couponId={couponId} />
          )}
        </Container>
      </section>
    </VerticalScrollbar>
  );
};

const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, { getCoupon, clearFetchedCoupon })(
  CouponDetails
);
