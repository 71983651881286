import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationDialog from "../components/Modals/ConfirmationDialog";
import { uploadFailureClear } from "../store/progress/actions";

import styles from "../pages/Dashboard/dashboard.module.scss";

const MODAL_TITLE = "Error";
const MODAL_DESCRIPTION = "Files uploading has been failed";

const withUploadingError = (WrappedComponent) => (props) => {
  const filesError = useSelector(({ Progress }) => Progress.error);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      uploadFailureClear();
    };
  }, []);

  return (
    <>
      <ConfirmationDialog
        isOpen={filesError?.isImageError}
        titleText={MODAL_TITLE}
        contentText={MODAL_DESCRIPTION}
        confirmButtonText="OK"
        bodyClassName={styles.modalBody}
        confirmBtnClassName={styles.modalConfirm}
        onCancel={() => dispatch(uploadFailureClear())}
        onConfirm={() => dispatch(uploadFailureClear())}
      />
      <WrappedComponent {...props} />
    </>
  );
};

export default withUploadingError;
