import React from "react";
import { CardTitle } from "reactstrap";

import CreateCouponButton from "../CreateCouponButton/CreateCouponButton";

const TableHeader = ({ title = 'Course coupons', buttonText = 'Create coupon', isSubscription }) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
      <CardTitle className='mb-0'>{title}</CardTitle>
      <CreateCouponButton text={buttonText} isSubscription={isSubscription} />
    </div>
  );
};

export default TableHeader;
