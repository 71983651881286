import React, { useEffect } from "react";
import ProgressDialog from "../Modals/ProgressDialog";
import useModal from "../../hooks/useModal";

const PROGRESS_TITLE = "Uploading";
const PROGRESS_TEXT = "Video is uploading now. Please, don't quit this page";

const ProgressWatcher = ({
  loading,
  titleText = PROGRESS_TITLE,
  contentText = PROGRESS_TEXT,
}) => {
  const [modalVisible, toggleModal] = useModal();
  useEffect(() => {
    toggleModal(loading);
  }, [loading]);

  return (
    <>
      <ProgressDialog
        isOpen={modalVisible}
        titleText={titleText}
        contentText={contentText}
      />
    </>
  );
};

export default ProgressWatcher;
