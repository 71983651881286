import React, { useMemo } from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import { Link, useHistory } from "react-router-dom";

import ConfirmationDialog from "../../../../components/Modals/ConfirmationDialog";
import useModal from "../../../../hooks/useModal";
import ImgWithBubbleDefault from "../../../../components/Common/ImgWithBubbleDefault";

const DELETE_MODAL_TITLE = "Delete course category";
const DELETE_MODAL_DESCRIPTION =
  "All courses and lessons attached to this course category will be deleted. Are you sure you want to delete this course category?";

const CourseCategoryCard = ({
  courseCategory = { name: "" },
  removeCourseCategory,
}) => {
  const history = useHistory();
  const [modalVisible, toggleModal] = useModal();
  const nameIcon = courseCategory.name.charAt(0);
  const link = useMemo(
    () => `/training/${courseCategory.id}`,
    [courseCategory]
  );
  const editRoute = useMemo(
    () => `/training/${courseCategory.id}/update`,
    [courseCategory]
  );

  const handleConfirmRemove = () => {
    toggleModal();
    removeCourseCategory(courseCategory.id);
  };

  const imageLink = useMemo(() => {
    if (!courseCategory.image) {
      return null;
    } else if (!courseCategory.image.resizedImages) {
      return courseCategory.image.link;
    }
    return courseCategory.image.resizedImages[
      Object.keys(courseCategory.image.resizedImages)[0]
    ];
  }, [courseCategory]);

  return (
    <Card>
      <ConfirmationDialog
        isOpen={modalVisible}
        titleText={DELETE_MODAL_TITLE}
        contentText={DELETE_MODAL_DESCRIPTION}
        cancelButtonText="No"
        confirmButtonText="Yes"
        onCancel={() => toggleModal()}
        onConfirm={handleConfirmRemove}
        altBtnStyle
      />
      <CardBody onClick={() => history.push(link)} className="cursor-pointer">
        <div className="text-lg-center">
          <ImgWithBubbleDefault imageLink={imageLink} nameIcon={nameIcon} />
          <h5 className="mb-1 font-size-15 text-truncate text-dark">
            {courseCategory.name}
          </h5>
        </div>
      </CardBody>
      <CardFooter className="bg-transparent border-top">
        <div className="d-flex flex-row justify-content-between font-size-20">
          <Link to={editRoute}>
            <i className="bx bx-edit text-grey" />
          </Link>
          <Link to="#" onClick={() => toggleModal()}>
            <i className="bx bx-trash text-grey" />
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
};

export default CourseCategoryCard;
