import React from "react";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import Table from "../../../components/Table";
import userListStructure from "./userList/tableStructure";
import { getUserList } from "../../../store/dashboard/Users/actions";
import { USER_LIST_LIMIT } from "../../../constants/paginationConstants";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import UsersFilters from "./userList/filters";
import useTable from "../../../hooks/useTable";
import SEO from "../../../components/Common/SEO";

const Users = ({ getUserList, usersState }) => {
  const { data, pagination, loading, handleTableChange, query } = useTable(
    usersState,
    getUserList,
    USER_LIST_LIMIT
  );

  return (
    <ScrollbarVertical>
      <SEO title="Users management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Users management</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table
                dataStructure={userListStructure}
                data={data}
                FiltersComponent={UsersFilters}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={USER_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                placeholderText="There are no users yet"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Users }) => {
  return {
    usersState: Users,
  };
};

export default connect(mapStateToProps, {
  getUserList,
})(Users);
