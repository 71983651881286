import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";

import {
  createRewardError,
  createRewardSuccess,
  getRewardError,
  getRewardSuccess,
  getListRewardsError,
  getListRewardsSuccess,
  removeRewardError,
  removeRewardSuccess,
  updateRewardError,
  updateRewardSuccess,
} from "./actions";
import {
  CREATE_REWARD,
  GET_REWARD,
  GET_LIST_REWARD,
  REMOVE_REWARD,
  UPDATE_REWARD,
} from "./actionTypes";
import FilesService from "../../../services/FilesService";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import errorCodes from "../../../constants/errorCodes";
import throwCustomError from "../../../helpers/throwCustomError";
import RewardService from '../../../services/RewardService';

const getRewardListAsync = async (data) => {
  const { model } = data;
  return await RewardService.getList(model);
};

const getRewardAsync = async (id) => {
  return await RewardService.getOne(id);
};

const rewardComposite = (model) => {
  const {
    title,
    subTitle,
    description,
    benefitDescription,
    firstButtonText,
    firstButtonLink,
    secondButtonText,
    secondButtonLink,
    files,
    isHidden,
    ordinalNumber,
  } = model;

  return {
    files,
    rewardData: {
      title,
      subTitle,
      description,
      benefitDescription,
      firstButtonText,
      firstButtonLink,
      secondButtonText,
      secondButtonLink,
      isHidden,
      ordinalNumber,
    },
  };
};

const getFilesArray = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return [...files, ...thumbnailFiles];
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles;
  }
};

const getFilesTotalSize = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return files[0].size + thumbnailFiles[0].size;
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles[0].size;
  }
};

/**
 * Reward files channel for files uploading watching
 * @param data<Object>
 * @param mode<"create"|"update"> - mode of files progress channel (create by default)
 */
const rewardFilesChannel = (data, mode = "create") => {
  return eventChannel((emit) => {
    const { model } = data;
    const { files, rewardData } = rewardComposite(model);
    const actionFunc =
      mode === "create"
        ? RewardService.create(rewardData)
        : RewardService.update(data.rewardId, rewardData);
    if (
      (!files || !files.length)
    ) {
      // here is only one file and it's mandatory
      actionFunc
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    } else {
      const onProgress = ({ loaded }, file, chunkIndex) => {
        emit({ file, loaded, chunkIndex });
      };
      let reward;
      actionFunc
        .then(async ({ data }) => {
          reward = data;
          emit({ files, filesTotalSize: files[0].size });
          return FilesService.uploadImageFile({ files }, onProgress).catch(
            (error) => {
              throwCustomError(error);
            }
          );
          // const videoThumbnaiResponse = await FilesService.uploadImageFile(
          //   { files: videoThumbnail },
          //   onProgress
          // ).catch((error) => {
          //   throwCustomError(error);
          // });
          // return { filesResponse, videoThumbnaiResponse };
        })
        .then((filesResponse) => {
          return RewardService.addImageToReward(reward.id, {
            fileId: filesResponse[0].file.id,
          }).catch((error) => {
            throwCustomError(error);
          });
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    }
  });
};

const removeRewardAsync = async (id) => {
  return await RewardService.remove(id);
};

function* getRewardList({ payload }) {
  try {
    const response = yield call(getRewardListAsync, payload);
    yield put(getListRewardsSuccess(response));
  } catch (error) {
    yield put(getListRewardsError(error));
  }
}

function* getReward({ payload: { rewardId } }) {
  try {
    const response = yield call(getRewardAsync, rewardId);
    yield put(getRewardSuccess(response));
  } catch (error) {
    yield put(getRewardError(error));
  }
}

function* createRewardProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(createRewardError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(createRewardError(err));
        // history.push(`/rewards`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createRewardSuccess(success));
      history.push(`/rewards`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded }));
    }
  }
}

function* createReward({ payload: { data, history } }) {
  try {
    yield fork(
      createRewardProgressListener,
      rewardFilesChannel(data),
      history
    );
  } catch (error) {
    yield put(createRewardError(error));
  }
}

function* updateRewardProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(updateRewardError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(updateRewardError(err));
        // history.push(`/rewards`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updateRewardSuccess(success));
      history.push(`/rewards`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* updateReward({ payload: { data, history } }) {
  try {
    yield fork(
      updateRewardProgressListener,
      rewardFilesChannel(data, "update"),
      history,
    );
  } catch (error) {
    yield put(updateRewardError(error));
  }
}

function* removeReward({ payload: { data } }) {
  try {
    const response = yield call(removeRewardAsync, data);
    yield put(removeRewardSuccess(response));
  } catch (error) {
    yield put(removeRewardError(error));
  }
}

export function* watchGetRewardList() {
  yield takeEvery(GET_LIST_REWARD, getRewardList);
}

export function* watchCreateReward() {
  yield takeEvery(CREATE_REWARD, createReward);
}

export function* watchUpdateReward() {
  yield takeEvery(UPDATE_REWARD, updateReward);
}

export function* watchRemoveReward() {
  yield takeEvery(REMOVE_REWARD, removeReward);
}

export function* watchGetReward() {
  yield takeEvery(GET_REWARD, getReward);
}

function* coursesSaga() {
  yield all([
    fork(watchGetRewardList),
    fork(watchCreateReward),
    fork(watchUpdateReward),
    fork(watchRemoveReward),
    fork(watchGetReward),
  ]);
}

export default coursesSaga;
