import {
  CREATE_REWARD,
  CREATE_REWARD_CLEAR,
  CREATE_REWARD_ERROR,
  CREATE_REWARD_SUCCESS,
  GET_LIST_REWARD,
  GET_LIST_REWARD_ERROR,
  GET_LIST_REWARD_SUCCESS,
  GET_REWARD,
  GET_REWARD_SUCCESS,
  GET_REWARD_CLEAR,
  GET_REWARD_ERROR,
  UPDATE_REWARD,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_CLEAR,
  UPDATE_REWARD_ERROR,
  REMOVE_REWARD,
  REMOVE_REWARD_SUCCESS,
  REMOVE_REWARD_CLEAR,
  REMOVE_REWARD_ERROR,
  CLEAR_LIST_REWARD,
} from "./actionTypes";

export const getListRewards = (payload) => {
  return {
    payload,
    type: GET_LIST_REWARD,
  };
};
export const getListRewardsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_REWARD_SUCCESS,
  };
};
export const getListRewardsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_REWARD_ERROR,
  };
};
export const clearListRewards = () => ({
  type: CLEAR_LIST_REWARD,
})

export const createReward = (data, history) => {
  return {
    payload: { data, history },
    type: CREATE_REWARD,
  };
};

export const createRewardSuccess = ({ data }) => {
  return {
    payload: data,
    type: CREATE_REWARD_SUCCESS,
  };
};

export const createRewardError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: CREATE_REWARD_ERROR,
  };
};

export const clearRewardCreated = () => {
  return {
    type: CREATE_REWARD_CLEAR,
  };
};

export const getReward = (rewardId) => {
  return {
    payload: { rewardId },
    type: GET_REWARD,
  };
};

export const getRewardSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_REWARD_SUCCESS,
  };
};

export const getRewardError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_REWARD_ERROR,
  };
};

export const clearRewardFetched = () => {
  return {
    type: GET_REWARD_CLEAR,
  };
};

export const updateReward = (data, history) => {
  return {
    payload: { data, history },
    type: UPDATE_REWARD,
  };
};

export const updateRewardSuccess = ({ data }) => {
  return {
    payload: data,
    type: UPDATE_REWARD_SUCCESS,
  };
};

export const updateRewardError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: UPDATE_REWARD_ERROR,
  };
};

export const clearRewardUpdated = () => {
  return {
    type: UPDATE_REWARD_CLEAR,
  };
};

export const removeReward = (data, history) => {
  return {
    payload: { data, history },
    type: REMOVE_REWARD,
  };
};

export const removeRewardSuccess = ({ data }) => {
  return {
    payload: data,
    type: REMOVE_REWARD_SUCCESS,
  };
};

export const removeRewardError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REMOVE_REWARD_ERROR,
  };
};

export const clearRewardRemoved = () => {
  return {
    type: REMOVE_REWARD_CLEAR,
  };
};