import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";

import {
  createRewardError,
  createRewardSuccess,
  getRewardError,
  getRewardSuccess,
  getListRewardsError,
  getListRewardsSuccess,
  removeRewardError,
  removeRewardSuccess,
  updateRewardError,
  updateRewardSuccess,
  getListProductActivationCodesSuccess,
  getListProductActivationCodesError,
  createBulkProductActivationCodeSuccess,
  createBulkProductActivationCodeError,
  removeProductActivationCodeSuccess,
  removeProductActivationCodeError,
} from "./actions";
import {
  CREATE_REWARD,
  GET_REWARD,
  GET_LIST_REWARD,
  REMOVE_REWARD,
  UPDATE_REWARD,
  GET_LIST_PRODUCT_ACTIVATION_CODE,
  CREATE_BULK_PRODUCT_ACTIVATION_CODE,
  REMOVE_PRODUCT_ACTIVATION_CODE,
} from "./actionTypes";
import FilesService from "../../../services/FilesService";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import errorCodes from "../../../constants/errorCodes";
import throwCustomError from "../../../helpers/throwCustomError";
import RewardService from '../../../services/RewardService';
import ProductActivationCodeService from '../../../services/ProductActivationCodeService';

const getProductActivationCodeListAsync = async (data) => {
  return await ProductActivationCodeService.getList(data);
};

const getRewardAsync = async (id) => {
  return await RewardService.getOne(id);
};

const createBulkProductActivationCodeAsync = async (model) => {
  return await ProductActivationCodeService.createBulk(model);
};

const removeProductActivationCodeAsync = async (id) => {
  return await ProductActivationCodeService.remove(id);
};

function* getProductActivationCodeList({ payload }) {
  try {
    const response = yield call(getProductActivationCodeListAsync, payload);
    yield put(getListProductActivationCodesSuccess(response));
  } catch (error) {
    yield put(getListProductActivationCodesError(error));
  }
}

// function* getReward({ payload: { rewardId } }) {
//   try {
//     const response = yield call(getRewardAsync, rewardId);
//     yield put(getRewardSuccess(response));
//   } catch (error) {
//     yield put(getRewardError(error));
//   }
// }



function* createBulkProductActivationCode({ payload: { data, history } }) {
  try {
    const { model } = data;
    const response = yield call(createBulkProductActivationCodeAsync, model);
    yield put(createBulkProductActivationCodeSuccess(response));
    history.push("/product-activation-codes");
  } catch (error) {
    yield put(createBulkProductActivationCodeError(error));
  }
}

// function* updateReward({ payload: { data, history } }) {
//   try {
//     yield fork(
//       updateRewardProgressListener,
//       rewardFilesChannel(data, "update"),
//       history,
//     );
//   } catch (error) {
//     yield put(updateRewardError(error));
//   }
// }

function* removeProductActivationCode({ payload: { data } }) {
  try {
    const response = yield call(removeProductActivationCodeAsync, data);
    yield put(removeProductActivationCodeSuccess(response));
  } catch (error) {
    yield put(removeProductActivationCodeError(error));
  }
}

export function* watchGetProductActivationCodeList() {
  yield takeEvery(GET_LIST_PRODUCT_ACTIVATION_CODE, getProductActivationCodeList);
}

export function* watchCreateBulkProductActivationCode() {
  yield takeEvery(CREATE_BULK_PRODUCT_ACTIVATION_CODE, createBulkProductActivationCode);
}

// export function* watchUpdateReward() {
//   yield takeEvery(UPDATE_REWARD, updateReward);
// }

export function* watchRemoveProductActivationCode() {
  yield takeEvery(REMOVE_PRODUCT_ACTIVATION_CODE, removeProductActivationCode);
}

// export function* watchGetReward() {
//   yield takeEvery(GET_REWARD, getReward);
// }

function* productActivationCodesSaga() {
  yield all([
    fork(watchGetProductActivationCodeList),
    fork(watchCreateBulkProductActivationCode),
    // fork(watchUpdateReward),
    fork(watchRemoveProductActivationCode),
    // fork(watchGetReward),
  ]);
}

export default productActivationCodesSaga;
