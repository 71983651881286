const djSoftware = [
  { label: "Serato", value: "SERATO" },
  { label: "Rekordbox", value: "RECORDBOX" },
  { label: "Traktor", value: "TRACTOR" },
  { label: "djay", value: "DJAY" },
  { label: "DJUCED", value: "DJUCED" },
  { label: "WeDJ", value: "WEDJ" },
  { label: "Engine Prime ", value: "ENGINE_PRIME" },
  { label: "Virtual DJ", value: "VIRTUAL_DJ" },
  { label: "edjing", value: "EDJING" },
];

export default djSoftware;
