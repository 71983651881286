import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { AvField } from "availity-reactstrap-validation";
import classNames from "classnames";

const FormGroupText = ({
  id,
  name,
  label,
  placeholder,
  validator,
  handleFormChange,
  className,
  labelClassName,
  autoComplete,
  value,
  type = "text",
  vertical = false,
  readOnly = false,
}) => (
  <FormGroup className={className || "mb-4"} row>
    <Col lg={vertical ? 12 : 2}>
      <Label
        htmlFor={id || name}
        className={classNames(labelClassName, "col-form-label")}
      >
        {label}
      </Label>
    </Col>
    <Col lg={vertical ? 12 : 10}>
      <AvField
        id={id || name}
        name={name}
        type={type}
        className="form-control"
        placeholder={placeholder}
        onChange={() => handleFormChange()}
        onBlur={() => handleFormChange()}
        validate={validator}
        autoComplete={autoComplete}
        value={value}
        disabled={readOnly}
        readOnly={readOnly}
      />
    </Col>
  </FormGroup>
);

export default FormGroupText;
