import React from "react";
import Icon from '../../../../components/Common/Icon';
import queryParser from '../../../../helpers/queryParser';
import {
    ORDER_ASC,
} from '../../../../constants/paginationConstants';
import formatDate from '../../../../helpers/formatDate';
import capitalizeFirstLetter from '../../../../helpers/capitilizeFirstLetter';
import statusColors from '../../../../constants/statusColors';
import SubscriptionStatusBadge from '../../../../components/Common/SubscriptionStatusBadge';
import parseInteger from '../../../../helpers/parseInteger';
import formatTabelCell from "../../../../helpers/formatTabelCell";

export default function userListStructure(location) {

    const { orderType, offset = 0 } = queryParser(location.search);

    return [
        {
            dataField: "id",
            text: "#",
            formatter: (cellContent, row, rowIndex) => (
                <span className="user-list--list__id">
                    {rowIndex + parseInteger(offset) + 1}
                </span>
            )
        },
        {
            dataField: "userName",
            text: "Username",
            formatter: (cellContent) => (
                <section className="d-flex flex-row align-items-center">
                    <span>{formatTabelCell(cellContent)}</span>
                </section>
            )
        },
        {
            dataField: "email",
            text: "Email address",
        },
        {
            text: "",
            headerFormatter: () =>
            (<>
                Date of registration <Icon icon={orderType === ORDER_ASC ? "sortASC" : "sortDESC"} />
            </>)
            ,
            dataField: "createdAt",
            sort: true,
            formatter: (cellContent) => (
                <>
                    {formatDate(cellContent)}
                </>
            ),
        },
        {
            dataField: "status",
            text: "Status",
            formatter: (cellContent) => {
                const textColor = 'user-list' + statusColors[cellContent];
                return (
                    <span className={textColor}>
                        {capitalizeFirstLetter(cellContent)}
                    </span>
                );
            }
        },
        {
            dataField: "userSubscription",
            text: "Subscription type",
            formatter: (cellContent) => (
                <span>
                    {capitalizeFirstLetter(cellContent?.billingType)}
                </span>
            )
        },
        {
            dataField: "canceledAt",
            text: "Subscription status",
            formatter: (cellContent, fullContent) => {
                return SubscriptionStatusBadge(fullContent)
            }
        },
    ]
}