export const GET_LIST_BUNDLE = "GET_LIST_BUNDLE";
export const GET_LIST_BUNDLE_SUCCESS = "GET_LIST_BUNDLE_SUCCESS";
export const GET_LIST_BUNDLE_ERROR = "GET_LIST_BUNDLE_ERROR";
export const CLEAR_LIST_BUNDLE = 'CLEAR_LIST_BUNDLE';

export const CREATE_BUNDLE = "CREATE_BUNDLE";
export const CREATE_BUNDLE_SUCCESS = "CREATE_BUNDLE_SUCCESS";
export const CREATE_BUNDLE_CLEAR = "CREATE_BUNDLE_CLEAR";
export const CREATE_BUNDLE_ERROR = "CREATE_BUNDLE_ERROR";

export const GET_BUNDLE = "GET_BUNDLE";
export const GET_BUNDLE_SUCCESS = "GET_BUNDLE_SUCCESS";
export const GET_BUNDLE_CLEAR = "GET_BUNDLE_CLEAR";
export const GET_BUNDLE_ERROR = "GET_BUNDLE_ERROR";

export const UPDATE_BUNDLE = "UPDATE_BUNDLE";
export const UPDATE_BUNDLE_SUCCESS = "UPDATE_BUNDLE_SUCCESS";
export const UPDATE_BUNDLE_CLEAR = "UPDATE_BUNDLE_CLEAR";
export const UPDATE_BUNDLE_ERROR = "UPDATE_BUNDLE_ERROR";

export const REMOVE_BUNDLE = "REMOVE_BUNDLE";
export const REMOVE_BUNDLE_SUCCESS = "REMOVE_BUNDLE_SUCCESS";
export const REMOVE_BUNDLE_CLEAR = "REMOVE_BUNDLE_CLEAR";
export const REMOVE_BUNDLE_ERROR = "REMOVE_COURSE_ERROR";

export const CHANGE_BUNDLE_STATUS = "CHANGE_BUNDLE_STATUS";
export const CHANGE_BUNDLE_STATUS_SUCCESS = "CHANGE_BUNDLE_STATUS_SUCCESS";
export const CHANGE_BUNDLE_STATUS_ERROR = "CHANGE_BUNDLE_STATUS_ERROR";
export const CHANGE_BUNDLE_STATUS_CLEAR = "CHANGE_BUNDLE_STATUS_CLEAR";

export const GET_COURSES_FOR_OPTION = "GET_COURSES_FOR_OPTION";
export const GET_COURSES_FOR_OPTION_CLEAR = "GET_COURSES_FOR_OPTION_CLEAR";
export const GET_COURSES_FOR_OPTION_SUCCESS = "GET_COURSES_FOR_OPTION_SUCCESS";
export const GET_COURSES_FOR_OPTION_ERROR = "GET_COURSES_FOR_OPTION_ERROR";