import React from "react";
/* SVG icons */
import { ReactComponent as ascSort } from "../../assets/images/icons/ic_sort_up.svg";
import { ReactComponent as descSort } from "../../assets/images/icons/ic_sort_down.svg";
import { ReactComponent as trash } from '../../assets/images/icons/ic_trash.svg';
import { ReactComponent as checkGray } from '../../assets/images/icons/ic_check_gray.svg';
import { ReactComponent as arrowPrimary } from '../../assets/images/icons/ic_arrow_primary.svg';
import { ReactComponent as dragActive } from '../../assets/images/icons/ic_drag&drop_bluesvg.svg';
import { ReactComponent as dragInActive } from '../../assets/images/icons/ic_drag&drop_graysvg.svg';

const iconsMap = {
    sortASC: ascSort,
    sortDESC: descSort,
    trash: trash,
    checkGray,
    arrowPrimary,
    dragActive,
    dragInActive,
};

const Icon = ({ className, icon }) => {
    let Svg = iconsMap[icon];

    const classes = "icon " + `${className}`;

    return <Svg className={classes} />;
};

Icon.defaultProps = {
    className: "",
    icon: 'sortASC',
};

export default Icon;
