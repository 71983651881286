import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";

import noop from "../../helpers/noop";
import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../../constants/pagination";

export const ScrollDirections = {
  vertical: "vertical",
  reverseVertical: "reverseVertical",
};

const InfiniteScroll = React.forwardRef((props = {}, ref) => {
  const {
    className,
    children,
    nextOffset,
    totalCount,
    loadMore = noop,
    direction = ScrollDirections.vertical,
    disabled = false,
    initialOffset = DEFAULT_OFFSET,
    limit = DEFAULT_LIMIT,
  } = props;

  const [offset, setOffset] = useState(initialOffset);

  useEffect(() => {
    if (offset && !disabled && nextOffset <= totalCount) {
      loadMore(nextOffset);
    }
  }, [offset]);

  const onScroll = useCallback(
    debounce(() => {
      setOffset((prev) => prev + limit);
    }, 300),
    [direction, offset, disabled, loadMore, limit]
  );

  return (
    <PerfectScrollbar
      className={className}
      options={{ suppressScrollX: true, wheelPropagation: false }}
      onScrollDown={onScroll}
      ref={ref}
    >
      {children}
    </PerfectScrollbar>
  );
});

export default InfiniteScroll;
