export const GET_LIST_REWARD = "GET_LIST_REWARD";
export const GET_LIST_REWARD_SUCCESS = "GET_LIST_REWARD_SUCCESS";
export const GET_LIST_REWARD_ERROR = "GET_LIST_REWARD_ERROR";
export const CLEAR_LIST_REWARD = 'CLEAR_LIST_REWARD';

export const CREATE_REWARD = "CREATE_REWARD";
export const CREATE_REWARD_SUCCESS = "CREATE_REWARD_SUCCESS";
export const CREATE_REWARD_CLEAR = "CREATE_REWARD_CLEAR";
export const CREATE_REWARD_ERROR = "CREATE_REWARD_ERROR";

export const GET_REWARD = "GET_REWARD";
export const GET_REWARD_SUCCESS = "GET_REWARD_SUCCESS";
export const GET_REWARD_CLEAR = "GET_REWARD_CLEAR";
export const GET_REWARD_ERROR = "GET_REWARD_ERROR";

export const UPDATE_REWARD = "UPDATE_REWARD";
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS";
export const UPDATE_REWARD_CLEAR = "UPDATE_REWARD_CLEAR";
export const UPDATE_REWARD_ERROR = "UPDATE_REWARD_ERROR";

export const REMOVE_REWARD = "REMOVE_REWARD";
export const REMOVE_REWARD_SUCCESS = "REMOVE_REWARD_SUCCESS";
export const REMOVE_REWARD_CLEAR = "REMOVE_REWARD_CLEAR";
export const REMOVE_REWARD_ERROR = "REMOVE_REWARD_ERROR";