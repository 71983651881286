import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import formsValidators, {
  requiredIfOtherExist,
} from "../../validators/form.validators";
import useDropzoneFacade from "../../hooks/useDropzoneFacade";
import useAvForm from "../../hooks/useAvForm";
import FormGroupText from "./inputs/FormGroupText";
import WYSISYGComponent from "./inputs/WYSISYGComponent";
import FormGroupReactSelect from "./inputs/FormGroupReactSelect";
import noop from "../../helpers/noop";
import useMountedState from "../../hooks/useMountedState";
import FormGroupImages from "./inputs/FormGroupImages";
import InputWithReadOnlyTextPart from "./inputs/InputWithReadOnlyTextPart";
import getReadOnlyUrlPart from "../../helpers/getReadOnlyUrlPart";

import styles from "./forms.module.scss";
import FormGroupMultiSelect from './inputs/FormGroupMultiSelect';
import { MAX_TAG_LENGTH } from '../../constants/leghtValidity';
import { VALUE_FIELD_ID } from '../../constants/selectConstants';
import parseInteger from '../../helpers/parseInteger';

const currencies = ["EUR", "USD", "GBP", "NZD", "AUD", "CAD", "INR"];
const applicableToSubscriptionValue = 'ApplicableToSubscription';
const hiddenValue = 'Hidden';



const SaveReward = ({
  model,
  saveRewardFunc,
  rewards,
  updateDirtyState = noop,
}) => {
  const initialState = {
    title: "",
    subTitle: "",
    description: "",
    benefitDescription: "",
    firstButtonText: "",
    firstButtonLink: "",
    secondButtonText: "",
    secondButtonLink: "",
    ordinalNumber: "",
    hidden: [],
  };
  const [formData, updateFormData] = useState(initialState);
  const [initialFile, updateInitialFile] = useMountedState(null);
  const { loading } = rewards;

  const [ready, updateReadyState] = useMountedState(false);
  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();
    const [
      error,
      selectedFiles,
      handleAcceptedFiles,
      setSelectedFiles,
    ] = useDropzoneFacade();

  // console.log('Courses: ', model.courses);

  const handleFormSubmit = (event, values) => {
    const {
      title,
      subTitle,
      description,
      benefitDescription,
      firstButtonText,
      firstButtonLink,
      secondButtonText,
      secondButtonLink,
      hidden,
      ordinalNumber,
    } = values;
    const model = {
      title,
      subTitle,
      description,
      benefitDescription,
      firstButtonText,
      firstButtonLink,
      secondButtonText: secondButtonText === "" ? null : secondButtonText,
      secondButtonLink: secondButtonLink === "" ? null : secondButtonLink,
      ordinalNumber: ordinalNumber === "" ? null : parseInteger(ordinalNumber),
      isHidden: hidden.length === 1,
    };
  
    if (initialFile !== selectedFiles[0]) {
      model.files = selectedFiles;
    }

    saveRewardFunc(model);
  };

  const pageTitle = useMemo(
    () => (model ? "Edit reward" : "Create reward"),
    [model]
  );
  const btnSaveLabel = useMemo(
    () => (model ? "Save" : "Create"),
    [model]
  );
  const disableState = useMemo(
    () =>
      !(
        isDirty ||
        initialFile !== selectedFiles[0]
      ) ||
      loading ||
      !selectedFiles.length ||
      hasFormError,
    [
      loading,
      selectedFiles,
      hasFormError,
      isDirty,
      initialFile,
      model,
    ]
  );

  useEffect(() => {
    updateDirtyState(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (!!model) {
      const {
        title,
        subTitle,
        description,
        benefitDescription,
        firstButtonText,
        firstButtonLink,
        secondButtonText,
        secondButtonLink,
        initialFile,
        isHidden,
        ordinalNumber,
      } = model;
      const formData = {
        ...initialState,
        title,
        subTitle,
        description,
        benefitDescription,
        firstButtonText,
        firstButtonLink,
        secondButtonText,
        secondButtonLink,
        hidden: isHidden ? [hiddenValue] : [],
        ordinalNumber: ordinalNumber ? ordinalNumber : '',
      };

      if (initialFile) {
        updateInitialFile(initialFile);
        handleAcceptedFiles([initialFile], []);
      }

      updateFormData(formData);
    }

    updateReadyState(true);
  }, [model]);

  useEffect(() => {
    if (ready && selectedFiles.length === 0 && initialFile) {
      setSelectedFiles([initialFile]);
    }
  }, [selectedFiles]);

  return (
    ready && (
      <>
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          model={formData}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{pageTitle}</CardTitle>
              <FormGroupText
                name={`ordinalNumber`}
                label="Ordinal Number"
                placeholder="Enter Ordinal Number"
                handleFormChange={handleFormChange}
                validator={formsValidators.reward.ordinalNumber}
              />
              <FormGroupText
                name="title"
                label="Reward Name"
                placeholder="Enter reward Name"
                validator={formsValidators.reward.title}
                handleFormChange={handleFormChange}
              />
               <FormGroupText
                name="subTitle"
                label="Reward Title"
                placeholder="Enter reward title"
                validator={formsValidators.reward.subTitle}
                handleFormChange={handleFormChange}
              />
              
              <FormGroupImages
                multiple={false}
                label="Reward Header Image"
                error={error}
                selectedImages={selectedFiles}
                initialFile={initialFile}
                handleAcceptedImages={handleAcceptedFiles}
                setSelectedImages={setSelectedFiles}
             />
              <CardTitle className="mb-4">Reward info</CardTitle>
              <WYSISYGComponent
                name="description"
                label="Reward Copy"
                validator={formsValidators.reward.description}
                handleFormChange={handleFormChange}
              />
              <WYSISYGComponent
                name="benefitDescription"
                label="Benefit Copy"
                validator={formsValidators.reward.benefitDescription}
                handleFormChange={handleFormChange}
              />

              
              <CardTitle className="mb-4">Reward Button Info</CardTitle>
              <FormGroupText
                name="firstButtonText"
                label="First Button Text"
                placeholder="Enter First Button Text"
                validator={formsValidators.reward.firstButtonText}
                handleFormChange={handleFormChange}
              />
              <FormGroupText
                name="firstButtonLink"
                label="First Button Link"
                placeholder="Enter First Button Link"
                validator={formsValidators.reward.firstButtonLink}
                handleFormChange={handleFormChange}
              />
              <FormGroupText
                name="secondButtonText"
                label="Second Button Text"
                placeholder="Enter Second Button Text"
                handleFormChange={handleFormChange}
              />
              <FormGroupText
                name="secondButtonLink"
                label="Second Button Link"
                placeholder="Enter Second Button Link"
                handleFormChange={handleFormChange}
              />
              <FormGroup className='mb-4' row>
                <Col lg={2}>
                  <Label>Is Hidden</Label>
                </Col>
                <Col lg={10}>
                <AvCheckboxGroup className='' name={'hidden'}>
                  <AvCheckbox
                    onChange={handleFormChange}
                    className="cursor-pointer"
                    customInput
                    label="Is Hidden"
                    value={hiddenValue}
                  />
                  </AvCheckboxGroup>
                </Col>
              
              </FormGroup>

            </CardBody>
          </Card>
          <Row className="justify-content-end">
            <Col xs="12">
              <Link
                to={`/rewards`}
              >
                <Button color="secondary" className="mr-2">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                color="primary"
                disabled={disableState}
                className={styles.btnSave}
              >
                {btnSaveLabel}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </>
    )
  );
};

const mapStateToProps = ({ Rewards }) => {
  return {
    rewards: Rewards,
  };
};

export default connect(mapStateToProps, { })(SaveReward);
