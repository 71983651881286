import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import classNames from "classnames";

import CustomReactPaginate from "../../../../components/Common/CustomReactPaginate";
import {
  DEFAULT_COMMENTS_LIMIT,
  DEFAULT_OFFSET,
} from "../../../../constants/pagination";
import {
  getListComments,
  readComment,
  approveComment,
  deleteComment,
  deleteCommentClear,
  getUnreadCount,
  replyToComment,
  replyToCommentClear,
  readAllComments,
  readAllCommentsClear,
  readCommentClear,
  getListCommentsClear,
} from "../../../../store/dashboard/Comments/actions";
import CommentCard from "./commentCard";
import useDebounce from "../../../../hooks/useDebounce";
import { MAX_SEARCH_LENGTH } from "../../../../constants/leghtValidity";
import { DEFAULT_COMMNET_FILTERS } from "../../../../constants/comments";

import styles from "../index.module.scss";
import CommnetsTopContent from "./CommnetsTopContent";
import IndividualContentTopContent from "./IndividualContentTopContent";
import BlueSpinner from "../../../../components/Common/Spinner";

const CommentsList = ({
  comments,
  getListComments,
  readComment,
  approveComment,
  deleteComment,
  deleteCommentClear,
  readAllComments,
  readAllCommentsClear,
  readCommentClear,
  getUnreadCount,
  replyToComment,
  replyToCommentClear,
  isPremiumArticlesComments,
  id,
  getListCommentsClear,
}) => {
  const {
    isRemoved,
    isReply,
    data,
    loading,
    pagination,
    isReadAllComments,
    readCommentItem,
  } = comments;

  const [ready, setReady] = useState(false);
  const [items, updateItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showResetFilters, setShowResetFilters] = useState(false);
  const [filterOption, setFilterOption] = useState(
    !!id ? [] : DEFAULT_COMMNET_FILTERS
  );
  const [markAll, setMarkAll] = useState(false);

  const handleChangeFilters = useCallback((filter) => {
    setFilterOption((prevState) => {
      if (prevState.includes(filter)) {
        return prevState.filter((option) => option !== filter);
      }
      return [...prevState, filter];
    });
  }, []);

  const fetchList = useCallback(
    (nextPagination = {}, query, filters = filterOption) => {
      const { limit = DEFAULT_COMMENTS_LIMIT, offset = DEFAULT_OFFSET } =
        nextPagination;
      getListComments({
        limit,
        offset,
        q: query,
        filters,
        isPremiumArticlesComments,
        id,
      });
    },
    [isPremiumArticlesComments, id, filterOption, getListComments]
  );

  const debouncedSearchTerm = useDebounce(searchQuery);

  const handleSubmitFilters = useCallback(() => {
    setIsFilterOpen((prevState) => !prevState);
    fetchList({}, debouncedSearchTerm || null, filterOption);
    filterOption.length && setShowResetFilters(true);
  }, [
    setIsFilterOpen,
    fetchList,
    setShowResetFilters,
    filterOption,
    debouncedSearchTerm,
  ]);

  const handleResetFilters = useCallback(() => {
    const resetFiltres = [];
    setFilterOption(resetFiltres);
    fetchList({}, debouncedSearchTerm || null, resetFiltres);
    setShowResetFilters(false);
  }, [fetchList, debouncedSearchTerm]);

  useEffect(() => {
    if (!id) {
      setFilterOption(DEFAULT_COMMNET_FILTERS);
      setShowResetFilters(true);
    }
    fetchList({}, null, !id ? DEFAULT_COMMNET_FILTERS : null);
    setReady(true);
  }, [isPremiumArticlesComments]);

  useEffect(() => {
    if (!loading && !!data && ready) {
      updateItems(data);
    }
  }, [data, loading, ready]);

  useEffect(() => {
    if (
      items.length ||
      (!items.length && filterOption[0] === DEFAULT_COMMNET_FILTERS[0])
    ) {
      getUnreadCount();
    }
  }, [items, getUnreadCount, filterOption]);

  useEffect(() => {
    if (!readCommentItem) return;
    if (filterOption[0] === DEFAULT_COMMNET_FILTERS[0]) {
      fetchList({
        limit: DEFAULT_COMMENTS_LIMIT,
        offset: pagination.nextOffset - DEFAULT_COMMENTS_LIMIT,
      });
    } else {
      updateItems(
        items.map((comment) =>
          comment.id === readCommentItem.id ? readCommentItem : comment
        )
      );
    }
    readCommentClear();
  }, [
    readCommentItem,
    fetchList,
    filterOption,
    items,
    pagination.nextOffset,
    readCommentClear,
  ]);

  const pageChange = useCallback(
    ({ selected }) => {
      fetchList(
        {
          limit: DEFAULT_COMMENTS_LIMIT,
          offset: selected * DEFAULT_COMMENTS_LIMIT,
        },
        debouncedSearchTerm,
        filterOption
      );
    },
    [fetchList, debouncedSearchTerm, filterOption]
  );

  useEffect(() => {
    if (isRemoved) {
      fetchList({
        limit: DEFAULT_COMMENTS_LIMIT,
        offset: pagination.nextOffset - DEFAULT_COMMENTS_LIMIT,
      });
      deleteCommentClear();
    }
  }, [isRemoved]);

  useEffect(() => {
    (isReply || isReadAllComments) && fetchList();
    isReply && replyToCommentClear();
    if (isReadAllComments) {
      getUnreadCount();
      readAllCommentsClear();
    }
  }, [
    isReply,
    isReadAllComments,
    fetchList,
    replyToCommentClear,
    readAllCommentsClear,
    getUnreadCount,
  ]);

  const handleEnterSearch = useCallback(({ target: { value } }) => {
    const trimmedSearch = value.trimLeft();
    if (trimmedSearch.length <= MAX_SEARCH_LENGTH) {
      setSearchQuery(trimmedSearch);
    }
  }, []);

  useEffect(() => {
    ready && fetchList({}, debouncedSearchTerm || null);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!loading && markAll) {
      setMarkAll(false);
    }
  }, [loading, markAll]);

  const readCommnetChange = useCallback(
    (id) => {
      if (!loading) {
        readComment(id);
      }
    },
    [loading]
  );

  const approveCommnetCallback = useCallback(
    (id) => {
      if (!loading) {
        approveComment(id);
      }
    },
    [approveComment]
  );

  const deleteCommentCallback = useCallback(
    (id) => {
      if (!loading) {
        deleteComment(id);
      }
    },
    [deleteComment]
  );

  const replyToCommentCallback = useCallback(
    (id, comment) => {
      if (!loading) {
        replyToComment(id, comment);
      }
    },
    [loading]
  );

  const getPaginationText = () =>
    `Showing ${(pagination.nextPage - 2) * DEFAULT_COMMENTS_LIMIT + 1} - ${
      pagination.nextOffset > pagination.totalCount
        ? pagination.totalCount
        : pagination.nextOffset
    } of ${pagination.totalCount}`;

  const TopContentComponetnt = useMemo(
    () => (id ? IndividualContentTopContent : CommnetsTopContent),
    [id]
  );

  const onReadAllComments = (data) => {
    getListCommentsClear();
    readAllComments(data);
    setMarkAll(true);
  };

  return (
    <>
      {loading && markAll ? (
        <BlueSpinner />
      ) : (
        <>
          <TopContentComponetnt
            handleEnterSearch={handleEnterSearch}
            searchQuery={searchQuery}
            items={items}
            pagination={pagination}
            pageChange={pageChange}
            showResetFilters={showResetFilters}
            handleResetFilters={handleResetFilters}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            isPremiumArticlesComments={isPremiumArticlesComments}
            filterOption={filterOption}
            handleChangeFilters={handleChangeFilters}
            handleSubmitFilters={handleSubmitFilters}
            readAllCommentsFunc={onReadAllComments}
          />
          <Row>
            <Col lg="12">
              {items.map((item) => (
                <CommentCard
                  displayViewBtn={!id}
                  key={item.id}
                  readCommnetChange={readCommnetChange}
                  approveCommnet={approveCommnetCallback}
                  deleteComment={deleteCommentCallback}
                  replyToComment={replyToCommentCallback}
                  isPremiumArticlesComments={isPremiumArticlesComments}
                  {...item}
                />
              ))}
            </Col>
          </Row>
          <Row
            className={classNames(
              "align-items-center",
              !id && "justify-content-between"
            )}
          >
            <Col lg="4">{!!items.length && getPaginationText()}</Col>
            <Col
              lg="4"
              className={classNames(!id && "d-flex justify-content-end")}
            >
              {!!items.length &&
                pagination?.totalCount > DEFAULT_COMMENTS_LIMIT && (
                  <CustomReactPaginate
                    pagination={pagination}
                    pageChange={pageChange}
                    limit={DEFAULT_COMMENTS_LIMIT}
                    className="mb-0"
                    needForcePage
                  />
                )}
            </Col>
          </Row>

          {!items.length && !loading && (
            <div className={styles.noComments}>
              {filterOption.length || debouncedSearchTerm
                ? "No results found"
                : "There are no comments"}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ Comments }) => {
  return {
    comments: Comments,
  };
};

export default connect(mapStateToProps, {
  getListComments,
  readComment,
  approveComment,
  deleteComment,
  deleteCommentClear,
  getUnreadCount,
  replyToComment,
  replyToCommentClear,
  readAllComments,
  readAllCommentsClear,
  readCommentClear,
  getListCommentsClear,
})(CommentsList);
