import React from "react";
import { CardTitle } from "reactstrap";

import CreateCouponButtonSubscriptions from "../CreateCouponButtonSubscriptions/CreateCouponButtonSubscriptions";

const TableHeaderSubscriptions = ({
  title = "Subscription coupons",
  buttonText = "Create subscription coupon",
}) => {
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-4">
      <CardTitle className="mb-0">{title}</CardTitle>
      <CreateCouponButtonSubscriptions buttonText={buttonText} />
    </div>
  );
};

export default TableHeaderSubscriptions;
