import {
  CREATE_BUNDLE,
  CREATE_BUNDLE_CLEAR,
  CREATE_BUNDLE_ERROR,
  CREATE_BUNDLE_SUCCESS,
  GET_LIST_BUNDLE,
  GET_LIST_BUNDLE_ERROR,
  GET_LIST_BUNDLE_SUCCESS,
  GET_BUNDLE,
  GET_BUNDLE_SUCCESS,
  GET_BUNDLE_CLEAR,
  GET_BUNDLE_ERROR,
  UPDATE_BUNDLE,
  UPDATE_BUNDLE_SUCCESS,
  UPDATE_BUNDLE_CLEAR,
  UPDATE_BUNDLE_ERROR,
  REMOVE_BUNDLE,
  REMOVE_BUNDLE_SUCCESS,
  REMOVE_BUNDLE_CLEAR,
  REMOVE_BUNDLE_ERROR,
  CHANGE_BUNDLE_STATUS,
  CHANGE_BUNDLE_STATUS_SUCCESS,
  CHANGE_BUNDLE_STATUS_ERROR,
  CHANGE_BUNDLE_STATUS_CLEAR,
  CLEAR_LIST_BUNDLE,
  GET_COURSES_FOR_OPTION,
  GET_COURSES_FOR_OPTION_SUCCESS,
  GET_COURSES_FOR_OPTION_ERROR,
  GET_COURSES_FOR_OPTION_CLEAR,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  data: [],
  dataCoursesForOption: [],
  item: null,
  created: null,
  updated: null,
  removed: null,
  changedStatus: null,
  loadingTriggerCourse: null,
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
  loadingForOption: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BUNDLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_BUNDLE_SUCCESS:
      state = {
        ...state,
        data: [...state.data, ...action.payload.data],
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_BUNDLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_BUNDLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_BUNDLE_SUCCESS:
      state = {
        ...state,
        created: action.payload,
        loading: false,
      };
      break;
    case CREATE_BUNDLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CREATE_BUNDLE_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case UPDATE_BUNDLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_BUNDLE_SUCCESS:
      state = {
        ...state,
        updated: action.payload,
        loading: false,
      };
      break;
    case UPDATE_BUNDLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_BUNDLE_CLEAR:
      state = {
        ...state,
        updated: null,
      };
      break;
    case GET_BUNDLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BUNDLE_SUCCESS:
      state = {
        ...state,
        item: action.payload,
        loading: false,
      };
      break;
    case GET_BUNDLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_BUNDLE_CLEAR:
      state = {
        ...state,
        data: [],
        item: null,
        loading: false,
      };
      break;
    case REMOVE_BUNDLE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REMOVE_BUNDLE_SUCCESS:
      state = {
        ...state,
        removed: action.payload,
        loading: false,
      };
      break;
    case REMOVE_BUNDLE_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REMOVE_BUNDLE_CLEAR:
      state = {
        ...state,
        created: null,
      };
      break;
    case CHANGE_BUNDLE_STATUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CHANGE_BUNDLE_STATUS_SUCCESS:
      state = {
        ...state,
        data: state.data.map((item) => item.id === action.payload.id
          ? action.payload
          : item
        ),
        changedStatus: action.payload,
        loading: false,
      };
      break;
    case CLEAR_LIST_BUNDLE:
      state = { ...initialState };
      break;
    case CHANGE_BUNDLE_STATUS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CHANGE_BUNDLE_STATUS_CLEAR:
      state = {
        ...state,
        changedStatus: null,
      };
      break;
    case GET_COURSES_FOR_OPTION:
      state = {
        ...state,
        loadingForOption: true,
      }
      break;
    case GET_COURSES_FOR_OPTION_SUCCESS:
      state = {
        ...state,
        dataCoursesForOption: action.payload,
        loadingForOption: false
      }
      break
    case GET_COURSES_FOR_OPTION_ERROR:
      state = {
        ...state,
        loadingForOption: false,
      };
      break;
    case GET_COURSES_FOR_OPTION_CLEAR:
      state = {
        ...state,
        dataCoursesForOption: []
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
