import {
  GET_ADMIN_PROFILE,
  GET_ADMIN_PROFILE_SUCCESS,
  GET_ADMIN_PROFILE_ERROR,
  CLEAR_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE,
  UPDATE_ADMIN_PROFILE_SUCCESS,
  UPDATE_ADMIN_PROFILE_ERROR,
  CHANGE_ADMIN_PASSWORD,
  CHANGE_ADMIN_PASSWORD_SUCCESS,
  CHANGE_ADMIN_PASSWORD_ERROR,
  CHANGE_ADMIN_PASSWORD_CLEAR,
  CHANGE_ADMIN_EMAIL,
  CHANGE_ADMIN_EMAIL_SUCCESS,
  CHANGE_ADMIN_EMAIL_ERROR,
  CHANGE_ADMIN_EMAIL_CLEAR,
  VERIFY_CHANGE_EMAIL,
  VERIFY_CHANGE_EMAIL_SUCCESS,
  VERIFY_CHANGE_EMAIL_ERROR,
  VERIFY_CHANGE_EMAIL_CLEAR,
  CHANGE_ADMIN_AVATAR,
  CHANGE_ADMIN_AVATAR_SUCCESS,
  CHANGE_ADMIN_AVATAR_ERROR,
} from "./actionTypes";

export const getAdminProfile = () => {
  return {
    type: GET_ADMIN_PROFILE,
  };
};

export const getProfileSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_ADMIN_PROFILE_SUCCESS,
  };
};

export const getAdminProfileError = ({ message }) => {
  return {
    payload: { message },
    type: GET_ADMIN_PROFILE_ERROR,
  };
};

export const clearAdminProfile = () => {
  return {
    type: CLEAR_ADMIN_PROFILE,
  };
};

export const updateAdminProfile = ({ model, file, toRemoveAvatar = false }) => {
  return {
    payload: { model, file, toRemoveAvatar },
    type: UPDATE_ADMIN_PROFILE,
  };
};

export const updateAdminProfileSuccess = ({ data }) => {
  return {
    payload: { data },
    type: UPDATE_ADMIN_PROFILE_SUCCESS,
  };
};

export const updateAdminProfileError = ({ message }) => {
  return {
    payload: { message },
    type: UPDATE_ADMIN_PROFILE_ERROR,
  };
};

export const changeAdminPassword = (payload) => {
  return {
    payload,
    type: CHANGE_ADMIN_PASSWORD,
  };
};

export const changeAdminPasswordSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CHANGE_ADMIN_PASSWORD_SUCCESS,
  };
};

export const changeAdminPasswordError = ({ message }) => {
  return {
    payload: { message },
    type: CHANGE_ADMIN_PASSWORD_ERROR,
  };
};

export const changeAdminPasswordClear = () => {
  return {
    type: CHANGE_ADMIN_PASSWORD_CLEAR,
  };
};

export const changeAdminEmail = (payload) => {
  return {
    payload,
    type: CHANGE_ADMIN_EMAIL,
  };
};

export const changeAdminEmailSuccess = (email) => {
  return {
    payload: { email },
    type: CHANGE_ADMIN_EMAIL_SUCCESS,
  };
};

export const changeAdminEmailError = ({ message }) => {
  return {
    payload: { message },
    type: CHANGE_ADMIN_EMAIL_ERROR,
  };
};

export const changeAdminEmailClear = () => {
  return {
    type: CHANGE_ADMIN_EMAIL_CLEAR,
  };
};

export const verifyChangeEmail = (model) => {
  return {
    type: VERIFY_CHANGE_EMAIL,
    payload: model,
  };
};

export const verifyChangeEmailSuccess = () => {
  return {
    type: VERIFY_CHANGE_EMAIL_SUCCESS,
  };
};

export const verifyChangeEmailError = ({ message }) => {
  return {
    type: VERIFY_CHANGE_EMAIL_ERROR,
    payload: { message },
  };
};

export const verifyChangeEmailClear = () => {
  return {
    type: VERIFY_CHANGE_EMAIL_CLEAR,
  };
};

export const changeAdminAvatar = ({ file, toRemoveAvatar = false }) => {
  return {
    payload: { file, toRemoveAvatar },
    type: CHANGE_ADMIN_AVATAR,
  };
};

export const changeAdminAvatarSuccess = ({ data }) => {
  return {
    payload: { data },
    type: CHANGE_ADMIN_AVATAR_SUCCESS,
  };
};

export const changeAdminAvatarError = ({ message }) => {
  return {
    payload: { message },
    type: CHANGE_ADMIN_AVATAR_ERROR,
  };
};