import {
  GET_LIST_COMMENTS,
  GET_LIST_COMMENTS_SUCCESS,
  GET_LIST_COMMENTS_ERROR,
  READ_COMMENT,
  READ_COMMENT_SUCCESS,
  READ_COMMENT_ERROR,
  READ_COMMENT_CLEAR,
  APPROVE_COMMENT,
  APPROVE_COMMENT_SUCCESS,
  APPROVE_COMMENT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_CLEAR,
  GET_UNREAD_COUNT,
  GET_UNREAD_COUNT_SUCCESS,
  GET_UNREAD_COUNT_ERROR,
  REPLY_TO_COMMENT,
  REPLY_TO_COMMENT_SUCCESS,
  REPLY_TO_COMMENT_ERROR,
  REPLY_TO_COMMENT_CLEAR,
  READ_ALL_COMMENTS,
  READ_ALL_COMMENTS_SUCCESS,
  READ_ALL_COMMENTS_ERROR,
  READ_ALL_COMMENTS_CLEAR,
  GET_LIST_COMMENTS_CLEAR,
} from "./actionTypes";

export const getListComments = (payload) => {
  return {
    payload,
    type: GET_LIST_COMMENTS,
  };
};

export const getListCommentsSuccess = ({ data, pagination }) => {
  return {
    payload: { data, pagination },
    type: GET_LIST_COMMENTS_SUCCESS,
  };
};

export const getListCommentsError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_LIST_COMMENTS_ERROR,
  };
};

export const getListCommentsClear = () => {
  return {
    type: GET_LIST_COMMENTS_CLEAR
  };
}

export const readComment = (id) => {
  return {
    payload: { id },
    type: READ_COMMENT,
  };
};

export const readCommentSuccess = ({ data }) => {
  return {
    payload: { data },
    type: READ_COMMENT_SUCCESS,
  };
};

export const readCommentError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: READ_COMMENT_ERROR,
  };
};

export const readCommentClear = () => {
  return {
    type: READ_COMMENT_CLEAR,
  };
};

export const approveComment = (id) => {
  return {
    payload: { id },
    type: APPROVE_COMMENT,
  };
};

export const approveCommentSuccess = ({ data }) => {
  return {
    payload: { data },
    type: APPROVE_COMMENT_SUCCESS,
  };
};

export const approveCommentError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: APPROVE_COMMENT_ERROR,
  };
};

export const deleteComment = (id) => {
  return {
    payload: { id },
    type: DELETE_COMMENT,
  };
};

export const deleteCommentSuccess = () => {
  return {
    type: DELETE_COMMENT_SUCCESS,
  };
};

export const deleteCommentError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: DELETE_COMMENT_ERROR,
  };
};

export const deleteCommentClear = () => {
  return {
    type: DELETE_COMMENT_CLEAR,
  };
};

export const getUnreadCount = () => {
  return {
    type: GET_UNREAD_COUNT,
  };
};

export const getUnreadCountSuccess = ({ data }) => {
  return {
    payload: { data },
    type: GET_UNREAD_COUNT_SUCCESS,
  };
};

export const getUnreadCountError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: GET_UNREAD_COUNT_ERROR,
  };
};

export const replyToComment = (id, comment) => {
  return {
    payload: { id, comment },
    type: REPLY_TO_COMMENT,
  };
};

export const replyToCommentSuccess = () => {
  return {
    type: REPLY_TO_COMMENT_SUCCESS,
  };
};

export const replyToCommentError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: REPLY_TO_COMMENT_ERROR,
  };
};

export const replyToCommentClear = () => {
  return {
    type: REPLY_TO_COMMENT_CLEAR,
  };
};

export const readAllComments = (commentProductType) => {
  return {
    payload: { commentProductType },
    type: READ_ALL_COMMENTS,
  };
};

export const readAllCommentsSuccess = () => {
  return {
    type: READ_ALL_COMMENTS_SUCCESS,
  };
};

export const readAllCommentsError = ({ message = "Unknown error" }) => {
  return {
    payload: { message },
    type: READ_ALL_COMMENTS_ERROR,
  };
};

export const readAllCommentsClear = () => {
  return {
    type: READ_ALL_COMMENTS_CLEAR,
  };
};
