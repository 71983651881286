import React from "react";
import { Fragment } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import PremiumArticleTags from "../PremiumArticleTags";
import PerfectScrollbar from "react-perfect-scrollbar";
import styles from "./PremiumArticleSectionCard.module.scss";

const PremiumArticleSectionCard = ({
  name,
  id,
  needUpate,
  setNeedUpdate,
  data,
  updateFunc,
  editId,
  setEditId,
}) => {
  return (
    <Card className={styles.card}>
      <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: true }}>
        <CardBody className={styles.cardBody}>
          <CardTitle className={styles.cardTitle}>{name}</CardTitle>
          {data.map((category, index) => (
            <Fragment key={"premium-article-category" + id + index}>
              <PremiumArticleTags
                {...category}
                needUpate={needUpate}
                setNeedUpdate={setNeedUpdate}
                updateFunc={updateFunc}
                isCategory
                editId={editId}
                setEditId={setEditId}
              />
            </Fragment>
          ))}
        </CardBody>
      </PerfectScrollbar>
    </Card>
  );
};

export default PremiumArticleSectionCard;
