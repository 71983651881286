import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import { END, eventChannel } from "redux-saga";

import {
  createBundleError,
  createBundleSuccess,
  getBundleError,
  getBundleSuccess,
  getListBundlesError,
  getListBundlesSuccess,
  removeBundleError,
  removeBundleSuccess,
  updateBundleError,
  updateBundleSuccess,
  changeBundleStatusSuccess,
  changeBundleStatusError,
  getCoursesForOptionSuccess,
  getCoursesForOptionError,
} from "./actions";
import {
  CREATE_BUNDLE,
  GET_BUNDLE,
  GET_LIST_BUNDLE,
  REMOVE_BUNDLE,
  UPDATE_BUNDLE,
  CHANGE_BUNDLE_STATUS,
  GET_COURSES_FOR_OPTION,
} from "./actionTypes";
import BundleService from "../../../services/BundleService";
import FilesService from "../../../services/FilesService";
import {
  uploadFailure,
  uploadInitiate,
  uploadProgress,
  uploadSuccess,
} from "../../progress/actions";
import errorCodes from "../../../constants/errorCodes";
import throwCustomError from "../../../helpers/throwCustomError";
import { MAX_COURSES_LIMIT } from "../../../constants/courses";
import CourseService from '../../../services/CourseService';

const getBundleListAsync = async (data) => {
  const { model } = data;
  return await BundleService.getList(model);
};

const getBundleAsync = async (id) => {
  return await BundleService.getOne(id);
};

const bundleComposite = (model) => {
  const {
    overview,
    productInfo,
    prices,
    title,
    urlPart,
    files,
    courseIds,
  } = model;

  return {
    files,
    bundleData: {
      overview,
      productInfo,
      prices,
      title,
      urlPart,
      courseIds,
    },
  };
};

const getFilesArray = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return [...files, ...thumbnailFiles];
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles;
  }
};

const getFilesTotalSize = (files, thumbnailFiles) => {
  if (
    !!files &&
    !!files.length &&
    !!thumbnailFiles &&
    !!thumbnailFiles.length
  ) {
    return files[0].size + thumbnailFiles[0].size;
  } else if (!!thumbnailFiles && !!thumbnailFiles.length) {
    return thumbnailFiles[0].size;
  }
};

/**
 * Bundle files channel for files uploading watching
 * @param data<Object>
 * @param mode<"create"|"update"> - mode of files progress channel (create by default)
 */
const bundleFilesChannel = (data, mode = "create") => {
  return eventChannel((emit) => {
    const { model } = data;
    const { files, bundleData } = bundleComposite(model);
    const actionFunc =
      mode === "create"
        ? BundleService.create(bundleData)
        : BundleService.update(data.bundleId, bundleData);
    if (
      (!files || !files.length)
    ) {
      // here is only one file and it's mandatory
      actionFunc
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    } else {
      const onProgress = ({ loaded }, file, chunkIndex) => {
        emit({ file, loaded, chunkIndex });
      };
      let bundle;
      actionFunc
        .then(async ({ data }) => {
          bundle = data;
          emit({ files, filesTotalSize: files[0].size });
          return FilesService.uploadImageFile({ files }, onProgress).catch(
            (error) => {
              throwCustomError(error);
            }
          );
          // const videoThumbnaiResponse = await FilesService.uploadImageFile(
          //   { files: videoThumbnail },
          //   onProgress
          // ).catch((error) => {
          //   throwCustomError(error);
          // });
          // return { filesResponse, videoThumbnaiResponse };
        })
        .then((filesResponse) => {
          return BundleService.addImageToBundle(bundle.id, {
            fileId: filesResponse[0].file.id,
          }).catch((error) => {
            throwCustomError(error);
          });
        })
        .then((result) => {
          emit({ success: result });
          emit(END);
        })
        .catch((err) => {
          emit({ err });
          emit(END);
        });
      return () => {};
    }
  });
};

const removeBundleAsync = async (id) => {
  return await BundleService.remove(id);
};

const changeBundleStatusAsync = async (id) => {
  return await BundleService.changeStatus(id);
};

const getCoursesForOptionListAsync = async (coursesCount = 0, coursesList = []) => {

  const { pagination: { totalCount }, data } = await CourseService.getAllCoursesList({
    limit: MAX_COURSES_LIMIT,
    offset: coursesCount,
  });
  coursesList = [...coursesList, ...data.map(({ id, title }) => ({ id, title }))]

  if (totalCount > coursesList.length) {
    coursesList = await getCoursesForOptionListAsync(coursesList.length, coursesList)
  }

  return coursesList;
};

function* getBundleList({ payload }) {
  try {
    const response = yield call(getBundleListAsync, payload);
    yield put(getListBundlesSuccess(response));
  } catch (error) {
    yield put(getListBundlesError(error));
  }
}

function* getBundle({ payload: { bundleId } }) {
  try {
    const response = yield call(getBundleAsync, bundleId);
    yield put(getBundleSuccess(response));
  } catch (error) {
    yield put(getBundleError(error));
  }
}

function* changeBundleStatus({ payload: { id } }) {
  try {
    const response = yield call(changeBundleStatusAsync, id);
    yield put(changeBundleStatusSuccess(response));
  } catch (error) {
    yield put(changeBundleStatusError(error));
  }
}

function* createBundleProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(createBundleError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(createBundleError(err));
        // history.push(`/bundles`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(createBundleSuccess(success));
      history.push(`/bundles`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded }));
    }
  }
}

function* createBundle({ payload: { data, history } }) {
  try {
    yield fork(
      createBundleProgressListener,
      bundleFilesChannel(data),
      history
    );
  } catch (error) {
    yield put(createBundleError(error));
  }
}

function* updateBundleProgressListener(chan, history) {
  while (true) {
    const { files, filesTotalSize, file, loaded, chunkIndex, err, success } =
      yield take(chan);
    if (!!files && !!filesTotalSize) {
      yield put(uploadInitiate({ files, filesTotalSize }));
    }
    if (err) {
      if (err.code === errorCodes.BAD_REQUEST_ERROR) {
        yield put(updateBundleError(err));
      } else {
        yield put(uploadFailure(err));
        yield put(updateBundleError(err));
        // history.push(`/bundles`);
      }
      return;
    }
    if (success) {
      yield put(uploadSuccess(success));
      yield put(updateBundleSuccess(success));
      history.push(`/bundles`);
      return;
    }
    if (!!file && !!loaded) {
      yield put(uploadProgress({ file, loaded, chunkIndex }));
    }
  }
}

function* updateBundle({ payload: { data, history } }) {
  try {
    yield fork(
      updateBundleProgressListener,
      bundleFilesChannel(data, "update"),
      history,
    );
  } catch (error) {
    yield put(updateBundleError(error));
  }
}

function* removeBundle({ payload: { data } }) {
  try {
    const response = yield call(removeBundleAsync, data);
    yield put(removeBundleSuccess(response));
  } catch (error) {
    yield put(removeBundleError(error));
  }
}

function* getCoursesForOption({payload: { limit = 0, offset = 0 }}) {
  try {
    const response = yield call(getCoursesForOptionListAsync);
    yield put(getCoursesForOptionSuccess(response));
  } catch (error) {
    yield put(getCoursesForOptionError(error))
  }
}

export function* watchGetBundleList() {
  yield takeEvery(GET_LIST_BUNDLE, getBundleList);
}

export function* watchBundleCourse() {
  yield takeEvery(CREATE_BUNDLE, createBundle);
}

export function* watchUpdateCourse() {
  yield takeEvery(UPDATE_BUNDLE, updateBundle);
}

export function* watchRemoveBundle() {
  yield takeEvery(REMOVE_BUNDLE, removeBundle);
}

export function* watchGetBundle() {
  yield takeEvery(GET_BUNDLE, getBundle);
}

export function* watchChangeBundleStatus() {
  yield takeEvery(CHANGE_BUNDLE_STATUS, changeBundleStatus);
}

export function* watchGetCoursesForOption() {
  yield takeEvery(GET_COURSES_FOR_OPTION, getCoursesForOption)
}

function* coursesSaga() {
  yield all([
    fork(watchGetBundleList),
    fork(watchBundleCourse),
    fork(watchUpdateCourse),
    fork(watchRemoveBundle),
    fork(watchGetBundle),
    fork(watchChangeBundleStatus),
    fork(watchGetCoursesForOption)
  ]);
}

export default coursesSaga;
