export const GET_LIST_ADMINS = "GET_LIST_ADMINS";
export const GET_LIST_ADMINS_SUCCESS = "GET_LIST_ADMINS_SUCCESS";
export const GET_LIST_ADMINS_ERROR = "GET_LIST_ADMINS_ERROR";

export const CHANGE_ADMIN_STATUS = "CHANGE_ADMIN_STATUS";
export const CHANGE_ADMIN_STATUS_SUCCESS = "CHANGE_ADMIN_STATUS_SUCCESS";
export const CHANGE_ADMIN_STATUS_ERROR = "CHANGE_ADMIN_STATUS_ERROR";

export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_ERROR = "CREATE_ADMIN_ERROR";
export const CREATE_ADMIN_CLEAR = "CREATE_ADMIN_CLEAR";

export const NEED_UPDATE_ADMIN_LIST_CLEAR = "NEED_UPDATE_ADMIN_LIST_CLEAR";
