import React, { useEffect, useMemo, useState } from "react";
import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Button, Card, CardBody, Label, FormGroup, Col } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";
import {
  getAdminProfile,
  updateAdminProfile,
  clearAdminProfile,
  changeAdminAvatar
} from "../../store/dashboard/Settings/actions";

import styles from "./forms.module.scss";
import UpdateAdminAvatarForm from "./UpdateAdminAvatarForm";
import FileHelper from "../../helpers/FileHelper";
import ProgressWatcher from "../Common/ProgressWatcher";
import keyEnterPrevent from "../../helpers/keyEnterPrevent";
import { clearList10MonthSubscriptionPlan, getList10MonthSubscriptionPlan, toggleShow10MonthSubscriptionPlan } from '../../store/dashboard/SubscriptionPlans/actions';

const PROGRESS_TEXT = "Image is uploading now. Please, don't quit this page";

const showSubscriptionPlanValue = 'ShowSubscriptionPlan';

const Toggle10MonthSubscriptionPlanFrom = ({
  settings,
  getList10MonthSubscriptionPlan,
  clearList10MonthSubscriptionPlan,
  toggleShow10MonthSubscriptionPlan,
  subscriptionPlans,
  getAdminProfile,
  updateAdminProfile,
  clearAdminProfile,
  changeAdminAvatar
}) => {
  const { tenMonthSubscriptionPlans, tenMonthSubscriptionPlanListLoading, loading } = subscriptionPlans;

  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const [readyState, setReadyState] = useState(false);
  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  useEffect(() => {
    getList10MonthSubscriptionPlan();
    setReadyState(true);
    return () => {
      clearList10MonthSubscriptionPlan();
    };
  }, []);

  const handleFormSubmit = (event, values) => {
    const { showSubscriptionPlan } = values;
    const model = {
      toShowSubscriptionPlan: showSubscriptionPlan.length === 1,
    };

    toggleShow10MonthSubscriptionPlan(model);
  };

  const getInitialState = useMemo(() => {
    if (!!tenMonthSubscriptionPlans.length) {
      return {
        // userName: tenMonthSubscriptionPlans.userName,
        showSubscriptionPlan: [showSubscriptionPlanValue],
      };
    }
    return {
      // userName: "",
      showSubscriptionPlan: [],
    };
  }, [tenMonthSubscriptionPlans]);

  const avatarUpdated = (result, fileName, sizes) => {
    if (result) {
      const fileCropped = FileHelper.dataUrlToFile(result, fileName);
      fileCropped.sizes = [sizes];

      changeAdminAvatar({ file: fileCropped })
    }
  };

  const removeImage = () => {
    if (!tenMonthSubscriptionPlans.avatar) {
      return;
    }

    changeAdminAvatar({ file: null, toRemoveAvatar: true });
  }

  return (
    <>
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <div className="d-flex flex-column h-100">
        {!tenMonthSubscriptionPlanListLoading && readyState && (
          <>
          <Card className={classNames(styles.card, styles.cardBottom, "mb-0")}>
            <CardBody>
              <div className={classNames(styles.cardTitle, "mb-3")}>
                Toggle 10 Month Subscription Plan
              </div>
              <AvForm
                onValidSubmit={handleFormSubmit}
                ref={formRef}
                model={getInitialState}
                onKeyDown={keyEnterPrevent}
              >
                <FormGroup className='mb-4' row>
                {/* <Col lg={2}>
                  <Label>Toggle 10 month subscription plan</Label>
                </Col> */}
                <Col lg={10}>
                <AvCheckboxGroup className='' name={'showSubscriptionPlan'}>
                  <AvCheckbox
                    onChange={handleFormChange}
                    className="cursor-pointer"
                    customInput
                    label="Show 10 month subscription plan"
                    value={showSubscriptionPlanValue}
                  />
                  </AvCheckboxGroup>
                </Col>
                
              </FormGroup>
                {/* <FormGroupText
                  vertical
                  className="mb-0"
                  labelClassName="pt-0"
                  name="userName"
                  label="Username"
                  placeholder="Enter new username"
                  validator={formsValidators.userName}
                  handleFormChange={handleFormChange}
                /> */}
                <Button
                  type="submit"
                  color="primary"
                  disabled={disableState}
                  className={classNames(styles.cardBtn, "ml-auto d-block mt-2")}
                >
                  Save
                </Button>
              </AvForm>
            </CardBody>
          </Card>
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = ({ Settings: settings, SubscriptionPlans: subscriptionPlans }) => ({ settings, subscriptionPlans });

export default connect(mapStateToProps, {
  getList10MonthSubscriptionPlan,
  toggleShow10MonthSubscriptionPlan,
  clearList10MonthSubscriptionPlan,
})(Toggle10MonthSubscriptionPlanFrom);
