import { useEffect, useRef } from "react";
const useIsMountedRef = () => {
  const ref = useRef(false);
  useEffect(() => {
    ref.current = true;
    return () => (ref.current = false);
  }, []);
  return ref;
};
export default useIsMountedRef;
