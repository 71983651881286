import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import noop from "../../helpers/noop";
import CreateAdminForm from "../Forms/CreateAdminForm";

import styles from "./modal.module.scss";

const CreateAdminModal = ({ isOpen, onCancel = noop, onConfirm = noop }) => {
  const saveAdmiHnandler = (model) => {
    onConfirm(model);
  };

  return (
    <Modal
      className={styles.modalWidth400px}
      isOpen={isOpen}
      centered
      toggle={() => onCancel()}
    >
      <ModalHeader className="border-0 pb-0" toggle={() => onCancel()}>
        Create new admin
      </ModalHeader>
      <ModalBody>
        <CreateAdminForm saveAdminFunc={saveAdmiHnandler} />
      </ModalBody>
    </Modal>
  );
};

export default CreateAdminModal;
