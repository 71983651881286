import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardTitle, Col, FormGroup, Label, Row } from "reactstrap";
import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import formsValidators, {
  requiredIfOtherExist,
} from "../../validators/form.validators";
import useDropzoneFacade from "../../hooks/useDropzoneFacade";
import useAvForm from "../../hooks/useAvForm";
import FormGroupText from "./inputs/FormGroupText";
import WYSISYGComponent from "./inputs/WYSISYGComponent";
import FormGroupReactSelect from "./inputs/FormGroupReactSelect";
import noop from "../../helpers/noop";
import { getConverterKitTags } from "../../store/dashboard/ConverterKitTags/actions";
import useMountedState from "../../hooks/useMountedState";
import FormGroupVideo from "./inputs/FormGroupVideo";
import InputWithReadOnlyTextPart from "./inputs/InputWithReadOnlyTextPart";
import useInputWithReadOnlyTextPart from "../../hooks/useInputWithReadOnlyTextPart";
import getReadOnlyUrlPart from "../../helpers/getReadOnlyUrlPart";

import styles from "./forms.module.scss";

const currencies = ["EUR", "USD", "GBP", "NZD", "AUD", "CAD", "INR"];
const applicableToSubscriptionValue = 'ApplicableToSubscription';
const collaborationCourseValue = 'CollaborationCourse';


const studentLabels = ["Student 1", "Student 2", "Student 3"];

const SaveCourse = ({
  model,
  saveCourseFunc,
  converterKitTags,
  getConverterKitTags,
  courses,
  updateDirtyState = noop,
  categoryId,
}) => {
  
  const initialState = {
    title: "",
    urlPart: "",
    overview: "",
    lessons: "",
    equipment: "",
    included: "",
    aboutTutor: "",
    convertkitTagId: "",
    applicableToSubscription: [applicableToSubscriptionValue],
    collaborationCourse: [],
    EUR: "",
    USD: "",
    GBP: "",
    NZD: "",
    AUD: "",
    CAD: "",
    INR: "",
  };

  const [formData, updateFormData] = useState(initialState);
  const [tagId, setTagId] = useMountedState(null);
  const [initialFile, updateInitialFile] = useMountedState(null);
  const [initialVideoThumbnail, updateInitialVideoThumbnail] =
    useMountedState(null);
  const [ready, updateReadyState] = useMountedState(false);
  const [formRef, hasFormError, handleFormChange, isDirty, values] =
    useAvForm();
  const [error, selectedFiles, handleAcceptedFiles, setSelectedFiles] =
    useDropzoneFacade({ type: "video" });

  const [
    thumbnailError,
    selectedThumbnailImages,
    handleTumbunalAcceptedFiles,
    setSelectedThumbnailImages,
    errorSelectedId,
  ] = useDropzoneFacade();

  const urlPart = useInputWithReadOnlyTextPart(
    values?.title,
    formRef,
    model?.urlPart
  );

  const { loading } = courses;
  const { data } = converterKitTags;

  const handleFormSubmit = (event, values) => {
    const {
      title,
      urlPart,
      overview,
      lessons,
      equipment,
      included,
      aboutTutor,
      convertkitTagId,
      feedback0,
      author0,
      feedback1,
      author1,
      feedback2,
      author2,
      applicableToSubscription,
      collaborationCourse,
    } = values;
    const model = {
      title,
      urlPart,
      overview,
      lessons,
      equipment,
      included,
      aboutTutor,
      convertkitTagId,
      isApplicableToSubscription: applicableToSubscription.length === 1,
      isCollaborationCourse: collaborationCourse.length === 1,
      prices: currencies.map((currency) => ({
        currency,
        price: Number(values[currency]),
      })),
    };
    if (feedback0 || feedback1 || feedback2 || author0 || author1 || author2) {
      model.studentsSays = [];

      feedback0 &&
        model.studentsSays.push({ feedback: feedback0, author: author0 });
      feedback1 &&
        model.studentsSays.push({ feedback: feedback1, author: author1 });
      feedback2 &&
        model.studentsSays.push({ feedback: feedback2, author: author2 });
    }
    if (initialFile !== selectedFiles[0]) {
      model.files = selectedFiles;
    }

    if (initialVideoThumbnail !== selectedThumbnailImages[0]) {
      model.videoThumbnail = selectedThumbnailImages;
    }

    saveCourseFunc(model);
  };

  const pageTitle = useMemo(
    () => (model ? "Edit course" : "Create course"),
    [model]
  );
  const btnSaveLabel = useMemo(
    () => (model ? "Save" : "Create draft"),
    [model]
  );
  const disableState = useMemo(
    () =>
      !(
        isDirty ||
        initialFile !== selectedFiles[0] ||
        initialVideoThumbnail !== selectedThumbnailImages[0] ||
        tagId !== model?.convertkitTagId
      ) ||
      loading ||
      !selectedFiles.length ||
      !selectedThumbnailImages[0] ||
      hasFormError,
    [
      loading,
      selectedFiles,
      selectedThumbnailImages,
      hasFormError,
      isDirty,
      initialFile,
      initialVideoThumbnail,
      tagId,
      model,
    ]
  );
  useEffect(() => {
    getConverterKitTags();
  }, []);

  useEffect(() => {
    updateDirtyState(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (!!model) {
      const {
        title,
        overview,
        lessons,
        equipment,
        included,
        aboutTutor,
        convertkitTagId,
        prices,
        studentsSays,
        initialFile,
        isApplicableToSubscription,
        isCollaborationCourse,
      } = model;
      const formData = {
        ...initialState,
        title,
        overview,
        lessons,
        equipment,
        included,
        aboutTutor,
        convertkitTagId,
        applicableToSubscription: isApplicableToSubscription ? [applicableToSubscriptionValue] : [],
        collaborationCourse: isCollaborationCourse ? [collaborationCourseValue] : []
      };

      if (prices) {
        prices.forEach(
          ({ currency, price }) => (formData[currency] = String(price))
        );
      }

      if (studentsSays) {
        studentsSays.forEach(({ author, feedback }, index) => {
          formData["author" + index] = author;
          formData["feedback" + index] = feedback;
        });
      }

      if (initialFile) {
        updateInitialFile(initialFile);
        handleAcceptedFiles([initialFile], []);
      }

      if (initialFile?.thumbnailImage) {
        updateInitialVideoThumbnail(initialFile.thumbnailImage);
        handleTumbunalAcceptedFiles([initialFile.thumbnailImage], []);
      }

      setTagId(convertkitTagId);

      updateFormData(formData);
    }

    updateReadyState(true);
  }, [model]);

  useEffect(() => {
    if (ready && selectedFiles.length === 0 && initialFile) {
      setSelectedFiles([initialFile]);
    }
  }, [selectedFiles]);

  const tagIdValue = useMemo(() => {
    if (data && tagId) {
      return data.find(({ id }) => id === tagId);
    }
  }, [data, tagId]);

  return (
    ready && (
      <>
        <AvForm
          onValidSubmit={handleFormSubmit}
          ref={formRef}
          model={formData}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              return false;
            }
          }}
        >
          <Card>
            <CardBody>
              <CardTitle className="mb-4">{pageTitle}</CardTitle>
              <FormGroupText
                name="title"
                label="Course title"
                placeholder="Enter course title"
                validator={formsValidators.course.title}
                handleFormChange={handleFormChange}
              />
              <InputWithReadOnlyTextPart
                name="urlPart"
                label="Course URL"
                readOnlyPart={getReadOnlyUrlPart("online-dj-courses")}
                validator={formsValidators.course.urlPart}
                handleFormChange={handleFormChange}
                value={urlPart}
              />
              <FormGroupVideo
                withThumbnail
                multiple={false}
                label="Overview video"
                error={error}
                initialFile={initialFile}
                initialVideoThumbnail={initialVideoThumbnail}
                updateInitialVideoThumbnail={updateInitialVideoThumbnail}
                selectedVideos={selectedFiles}
                handleAcceptedVideos={handleAcceptedFiles}
                setSelectedVideos={setSelectedFiles}
                thumbnailError={thumbnailError}
                selectedThumbnailImages={selectedThumbnailImages}
                handleTumbunalAcceptedFiles={handleTumbunalAcceptedFiles}
                setSelectedThumbnailImages={setSelectedThumbnailImages}
                errorIndex={errorSelectedId}
              />
              <CardTitle className="mb-4">Course info</CardTitle>
              <WYSISYGComponent
                name="overview"
                label="Overview"
                validator={formsValidators.course.overview}
                handleFormChange={handleFormChange}
              />
              <WYSISYGComponent
                name="lessons"
                label="Lessons"
                validator={formsValidators.course.lessons}
                handleFormChange={handleFormChange}
              />
              <WYSISYGComponent
                name="included"
                label="What’s included?"
                placeholder="Enter what's included"
                validator={formsValidators.course.included}
                handleFormChange={handleFormChange}
              />
              <FormGroup className='mb-4' row>
              <Col lg={2}>
               <Label>Subscription Preference</Label>
              </Col>
              <Col lg={10}>
              <AvCheckboxGroup className='' name={'applicableToSubscription'}>
                <AvCheckbox
                  onChange={handleFormChange}
                  className="cursor-pointer"
                  customInput
                  label="Applicable to Subscription"
                  value={applicableToSubscriptionValue}
                />
                </AvCheckboxGroup>
              </Col>
              
              </FormGroup>

              <FormGroup className='mb-4' row>
              <Col lg={2}>
               <Label>Is Collaboration Course</Label>
              </Col>
              <Col lg={10}>
              <AvCheckboxGroup className='' name={'collaborationCourse'}>
                <AvCheckbox
                  disabled={!!model}
                  onChange={handleFormChange}
                  className="cursor-pointer"
                  customInput
                  label="Is Collaboration Course"
                  value={collaborationCourseValue}
                />
                </AvCheckboxGroup>
              </Col>
              
              </FormGroup>
              
              <WYSISYGComponent
                name="equipment"
                label="Equipment"
                validator={formsValidators.course.equipment}
                handleFormChange={handleFormChange}
              />
              <FormGroupText
                name="aboutTutor"
                label="About the tutor"
                placeholder="Enter about the tutor"
                validator={formsValidators.course.aboutTutor}
                handleFormChange={handleFormChange}
              />
              <CardTitle className="mb-4">Prices</CardTitle>
              {currencies.map((currency, index) => (
                <Fragment key={"currency-" + index}>
                  <FormGroupText
                    name={currency}
                    label={currency}
                    placeholder={`Enter ${currency}`}
                    validator={formsValidators.course.prices}
                    handleFormChange={handleFormChange}
                  />
                </Fragment>
              ))}
              <CardTitle className="mb-4">Add ConvertKit tag</CardTitle>
              <FormGroupReactSelect
                options={data}
                name="convertkitTagId"
                label="Tag"
                value={tagIdValue}
                getValue={(value) => setTagId(value)}
                placeholder="Choose tag"
                validator={formsValidators.course.convertkitTagId}
                handleFormChange={handleFormChange}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">What our students say</CardTitle>
              {studentLabels.map((title, sectionIndex) => (
                <Fragment key={"feedback" + sectionIndex}>
                  <CardTitle className="mb-4">{title}</CardTitle>
                  <FormGroupText
                    name={"feedback" + sectionIndex}
                    label="Feedback"
                    placeholder="Enter feedback"
                    validator={{
                      requiredIfOtherExist: requiredIfOtherExist(
                        "author" + sectionIndex,
                        "“Feedback” field is required"
                      ),
                      ...formsValidators.course.feedback,
                    }}
                    handleFormChange={handleFormChange}
                  />
                  <FormGroupText
                    name={"author" + sectionIndex}
                    label="Author"
                    placeholder="Enter author"
                    validator={{
                      requiredIfOtherExist: requiredIfOtherExist(
                        "feedback" + sectionIndex,
                        "“Author” field is required"
                      ),
                      ...formsValidators.course.author,
                    }}
                    handleFormChange={handleFormChange}
                  />
                </Fragment>
              ))}
            </CardBody>
          </Card>
          <Row className="justify-content-end">
            <Col xs="12">
              <Link
                to={`/training/${
                  !!model ? model.courseCategoryId : categoryId
                }`}
              >
                <Button color="secondary" className="mr-2">
                  Cancel
                </Button>
              </Link>
              <Button
                type="submit"
                color="primary"
                disabled={disableState}
                className={styles.btnSave}
              >
                {btnSaveLabel}
              </Button>
            </Col>
          </Row>
        </AvForm>
      </>
    )
  );
};

const mapStateToProps = ({ Courses, ConverterKitTags }) => {
  return {
    converterKitTags: ConverterKitTags,
    courses: Courses,
  };
};

export default connect(mapStateToProps, { getConverterKitTags })(SaveCourse);
