import React from "react";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";

import styles from './index.module.scss';

const dataLabels = [
  'Monthly',
  'Regular Annual',
  'Black Friday Annual',
];

const AnalyticsCard = ({
  title,
  total = 0,
  data = ['', '', '']
}) => (
  <Card className={styles.card}>
    <CardBody>
      <div className={classNames(styles.cardTitle, "font-size-16 font-weight-medium mb-4")}>
        {title}
      </div>

      <div className={classNames(styles.cardData, styles.total, "d-flex flex-between align-items-center p-3 border-radius-8px")}>
        <div className="font-size-15 px-1 font-weight-bold">All</div>
        <div className="font-size-21 px-1 font-weight-bold ml-auto">{total}</div>
      </div>

      {data.map((value, index) => (
        <div key={`${value}-${index}`} className={classNames(styles.cardData, "d-flex flex-between align-items-center p-3 border border-radius-8px")}>
          <div className={classNames(styles.cardDataLabel, "font-size-13 px-1 font-weight-medium")}>
            {dataLabels[index]}
          </div>
          <div className="font-size-17 px-1 font-weight-bold ml-auto">
            {value}
          </div>
        </div>
      ))}
    </CardBody>
  </Card>
);

export default AnalyticsCard;
