import React, { useMemo, useRef, useState } from "react";
import shortid from "shortid";
import { Tooltip, UncontrolledTooltip } from "reactstrap";

/* array of strings expected */
const CellWithTooltip = ({ children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const target = useMemo(() => "t" + shortid.generate(), []);
  const targetRef = useRef();

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <div id={target} className={"cursor-pointer"} ref={targetRef}>
        {children.join(", ")}
      </div>
      <Tooltip
        className={"custom-tooltip"}
        placement={"bottom"}
        boundariesElement={"window"}
        isOpen={tooltipOpen}
        target={target}
        toggle={toggle}
      >
        {children.map((item, index) => (
          <p key={"cell-item" + target + index}>{item}</p>
        ))}
      </Tooltip>
    </>
  );
};

export default CellWithTooltip;
