import formsValidators from "../validators/form.validators";

export const COMMENTS_STATES = {
  traning: "Traning",
  djHub: "DJ Hub",
};

export const COMMENTS_STATUS = {
  pending: "PENDING",
  published: "PUBLISHED",
};

export const COMMENTS_TYPES = {
  reply: "REPLY",
  original: "ORIGINAL",
};

export const COMMENTS_FILTERS = [
  {
    label: "Unread",
    value: 1,
  },
  {
    label: "Read",
    value: 2,
  },
  {
    label: "Published",
    value: 3,
  },
  {
    label: "Pending",
    value: 4,
  },
];

export const DEFAULT_COMMNET_FILTERS = [1];

export const APPROVE_MODAL_TITLE = "Publish";
export const APPROVE_MODAL_DESCRIPTION =
  "This comment will be published and visible for all users who have the course purchased";

export const DELETE_MODAL_TITLE = "Delete";
export const DELETE_MODAL_DESCRIPTION = "This comment will be deleted";

export const READ_ALL_COMMENTS_MODAL_TITLE = "Mark all as read";
export const getReadAllCommentsModalDescription = (isArticle = false) =>
  `All comments to ${
    isArticle ? "premium articles" : "lessons"
  } will be marked as read. Do you want to read them?`;

export const TEXT_EDITOR_MODAL_DATA = {
  titleText: "Reply",
  cancelButtonText: "Cancel",
  confirmButtonText: "Send",
  textEditorName: "comment",
  textEditorLabel: "Your reply",
  textEditorValidator: formsValidators.comment,
};

export const INDIVIDUAL_CONTENT_NAMES = {
  lesson: "lesson",
  article: "article",
};

export const COMMENTS_PRODUCT_TYPES = {
  lesson: "LESSON",
  article: "PREMIUM_ARTICLE",
};
