import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

import CreateCourseCouponModal from "../../../../../components/Modals/CreateCourseCouponModal";
import useModal from "../../../../../hooks/useModal";
import { createCoupon } from "../../../../../store/dashboard/Coupons/actions";

const CreateCouponButton = ({
  coupons,
  createCoupon,
  text = 'Create coupon',
}) => {
  const { loading, created } = coupons;
  const [modalVisible, toggleModal] = useModal();
  
  const handleCreateCouponClick = () => toggleModal(true);
  const handleCreateCouponConfirmed = (model) => createCoupon({ model });
  
  useEffect(() => {
    if (!loading && created) {
      toggleModal(false);
    }
  }, [loading, created]);
  
  return (
    <>
      <Button color="primary" onClick={handleCreateCouponClick}>
        {text}
      </Button>
      <CreateCourseCouponModal
        isOpen={modalVisible}
        onConfirm={handleCreateCouponConfirmed}
        onCancel={() => toggleModal(false)}
      />
    </>
  );
};

const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps, { createCoupon })(CreateCouponButton);
