import React from 'react'
import { Badge } from 'reactstrap';
import getSubscriptionStatus from '../../helpers/getSubscriptionStatus';

export default function SubscriptionStatusBadge(profile) {
    const statusValues = getSubscriptionStatus(profile.userSubscription);
    return (
        <Badge className={`badge-soft-${statusValues.color}  user-list--list__badge`}>
            {statusValues.label}
        </Badge>
    );
}