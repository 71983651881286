import {
  GET_LIST_PREMIUM_ARTICLES_SECTIONS,
  GET_LIST_PREMIUM_ARTICLES_SECTIONS_SUCCESS,
  GET_LIST_PREMIUM_ARTICLES_SECTIONS_ERROR,
} from "./actionTypes";

export const getPremiumArticlesSections = (payload) => {
  return {
    payload,
    type: GET_LIST_PREMIUM_ARTICLES_SECTIONS,
  };
};
export const getPremiumArticlesSectionsSuccess = ({ data }) => {
  return {
    payload: data,
    type: GET_LIST_PREMIUM_ARTICLES_SECTIONS_SUCCESS,
  };
};
export const getPremiumArticlesSectionsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_LIST_PREMIUM_ARTICLES_SECTIONS_ERROR,
  };
};
