import Http from "./HttpService";

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from "../constants/pagination";

class CourseCategoriesService extends Http {
  BASE_URL = "/admins/course-categories";

  getList(pagination = {}) {
    const { limit = DEFAULT_LIMIT, offset = DEFAULT_OFFSET } = pagination;

    return this.get(`${this.BASE_URL}`, {
      params: {
        limit,
        offset,
      },
    });
  }

  async getOne(id) {
    const { data } = await this.get(`${this.BASE_URL}/${id}`);
    if (!data.image) {
      return { data };
    }
    const initialFile = data.image;
    initialFile.preview = data.image.resizedImages
      ? data.image.resizedImages[Object.keys(data.image.resizedImages)[0]]
      : data.image.link;
    initialFile.size = null;

    return { data: { ...data, initialFile } };
  }

  create(model) {
    return this.post(`${this.BASE_URL}`, model);
  }

  addImageToCategory(id, model) {
    return this.post(`${this.BASE_URL}/${id}/image`, model);
  }

  update(id, model) {
    return this.put(`${this.BASE_URL}/${id}`, model);
  }

  remove(id) {
    return this.delete(`${this.BASE_URL}/${id}`);
  }
}

export default new CourseCategoriesService();
