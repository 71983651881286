import React, { useEffect, useMemo } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";

import { connect } from "react-redux";
import { withRouter, useLocation, useHistory, Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import classNames from "classnames";

import {
  validateRestorePasswordToken,
  validateRestorePasswordTokenClear,
  restorePassword,
} from "../../store/actions";
import useAvForm from "../../hooks/useAvForm";
import formsValidators from "../../validators/form.validators";
import queryParser from "../../helpers/queryParser";

import logo from "../../assets/images/logo-dark.svg";
import FormGroupText from "../../components/Forms/inputs/FormGroupText";

import styles from "./index.module.scss";

const initialState = {
  password: "",
  confirmPassword: "",
};

const RestorePassword = ({
  forgetPassword,
  validateRestorePasswordToken,
  validateRestorePasswordTokenClear,
  restorePassword,
}) => {
  const { isValidTokent, loading } = forgetPassword;

  const location = useLocation();
  const { token } = queryParser(location.search);
  const history = useHistory();

  useEffect(() => {
    if (token) {
      validateRestorePasswordToken({ token, history });
    } else {
      history.push("/login");
    }

    return () => {
      validateRestorePasswordTokenClear();
    };
  }, []);

  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();

  const disableState = useMemo(
    () => !isDirty || loading || hasFormError,
    [loading, hasFormError, isDirty]
  );

  const handleFormSubmit = (event, values) => {
    const { password } = values;
    const model = { password, token };
    restorePassword({ model, history });
  };

  return (
    <>
      {isValidTokent && (
        <>
          <div className={styles.wr}>
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                      <Row>
                        <Col className="col-7">
                          <div
                            className={classNames(
                              "text-primary",
                              styles.headerContent
                            )}
                          >
                            <h5 className="text-primary mb-3">
                              Create new password
                            </h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0 pb-0">
                      <div>
                        <div className="avatar-md profile-user-wid">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" width="47" height="16" />
                          </span>
                        </div>
                      </div>
                      <div className="p-2 pb-0">
                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={handleFormSubmit}
                          ref={formRef}
                          model={initialState}
                          onKeyDown={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              return false;
                            }
                          }}
                        >
                          <FormGroupText
                            vertical
                            className="mb-0"
                            labelClassName="pt-0"
                            name="password"
                            label="New password"
                            placeholder="Enter new password"
                            type="password"
                            validator={formsValidators.restorePassword.password}
                            handleFormChange={handleFormChange}
                            autoComplete="on"
                          />
                          <FormGroupText
                            vertical
                            className="mb-2"
                            labelClassName="pt-0"
                            name="confirmPassword"
                            label="Repeat new password"
                            placeholder="Repeat new password"
                            type="password"
                            validator={
                              formsValidators.restorePassword.confirmPassword
                            }
                            handleFormChange={handleFormChange}
                            autoComplete="on"
                          />
                          <div className="text-right mb-4">
                            <Button
                              type="submit"
                              color="primary"
                              className={styles.confirmBtn}
                              disabled={disableState}
                            >
                              Confirm
                            </Button>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                  <div
                    className={classNames(
                      "text-center",
                      styles.resetPasswordFooter
                    )}
                  >
                    <p className="d-flex flex-row justify-content-center mb-0">
                      <span className="mr-2">Remember it?</span>
                      <Link
                        to="login"
                        className="font-weight-medium text-primary"
                      >
                        Log in
                      </Link>
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

const mapStatetoProps = ({ ForgetPassword: forgetPassword }) => ({
  forgetPassword,
});

export default withRouter(
  connect(mapStatetoProps, {
    validateRestorePasswordToken,
    validateRestorePasswordTokenClear,
    restorePassword,
  })(RestorePassword)
);
