import {
  GET_LIST_COMMENTS,
  GET_LIST_COMMENTS_SUCCESS,
  GET_LIST_COMMENTS_ERROR,
  READ_COMMENT,
  READ_COMMENT_SUCCESS,
  READ_COMMENT_ERROR,
  READ_COMMENT_CLEAR,
  APPROVE_COMMENT,
  APPROVE_COMMENT_SUCCESS,
  APPROVE_COMMENT_ERROR,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR,
  DELETE_COMMENT_CLEAR,
  GET_UNREAD_COUNT,
  GET_UNREAD_COUNT_SUCCESS,
  GET_UNREAD_COUNT_ERROR,
  REPLY_TO_COMMENT,
  REPLY_TO_COMMENT_SUCCESS,
  REPLY_TO_COMMENT_ERROR,
  REPLY_TO_COMMENT_CLEAR,
  READ_ALL_COMMENTS,
  READ_ALL_COMMENTS_SUCCESS,
  READ_ALL_COMMENTS_ERROR,
  READ_ALL_COMMENTS_CLEAR,
  GET_LIST_COMMENTS_CLEAR,
} from "./actionTypes";
import { DEFAULT_OFFSET } from "../../../constants/pagination";

const initialState = {
  readCommentItem: null,
  isRemoved: false,
  isReply: false,
  unreadCount: 0,
  unreadCountLoading: false,
  isReadAllComments: false,
  data: [],
  pagination: { totalCount: 0, nextOffset: DEFAULT_OFFSET },
  error: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_COMMENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_LIST_COMMENTS_SUCCESS:
      state = {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        loading: false,
      };
      break;
    case GET_LIST_COMMENTS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case GET_LIST_COMMENTS_CLEAR:
      state = {
        ...state,
        data: [],
      };
      break;
    case READ_COMMENT:
      state = {
        ...state,
        loading: false,
      };
      break;
    case READ_COMMENT_SUCCESS:
      state = {
        ...state,
        readCommentItem: action.payload.data,
        loading: false,
      };
      break;
    case READ_COMMENT_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case READ_COMMENT_CLEAR:
      state = {
        ...state,
        readCommentItem: null,
      };
      break;
    case APPROVE_COMMENT:
      state = {
        ...state,
        loading: false,
      };
      break;
    case APPROVE_COMMENT_SUCCESS:
      state = {
        ...state,
        readCommentItem: action.payload.data,
        loading: false,
      };
      break;
    case APPROVE_COMMENT_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_COMMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_COMMENT_SUCCESS:
      state = {
        ...state,
        isRemoved: true,
        loading: false,
      };
      break;
    case DELETE_COMMENT_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case DELETE_COMMENT_CLEAR:
      state = {
        ...state,
        isRemoved: false,
        loading: false,
      };
      break;
    case GET_UNREAD_COUNT:
      state = {
        ...state,
        unreadCountLoading: true,
      };
      break;
    case GET_UNREAD_COUNT_SUCCESS:
      state = {
        ...state,
        unreadCount: action.payload.data.commentsUnreadCount,
        unreadCountLoading: false,
      };
      break;
    case GET_UNREAD_COUNT_ERROR:
      state = {
        ...state,
        unreadCountLoading: false,
      };
      break;
    case REPLY_TO_COMMENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case REPLY_TO_COMMENT_SUCCESS:
      state = {
        ...state,
        isReply: true,
        loading: false,
      };
      break;
    case REPLY_TO_COMMENT_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case REPLY_TO_COMMENT_CLEAR:
      state = {
        ...state,
        isReply: false,
        loading: false,
      };
      break;
    case READ_ALL_COMMENTS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case READ_ALL_COMMENTS_SUCCESS:
      state = {
        ...state,
        isReadAllComments: true,
        loading: false,
      };
      break;
    case READ_ALL_COMMENTS_ERROR:
      state = {
        ...state,
        loading: false,
      };
      break;
    case READ_ALL_COMMENTS_CLEAR:
      state = {
        ...state,
        isReadAllComments: false,
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};
