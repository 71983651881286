import React, { useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import SaveLesson from "../../../../components/Forms/SaveLesson";
import RouteLeavingGuard from "../../../../routes/middleware/RouteLeavingGuard";
import {
  clearLessonFetched,
  clearLessonsCount,
  getLesson,
  getLessonsCount,
  updateLesson,
} from "../../../../store/dashboard/Lessons/actions";
import ScrollbarVertical from "../../../../components/Common/ScrollbarVertical";
import ProgressWatcher from "../../../../components/Common/ProgressWatcher";
import SEO from "../../../../components/Common/SEO";

const PROGRESS_TEXT = "Files are uploading now. Please, don't quit this page";

const UpdateLesson = ({
  updateLesson,
  lessons,
  getLesson,
  clearLessonFetched,
  getLessonsCount,
  clearLessonsCount,
  filesError,
}) => {
  const { updated, loading, item, ordinalNumber } = lessons;
  const {
    params: { lessonId },
  } = useRouteMatch("/lessons/:lessonId");
  const [isDirty, updateDirty] = useState(false);
  const [ready, updateReadyState] = useState(false);
  const history = useHistory();
  const saveLessonCallback = useCallback(
    (model) => {
      updateLesson({ lessonId, model }, history, item.courseId);
    },
    [item]
  );
  const isBlocked = (updated ? false : isDirty) && !filesError;

  useEffect(() => {
    if (!ready && lessonId && !loading) {
      getLesson(lessonId);
      updateReadyState(true);
    }
  }, [lessonId, ready, loading]);

  useEffect(() => {
    return () => {
      clearLessonFetched();
    };
  }, []);

  useEffect(() => {
    if (item) {
      getLessonsCount({ courseId: item.courseId });
    }

    return () => {
      clearLessonsCount();
    };
  }, [item]);

  return (
    <>
      <SEO title="Edit lesson" />
      <RouteLeavingGuard
        contentText="Changed data will not be saved. Are you sure you want to cancel?"
        when={isBlocked}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={() => {
          return isBlocked;
        }}
      />
      <ProgressWatcher loading={loading} contentText={PROGRESS_TEXT} />
      <ScrollbarVertical>
        <Row className="page-content h-100">
          <Container fluid className="h-100">
            <Row>
              <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0 font-size-18 text-uppercase">
                    Edit lesson
                  </h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="pb-4">
                {item && ordinalNumber && (
                  <SaveLesson
                    model={item}
                    saveLessonFunc={saveLessonCallback}
                    updateDirtyState={updateDirty}
                    ordinalNumber={ordinalNumber}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </Row>
      </ScrollbarVertical>
    </>
  );
};

const mapStateToProps = ({ Lessons, Progress }) => {
  return { lessons: Lessons, filesError: Progress.error };
};

export default connect(mapStateToProps, {
  updateLesson,
  getLesson,
  clearLessonFetched,
  getLessonsCount,
  clearLessonsCount,
})(UpdateLesson);
