import classNames from 'classnames';
import React from 'react'
import { Spinner } from 'reactstrap';

export default function BlueSpinner({ color = 'info', withPadding = true, withMargin = true }) {
    return (
        <section className={
            classNames(
                "w-100 h-100 d-flex flex-column justify-content-center align-items-center",
                withPadding && 'py-5',
                withMargin && 'my-5',
            )}>
            <Spinner color={color} />
        </section>
    )
}