import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import PremiumArticlesSectionsService from "../../../services/PremiumArticlesSectionsService";
import {
  getPremiumArticlesSectionsSuccess,
  getPremiumArticlesSectionsError,
} from "./actions";
import { GET_LIST_PREMIUM_ARTICLES_SECTIONS } from "./actionTypes";

const getPremiumArticlesSectionsAsync = async () => {
  return await PremiumArticlesSectionsService.getList();
};

function* getPremiumArticlesSections() {
  try {
    const response = yield call(getPremiumArticlesSectionsAsync);
    yield put(getPremiumArticlesSectionsSuccess(response));
  } catch (error) {
    yield put(getPremiumArticlesSectionsError(error));
  }
}

export function* watchPremiumArticlesSectionsList() {
  yield takeEvery(GET_LIST_PREMIUM_ARTICLES_SECTIONS, getPremiumArticlesSections);
}

function* premiumArticlesSectionsSaga() {
  yield all([fork(watchPremiumArticlesSectionsList)]);
}

export default premiumArticlesSectionsSaga;
