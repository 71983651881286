import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import SEO from "../../../components/Common/SEO";
import { COMMENTS_STATES } from "../../../constants/comments";
import CommentsList from "./components/commentsList";

import styles from "./index.module.scss";

const Comments = () => {
  const [pageState, setPageState] = useState(COMMENTS_STATES.traning);

  return (
    <ScrollbarVertical>
      <SEO title="Comments management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-16">Comments management</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="d-flex flex-row">
                {Object.keys(COMMENTS_STATES).map((state, index) => (
                  <Button
                    key={`comments-state-${index}`}
                    className={styles.buttons}
                    color={
                      pageState === COMMENTS_STATES[state] ? "primary" : ""
                    }
                    onClick={() => setPageState(COMMENTS_STATES[state])}
                  >
                    {COMMENTS_STATES[state]}
                  </Button>
                ))}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <CommentsList
                isPremiumArticlesComments={
                  pageState === COMMENTS_STATES.djHub ? true : false
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollbarVertical>
  );
};

export default Comments;
