import React, { useMemo } from "react";
import { AvForm } from "availity-reactstrap-validation";
import { Button, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";

import FormGroupText from "./inputs/FormGroupText";
import FormGroupReactDatePicker from "./inputs/FormGroupReactDatePicker";
import formsValidators from "../../validators/form.validators";
import useAvForm from "../../hooks/useAvForm";

import styles from "./forms.module.scss";

const initialState = {
  body: "",
  discount: "",
  startDate: "",
  endDate: "",
};

const CreateCouponForm = ({ coupons, saveCouponFunc }) => {
  const { loading } = coupons;
  const [formRef, hasFormError, handleFormChange, isDirty] = useAvForm();
  const disableState = useMemo(() => !isDirty || loading || hasFormError, [
    loading,
    hasFormError,
    isDirty,
  ]);

  const handleFormSubmit = (event, values) => {
    saveCouponFunc(values);
  };

  return (
    <>
      <AvForm
        onValidSubmit={handleFormSubmit}
        ref={formRef}
        model={initialState}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            return false;
          }
        }}
      >
        <FormGroupText
          vertical
          className="mb-0"
          name="body"
          label="Coupon body"
          placeholder="Enter coupon body"
          validator={formsValidators.courseCoupon.body}
          handleFormChange={handleFormChange}
        />
        <FormGroupText
          vertical
          className="mb-0"
          name="discount"
          label="Discount, %"
          placeholder="Enter discount, %"
          validator={formsValidators.courseCoupon.discount}
          handleFormChange={handleFormChange}
        />
        <Row>
          <Col xs="6">
            <FormGroupReactDatePicker
              vertical
              className="mb-0"
              name="startDate"
              label="Start date"
              validator={formsValidators.courseCoupon.startDate}
              handleFormChange={handleFormChange}
            />
          </Col>
          <Col xs="6">
            <FormGroupReactDatePicker
              vertical
              className="mb-0"
              name="endDate"
              label="End date"
              validator={formsValidators.courseCoupon.endDate}
              handleFormChange={handleFormChange}
            />
          </Col>
        </Row>
        <Row className="justify-content-end mt-3">
          <Col xs="12">
            <Button
              type="submit"
              color="primary"
              disabled={disableState}
              className={classNames(styles.btnSave, "mw-100")}
            >
              Create
            </Button>
          </Col>
        </Row>
      </AvForm>
    </>
  );
};
const mapStateToProps = ({ Coupons: coupons }) => ({ coupons });

export default connect(mapStateToProps)(CreateCouponForm);
