import React from 'react';
import {
    Input,
    Label,
} from "reactstrap";

export default function Checkbox({
    value,
    onChange,
    checked,
    label,
    className,
    disabled,
}) {
    return (
        <div className={"custom-control custom-checkbox mt-3 " + className}>
            <Input
                type="checkbox"
                className="custom-control-input"
                id={value}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <Label className="custom-control-label ml-2" htmlFor={value}>
                {label}
            </Label>
        </div>
    )
}
