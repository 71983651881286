import Http from "./HttpService";

class PremiumArticlesSectionsService extends Http {
  BASE_URL = "/admins/premium-article-sections";

  getList() {
    return this.get(this.BASE_URL);
  }
}

export default new PremiumArticlesSectionsService();
