import classNames from "classnames";
import React from "react";

export default function SearchBar({
  handleEnterSearch,
  searchQuery,
  inputClassName,
  className,
  disabled = false,
}) {
  return (
    <div
      className={classNames(
        "position-relative list-search-bar mb-2",
        className
      )}
    >
      <input
        onChange={handleEnterSearch}
        value={searchQuery}
        className={inputClassName ? inputClassName : "list-search-bar--input"}
        placeholder="Search..."
        disabled={disabled}
      />
      <i className="bx bx-search-alt search-icon" />
    </div>
  );
}
