import React from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import SEO from "../../../components/Common/SEO";
import AnalyticsCard from "./components/Card";
import AnalyticsHeader from "./components/Header";
import BlueSpinner from "../../../components/Common/Spinner";

import styles from './index.module.scss';

const DJHubAnalytics = () => {
  const { isLoading, ...data } = useSelector(({ DJHubAnalytics }) => DJHubAnalytics);

  return (
    <div className="page-content">
      <SEO title="DJ Hub analytics" />
      <Container fluid>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-16">DJ Hub analytics</h4>
            </div>
          </Col>
        </Row>

        <AnalyticsHeader isLoading={isLoading} />

        <div className="position-relative">
          {isLoading && (
            <div className={styles.loading}>
              <BlueSpinner withMargin={false} withPadding={false} />
            </div>
          )}
          <Row>
            <Col>
              <AnalyticsCard
                title="Total subscribers"
                total={data.allTotalSubscribersCount}
                data={[
                  data.monthlyTotalSubscribersCount,
                  data.regularAnnualTotalSubscribersCount,
                  data.blackFridayAnnualTotalSubscribersCount,
                ]}
              />
            </Col>
            <Col>
              <AnalyticsCard
                title="New subscribers"
                total={data.allNewSubscribersCount}
                data={[
                  data.monthlyNewSubscribersCount,
                  data.regularAnnualNewSubscribersCount,
                  data.blackFridayAnnualNewSubscribersCount,
                ]}
              />
            </Col>
            <Col>
              <AnalyticsCard
                title="New unsubscribers"
                total={data.allNewUnsubscribersCount}
                data={[
                  data.monthlyNewUnsubscribersCount,
                  data.regularAnnualNewUnsubscribersCount,
                  data.blackFridayAnnualNewUnsubscribersCount,
                ]}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DJHubAnalytics;
