import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import ScrollbarVertical from "../../../components/Common/ScrollbarVertical";
import SEO from "../../../components/Common/SEO";
import PaymentDetailsModal from "../../../components/Modals/PaymentDetailsModal";
import Table from "../../../components/Table";
import { PAYMENTS_LIST_LIMIT } from "../../../constants/paginationConstants";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import {
  getPaymentsList,
  getPayment,
  getPaymentClear,
} from "../../../store/dashboard/Payments/actions";
import paymentsListStructure from "./tableStructure";

const Payments = ({
  payments,
  getPaymentsList,
  getPayment,
  getPaymentClear,
}) => {
  const { item, getPaymentLoading } = payments;
  const { data, pagination, loading, handleTableChange, query } = useTable(
    payments,
    getPaymentsList,
    PAYMENTS_LIST_LIMIT
  );

  const [modalVisible, toggleModal] = useModal();

  const getPaymentCallback = useCallback(
    (id) => {
      if (!getPaymentLoading && !!id) {
        getPayment(id);
      }
    },
    [getPaymentLoading]
  );

  useEffect(() => {
    !getPaymentLoading && toggleModal(!!item);
  }, [item, getPaymentLoading]);

  return (
    <ScrollbarVertical>
      <SEO title="Payments management" />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-16">Payments management</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Table
                needSearch
                dataStructure={paymentsListStructure}
                data={data}
                handleTableChange={handleTableChange}
                totalCount={pagination.totalCount || 0}
                limit={PAYMENTS_LIST_LIMIT}
                offset={query.offset || 0}
                loading={loading}
                rowClickFunc={getPaymentCallback}
                placeholderText="There are no payments yet"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <PaymentDetailsModal isOpen={modalVisible} onCancel={getPaymentClear} />
    </ScrollbarVertical>
  );
};

const mapStateToProps = ({ Payments: payments }) => ({ payments });

export default connect(mapStateToProps, {
  getPaymentsList,
  getPayment,
  getPaymentClear,
})(Payments);
