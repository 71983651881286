import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// Uploading progress
import Progress from "./progress/reducer";

// Dashboard
import Training from "./dashboard/Training/reducer";
import Courses from "./dashboard/Courses/reducer";
import ConverterKitTags from "./dashboard/ConverterKitTags/reducer";
import Lessons from "./dashboard/Lessons/reducer";
import PremiumArticles from "./dashboard/PremiumArticles/reducer";
import PremiumArticlesSections from "./dashboard/PremiumArticlesSections/reducer";
import PremiumArticlesTags from "./dashboard/PremiumArticlesTags/reducer";
import PremiumArticlesCategories from "./dashboard/PremiumArticlesCategories/reducer";
import Users from "./dashboard/Users/reducer";
import Comments from "./dashboard/Comments/reducer";
import Coupons from "./dashboard/Coupons/reducer";
import Sale from "./dashboard/Sale/reducer";
import Admins from "./dashboard/Admins/reducer";
import Payments from "./dashboard/Payments/reducer";
import Settings from "./dashboard/Settings/reducer";
import Notifications from "./dashboard/Notifications/reducer";
import DJHubAnalytics from "./dashboard/DjHubAnal/reducer";
import Bundles from "./dashboard/Bundles/reducer";
import Rewards from "./dashboard/Rewards/reducer";
import ProductActivationCodes from './dashboard/ProductActivationCodes/reducer';
import SubscriptionPlans from "./dashboard/SubscriptionPlans/reducer";



const rootReducer = combineReducers({
  // public
  Layout,
  Progress,
  Login,
  ForgetPassword,
  Profile,
  Training,
  Courses,
  ConverterKitTags,
  Lessons,
  PremiumArticles,
  PremiumArticlesSections,
  PremiumArticlesTags,
  PremiumArticlesCategories,
  Users,
  Comments,
  Coupons,
  Sale,
  Admins,
  Payments,
  Settings,
  Notifications,
  DJHubAnalytics,
  Bundles,
  Rewards,
  ProductActivationCodes,
  SubscriptionPlans,
});

export default rootReducer;
